























































































































import Vue from 'vue';
import { BootstrapVue, BModal } from 'bootstrap-vue';
import { Component, Prop } from 'vue-property-decorator';
import { IModelSummary } from '@/view-models/model-summary';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import store from '@/store';
import { FlowElementModel } from '@/view-models/flow-element-models';
import { Mode } from '@/view-models/mode';

Vue.use(BootstrapVue);

@Component({
  name: 'EditorToolbarNav'
})
export default class EditorToolbarNav extends Vue {
  @Prop({ required: false })
  private selectedModel: IModelSummary;

  private isCompleteModel: boolean = false;
  private allElementNameUnique: boolean = false;
  private exactlyOneAirInlet: boolean = false;
  private allRequiredFieldsPopulated: boolean = true;
  private allOutletsEndInBurners: boolean = false;
  private allAssetBurnersPlaced: boolean = false;
  private burnerSetupError: boolean = false;
  private changesSaved: boolean = false;
  private previewMode: boolean = false;

  private mounted(): void {
    EventBus.$on(hmbEvents.confirmLeaveEditor, () => {
      this.$router.push('/');
    });
    EventBus.$on(hmbEvents.burnerSetupError, () => {
      this.burnerSetupError = true;
    });
    EventBus.$on(hmbEvents.checkForCompleteModel, () => {
      this.checkIfModelComplete();
    });
    EventBus.$on(hmbEvents.previewModeChanged, (previewMode: Mode) => {
      this.previewMode = previewMode !== Mode.EDIT;
    });
    EventBus.$on(hmbEvents.confirmEditModel, () => {
      this.$bvModal.show('edit-model-modal');
    });
  }

  private beforeDestroy(): void {
    // Remove event listeners
    EventBus.$off(hmbEvents.burnerSetupError);
    EventBus.$off(hmbEvents.checkForCompleteModel);
    EventBus.$off(hmbEvents.confirmEditModel);
    EventBus.$off(hmbEvents.confirmLeaveEditor);
  }

  private findModalPosition(): void {
    const infoIconRef = this.$refs.infoIconRef as Element;
    const left = infoIconRef.getBoundingClientRect().left;
    const top = infoIconRef.getBoundingClientRect().top;
    const modalWindow: HTMLElement = document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot?.querySelector('#show-info-modal___BV_modal_content_');
    modalWindow.style.position = 'fixed';
    modalWindow.style.left = left + 25 + 'px';
    modalWindow.style.top = top + 20 + 'px';
  }

  private toggleInfoModal(): void {
    (this.$refs['show-info-modal'] as BModal)?.toggle();
  }

  private formattedSummaryBy(summary: IModelSummary): string {
    if (summary) {
      return summary.updateBy ? summary.updateBy : summary.createBy;
    }
    return '';
  }

  private async checkIfModelComplete(): Promise<void> {
    // re-init default values
    this.isCompleteModel = false;
    this.allElementNameUnique = false;
    this.exactlyOneAirInlet = false;
    this.allRequiredFieldsPopulated = true;
    this.allOutletsEndInBurners = false;
    this.allAssetBurnersPlaced = false;
    this.changesSaved = false;

    const diagramElements = store.getters['diagram/getDiagramElements'] as FlowElementModel[];

    if (diagramElements.length) {
      // check for duplicated element name
      const elementNames = diagramElements.map((element) => element.elementModelName);
      this.allElementNameUnique =
        elementNames &&
        elementNames.length > 0 &&
        !elementNames.some((name) => {
          return elementNames.indexOf(name) !== elementNames.lastIndexOf(name);
        });

      // check for more than 1 air inlet:
      this.exactlyOneAirInlet = diagramElements.filter((element) => !element.flowElementId.parentId).length === 1;

      // check for any unpopulated elements:
      this.allRequiredFieldsPopulated = diagramElements.every((element) =>
        store.getters['diagram/isElementPopulated'](element)
      );

      // check for all outlets are terminated with burners:
      this.allOutletsEndInBurners = !store.getters['diagram/getOutletNotConnected'];

      // check for all burners used:
      const allBurners = store.getters['burner/allBurners'];
      const elementBurners = store.getters['diagram/getBurnerElements'];
      if (allBurners && elementBurners) {
        this.allAssetBurnersPlaced = allBurners.length === elementBurners.length;
      } else {
        this.allAssetBurnersPlaced = !allBurners && (!elementBurners || elementBurners.length === 0) ? true : false;
      }

      // check if model and all current changes are saved (not dirty grid and not brand new)
    } else {
      this.exactlyOneAirInlet = false;
      this.allElementNameUnique = false;
      this.allRequiredFieldsPopulated = false;
      this.allOutletsEndInBurners = false;
      this.allAssetBurnersPlaced = false;
    }
    this.changesSaved = !store.getters['diagram/getDiagramModified'];

    if (
      this.exactlyOneAirInlet &&
      this.allRequiredFieldsPopulated &&
      this.allElementNameUnique &&
      this.allOutletsEndInBurners &&
      this.allAssetBurnersPlaced &&
      this.changesSaved &&
      !this.burnerSetupError
    ) {
      this.isCompleteModel = true;
    }

    const currentModelSummary = store.getters['diagram/getCurrentModelSummary'];
    if (currentModelSummary !== null && currentModelSummary.modelComplete !== undefined) {
      currentModelSummary.modelComplete = this.isCompleteModel;
    } else {
      return;
    }

    // incrementing manual version breaks auto save when we check for complete
    if (currentModelSummary.autoSaveVersion > currentModelSummary.manualSaveVersion) {
      currentModelSummary.modelComplete = false;
    }

    await store
      .dispatch('model/saveModelSummaryItem', {
        summaryItem: currentModelSummary,
        overwriteDuplicateModel: false,
        saveCompleteCheckOnly: true
      })
      .then((response) => {
        if (response?.name?.length > 20) {
          response.truncatedName = response?.name.substring(0, 19) + '...';
        } else {
          response.truncatedName = response?.name;
        }
        store.commit('diagram/setCurrentModelSummary', response);
      });
  }

  private showStatusModal(): void {
    this.checkIfModelComplete();
    this.$bvModal.show('completed-status-modal');
  }

  private tryEditModel(): void {
    const diagramModified = store.getters['diagram/getDiagramModified'];

    if (diagramModified) {
      EventBus.$emit(hmbEvents.showSaveModelPromptModal, 'editModel');
    } else {
      this.$bvModal.show('edit-model-modal');
    }
  }
}
