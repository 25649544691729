export enum DirectionFlowType {
  ELEMENT = 0,
  INLET = 1,
  OUTLET = 2,
}

export class DirectionFlowModel {
  public id: string = null;
  public degree: number = 0;
  public type: DirectionFlowType = DirectionFlowType.ELEMENT;
  public xAxis: number = 0;
  public yAxis: number = 0;
  public hFlip?: boolean = false;
}
