// string utilities
export default class StringUtil {
  // determine str is null, undefined, or empty
  public static empty(str: string): boolean {
    return (!str || 0 === str.length);
  }

  // compare two strings for equality - null, undefined, and empty strings are euqal.
  public static equal(str1: string, str2: string): boolean {
    if (this.empty(str1) && this.empty(str2)) {
      return true;
    }
    return str1 === str2;
  }
}
