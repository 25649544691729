import { IModelSummary } from '@/view-models/model-summary';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '@/store';
import { HydraulicModelBuilderService } from '@/services/hydraulic-model-builder-service';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '../../services/config';
import { IAsset } from '../../view-models/assets-view-models';

export type IModelContext = ActionContext<IModelStoreState, IRootState>;

export interface IModelStoreState {
  filterValue: string;
  models: IModelSummary[];
  selectedModel: IModelSummary;
  sortedAtoZ: boolean;
  showGridView: boolean;
}

export interface IModelStoreGetters extends GetterTree<IModelStoreState, IRootState> {
  loadedModels(state: IModelStoreState): IModelSummary[];
  filteredModels(state: IModelStoreState): IModelSummary[];
  selectedModel(state: IModelStoreState): IModelSummary;
  showGridView(state: IModelStoreState): boolean;
}

export interface IModelStoreMutations extends MutationTree<IModelStoreState> {
  initializeModel(state: IModelStoreState): void;
  toggleGridView(state: IModelStoreState): boolean;
  updateFilterText(state: IModelStoreState, filterValue: string): void;
  sortModels(state: IModelStoreState): void;
  selectModel(state: IModelStoreState, selected: IModelSummary): void;
  setModels(state: IModelStoreState, models: IModelSummary[]): void;
}

export interface IModelStoreActions extends ActionTree<IModelStoreState, IRootState> {
  loadModelSummaryList(context: IModelContext, customerKey: string): Promise<void>;
  loadModelSummaryListByAsset(context: IModelContext): Promise<void>;
  getSummaryItem(context: IModelContext, summaryItem: IModelSummary): Promise<IModelSummary>;
  saveModelSummaryItem(
    context: IModelContext,
    params: { summaryItem: IModelSummary; overwriteDuplicateModel: boolean; saveCompleteCheckOnly: boolean }
  ): Promise<IModelSummary>;
  getPermalinkSummaryItem(
    context: IModelContext,
    params: {customerKey: string, assetKey: string, modelName: string}
  ): Promise<IModelSummary>;
  deleteModelSummaryItem(
    context: IModelContext,
    params: { summaryItem: IModelSummary }
  ): Promise<IModelSummary>;
  hideModelSummaryItem(
    context: IModelContext,
    params: { summaryItem: IModelSummary; hide: boolean }
  ): Promise<IModelSummary>;
}

export const ModelStore = {
  namespaced: true as true,
  state: {
    filterValue: '',
    models: [],
    selectedModel: undefined,
    sortedAtoZ: false,
    showGridView: false,
  } as IModelStoreState,
  getters: {
    loadedModels(state: IModelStoreState): IModelSummary[] {
      const selectedAsset: IAsset = store.getters['asset/selectedAsset'];
      if (selectedAsset) {
        return state.models.filter(
          (modelSummary: IModelSummary) =>
            modelSummary.asset.key === selectedAsset.key && !modelSummary.markedForDelete
        );
      }
      return [];
    },
    filteredModels(state: IModelStoreState): IModelSummary[] {
      if (state.filterValue === '') {
        return state.models;
      } else {
        return state.models.filter((el) => {
          return el.name.toLowerCase().includes(state.filterValue.toLowerCase());
        });
      }
    },
    selectedModel(state: IModelStoreState): IModelSummary {
      return state?.selectedModel;
    },
    showGridView(state: IModelStoreState): boolean {
      return state.showGridView;
    },
  } as IModelStoreGetters,
  mutations: {
    initializeModel(state: IModelStoreState): void {
      state.models = [];
    },
    toggleGridView(state: IModelStoreState): boolean {
      state.showGridView = !state.showGridView;
      return state.showGridView;
    },
    updateFilterText(state: IModelStoreState, filterValue: string): void {
      state.filterValue = filterValue;
    },
    sortModels(state: IModelStoreState): void {
      state.models.sort((a, b) => {
        const siteA = a.location.name?.toUpperCase();
        const siteB = b.location.name?.toUpperCase();

        const assetA = a.asset.name?.toUpperCase();
        const assetB = b.asset.name?.toUpperCase();

        const above = state.sortedAtoZ ? 1 : -1;
        const below = state.sortedAtoZ ? -1 : 1;

        // Sort by site name, then by asset name within the site
        if (siteA < siteB) {
          return above;
        } else if (siteA > siteB) {
          return below;
        }

        if (assetA < assetB) {
          return above;
        } else if (assetA > assetB) {
          return below;
        } else {
          return 0;
        }
      });
      state.sortedAtoZ = !state.sortedAtoZ;
    },
    selectModel(state: IModelStoreState, selected: IModelSummary): void {
      state.selectedModel = selected;
    },
    setModels(state: IModelStoreState, models: IModelSummary[]): void {
      state.models = models;
    },
  } as IModelStoreMutations,
  actions: {
    async loadModelSummaryList(context: IModelContext, customerKey: string): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      hmbService
        .loadModelSummaryList(customerKey)
        .then((response) => {
          response.forEach((element) => {
            element.isExpanded = false;
          });
          context.state.models = response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Summary\n',
            handleError: true,
            routeHomeAfterError: false,
          });
        });
    },
    async loadModelSummaryListByAsset(context: IModelContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      const customers: IAsset[] = store.getters['asset/allCustomers'];
      let selectedCustomer: IAsset = null;
      if (customers && customers.length > 0) {
        selectedCustomer = customers[0];
      }
      const selectedAsset: IAsset = store.getters['asset/selectedAsset'];
      if (selectedCustomer && selectedAsset) {
        try {
          const response: IModelSummary[] = await hmbService.loadModelSummaryListByAsset(
            selectedCustomer?.key,
            selectedAsset.key
          );
          context.commit('setModels', response);
        } catch (ex) {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Summary By Assets\n',
            handleError: true,
            routeHomeAfterError: false,
          });
        }
      }
    },
    async getSummaryItem(context: IModelContext, summaryItem: IModelSummary): Promise<IModelSummary> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return hmbService
        .getModelSummary(summaryItem.customer.key, summaryItem.asset.key, summaryItem.name )
        .then((response) => {
          if (response) {
            if (response.name.length > 20) {
              response.truncatedName = response.name.substring(0, 19) + '...';
            } else {
              response.truncatedName = response.name;
            }
          }
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Getting Summary\n',
            handleError: true,
            routeHomeAfterError: false,
          });
          return Promise.reject(ex);
        });
    },
    async saveModelSummaryItem(
      context: IModelContext,
      params: { summaryItem: IModelSummary; overwriteDuplicateModel: boolean; saveCompleteCheckOnly: boolean }
    ): Promise<IModelSummary> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return hmbService
        .saveModelSummaryItem(params.summaryItem, params.overwriteDuplicateModel, params.saveCompleteCheckOnly)
        .then((response) => {
          context.state.selectedModel = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error During Auto Save\n',
            handleError: true,
            routeHomeAfterError: false,
          });
          return Promise.reject(ex);
        });
    },
    async getPermalinkSummaryItem(
      context: IModelContext,
      params: {customerKey: string, assetKey: string, modelName: string}
    ): Promise<IModelSummary> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return hmbService
        .getModelSummary(params.customerKey, params.assetKey, params.modelName )
        .then((response) => {
          if (response) {
            if (response.name.length > 20) {
              response.truncatedName = response.name.substring(0, 19) + '...';
            } else {
              response.truncatedName = response.name;
            }
          }
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Getting Summary\n',
            handleError: true,
            routeHomeAfterError: false,
          });
          return Promise.reject(ex);
        });
    },
    async deleteModelSummaryItem(
      context: IModelContext,
      params: { summaryItem: IModelSummary }
    ): Promise<IModelSummary> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return hmbService
        .deleteModelSummaryItem(params.summaryItem)
        .then((response) => {
          context.state.selectedModel = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error During Deletion\n',
            handleError: true,
            routeHomeAfterError: false,
          });
          return Promise.reject(ex);
        });
    },
    async hideModelSummaryItem(
      context: IModelContext,
      params: { summaryItem: IModelSummary; hide: boolean }
    ): Promise<IModelSummary> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicModelBuilderService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return hmbService
        .hideOrUnhideModelSummaryItem(params.summaryItem, params.hide)
        .then((response) => {
          context.state.selectedModel = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error During Deletion\n',
            handleError: true,
            routeHomeAfterError: false,
          });
          return Promise.reject(ex);
        });
    },
  } as IModelStoreActions,
};
