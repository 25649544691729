

















import { Vue, Component } from 'vue-property-decorator';
import store from '../../store';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

@Component({
  name: 'error'
})
export default class Error extends Vue {
  private store = store;

  get error(): any {
    return store.getters['error/getError'];
  }

  private onClose(): void {
    // Handle Error
    if (store.getters['error/getRouteHomeAfterError']) {
      this.$router.push('/');
    }
    // Clear Error
    store.commit('error/clearError');
  }
}
