import { getAuthToken } from './auth';

const authInterceptor = async (config: any): Promise<any> => {
  const authorization = 'Authorization';
  const token = await getAuthToken();
  config.headers[authorization] = `Bearer ${token}`;
  return config;
};

export default authInterceptor;
