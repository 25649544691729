import { ILocation } from './location-model';
import { ICustomer } from './customer-model';
import { IAsset } from './asset-model';
import { SolverType, ISolver } from '@/view-models/solver-model';

export enum TuningStatusType {
  INITIAL = 0,
  IN_PROGRESS = 1,
  RESULTS_PARSING = 2,
  COMPLETE = 3,
  ERROR = 4
}

export interface ITuningHistoryModel {
  key?: string;
  customer: ICustomer;
  location: ILocation;
  asset: IAsset;
  modelKey: string;
  modelName: string;
  truncatedName?: string;
  trainingDataKeys?: string[];
  tuningStatus?: TuningStatusType;
  tuningStartDateTime?: string;
  tuningCompletionDateTime?: string;
  tuningResultValue?: number;
  currentlyPublished?: boolean;
  published?: boolean;
  publishBy?: string;
  publishDateTime?: string;
  hidden?: boolean;
  markedForDelete?: boolean;
  createBy?: string;
  createDateTime?: string;
  updateBy?: string;
  updateDateTime?: string;
  solver?: SolverType | ISolver;
  efficiencyEstimate?: string;
  engineerNotes?: string;
}
