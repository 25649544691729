import { FlowElementModel } from '@/view-models/flow-element-models';
import { IModelSummary } from '@/view-models/model-summary';
import { DirectionFlowType, DirectionFlowModel } from '@/view-models/direction-flow-model';
import { HydraulicModelPreviewElement } from '@/view-models/hydraulic-model-preview-element';
import { IDiagramStoreState } from './diagramStore';

// Helper class for DiagramStore
export default class DiagramStoreHelper {
  public static outletNotConnected(e: FlowElementModel): boolean {
    if (!e) {
      return false;
    }

    const numOutlets: number = this.numberOfOutlets(e);
    const numChildren: number = this.numberOfChildElement(e);
    return numOutlets > numChildren;
  }

  public static constructDiagramPreviewHierarchy(state: IDiagramStoreState): HydraulicModelPreviewElement[] {
    const diagramHierarchy: HydraulicModelPreviewElement[] = [];
    const diagramElementMap = new Map<string, FlowElementModel>();
    const roots: string[] = [];
    for (const elem of state.diagramElements) {
      if (elem.flowElementId) {
        diagramElementMap.set(elem.flowElementId.id, elem);
        if (!elem.flowElementId.parentId) {
          roots.push(elem.flowElementId.id);
        }
      }
    }
    for (const root of roots) {
      const elem = diagramElementMap.get(root);
      if (elem && elem.flowElementId) {
        const rootElem = {
          id: elem?.flowElementId?.id,
          name: elem?.name,
          type: elem?.type,
          childElements: [],
          elementModelName: elem.elementModelName ? elem.elementModelName : '',
        };
        diagramHierarchy.push(DiagramStoreHelper.hydrateHierarchy(diagramElementMap, rootElem));
      }
    }
    return diagramHierarchy;
  }

  public static hydrateHierarchy(
    elementMap: Map<string, FlowElementModel>,
    root: HydraulicModelPreviewElement
  ): HydraulicModelPreviewElement {
    const childIdList = elementMap.get(root.id)?.flowElementId?.childIdList;
    if (childIdList) {
      childIdList.sort((i1, i2) => {
        if (i1 && i2 && i1.childId && i2.childId) {
          const e1 = elementMap.get(i1.childId);
          const e2 = elementMap.get(i2.childId);
          return e1 && e2 ? e2.type - e1.type : 0;
        }
        return 0;
      });
      for (const childId of childIdList) {
        const id = childId.childId;
        if (id) {
          const childElement = elementMap.get(id);
          if (childElement && childElement.flowElementId) {
            const elementModelName = childElement.elementModelName ? childElement.elementModelName : '';
            const previewElement = {
              id: childElement.flowElementId.id,
              name: childElement.name,
              type: childElement.type,
              childElements: [],
              elementModelName,
            };
            root.childElements?.push(previewElement);
            DiagramStoreHelper.hydrateHierarchy(elementMap, previewElement);
          }
        }
      }
    }
    return root;
  }

  public static evaluateDiagramModified(diagramModified: boolean, modelSummary: IModelSummary): boolean {
    if (diagramModified) {
      return true;
    }

    if (modelSummary && modelSummary.autoSaveVersion && modelSummary.manualSaveVersion) {
      return modelSummary.autoSaveVersion > modelSummary.manualSaveVersion;
    }
    return false;
  }

  public static hasEmptyRequiredAttribute(flowElement: FlowElementModel): boolean {
    const flowElementModels: FlowElementModel[] = [flowElement];
    while (flowElementModels.length) {
      const currentFlowElement = flowElementModels.pop();
      flowElementModels.push(...(currentFlowElement?.subElementList ?? []));
      if (currentFlowElement?.elementAttributeList) {
        for (const a of currentFlowElement.elementAttributeList) {
          if (a.required === true && (!a.value || a.value.replace(/\s/g, '').length === 0)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private static numberOfOutlets(e: FlowElementModel): number {
    let num = 0;
    if (e.directionFlowList) {
      num = e.directionFlowList.filter((d: DirectionFlowModel) => d.type === DirectionFlowType.OUTLET).length;
    }
    if (!e.subElementList) {
      return num;
    }
    e.subElementList.forEach((s: FlowElementModel) => {
      num = num + this.numberOfOutlets(s);
    });
    return num;
  }

  private static numberOfChildElement(e: FlowElementModel): number {
    if (!e.flowElementId) {
      return 0;
    }
    if (!e.flowElementId.childIdList) {
      return 0;
    }
    return e.flowElementId.childIdList.length;
  }
}
