import { IGridConfig } from '../../view-models/grid-view-models';

export const gridConfig: IGridConfig = {
  defaultGridBoxSize: 37.4,
  gridLineColor: '#5B6375', // NOTE: gridline color is hardcoded until color appears in Invision theme. DBB - 3/20/20
  gridLineName: 'gridline',
  zoomFactor: 0.1,
  minZoomMagnification: 0.05,
  maxZoomMagnification: 2,
  movementFactor: 2,
  idelchikShadowName: 'idelchikShadow',
  burnerShadowName: 'burnerShadow',
  shadowColor: '#20222A',
  shadowBorderColor: '#84B7F4',
  burnerLineColor: '#979797',
  burnerFont: 'arial',
  burnerFontWeight: 'normal',
  burnerFontColor: '#DADADA',
  burnerGroupName: 'burnerGroup',
  burnerLineBorderWidth: 1,
  highlightName: 'highlight Name',
  highlightStrokeColor: '#84B7F4',
  baseStrokeColor: '#DADADA',
  invalidFieldsStrokeColor: '#FFEA06',
  debounceTime: 25,
  angleDiffThreshold: 100,
  enabledAttributes: {
    selectable: true,
    lockMovementX: false,
    lockMovementY: false
  },
  disabledAttributes: {
    selectable: false,
    lockMovementX: true,
    lockMovementY: true
  }
};
