



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import EventBus, { hmbEvents, navigateTypes } from '@/components/hydraulicModelTuner/eventBus';

Vue.use(BootstrapVue);

@Component({
  name: 'confirm-discard-note-updates-modal',
})
export default class ConfirmDiscardNoteUpdatesModal extends Vue {
  @Prop({ required: true })
  public navigateContext: string;

  private discardNoteUpdates(): void {
    this.$bvModal.hide('confirmDiscardNoteUpdates');

    switch (this.navigateContext) {
      case navigateTypes.goBack:
        EventBus.$emit(hmbEvents.navigateGoBack);
        break;
      case navigateTypes.home:
        EventBus.$emit(hmbEvents.navigateHome);
        break;
      case navigateTypes.trainingData:
        EventBus.$emit(hmbEvents.navigateTrainingData);
        break;
      case navigateTypes.newModelTuning:
        EventBus.$emit(hmbEvents.navigateNewModelTuning);
        break;
      case navigateTypes.retrainModel:
        EventBus.$emit(hmbEvents.retrainModel);
        break;
      case navigateTypes.toggleResultDetails:
        EventBus.$emit(hmbEvents.toggleResultDetails);
        break;
      default:
        break;
    }
  }
}
