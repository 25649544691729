



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import store from '@/store/';
import { gridConfig } from '@/assets/configs/gridConfig';

@Component({
  name: 'zoom-button'
})
export default class ZoomButton extends Vue {
  private get canvas(): fabric.Canvas {
    return store.getters['grid/editorGrid'] as fabric.Canvas;
  }

  private get noCanvasObjectsExist(): boolean {
    return this.canvas?.getObjects()?.length === 0;
  }

  private get isZoomInDisabled(): boolean {
    return this.canvas.getZoom() >= gridConfig.maxZoomMagnification || this.noCanvasObjectsExist;
  }

  private get isZoomOutDisabled(): boolean {
    return this.canvas.getZoom() <= gridConfig.minZoomMagnification || this.noCanvasObjectsExist;
  }

  public zoomIn(): void {
    store.dispatch('grid/zoomIn');
  }

  public zoomOut(): void {
    store.dispatch('grid/zoomOut');
  }

  public zoomToFit(): void {
    store.dispatch('grid/zoomFit');
  }
}
