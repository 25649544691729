import { fabric } from 'fabric';
import { gridConfig } from '@/assets/configs/gridConfig';
import { IGridContext } from '../gridStoreInterface';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';

const TRI_WIDTH = 16;
const TRI_HEIGHT = 8;

/**
 * Custom line object that allows us to send
 * the necessary data we need to the dynamic
 * airflow components
 */
const CustomLine = fabric.util.createClass(fabric.Line, {
  type: 'customLine',
  fromLineObjectName: fabric.Object,
  toLineObjectName: fabric.Object,
  fromArrowName: fabric.Object,
  toArrowName: fabric.Object,
  lineArrowName: fabric.Object,
  x1: Number,
  x2: Number,
  y1: Number,
  y2: Number,
  initialize(points?: [], options?: any): void {
    options = options || {};
    this.callSuper('initialize', points, options);
  },
  toObject(): any {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      fromLineObjectName: this.get('fromLineObjectName'),
      toLineObjectName: this.get('toLineObjectName'),
      fromArrowName: this.get('fromArrowName'),
      toArrowName: this.get('toArrowName'),
      lineArrowName: this.get('lineArrowName'),
      selectable: false, // Arrows are not selectable
      hasControls: false,
      name: this.get('name')
    });
  }
});

(fabric as any).CustomLine = CustomLine;

CustomLine.fromObject = (object: any, callback: any) => {
  let enlivenedObjectsParam;
  fabric.util.enlivenObjects(
    object.objects,
    (enlivenedObjects: any) => (enlivenedObjectsParam = enlivenedObjects),
    'util'
  );
  const newObject = new CustomLine(enlivenedObjectsParam, object) as fabric.Line;
  newObject.x1 = object.x1;
  newObject.x2 = object.x2;
  newObject.y1 = object.y1;
  newObject.y2 = object.y2;
  newObject.width = object.width;
  newObject.height = object.height;
  newObject.strokeWidth = object.strokeWidth;
  callback(newObject);
};

/**
 * This function calculates the necessary angle for the arrow on the line,
 * depending on the x,y locations
 * @param fromPoint line start
 * @param toPoint line end
 */
function calcArrowAngle(fromPoint: fabric.Point, toPoint: fabric.Point): number {
  const x = toPoint.x - fromPoint.x;
  const y = toPoint.y - fromPoint.y;
  let angle = Math.atan(y / x);
  if (x < 0) {
    angle += Math.PI;
  }
  return (angle * 180) / Math.PI + 90;
}

/**
 * This function takes in the from and to objects / arrow objects
 * and renders a line and arrow to dynamically show airflow.
 * @param gridContext the context of the canvas
 * @param fromLineObject the object the arrow is coming from
 * @param toLineObject the object the arrow is going to
 * @param outArrow the original out arrow from the object group
 * @param inArrow the original in arrow from the object group
 */
export function addChildLine(
  gridContext: IGridContext,
  fromLineObject: fabric.Object,
  toLineObject: fabric.Object,
  outArrow: fabric.Object,
  inArrow: fabric.Object
): any {
  const canvas = gridContext.state.editorGrid;
  // add the line
  const fromPoint = getCoordinates(outArrow);
  const toPoint = getCoordinates(inArrow);

  const fromLineString = `from-${fromLineObject.name}`;
  const toLineString = `to-${toLineObject.name}`;
  const line = new CustomLine([fromPoint.x - 10, fromPoint.y, toPoint.x + 10, toPoint.y], {
    fill: gridConfig.baseStrokeColor,
    stroke: gridConfig.baseStrokeColor,
    originX: 'center',
    originY: 'center',
    strokeWidth: 5,
    selectable: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    name: `${fromLineString}-${toLineString}-line`,
    top: fromPoint.y,
    left: fromPoint.x
  });
  line.fromLineObjectName = fromLineObject.name;
  line.toLineObjectName = toLineObject.name;
  line.fromArrowName = outArrow.name;
  line.toArrowName = inArrow.name;
  line.sendToBack();
  line.setCoords();

  const lineArrow = new fabric.Triangle({
    left: line.x2,
    top: line.y2,
    angle: calcArrowAngle(new fabric.Point(line.x1, line.y1), new fabric.Point(line.x2, line.y2)),
    hasBorders: false,
    hasControls: false,
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    originX: 'center',
    originY: 'center',
    width: TRI_WIDTH,
    height: TRI_HEIGHT,
    fill: gridConfig.baseStrokeColor,
    selectable: false,
    name: `${line.name}-arrow`
  });
  lineArrow.setCoords();
  line.lineArrowName = lineArrow.name;

  line.set('visible', false);
  lineArrow.set('visible', false);
  canvas.add(line, lineArrow);
  // Add to lineArrows
  gridContext.state.lineArrows.set(lineArrow.name ?? '', lineArrow);
  // Add to fromLines
  const fromLines = gridContext.state.fromLines.get(fromLineString) ?? [];
  fromLines.push(line);
  gridContext.state.fromLines.set(fromLineString, fromLines);
  // Add to toLines
  const toLines = gridContext.state.toLines.get(toLineString) ?? [];
  toLines.push(line);
  gridContext.state.toLines.set(toLineString, toLines);
  return line;
}

/**
 * updates the position of the dynamic airflow line and arrow
 * to match the objects that they are supposed to be pointing tdropZone.
 * @param gridContext the context of the canvas
 * @param obj the target object being moved
 * @param setModified the flag to set modified or not
 */
export function moveLine(gridContext: IGridContext, obj: fabric.Object, setModified: boolean): void {
  const toLines = gridContext.state.toLines.get(`to-${obj?.name}`) ?? [];
  const fromLines = gridContext.state.fromLines.get(`from-${obj?.name}`) ?? [];
  // udpate lines (if any)
  if (obj) {
    if (setModified) {
      store.commit('diagram/setDiagramModified', true);
    }
    toLines.forEach((line: any) => modifyLine(gridContext, line));
    fromLines.forEach((line: any) => modifyLine(gridContext, line));
  }
}

/**
 * updates the position of the dynamic airflow drop zones
 * to match the flow elements/arrows that they are attached to.
 * @param gridContext the context of the canvas
 * @param obj the target object being moved
 */
export function moveDropZones(gridContext: IGridContext, obj: fabric.Object): void {
  const allDropZones = gridContext.state.gridGroupDropAndLandingZones;
  const objectDropZones = allDropZones.get(obj);
  objectDropZones?.forEach((dropZone) => {
    if (!HelperMethods.isNullOrUndefined(dropZone.group)) {
      return;
    }
    const newTransform = fabric.util.multiplyTransformMatrices(
      obj.calcTransformMatrix(),
      (dropZone as any).relationship
    );
    const opt = fabric.util.qrDecompose(newTransform);
    dropZone.setPositionByOrigin(
      new fabric.Point(opt.translateX, opt.translateY),
      'center',
      'center'
    );
    dropZone.set(opt);
    dropZone.setCoords();
  });
}

/**
 * This function takes in a group, and one of its members, and finds the specific
 * x,y coords of that member with all transformations applied.
 * @param group the group for which you need the context of the specific members coordinates for
 * @param groupMember the group member that you are getting's coords.
 */
export const getCoordinates = (groupMember: fabric.Object): fabric.Point => {
  // determine transformations applied to the group member
  const mObject = groupMember.calcTransformMatrix(false);
  // apply transformations to origin to get coordinates
  const c = new fabric.Point(0, 0);
  return fabric.util.transformPoint(c, mObject);
};

/**
 * Searches through groups for the five type of arrows for the line
 * @param gridContext the context of the canvas
 * @param line line to find arrows of
 */
export const getAllArrows = (gridContext: IGridContext, line: any): fabric.Object[] => {
  const lineArrow = gridContext.state.lineArrows.get(line.lineArrowName);
  const fromArrow = gridContext.state.gridGroupArrows.get(line.fromArrowName);
  const toArrow = gridContext.state.gridGroupArrows.get(line.toArrowName);
  const fromLineObject = gridContext.state.gridGroups.get(line.fromLineObjectName);
  const toLineObject = gridContext.state.gridGroups.get(line.toLineObjectName);
  return [lineArrow, fromArrow, toArrow, fromLineObject, toLineObject];
};

/**
 * Updates lines based on current position
 * @param gridContext canvas context
 * @param line line to update
 */
const modifyLine = (gridContext: IGridContext, line: fabric.Line): void => {
  const [lineArrow, fromArrow, toArrow, fromLineObject, toLineObject] = getAllArrows(gridContext, line);
  line.set('visible', true);
  lineArrow?.set('visible', true);
  fromArrow?.set('visible', false);
  if (fromArrow && toArrow && toLineObject && fromLineObject) {
    // Calculate points
    const fromPoint = getCoordinates(fromArrow);
    const toPoint = getCoordinates(toArrow);

    if (Math.abs(fromPoint.y - toPoint.y) <= 0.5) {
      // when fy and ty are close enough, Fabric deos not set the height which cause the line not display.
      line.set({ x1: fromPoint.x, y1: fromPoint.y, x2: toPoint.x, y2: toPoint.y, height: 3 }).setCoords();
    } else {
      line.set({ x1: fromPoint.x, y1: fromPoint.y, x2: toPoint.x, y2: toPoint.y }).setCoords();
    }
    line.sendToBack();
    lineArrow?.set({ left: line.x2, top: line.y2, angle: calcArrowAngle(fromPoint, toPoint) }).setCoords();
  }
};
