// @ts-nocheck
import Vue from 'vue';
import { camelCase } from 'lodash';
import { LDUser } from 'launchdarkly-js-sdk-common';
import { LDOptions, LDClient, initialize } from 'launchdarkly-js-client-sdk';
import { ILaunchDarklyOptions } from './types';

let ldClient: LDClient = null;

export function getLdClient() {
  return ldClient;
}

export async function ldIdentifyUser(ldClient: LDClient, user: LDUser) {
  if (ldClient != null && user != null) {
    try {
      await ldClient.waitForInitialization();
      ldClient.identify(user);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("[Vue-Launch-Darkly] Couldn't identify user with Launch Darkly");
    }
  }
}

const anonymousUserKey: string = '9c3a04d7-d8d7-4123-ba40-78102224783a';

export default {
  install(vue, options: ILaunchDarklyOptions) {
    if (options.clientId == null) {
      throw new Error('[Vue-Launch-Darkly] clientId is required.');
    }

    if (window.opLdClient != null) {
      ldClient = window.opLdClient;
    } else {
      const user: LDUser = Object.assign({
        // Use empty guid for anon. This should be updated after login ID is returned post login.
        key: anonymousUserKey
      }, options.user);

      if (options.user == null) {
        user.anonymous = true;
      }

      const ldOptions: LDOptions = Object.assign({
        bootstrap: 'localStorage',
        streaming: true
      }, options.ldOptions);

      ldClient = initialize(options.clientId, user, ldOptions);
      
      window.opLdClient = ldClient;
    }

    if (typeof options.nuxtInject === 'function') {
      options.nuxtInject('ldClient', ldClient);
    } else {
      // add to vue prototype
      vue.prototype.$ldClient = ldClient;
      if (options.store != null) {
        options.store.$ldClient = ldClient;
      }
    }

    vue.mixin({
      data() {
        return {
          flags: flattenLDFlags({}, ldClient.allFlags())
        };
      },
      mounted(this) {
        if (ldClient != null) {
          ldClient
            .waitForInitialization()
            .then(() => {
              this.flagUpdates(ldClient.allFlags());
              ldClient.on('change', this.flagUpdates);
            })
            .catch(() => {
              // eslint-disable-next-line no-console
              console.log('failed to get Launch Darkly flags');
            });
        }
      },
      beforeDestroy(this) {
        if (ldClient != null) {
          ldClient.off('change', this.flagUpdates);
        }
      },
      methods: {
        flagUpdates(changes: Object) {
          this.flags = flattenLDFlags(this.flags, changes);
        },
        flagCheck(flag: string, defaultValue: boolean): boolean {
          return this.flags[flag] != null ? this.flags[flag] : defaultValue;
        }
      }
    });

    function flattenLDFlags(currentFlags: any, changedFlags: any): any {
      if (ldClient != null) {
        const flattened = {};
        for (const key in changedFlags) {
          flattened[camelCase(key)] = ldClient.variation(key);
        }
        return { ...currentFlags, ...flattened };
      }
      return currentFlags;
    }
  }
};

export * from './types';

declare global {
  interface Window {
    opLdClient?: LDClient;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    flags?: { [key: string]: boolean };
    flagUpdates?: (changes: Object) => void;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $ldClient: LDClient;
    flags: { [key: string]: boolean };
    flagUpdates: (changes: Object) => void;
    flagCheck: (flag: string, defaultValue: boolean) => boolean;
  }
}

declare module 'vuex' {
  interface Store<S> {
    $ldClient?: LDClient;
  }
}
