









































import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { Component, Prop } from 'vue-property-decorator';
import { IBurnerResult, ICaseResult, ITuningResultModel } from '@/view-models/tuning-result-model';
import store from '@/store';

Vue.use(BootstrapVue);

@Component({
  name: 'ResultTable'
})
export default class ResultTable extends Vue {
  @Prop({ required: true })
  private setHighlightedBurnerCount: (count: number) => void;
  @Prop({ required: true })
  private setTotalBurnerCount: (count: number) => void;
  @Prop({ required: true })
  private setMaxPercentage: (newPercentage: number) => void;
  @Prop({ required: true })
  private highlightPercent: number;
  @Prop({ required: true })
  private filterHighlight: boolean;
  @Prop({ required: true })
  private showPercent: boolean;

  private caseResults: ICaseResult[] = [];
  private highlightThreshold: number = 2;
  private targetTitle: string = 'Target dP';
  private useMaxPercent: boolean = false;
  private maxPercentToUse: number = 0;

  private mounted(): void {
    const tuningResult: ITuningResultModel = store.getters['tuner/getTuningResult'];
    this.caseResults = tuningResult?.caseResults || [];
  }

  get maxWidth(): number {
    return this.fields
      .map((field) => field.label?.length || 0)
      .reduce((a, b) => Math.max(a, b));
  }

  get calcWidth(): string {
    // Provide a minWidth, which corresponds to a number with 4 digit precision
    const minWidth = 6;
    return Math.max((this.maxWidth * .8) + 2, minWidth) + 'rem';
  }

  private formatter(value: string | number): string | number {
    if (!value || typeof value !== 'number' || isNaN(value)) {
      return value;
    }
    return this.showPercent ? value.toFixed(1) + '%' : value.toFixed(0);
  }

  get fields(): Array<{
    key: string;
    label: string;
    deletedCase?: boolean;
    formatter?: (value: string | number) => string | number;
    stickyColumn?: boolean
    thStyle?: string
  }> {
    const newDataFields = this.caseResults
      .map(({ caseName, caseKey, markedForDelete }) => {
        const splitKey = caseName.split(/(\d+|[A-Z]+[a-z]*)/);
        const label = splitKey.filter((word) => word).join(' ');

        return {
          key: caseKey,
          label,
          deletedCase: markedForDelete,
          formatter: this.formatter
        };
      });
    return [
      {
        key: 'burnerName',
        label: 'Burner Name',
        stickyColumn: true,
        thStyle: `width: 30%; `,
      },
      ...newDataFields
    ];
  }

  get highlightedItems(): Item[] {
    const items = this.items.map((tableRow, index) => {
      if (index === 0) {
        // Target dp row
        return tableRow;
      }
      tableRow._cellVariants = {};
      Object.keys(tableRow)
        .filter((columnKey) => columnKey !== 'BurnerName' && columnKey !== '_cellVariants')
        .forEach((columnKey) => {
          const percentDifference = this.percentItems[tableRow.burnerName][columnKey];
          let comparePercent: number = this.highlightPercent;
          if (this.useMaxPercent) {
            comparePercent = this.maxPercentToUse;
          }
          // eslint-disable-next-line keyword-spacing
          if (typeof percentDifference === 'number' && percentDifference > comparePercent) {
            tableRow._cellVariants[columnKey] = 'danger'; // Outline cell
            tableRow._cellVariants.burnerName = 'icon'; // Put warning icon in name column
          }
        });
      return tableRow;
    });
    this.setHighlightedBurnerCount(items.filter((item) => item._cellVariants.burnerName).length);
    this.setTotalBurnerCount(items.length - 1);
    return this.filterHighlight
      ? items.filter((item) => item._cellVariants.burnerName || item.burnerName === this.targetTitle)
      : items;
  }

  get items(): Item[] {
    const items = this.showPercent ? this.percentItems : this.actualItems;
    // Transforms objects of { baseName: { caseName: result, ... }, ... }
    // into array of { caseName: result, ... } objects
    return Object.entries(items).map(([, value]) => value);
  }

  get percentItems(): { [key: string]: Item } {
    return this.calcItems(true);
  }

  get actualItems(): { [key: string]: Item } {
    return this.calcItems(false);
  }

  private calcItems(percent: boolean): { [key: string]: Item } {
    let maxPercentage = 0;
    const items: {
      [key: string]: { // Row key
        [key: string]: any, // Map of key: any value
        burnerName: string, // Declare burnerName values as string type
        _cellVariants: { [key: string]: string }, // Declare _cellVariants values as { [key: string]: string } type
      }
    } = {
      // Add TargetDP row
      // Note: Target dP is set as burner name but will be right aligned to make the distinction
      TargetDP: {
        burnerName: this.targetTitle,
        _cellVariants: {}
      }
    };
    this.caseResults
      .forEach(({ caseKey, targetDp, results }: ICaseResult) => {
        // Add targetDP for this case to targetDP item
        Vue.set(items.TargetDP, caseKey, targetDp.toFixed(0));
        // Add case results to items
        results?.map(({ identifier: burnerName, difference, differencePercent }: IBurnerResult) => {
          maxPercentage = Math.max(maxPercentage, differencePercent);
          const item = items[burnerName] || { burnerName, _cellVariants: {} };
          Vue.set(item, caseKey, percent ? differencePercent : difference);
          Vue.set(items, burnerName, item);
        });
      });

    if (this.highlightPercent === -1) {
      this.useMaxPercent = true;
      this.maxPercentToUse = maxPercentage;
    } else {
      this.useMaxPercent = false;
      this.maxPercentToUse = 0;
    }

    // Set max percentage to control highlight slider max
    this.setMaxPercentage(Math.ceil(maxPercentage));
    return items;
  }
}

interface Item {
  [key: string]: any;
  burnerName: string;
  _cellVariants: {
    [key: string]: string;
  };
}
