























import CollapsibleSidePanel from './components/flowElementLibrary/CollapsibleSidePanel.vue';
import EditorToolbar from './components/editorToolbar/EditorToolbar.vue';
import ZoomButton from './components/ZoomButton.vue';
import FlowElementDetailsPanel from './components/flowElementDetails/FlowElementDetails.vue';
import PreviewHierarchy from './components/modelPreview/PreviewHierarchy.vue';
import Grid from './components/GridHMB.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Mode } from '@/view-models/mode';
import { Route } from 'vue-router';
import store from '@/store/';

@Component({
  name: 'Editor',
  components: {
    CollapsibleSidePanel,
    EditorToolbar,
    FlowElementDetailsPanel,
    Grid,
    PreviewHierarchy,
    ZoomButton
  },
})
export default class Editor extends Vue {
  private previewMode: boolean = false;
  private unSubscribeModeSwitch: (() => void) = null;
  private collapsed = '';
  private gridKey = Math.random() * 10;
  private isLoaded: boolean = false;

  private mounted(): void {
    store.commit(`app/updateFF`, (this as any).flags);
    if (this.$route.query.initialState === 'preview') {
      this.previewMode = true;
    }

    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }

    this.unSubscribeModeSwitch = store.subscribe((mutation, state) => {
      if (mutation.type === 'grid/switchMode') {
        switch (state.grid.mode) {
          case Mode.PREVIEW:
            this.previewMode = true;
            break;
          case Mode.EDIT:
            this.previewMode = false;
            break;
        }
      }
    });

    this.isLoaded = true;
  }

  private beforeDestroy(): void {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  private async handleRouteChange(parentRoute: Route): Promise<void> {
    if (parentRoute.path.match(/^\/?utilities\/ember\/hydraulic-modeler\//)) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/hydraulic-modeler\//, '/');
      if (currentRelativeRoute === '/') {
        await this.refreshData();
        await this.$router.push('../modelSelector');
      }
    }
  }

  private async refreshData(): Promise<void> {
    await store.dispatch('asset/loadAssets', true);
    store.commit('asset/initialize');
    store.commit('model/initializeModel');
    store.commit('diagram/initializeDiagram');
    store.commit('grid/initializeGrid');
    store.commit('tuner/initializeTuner');
    store.commit('model/updateFilterText', '');
  }

  private updateGrid(): void {
    setTimeout(()=> { this.gridKey++; }, 1000);
  }
}
