



























































import HelperMethods from '@/shared/helper-methods';
import store from '@/store/';
import { ElementAttributeModel } from '@/view-models/element-attribute-model';
import { FlowElementModel } from '@/view-models/flow-element-models';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'copy-attributes-modal'
})
export default class CopyAttributesModal extends Vue {
  private showCopyModal: boolean = false;
  private showCopyResultModal: boolean = false;
  private sourceElement: FlowElementModel = null;
  private elementsOnGrid: FlowElementModel[] = [];
  private destinationElements: FlowElementModel[] = [];
  private currentTab: number = 0;

  private get possibleDestinationElements(): FlowElementModel[] {
    return this.elementsOnGrid.filter((elem) => {
      return elem.name === this.sourceElement.name &&
        elem.flowElementId.id !== this.sourceElement.flowElementId.id;
    });
  }

  private get isCompositeElement(): boolean {
    if (this.sourceElement && !HelperMethods.isArrayEmpty(this.sourceElement.subElementList)) {
      return this.sourceElement.subElementList.some((subElement) => {
        return !HelperMethods.isArrayEmpty(subElement.elementAttributeList);
      });
    }
    return false;
  }

  private get isCopyDisabled(): boolean {
    return this.possibleDestinationElements.length === 0 ||
      this.nonEmptyAttributes(this.sourceElement).length === 0;
  }

  private isStringEmpty(text: string): boolean {
    return HelperMethods.isStringEmpty(text);
  }

  private nonEmptyAttributes(element: FlowElementModel): ElementAttributeModel[] {
    return element.elementAttributeList.filter((attr) => !this.isStringEmpty(attr.value));
  }

  private copyElement(): void {
    this.destinationElements.forEach((destinationElement) => {
      // Copy main attributes
      this.copyAttributes(this.sourceElement, destinationElement);
      // Copy sub attributes
      this.sourceElement.subElementList.forEach((subSourceElem) => {
        const subDestElem = destinationElement.subElementList.find((elem) => elem.name === subSourceElem.name);
        this.copyAttributes(subSourceElem, subDestElem);
      });
    });
    this.showCopyResultModal = true;
  }

  private copyAttributes(sourceElement: FlowElementModel, destinationElement: FlowElementModel): void {
    const sourceAttributes = sourceElement.elementAttributeList;
    destinationElement.elementAttributeList.forEach((destAttr) => {
      const sourceAttr = sourceAttributes.find((attr) => attr.name === destAttr.name);
      if (!this.isStringEmpty(sourceAttr.value)) {
        destAttr.value = sourceAttr.value;
      }
    });
  }

  private close(): void {
    this.sourceElement = null;
    this.showCopyModal = false;
    this.showCopyResultModal = false;
    this.elementsOnGrid = [];
    this.destinationElements = [];
  }

  public open(element: FlowElementModel): void {
    this.sourceElement = element;
    this.showCopyModal = true;
    this.elementsOnGrid = store.getters['diagram/getDiagramElements'] as FlowElementModel[];
    this.destinationElements = this.possibleDestinationElements;
    if (this.nonEmptyAttributes(this.sourceElement).length === 0) {
      this.currentTab = 1;
    } else {
      this.currentTab = 0;
    }
  }
}
