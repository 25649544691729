





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import store from '@/store';
import Loading from '@/components/common/Loading.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'save-editor-changes-modal',
  components: {
    Loading
  }
})
export default class SaveEditorChangesModal extends Vue {
  @Prop({ required: true })
  public context: string;

  private isLoading: boolean = false;

  private mounted(): void {
    EventBus.$on(hmbEvents.resetModelComplete, () => {
      this.clearModal();
    });
  }

  private beforeDestroy(): void {
    EventBus.$off(hmbEvents.resetModelComplete);
  }

  private async resolvePrompt(action: string): Promise<void> {
    if (action === 'save') {
      this.isLoading = true;
      await store.dispatch('diagram/saveDiagramDetails');
      store.commit('diagram/resetDiagramPreviewHierarchy');
      EventBus.$emit(hmbEvents.checkForCompleteModel);
      this.clearModal();
    } else {
      this.isLoading = true;
      store.commit('diagram/resetDiagramPreviewHierarchy');
      EventBus.$emit(hmbEvents.resetFromPrompt);
    }
  }

  private clearModal(): void {
    this.isLoading = false;
    this.$bvModal.hide('saveEditorChangesModal');

    switch (this.context) {
      case 'leaveEditor':
        EventBus.$emit(hmbEvents.confirmLeaveEditor);
        break;
      case 'editModel':
        EventBus.$emit(hmbEvents.confirmEditModel);
        break;
      default:
        break;
    }
  }
}
