import BurnerBaseService from '@/services/burner-base-service';
import { AxiosInstance } from 'axios';
import { IBurnerListServiceResponse } from '@/view-models/burner-response-view-models';

export class AssetBurnerService extends BurnerBaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  /**
   * @todo This endpoint does not exist anymore. Use Hierarchy Builder's GET /heater/{key}/burnertree endpoint
   * @param selectedAssetKey 
   * @returns Promise<IBurnerListServiceResponse>
   */
  public async getBurnerList(selectedAssetKey: string): Promise<IBurnerListServiceResponse> {
    const url = `/api/heater/${selectedAssetKey}/hmb-burnertree`;
    const response =  (await this.httpClient.get<IBurnerListServiceResponse>(url));
    this.updateBurnerResponse(response);
    return response;
  }
}
