











































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { ITrainingCase, ITrainingCaseRequest } from '@/view-models/training-data-model';
import { HydraulicTuningDataService } from '@/services/hydraulic-tuning-data-service';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import ConfigFactory from '@/services/config';
import sharedAxiosInstance from '@/services/api-service';
import Loading from '@/components/common/Loading.vue';
import { ModelType } from '@/view-models/model-summary';

enum SortOrder {
  DESCENDING = -1,
  ASCENDING = 1
}

interface ITableCaseItem {
  editMode: boolean;
  trainingCase: ITrainingCase;
  oldCaseName?: string;
}

interface IErrorMessage {
  errorType: string;
  message: string;
  showMessage: boolean;
}

@Component({
  name: 'TunerTrainingData',
  components: { Loading }
})
export default class TunerTrainingData extends Vue {
  private isLoading: boolean = false;
  private trainingCases: ITrainingCase[] = [];
  private caseItems: ITableCaseItem[] = [];
  private sortOrder: number = SortOrder.ASCENDING;
  private currentSortedColumn: keyof ITrainingCase = 'name';
  private file: any = null;
  private fileValid: boolean = true;
  private fileName: string = 'Browse for local file';
  private invalidFileMessage: string = '';
  private showCancelImport: boolean = false;
  private showDeleteModal: boolean = false;
  private loading: boolean = false;
  private deleteCaseItem: ITableCaseItem = null;
  private testCases: any = {};
  private testCasesCreated: boolean = false;
  private importCases: string[] = [];
  private burners: string[] = [];
  private acknowledgeDelete: boolean = false;
  private casesSubmitted = 0;
  private keyInvalid = false;
  private duplicateFileName = false;
  private fileObj: any = {};
  private individualErrorMessages: IErrorMessage[] = [
    { errorType: 'burnerInvalid',
      message: 'This test case is missing the necessary burner keys.',
      showMessage: false },
    { errorType: 'configInvalid',
      message: 'This test case does not meet the required specification.',
      showMessage: false },
    { errorType: 'dpInvalid',
      message: 'This test case has an Overall dP that is less than 0.',
      showMessage: false },
  ];

  private created(): void {
    EventBus.$on(hmbEvents.hideAllInfo, this.closeAllIndividualErrors);
  }

  private async mounted(): Promise<void> {
    await this.refreshData();
  }

  private async refreshData(): Promise<void> {
    this.isLoading = true;
    try {
      await store.dispatch('tuner/loadTrainingData');
      this.burners = await store.dispatch('tuner/loadBurners');
    } catch (ex) {
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'Error Loading Training Data for Training Cases\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }

    const tunerTrainingCases = store.getters['tuner/getTrainingData']
      .filter((trainingCase: ITrainingCase) => trainingCase.markedForDelete === false);

    this.caseItems = [];
    tunerTrainingCases.forEach((trainingCase: ITrainingCase) => {
      this.caseItems.push({editMode: false, trainingCase});
    });
    this.caseItems.sort(this.sortByString);
    this.isLoading = false;
  }

  private sortByString(a: ITableCaseItem, b: ITableCaseItem): number {
    if ((typeof a.trainingCase[this.currentSortedColumn] !== 'string'
      || !a.trainingCase[this.currentSortedColumn])
      && (typeof b.trainingCase[this.currentSortedColumn] !== 'string'
      || !b.trainingCase[this.currentSortedColumn])) {
        return this.sortByCompareDateImported(a,b);
      } else {
        if (typeof a.trainingCase[this.currentSortedColumn] !== 'string'
          || !a.trainingCase[this.currentSortedColumn]) {
            return -1 * this.sortOrder;
        }

        if (typeof b.trainingCase[this.currentSortedColumn] !== 'string'
          || !b.trainingCase[this.currentSortedColumn]) {
            return 1 * this.sortOrder;
        }

        const columnValueA: string = a.trainingCase[this.currentSortedColumn].toString();
        const columnValueB: string = b.trainingCase[this.currentSortedColumn].toString();
        let compareInt: number = 0;
        compareInt = columnValueA.localeCompare(columnValueB, 'en', { numeric: true, sensitivity: 'base' });
        if ( compareInt === 0) {
          return this.sortByCompareDateImported(a, b);
        } else {
          return compareInt * this.sortOrder;
        }
    }
  }

  get warnings(): IErrorMessage[] {
    for (const key in this.testCases) {
      if (this.testCases[key].invalid.length > 0) {
        const invalidObjs: any[] = this.testCases[key].invalid;
        invalidObjs.forEach((obj: any) => {
          const errorInfo: IErrorMessage =
            this.individualErrorMessages.find((message: IErrorMessage) => {
              const keys: string[] = Object.keys(obj);
              return message.errorType === keys[0];
            });
          if (errorInfo) {
            errorInfo.showMessage = true;
          }
        });
      }
    }
    return this.individualErrorMessages.filter((warning: IErrorMessage) => warning.showMessage === true);
  }

  private reset(): void {
    this.file = null;
    this.fileValid = true;
    this.fileName = 'Browse for local file';
    this.loading = false;
    this.testCases = {};
    this.testCasesCreated = false;
    this.importCases = [];
    this.duplicateFileName = false;
    if ((document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('file-for-upload') as any)) {
      (document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById(
        'file-for-upload') as any).value = '';
    }

    this.individualErrorMessages.forEach((e) => {
      e.showMessage = false;
    });
  }

  private sortByNumber(a: ITableCaseItem, b: ITableCaseItem): number {
    if (typeof a.trainingCase[this.currentSortedColumn] === 'number'
      && a.trainingCase[this.currentSortedColumn] !== null
      && a.trainingCase[this.currentSortedColumn] !== undefined) {
        const columnValueA: number = Number(a.trainingCase[this.currentSortedColumn]);
        const columnValueB: number = Number(b.trainingCase[this.currentSortedColumn]);
        const compareInt = columnValueA - columnValueB;
        if (compareInt === 0) {
          return this.sortByCompareDateImported(a, b);
        } else {
          return compareInt * this.sortOrder;
        }
    }
    return -1;
  }

  private sortByCompareDateImported(a: ITableCaseItem, b: ITableCaseItem, sortOrder: number = 1): number {
    const initialDate: Date = new Date(0);
    let aDate: Date = initialDate;
    let bDate: Date = initialDate;

    if (a.trainingCase.dateImported) {
      aDate = new Date(a.trainingCase.dateImported);
    }

    if (b.trainingCase.dateImported) {
      bDate = new Date(b.trainingCase.dateImported);
    }

    if (aDate !== null && bDate !== null) {
      return (bDate.getTime() - aDate.getTime()) * sortOrder;
    } else {
      if (a.trainingCase.dateImported && b.trainingCase.dateImported === null) {
        return -1 * sortOrder;
      }
      if (a.trainingCase.dateImported == null && b.trainingCase.dateImported) {
        return 1 * sortOrder;
      }
    }

    return 0;
  }

  private editName(caseItem: ITableCaseItem): void {
    EventBus.$emit(hmbEvents.setEnableState,false);
    if (!caseItem.editMode) {
      caseItem.oldCaseName = caseItem.trainingCase.name;
      this.caseItems
        .filter((item: ITableCaseItem) => {
          return item.editMode === true;
        })
        .forEach((item: ITableCaseItem) => {
          if (item.oldCaseName) {
            item.trainingCase.name = item.oldCaseName;
          }
          item.editMode = false;
        });
      caseItem.editMode = true;
    }
  }

  private async submitChanges(caseItem: ITableCaseItem): Promise<void> {
    EventBus.$emit(hmbEvents.setEnableState,true);
    caseItem.editMode = false;
    this.caseItems.sort(this.sortByString);
    try {
      const conf = await ConfigFactory.GetConfig();
      conf.get('hmbApiUrl');
      const tuningDataService: HydraulicTuningDataService =
        new HydraulicTuningDataService(sharedAxiosInstance, process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ??
        conf.get('hmbApiUrl'));
      await tuningDataService.updateTrainingCaseName(caseItem.trainingCase);
      caseItem.editMode = false;
      this.caseItems.sort(this.sortByString);
      store.commit('tuner/updateTrainingCaseName', caseItem.trainingCase);
    } catch (ex) {
      if (caseItem.oldCaseName) {
        caseItem.trainingCase.name = caseItem.oldCaseName;
      }
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'Error Loading Updating Training Case\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }
  }

  private showUploadModal(): void {
    this.$bvModal.show('upload-modal');
  }

  private cancelEdit(caseItem: ITableCaseItem): void {
    EventBus.$emit(hmbEvents.setEnableState,true);
    if (caseItem.oldCaseName) {
      caseItem.trainingCase.name = caseItem.oldCaseName;
    }
    caseItem.editMode = false;
  }

  private deleteTrainingCase(caseItem: ITableCaseItem): void {
    this.acknowledgeDelete = false;
    this.showDeleteModal = true;
    this.deleteCaseItem = caseItem;
  }

  private async confirmCancelImport(): Promise<void> {
    this.reset();
    this.$bvModal.hide('upload-modal');
    this.showCancelImport = false;
    await this.refreshData();
  }

  private async confirmDeleteTrainingCase(): Promise<void> {
    if (this.deleteCaseItem) {
      try {
        this.deleteCaseItem.trainingCase.markedForDelete = true;
        const conf = await ConfigFactory.GetConfig();
        const tuningDataService: HydraulicTuningDataService =
          new HydraulicTuningDataService(
            sharedAxiosInstance,
            process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
        await tuningDataService.deleteTrainingCaseName(this.deleteCaseItem.trainingCase);
        this.caseItems = this.caseItems.filter((deleteItem: ITableCaseItem) => {
          return deleteItem !== this.deleteCaseItem;
        });
        this.deleteCaseItem.editMode = false;
      } catch (ex) {
          if (this.deleteCaseItem.oldCaseName) {
            this.deleteCaseItem.trainingCase.name = this.deleteCaseItem.oldCaseName;
          }
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Updating Training Case\n',
            handleError: true,
            routeHomeAfterError: false
          });
      }
    }
    this.showDeleteModal = false;
  }

  private toggleAcknowledgeChecked(): void {
    this.acknowledgeDelete = !this.acknowledgeDelete;
  }

  private sortColumn(sortBy: keyof ITrainingCase): void {
    if (this.currentSortedColumn === sortBy) {
      this.sortOrder = this.sortOrder * -1;
    } else {
      this.sortOrder = SortOrder.ASCENDING;
      this.currentSortedColumn = sortBy;
    }

    if (this.currentSortedColumn === 'name' || this.currentSortedColumn === 'dataSourceName') {
      this.caseItems.sort(this.sortByString);
    }

    if (this.currentSortedColumn === 'overallDp') {
      this.caseItems.sort(this.sortByNumber);
    }

    if (this.currentSortedColumn === 'dateImported') {
      this.caseItems.sort((a,b) => {
        return this.sortByCompareDateImported(a, b, this.sortOrder);
      });
    }
  }

  private isValidCaseName(caseItem: ITableCaseItem): boolean {
    const regex: RegExp = /^([a-zA-Z0-9-_#\s]{1,32})$/g;
    if (!(caseItem.trainingCase.name)) {
      return false;
    }

    if (/^\s+/.test(caseItem.trainingCase.name)) {
      // leading white spaces
      return false;
    } else if (/\S/.test(caseItem.trainingCase.name)) {
      return regex.test(caseItem.trainingCase.name);
    } else {
      return false;
    }
  }

  private destroyed(): void {
    EventBus.$emit(hmbEvents.setEnableState, true);
  }

  private getIndividualError(caseItem: any): string[] {
    const errorMessages: string[] = [];
    const caseInvalid: any[] = this.testCases[caseItem.name].invalid;
    if (caseInvalid) {
      caseInvalid.forEach((invalidObj: any) => {
        const keys: string[] = Object.keys(invalidObj);
        const foundMessage: IErrorMessage =
          this.individualErrorMessages.find((message: IErrorMessage) => {
          return message.errorType === keys[0];
        });
        if (foundMessage) {
          errorMessages.push(foundMessage.message);
        }
      });
    }
    return errorMessages;
  }

  private showIndividualError(caseItem: any): void {
    this.testCases[caseItem.name].showError = !caseItem.showError;
    for (const testCase in this.testCases) {
      if (this.testCases.hasOwnProperty(testCase) && testCase !== caseItem.name) {
        this.testCases[testCase].showError = false;
      }
    }
    this.$forceUpdate();
  }

  private closeAllIndividualErrors(): void {
    for (const testCase in this.testCases) {
      if (this.testCases.hasOwnProperty(testCase)) {
        this.testCases[testCase].showError = false;
      }
    }

    this.$forceUpdate();
  }

  private onModalClose(event: any): void {
    if (this.file && this.testCasesCreated) {
      event.preventDefault();
      this.showCancelImport = true;
    } else {
      this.confirmCancelImport();
    }
  }

  private cancelImport(): void {
    if (this.file && this.testCasesCreated) {
      this.showCancelImport = true;
    } else {
      this.confirmCancelImport();
    }
  }

  private updateFile(): void {
    this.duplicateFileName = false;
    const file = (document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot?.getElementById('file-for-upload') as any)?.files[0];
    this.fileName = file.name;
    const fileNames = this.caseItems.map((item) => item.trainingCase.dataSourceName + '.json');

    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (evt) => {
        this.file = evt.target?.result;
        this.loading = true;
        this.confirmFile(evt.target?.result);

        if (this.fileName && fileNames.includes(this.fileName)) {
          this.reset();
          this.duplicateFileName = true;
          this.fileName = file.name;
          return;
        }

        if (this.fileValid) {
          this.createTestCases();
        }
      };
    }
  }

  private confirmFile(file: any): void {
    let errorCount = 0;
    let caseCount = 0;

    try {
      this.fileObj = JSON.parse(file);
    } catch (err) {
      this.fileValid = false;
      this.invalidFileMessage = 'File contents are not in a valid JSON format.';
    }

    const requiredKeys = ['CombustionAirTemperature', 'AmbientPressure', 'AirFlow',
      'AirComposition', 'OverallDP', 'FlowSplits', 'DuctDamperSettings',
      'DamperSettings', 'Groupings'];

    if (this.fileObj && !Array.isArray(this.fileObj)) {
      for (const p in this.fileObj) {
        if (!this.fileObj.hasOwnProperty(p) || (typeof this.fileObj[p] !== 'object')) {
          continue;
        }
        const errorSet: Set<any> = new Set();
        const invalidBurnerObj = {burnerInvalid: true};
        const invalidDpObj = {dpInvalid: true};
        const invalidConfigObj = {configInvalid: true};
        const caseKeys = Object.keys(this.fileObj[p]);
        requiredKeys.forEach((e) => {
          if (!caseKeys.includes(e)) {
            errorSet.add(invalidConfigObj);
            this.keyInvalid = true;
          }
          if (this.fileObj[p].OverallDP < 0) {
            errorSet.add(invalidDpObj);
            this.keyInvalid = true;
          }
        });

        caseKeys.forEach((key) => {
          if ((key === 'FlowSplits' || key === 'DamperSettings')) {
            const burnerKeys = Object.keys(this.fileObj[p][key]);
            if (this.burners) {
              this.burners.forEach((burner) => {
                if (burnerKeys.indexOf(burner) < 0) {
                  errorSet.add(invalidBurnerObj);
                  this.keyInvalid = true;
                }
              });
            }
          }
        });

        this.fileObj[p].invalid = Array.from(errorSet);
        caseCount++;

        if (this.fileObj[p].invalid.length > 0) {
          errorCount++;
        }
      }
    } else {
      this.fileValid = false;
      this.invalidFileMessage = 'No test cases found in file.';
    }

    if (caseCount === errorCount) {
      this.fileValid = false;
      this.invalidFileMessage = 'No test cases found in file.';
    }

    this.loading = false;
  }

  private createTestCases(): void {
    // tslint:disable-next-line:forin
    for (const key in this.fileObj) {
      if (this.fileObj[key].invalid.length < 1) {
        this.importCases.push(key);
      }
      this.testCases[key] = { import: true, overallDp: this.fileObj[key].OverallDP, name: key,
        invalid: this.fileObj[key].invalid, trainingDataJson: JSON.stringify(this.fileObj[key]),
        markedForDelete: false, showError: false };
    }

    this.testCasesCreated = true;
  }

  private toggle(name: string): void {
    const index = this.importCases.indexOf(name);
    if (index > -1) {
      this.importCases.splice(index, 1);
    } else {
      this.importCases.push(name);
    }
  }

  private submit(): void {
    let model = store.getters['tuner/getCurrentModelSummary'];

    if (!model) {
      model = { customer: store.getters['asset/allCustomers'][0] };
    }
    const asset = store.getters['tuner/getCurrentAsset'];
    const location = store.getters['tuner/getCurrentSite'];
    const casesToSubmit = [];

    for (const key in this.testCases) {
      if (this.importCases.includes(key) && this.testCases[key].invalid.length < 1) {
        casesToSubmit.push(this.testCases[key]);
      }
    }

    const newTrainingData: ITrainingCaseRequest = {
      asset,
      location,
      customer: model.customer,
      modelType: model && model.modelType ? model.modelType : ModelType.Air_Duct_Network,
      dataSourceName: this.fileName ? this.fileName.split('.json')[0] : '',
      trainingCases: casesToSubmit
    };

    store
      .dispatch('tuner/uploadTrainingData', newTrainingData)
      .then( async (item) => {
        this.casesSubmitted = item.length;
        this.$bvModal.hide('upload-modal');
        this.$bvModal.show('successful-training-modal');
        window.setTimeout(this.closeConnectionModal, 2000);
        this.reset();
        item.forEach((i: any) => {
          this.caseItems.push({editMode: false, trainingCase: i});
        });
        this.caseItems.sort(this.sortByString);
        await store.dispatch('tuner/loadTrainingData');
      });
  }

  private closeConnectionModal(): void {
    this.$bvModal.hide('successful-training-modal');
  }

  private async onComponentClick(): Promise<void> {
    EventBus.$emit(hmbEvents.hideAllInfo);
  }
}
