export const svgKlasses = {
  arrow: [
    {
      type: 'rect',
      version: '3.6.3',
      left: -12.5,
      top: 12.5,
      width: 24,
      height: 24,
      fill: 'rgba(216,216,216,0.25)',
      stroke: '',
      strokeWidth: 5,
      angle: -90,
      opacity: 0.01,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -7.5,
      top: -10.5,
      width: 25,
      height: 20,
      fill: '#D3D4D6',
      stroke: '',
      strokeWidth: 5,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 12,
          y: 2
        },
        {
          x: 19,
          y: 7.75342466
        },
        {
          x: 14.5454545,
          y: 7.75342466
        },
        {
          x: 14.5454545,
          y: 22
        },
        {
          x: 9.45454545,
          y: 22
        },
        {
          x: 9.45454545,
          y: 7.75342466
        },
        {
          x: 5,
          y: 7.75342466
        }
      ]
    }
  ],
  burner: [
    {
      type: 'rect',
      version: '3.6.3',
      left: -22.05,
      top: 18.95,
      width: 43.1,
      height: 13.79,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -25.5,
      top: -20.71,
      width: 50,
      height: 13.79,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -16.88,
      top: -34.5,
      width: 32.76,
      height: 13.79,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -15.16,
      top: -6.91,
      width: 29.31,
      height: 25.86,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -22.55,
      top: 18.45,
      width: 43.1,
      height: 13.79,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -26,
      top: -21.21,
      width: 50,
      height: 13.79,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -17.38,
      top: -35,
      width: 32.76,
      height: 13.79,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -15.66,
      top: -7.41,
      width: 29.31,
      height: 25.86,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    }
  ],
  dropZone: [
    {
      type: 'rect',
      version: '3.6.3',
      left: -43,
      top: -43,
      width: 80,
      height: 80,
      fill: '',
      stroke: '#00BFFF',
      strokeWidth: 6,
      strokeDashArray: [
        6
      ],
      fillRule: 'evenodd',
      rx: 2,
      ry: 2,
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -25.5,
      top: -24.5,
      width: 50,
      height: 50,
      fill: '',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false,
      points: [
        {
          x: 0,
          y: 0
        },
        {
          x: 50,
          y: 0
        },
        {
          x: 50,
          y: 50
        },
        {
          x: 0,
          y: 50
        }
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -21.33,
      top: -20.33,
      width: 41.67,
      height: 41.67,
      fill: '#00BFFF',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false,
      path: [
        [
          'M',
          25,
          4.16666667
        ],
        [
          'C',
          13.5,
          4.16666667,
          4.16666667,
          13.5,
          4.16666667,
          25
        ],
        [
          'C',
          4.16666667,
          36.5,
          13.5,
          45.8333333,
          25,
          45.8333333
        ],
        [
          'C',
          36.5,
          45.8333333,
          45.8333333,
          36.5,
          45.8333333,
          25
        ],
        [
          'C',
          45.8333333,
          13.5,
          36.5,
          4.16666667,
          25,
          4.16666667
        ],
        [
          'L',
          25,
          4.16666667
        ],
        [
          'Z'
        ],
        [
          'M',
          35.4166667,
          27.0833333
        ],
        [
          'L',
          27.0833333,
          27.0833333
        ],
        [
          'L',
          27.0833333,
          35.4166667
        ],
        [
          'L',
          22.9166667,
          35.4166667
        ],
        [
          'L',
          22.9166667,
          27.0833333
        ],
        [
          'L',
          14.5833333,
          27.0833333
        ],
        [
          'L',
          14.5833333,
          22.9166667
        ],
        [
          'L',
          22.9166667,
          22.9166667
        ],
        [
          'L',
          22.9166667,
          14.5833333
        ],
        [
          'L',
          27.0833333,
          14.5833333
        ],
        [
          'L',
          27.0833333,
          22.9166667
        ],
        [
          'L',
          35.4166667,
          22.9166667
        ],
        [
          'L',
          35.4166667,
          27.0833333
        ],
        [
          'L',
          35.4166667,
          27.0833333
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  landingZone: [
    {
      type: 'rect',
      version: '3.6.3',
      left: -40.5,
      top: -40.5,
      width: 80,
      height: 80,
      fill: '#00BFFF',
      stroke: '',
      fillRule: 'evenodd',
      rx: 2,
      ry: 2,
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -40.5,
      top: -40.5,
      width: 80,
      height: 80,
      fill: '#000000',
      stroke: '',
      opacity: 0.2,
      fillRule: 'evenodd',
      rx: 2,
      ry: 2,
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -43,
      top: -43,
      width: 80,
      height: 80,
      fill: '',
      stroke: '#4E5266',
      strokeWidth: 6,
      strokeDashArray: [
        6,
        6
      ],
      fillRule: 'evenodd',
      rx: 2,
      ry: 2,
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -25.5,
      top: -24.5,
      width: 50,
      height: 50,
      fill: '',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false,
      points: [
        {
          x: 0,
          y: 0
        },
        {
          x: 50,
          y: 0
        },
        {
          x: 50,
          y: 50
        },
        {
          x: 0,
          y: 50
        }
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -21.33,
      top: -20.33,
      width: 41.67,
      height: 41.67,
      fill: '#4E5266',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      selectable: false,
      path: [
        [
          'M',
          25,
          4.16666667
        ],
        [
          'C',
          13.5,
          4.16666667,
          4.16666667,
          13.5,
          4.16666667,
          25
        ],
        [
          'C',
          4.16666667,
          36.5,
          13.5,
          45.8333333,
          25,
          45.8333333
        ],
        [
          'C',
          36.5,
          45.8333333,
          45.8333333,
          36.5,
          45.8333333,
          25
        ],
        [
          'C',
          45.8333333,
          13.5,
          36.5,
          4.16666667,
          25,
          4.16666667
        ],
        [
          'L',
          25,
          4.16666667
        ],
        [
          'Z'
        ],
        [
          'M',
          35.4166667,
          27.0833333
        ],
        [
          'L',
          27.0833333,
          27.0833333
        ],
        [
          'L',
          27.0833333,
          35.4166667
        ],
        [
          'L',
          22.9166667,
          35.4166667
        ],
        [
          'L',
          22.9166667,
          27.0833333
        ],
        [
          'L',
          14.5833333,
          27.0833333
        ],
        [
          'L',
          14.5833333,
          22.9166667
        ],
        [
          'L',
          22.9166667,
          22.9166667
        ],
        [
          'L',
          22.9166667,
          14.5833333
        ],
        [
          'L',
          27.0833333,
          14.5833333
        ],
        [
          'L',
          27.0833333,
          22.9166667
        ],
        [
          'L',
          35.4166667,
          22.9166667
        ],
        [
          'L',
          35.4166667,
          27.0833333
        ],
        [
          'L',
          35.4166667,
          27.0833333
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_4_5: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -50.5,
      top: -25.5,
      width: 100,
      height: 49.74,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 49.744898
        },
        {
          x: 0,
          y: 34.1395435
        },
        {
          x: 30.2166141,
          y: 0
        },
        {
          x: 100,
          y: 0
        },
        {
          x: 100,
          y: 49.744898
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -51.5,
      top: -26.5,
      width: 100,
      height: 49.74,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 49.744898
        },
        {
          x: 0,
          y: 34.1395435
        },
        {
          x: 30.2166141,
          y: 0
        },
        {
          x: 100,
          y: 0
        },
        {
          x: 100,
          y: 49.744898
        }
      ]
    }
  ],
  idelchik_5_23: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: 38,
      top: 36.21,
      width: 75,
      height: 71.71,
      fill: '#393C4B',
      stroke: '',
      angle: 180,
      flipY: true,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 53.7822581
        },
        {
          x: 0,
          y: 20.7580645
        },
        {
          x: 75,
          y: 0
        },
        {
          x: 75,
          y: 71.7096774
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: 39,
      top: 37.21,
      width: 75,
      height: 71.71,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      angle: 180,
      flipY: true,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 53.7822581
        },
        {
          x: 0,
          y: 20.7580645
        },
        {
          x: 75,
          y: 0
        },
        {
          x: 75,
          y: 71.7096774
        }
      ]
    }
  ],
  idelchik_6_1: [
    {
      type: 'path',
      version: '3.6.3',
      left: -38,
      top: -38,
      width: 75,
      height: 74.72,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          0,
          74.7219648
        ],
        [
          'L',
          44.2771084,
          74.7219648
        ],
        [
          'C',
          44.1397021,
          60.9523254,
          46.5493406,
          51.6844848,
          51.5060241,
          46.918443
        ],
        [
          'C',
          56.4627076,
          42.1524012,
          64.2940329,
          38.387341,
          75,
          35.6232623
        ],
        [
          'L',
          75,
          -6.14892752e-14
        ],
        [
          'C',
          45.6104418,
          1.22026568,
          26.3975904,
          8.20203892,
          17.3614458,
          20.9453197
        ],
        [
          'C',
          8.3253012,
          33.6886006,
          2.53815261,
          51.6141489,
          0,
          74.7219648
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -39,
      top: -39,
      width: 75,
      height: 74.72,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          0,
          74.7219648
        ],
        [
          'L',
          44.2771084,
          74.7219648
        ],
        [
          'C',
          44.1397021,
          60.9523254,
          46.5493406,
          51.6844848,
          51.5060241,
          46.918443
        ],
        [
          'C',
          56.4627076,
          42.1524012,
          64.2940329,
          38.387341,
          75,
          35.6232623
        ],
        [
          'L',
          75,
          0
        ],
        [
          'C',
          45.6104418,
          1.22026568,
          26.3975904,
          8.20203892,
          17.3614458,
          20.9453197
        ],
        [
          'C',
          8.3253012,
          33.6886006,
          2.53815261,
          51.6141489,
          0,
          74.7219648
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_6_6: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -43,
      top: -38,
      width: 85,
      height: 74.98,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 1.29810692e-13
        },
        {
          x: 85,
          y: 1.29810692e-13
        },
        {
          x: 85,
          y: 74.9823571
        },
        {
          x: 51.4678899,
          y: 74.9823571
        },
        {
          x: 51.4678899,
          y: 43.4897671
        },
        {
          x: 0,
          y: 43.4897671
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -44,
      top: -39,
      width: 85,
      height: 74.98,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 1.29810692e-13
        },
        {
          x: 85,
          y: 1.29810692e-13
        },
        {
          x: 85,
          y: 74.9823571
        },
        {
          x: 51.4678899,
          y: 74.9823571
        },
        {
          x: 51.4678899,
          y: 43.4897671
        },
        {
          x: 0,
          y: 43.4897671
        }
      ]
    }
  ],
  idelchik_6_7: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -51,
      top: -32,
      width: 100,
      height: 62,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 7.88362431e-13
        },
        {
          x: 0,
          y: 40.7894737
        },
        {
          x: 47.107438,
          y: 40.7894737
        },
        {
          x: 75.2066116,
          y: 62
        },
        {
          x: 100,
          y: 31.8157895
        },
        {
          x: 59.5041322,
          y: 0
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -52,
      top: -33,
      width: 100,
      height: 62,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 7.88362431e-13
        },
        {
          x: 0,
          y: 40.7894737
        },
        {
          x: 47.107438,
          y: 40.7894737
        },
        {
          x: 75.2066116,
          y: 62
        },
        {
          x: 100,
          y: 31.8157895
        },
        {
          x: 59.5041322,
          y: 0
        }
      ]
    }
  ],
  idelchik_6_8: [
    {
      type: 'path',
      version: '3.6.3',
      left: -38,
      top: -38,
      width: 75,
      height: 74.72,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          0,
          74.7219648
        ],
        [
          'L',
          44.2771084,
          74.7219648
        ],
        [
          'C',
          44.1397021,
          60.9523254,
          46.5493406,
          51.6844848,
          51.5060241,
          46.918443
        ],
        [
          'C',
          56.4627076,
          42.1524012,
          64.2940329,
          38.387341,
          75,
          35.6232623
        ],
        [
          'L',
          75,
          -6.14892752e-14
        ],
        [
          'C',
          45.6104418,
          1.22026568,
          26.3975904,
          8.20203892,
          17.3614458,
          20.9453197
        ],
        [
          'C',
          8.3253012,
          33.6886006,
          2.53815261,
          51.6141489,
          0,
          74.7219648
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -39,
      top: -39,
      width: 75,
      height: 74.72,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          0,
          74.7219648
        ],
        [
          'L',
          44.2771084,
          74.7219648
        ],
        [
          'C',
          44.1397021,
          60.9523254,
          46.5493406,
          51.6844848,
          51.5060241,
          46.918443
        ],
        [
          'C',
          56.4627076,
          42.1524012,
          64.2940329,
          38.387341,
          75,
          35.6232623
        ],
        [
          'L',
          75,
          0
        ],
        [
          'C',
          45.6104418,
          1.22026568,
          26.3975904,
          8.20203892,
          17.3614458,
          20.9453197
        ],
        [
          'C',
          8.3253012,
          33.6886006,
          2.53815261,
          51.6141489,
          0,
          74.7219648
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_6_10_1: [
    {
      type: 'path',
      version: '3.6.3',
      left: -38,
      top: -38,
      width: 75,
      height: 74.72,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          -2.48689958e-13,
          74.7219648
        ],
        [
          'L',
          44.2771084,
          74.7219648
        ],
        [
          'C',
          44.1397021,
          60.9523254,
          46.5493406,
          51.6844848,
          51.5060241,
          46.918443
        ],
        [
          'C',
          56.4627076,
          42.1524012,
          64.2940329,
          38.387341,
          75,
          35.6232623
        ],
        [
          'L',
          75,
          -6.14892752e-14
        ],
        [
          'L',
          -2.48689958e-13,
          -6.14892752e-14
        ],
        [
          'L',
          -2.48689958e-13,
          74.7219648
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -39,
      top: -39,
      width: 75,
      height: 74.72,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          -2.48689958e-13,
          74.7219648
        ],
        [
          'L',
          44.2771084,
          74.7219648
        ],
        [
          'C',
          44.1397021,
          60.9523254,
          46.5493406,
          51.6844848,
          51.5060241,
          46.918443
        ],
        [
          'C',
          56.4627076,
          42.1524012,
          64.2940329,
          38.387341,
          75,
          35.6232623
        ],
        [
          'L',
          75,
          -6.14892752e-14
        ],
        [
          'L',
          -2.48689958e-13,
          -6.14892752e-14
        ],
        [
          'L',
          -2.48689958e-13,
          74.7219648
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_6_10_3: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -38,
      top: -38,
      width: 75,
      height: 75,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 75
        },
        {
          x: 0,
          y: 0
        },
        {
          x: 75,
          y: 0
        },
        {
          x: 75,
          y: 45.4545455
        },
        {
          x: 57.5757576,
          y: 45.4545455
        },
        {
          x: 47.7272727,
          y: 59.8484848
        },
        {
          x: 47.7272727,
          y: 75
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -39,
      top: -39,
      width: 75,
      height: 75,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 75
        },
        {
          x: 0,
          y: 0
        },
        {
          x: 75,
          y: 0
        },
        {
          x: 75,
          y: 45.4545455
        },
        {
          x: 57.5757576,
          y: 45.4545455
        },
        {
          x: 47.7272727,
          y: 59.8484848
        },
        {
          x: 47.7272727,
          y: 75
        }
      ]
    }
  ],
  idelchik_6_11: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -50.5,
      top: -34.5,
      width: 100,
      height: 68,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 7.55331302e-13
        },
        {
          x: 0,
          y: 39.0804598
        },
        {
          x: 44.53125,
          y: 39.0804598
        },
        {
          x: 66.6362297,
          y: 51.3254732
        },
        {
          x: 67.1875,
          y: 68
        },
        {
          x: 100,
          y: 68
        },
        {
          x: 99.21875,
          y: 24.2298851
        },
        {
          x: 56.25,
          y: 0
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -51.5,
      top: -35.5,
      width: 100,
      height: 68,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 7.55331302e-13
        },
        {
          x: 0,
          y: 39.0804598
        },
        {
          x: 44.53125,
          y: 39.0804598
        },
        {
          x: 66.6362297,
          y: 51.3254732
        },
        {
          x: 67.1875,
          y: 68
        },
        {
          x: 100,
          y: 68
        },
        {
          x: 99.21875,
          y: 24.2298851
        },
        {
          x: 56.25,
          y: 0
        }
      ]
    }
  ],
  idelchik_6_13_1: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -50.5,
      top: -25,
      width: 100,
      height: 49.36,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: -3.52658269e-12
        },
        {
          x: 0,
          y: 20.5128205
        },
        {
          x: 32,
          y: 20.5128205
        },
        {
          x: 32,
          y: 49.3589744
        },
        {
          x: 100,
          y: 49.3589744
        },
        {
          x: 100,
          y: 30.7692308
        },
        {
          x: 70.5384615,
          y: 30.7692308
        },
        {
          x: 70.5384615,
          y: 1.17239551e-13
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -51.5,
      top: -26,
      width: 100,
      height: 49.36,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: -3.52658269e-12
        },
        {
          x: 0,
          y: 20.5128205
        },
        {
          x: 32,
          y: 20.5128205
        },
        {
          x: 32,
          y: 49.3589744
        },
        {
          x: 100,
          y: 49.3589744
        },
        {
          x: 100,
          y: 30.7692308
        },
        {
          x: 70.5384615,
          y: 30.7692308
        },
        {
          x: 70.5384615,
          y: 1.17239551e-13
        }
      ]
    }
  ],
  idelchik_6_13_2: [
    {
      type: 'rect',
      version: '3.6.3',
      left: -50.5,
      top: -33.18,
      width: 66.01,
      height: 33.65,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: 50.5,
      top: -33.18,
      width: 65.35,
      height: 33.99,
      fill: '#393C4B',
      stroke: '',
      angle: 90,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 50.3300654,
          y: 16.0065359
        },
        {
          x: 115.683007,
          y: 16.0065359
        },
        {
          x: 115.683007,
          y: 49.9934641
        },
        {
          x: 50.3300654,
          y: 49.9934641
        }
      ]
    },
    {
      type: 'rect',
      version: '3.6.3',
      left: -51.5,
      top: -34.18,
      width: 66.01,
      height: 33.65,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: 51.5,
      top: -34.18,
      width: 65.35,
      height: 33.99,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      angle: 90,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 50.3300654,
          y: 16.0065359
        },
        {
          x: 115.683007,
          y: 16.0065359
        },
        {
          x: 115.683007,
          y: 49.9934641
        },
        {
          x: 50.3300654,
          y: 49.9934641
        }
      ]
    }
  ],
  idelchik_6_13_3: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -50.5,
      top: -26,
      width: 99.86,
      height: 51.44,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 8.1106094e-13,
          y: 7.3618465e-13
        },
        {
          x: 0.729927007,
          y: 31.4929577
        },
        {
          x: 32.1167883,
          y: 32.2253521
        },
        {
          x: 51.8248175,
          y: 51.2676056
        },
        {
          x: 99.8575287,
          y: 51.4381921
        },
        {
          x: 99.3809186,
          y: 21.8590725
        },
        {
          x: 69.3430657,
          y: 21.2394366
        },
        {
          x: 43.0656934,
          y: 5.20397497e-15
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -51.5,
      top: -27,
      width: 99.86,
      height: 51.44,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 8.1106094e-13,
          y: 7.3618465e-13
        },
        {
          x: 0.729927007,
          y: 31.4929577
        },
        {
          x: 32.1167883,
          y: 32.2253521
        },
        {
          x: 51.8248175,
          y: 51.2676056
        },
        {
          x: 99.8575287,
          y: 51.4381921
        },
        {
          x: 99.3809186,
          y: 21.8590725
        },
        {
          x: 69.3430657,
          y: 21.2394366
        },
        {
          x: 43.0656934,
          y: 5.20397497e-15
        }
      ]
    }
  ],
  idelchik_7_20: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -50.5,
      top: -30.5,
      width: 100,
      height: 60,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0.680272109,
          y: 42.0689655
        },
        {
          x: 0,
          y: 0
        },
        {
          x: 100,
          y: 0
        },
        {
          x: 100,
          y: 26.2068966
        },
        {
          x: 80.2721088,
          y: 26.8965517
        },
        {
          x: 92.5170068,
          y: 46.8965517
        },
        {
          x: 71.4285714,
          y: 60
        },
        {
          x: 58.5034014,
          y: 42.0689655
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -51.5,
      top: -31.5,
      width: 100,
      height: 60,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0.680272109,
          y: 42.0689655
        },
        {
          x: 0,
          y: 0
        },
        {
          x: 100,
          y: 0
        },
        {
          x: 100,
          y: 26.2068966
        },
        {
          x: 80.2721088,
          y: 26.8965517
        },
        {
          x: 92.5170068,
          y: 46.8965517
        },
        {
          x: 71.4285714,
          y: 60
        },
        {
          x: 58.5034014,
          y: 42.0689655
        }
      ]
    }
  ],
  idelchik_7_21: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -50.5,
      top: -35,
      width: 100,
      height: 68.57,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: -5.18696197e-13
        },
        {
          x: 100,
          y: -5.18696197e-13
        },
        {
          x: 100,
          y: 39.4655704
        },
        {
          x: 74.6709024,
          y: 39.4655704
        },
        {
          x: 74.6709024,
          y: 68.5714286
        },
        {
          x: 25.2217484,
          y: 68.5714286
        },
        {
          x: 25.2217484,
          y: 39.4655704
        },
        {
          x: 0,
          y: 39.4655704
        }
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -50,
      top: -34.5,
      width: 98,
      height: 66.57,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          99,
          1
        ],
        [
          'L',
          1,
          1
        ],
        [
          'L',
          1,
          38.4655704
        ],
        [
          'L',
          26.2217484,
          38.4655704
        ],
        [
          'L',
          26.2217484,
          67.5714286
        ],
        [
          'L',
          73.6709024,
          67.5714286
        ],
        [
          'L',
          73.6709024,
          38.4655704
        ],
        [
          'L',
          99,
          38.4655704
        ],
        [
          'L',
          99,
          1
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_7_29: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -50.5,
      top: -35,
      width: 100,
      height: 68.57,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: -5.18696197e-13
        },
        {
          x: 100,
          y: -5.18696197e-13
        },
        {
          x: 100,
          y: 39.4655704
        },
        {
          x: 74.6709024,
          y: 39.4655704
        },
        {
          x: 74.6709024,
          y: 68.5714286
        },
        {
          x: 25.2217484,
          y: 68.5714286
        },
        {
          x: 25.2217484,
          y: 39.4655704
        },
        {
          x: 0,
          y: 39.4655704
        }
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -50,
      top: -34.5,
      width: 98,
      height: 66.57,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          99,
          1
        ],
        [
          'L',
          1,
          1
        ],
        [
          'L',
          1,
          38.4655704
        ],
        [
          'L',
          26.2217484,
          38.4655704
        ],
        [
          'L',
          26.2217484,
          67.5714286
        ],
        [
          'L',
          73.6709024,
          67.5714286
        ],
        [
          'L',
          73.6709024,
          38.4655704
        ],
        [
          'L',
          99,
          38.4655704
        ],
        [
          'L',
          99,
          1
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_7_30: [
    {
      type: 'polygon',
      version: '3.6.3',
      left: -51,
      top: -38,
      width: 100,
      height: 75,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 25.3759398
        },
        {
          x: 0,
          y: 54.6992481
        },
        {
          x: 53.5294118,
          y: 54.6992481
        },
        {
          x: 81.1764706,
          y: 75
        },
        {
          x: 100,
          y: 54.6992481
        },
        {
          x: 78.2352941,
          y: 37.7819549
        },
        {
          x: 98.8235294,
          y: 17.481203
        },
        {
          x: 78.2352941,
          y: 0
        },
        {
          x: 53.5294118,
          y: 24.8120301
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      left: -52,
      top: -39,
      width: 100,
      height: 75,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      points: [
        {
          x: 0,
          y: 25.3759398
        },
        {
          x: 0,
          y: 54.6992481
        },
        {
          x: 53.5294118,
          y: 54.6992481
        },
        {
          x: 81.1764706,
          y: 75
        },
        {
          x: 100,
          y: 54.6992481
        },
        {
          x: 78.2352941,
          y: 37.7819549
        },
        {
          x: 98.8235294,
          y: 17.481203
        },
        {
          x: 78.2352941,
          y: 0
        },
        {
          x: 53.5294118,
          y: 24.8120301
        }
      ]
    }
  ],
  idelchik_7_32: [
    {
      type: 'path',
      version: '3.6.3',
      left: -50.5,
      top: -35.5,
      width: 100,
      height: 70,
      fill: '#393C4B',
      stroke: '',
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          0,
          0.515916463
        ],
        [
          'C',
          22.7736379,
          -0.355507195,
          35.1337141,
          -0.123003309,
          37.0802284,
          1.21342812
        ],
        [
          'C',
          38.9688962,
          2.51014345,
          42.8702404,
          5.29771867,
          48.784261,
          9.57615381
        ],
        [
          'L',
          48.7842493,
          9.57616999
        ],
        [
          'C',
          49.1159817,
          9.81615822,
          49.5604964,
          9.82968338,
          49.9062057,
          9.61030753
        ],
        [
          'C',
          56.685319,
          5.30850354,
          60.9700108,
          2.4346408,
          62.760281,
          0.988719317
        ],
        [
          'C',
          68.0312048,
          0.673517414,
          80.4444444,
          0.515916463,
          100,
          0.515916463
        ],
        [
          'L',
          100,
          40.5067559
        ],
        [
          'L',
          84.6709024,
          40.5067559
        ],
        [
          'C',
          79.1480549,
          40.5067559,
          74.6709024,
          44.9839084,
          74.6709024,
          50.5067559
        ],
        [
          'L',
          74.6709024,
          70
        ],
        [
          'L',
          74.6709024,
          70
        ],
        [
          'L',
          25.2217484,
          70
        ],
        [
          'L',
          25.2217484,
          50.5067559
        ],
        [
          'C',
          25.2217484,
          44.9839084,
          20.7445959,
          40.5067559,
          15.2217484,
          40.5067559
        ],
        [
          'L',
          0,
          40.5067559
        ],
        [
          'L',
          0,
          40.5067559
        ],
        [
          'L',
          0,
          0.515916463
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      left: -50,
      top: -35.08,
      width: 97,
      height: 67.08,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      fillRule: 'evenodd',
      hasControls: true,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      selectable: true,
      path: [
        [
          'M',
          36.2312107,
          2.45002378
        ],
        [
          'C',
          34.3734981,
          1.17456157,
          22.7013687,
          1.16824705,
          1.5,
          1.96026296
        ],
        [
          'L',
          1.5,
          39.0067559
        ],
        [
          'L',
          15.2217484,
          39.0067559
        ],
        [
          'C',
          18.3973857,
          39.0067559,
          21.2723857,
          40.2939373,
          23.3534764,
          42.3750279
        ],
        [
          'C',
          25.4345671,
          44.4561186,
          26.7217484,
          47.3311186,
          26.7217484,
          50.5067559
        ],
        [
          'L',
          26.7217484,
          68.5
        ],
        [
          'L',
          73.1709024,
          68.5
        ],
        [
          'L',
          73.1709024,
          50.5067559
        ],
        [
          'C',
          73.1709024,
          47.3311186,
          74.4580838,
          44.4561186,
          76.5391744,
          42.3750279
        ],
        [
          'C',
          78.6202651,
          40.2939373,
          81.4952651,
          39.0067559,
          84.6709024,
          39.0067559
        ],
        [
          'L',
          98.5,
          39.0067559
        ],
        [
          'L',
          98.4999893,
          2.0162292
        ],
        [
          'C',
          80.4028151,
          2.0238488,
          68.6755894,
          2.17072438,
          63.3184344,
          2.45945623
        ],
        [
          'C',
          61.3187796,
          4.00226447,
          57.1180511,
          6.81042504,
          50.709899,
          10.8768292
        ],
        [
          'C',
          50.2777675,
          11.1510457,
          49.7838834,
          11.2797008,
          49.2943671,
          11.2648064
        ],
        [
          'C',
          48.8048507,
          11.249912,
          48.3197021,
          11.0914681,
          47.9050416,
          10.7914864
        ],
        [
          'L',
          47.4653493,
          10.4734362
        ],
        [
          'C',
          41.8124676,
          6.38561176,
          38.0677876,
          3.71097471,
          36.2312107,
          2.45002378
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  idelchik_4_9: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -48,
      top: -25.5,
      width: 95,
      height: 49.74,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 49.744898
        },
        {
          x: 0,
          y: 0.139543526
        },
        {
          x: 64,
          y: 0
        },
        {
          x: 64,
          y: 12
        },
        {
          x: 95,
          y: 12
        },
        {
          x: 95,
          y: 35
        },
        {
          x: 64,
          y: 35
        },
        {
          x: 64,
          y: 49.744898
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -49,
      top: -26.5,
      width: 95,
      height: 49.74,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 49.744898
        },
        {
          x: 0,
          y: 0.139543526
        },
        {
          x: 64,
          y: 0
        },
        {
          x: 64,
          y: 12
        },
        {
          x: 95,
          y: 12
        },
        {
          x: 95,
          y: 35
        },
        {
          x: 64,
          y: 35
        },
        {
          x: 64,
          y: 49.744898
        }
      ]
    }
  ],
  idelchik_6_9: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -51,
      top: -32,
      width: 100,
      height: 62,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 7.88362431e-13
        },
        {
          x: 0,
          y: 40.7894737
        },
        {
          x: 47.107438,
          y: 40.7894737
        },
        {
          x: 75.2066116,
          y: 62
        },
        {
          x: 100,
          y: 31.8157895
        },
        {
          x: 59.5041322,
          y: 0
        }
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -52,
      top: -33,
      width: 100,
      height: 62,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          0,
          0
        ],
        [
          'L',
          0,
          40.7894737
        ],
        [
          'L',
          39.0661671,
          40.7894737
        ],
        [
          'C',
          44.2844569,
          40.7894737,
          49.360572,
          42.490241,
          53.5254975,
          45.6341151
        ],
        [
          'L',
          75.2066116,
          62
        ],
        [
          'L',
          75.2066116,
          62
        ],
        [
          'L',
          100,
          31.8157895
        ],
        [
          'L',
          66.0309371,
          5.12781826
        ],
        [
          'C',
          61.8025986,
          1.80580206,
          56.5811426,
          9.6703593e-15,
          51.2039092,
          0
        ],
        [
          'L',
          0,
          0
        ],
        [
          'L',
          0,
          0
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  custom_k_one: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -33,
      top: -25.5,
      width: 65,
      height: 49.74,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 49.744898
        },
        {
          x: 0,
          y: 0.139543526
        },
        {
          x: 65,
          y: 1.38069828e-15
        },
        {
          x: 65,
          y: 49.744898
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -34,
      top: -26.5,
      width: 65,
      height: 49.74,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 49.744898
        },
        {
          x: 0,
          y: 0.139543526
        },
        {
          x: 65,
          y: 1.38069828e-15
        },
        {
          x: 65,
          y: 49.744898
        }
      ]
    }
  ],
  custom_k_two: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -51.5,
      top: -31,
      width: 102,
      height: 59.98,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 29.7458698
        },
        {
          x: 0,
          y: -1.30060989e-17
        },
        {
          x: 102,
          y: 0.80952381
        },
        {
          x: 102,
          y: 29.7458698
        },
        {
          x: 81.7619048,
          y: 29.7458698
        },
        {
          x: 91.4761905,
          y: 42.6982507
        },
        {
          x: 67.8214698,
          y: 59.9800443
        },
        {
          x: 47.4785774,
          y: 30.0108552
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -52.5,
      top: -32,
      width: 102,
      height: 59.98,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 29.7458698
        },
        {
          x: 0,
          y: -1.30060989e-17
        },
        {
          x: 102,
          y: 0.80952381
        },
        {
          x: 102,
          y: 29.7458698
        },
        {
          x: 81.7619048,
          y: 29.7458698
        },
        {
          x: 91.4761905,
          y: 42.6982507
        },
        {
          x: 67.8214698,
          y: 59.9800443
        },
        {
          x: 47.4785774,
          y: 30.0108552
        }
      ]
    }
  ],
  burner_eductor: [
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -99.5,
      top: -15.24,
      width: 51.83,
      height: 54.54,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          -1.03673146e-13,
          54.7445255
        ],
        [
          'L',
          28.6694222,
          54.7445255
        ],
        [
          'C',
          29.0557436,
          44.6936939,
          31.0579129,
          37.9288467,
          34.67593,
          34.4499841
        ],
        [
          'C',
          38.2939471,
          30.9711215,
          44.010243,
          28.2229023,
          51.8248175,
          26.2053266
        ],
        [
          'L',
          51.8248175,
          0.202945414
        ],
        [
          'C',
          30.3725852,
          1.09365029,
          16.3486061,
          6.18983514,
          9.75288013,
          15.4915
        ],
        [
          'C',
          3.15715416,
          24.7931648,
          -0.0938058805,
          37.8775067,
          -1.03673146e-13,
          54.7445255
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -100.1,
      top: -15.84,
      width: 51.83,
      height: 54.54,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2.19,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          -1.03673146e-13,
          54.7445255
        ],
        [
          'L',
          28.6694222,
          54.7445255
        ],
        [
          'C',
          29.0557436,
          44.6936939,
          31.0579129,
          37.9288467,
          34.67593,
          34.4499841
        ],
        [
          'C',
          38.2939471,
          30.9711215,
          44.010243,
          28.2229023,
          51.8248175,
          26.2053266
        ],
        [
          'L',
          51.8248175,
          0.202945414
        ],
        [
          'C',
          30.3725852,
          1.09365029,
          16.3486061,
          6.18983514,
          9.75288013,
          15.4915
        ],
        [
          'C',
          3.15715416,
          24.7931648,
          -0.0938058805,
          37.8775067,
          -1.03673146e-13,
          54.7445255
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: 101.5,
      top: 11.28,
      width: 65.69,
      height: 49.78,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: -180,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          134.306569,
          78.9995468
        ],
        [
          'L',
          165.763785,
          78.9995468
        ],
        [
          'C',
          165.666163,
          69.2167373,
          167.37812,
          62.632286,
          170.899657,
          59.2461931
        ],
        [
          'C',
          174.421194,
          55.8601001,
          184.121308,
          54.4017118,
          200,
          54.8710282
        ],
        [
          'L',
          200,
          29.5620438
        ],
        [
          'C',
          170.847321,
          27.9959065,
          153.061062,
          31.7396481,
          146.641222,
          40.7932685
        ],
        [
          'C',
          140.221382,
          49.846889,
          136.109831,
          62.582315,
          134.306569,
          78.9995468
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: 99.91,
      top: 11.88,
      width: 63.5,
      height: 49.78,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2.19,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: -180,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          134.306569,
          78.9995468
        ],
        [
          'L',
          162.11415,
          78.9995468
        ],
        [
          'C',
          162.989764,
          69.2167373,
          165.188339,
          62.632286,
          168.709876,
          59.2461931
        ],
        [
          'C',
          172.231413,
          55.8601001,
          181.931527,
          54.4017118,
          197.810219,
          54.8710282
        ],
        [
          'L',
          197.810219,
          29.5620438
        ],
        [
          'C',
          168.65754,
          27.9959065,
          150.871281,
          31.7396481,
          144.451441,
          40.7932685
        ],
        [
          'C',
          138.031601,
          49.846889,
          134.649977,
          62.582315,
          134.306569,
          78.9995468
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -5.8,
      top: 11.3,
      width: 38.69,
      height: 25.55,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: -180,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 54.0145985,
          y: 48.3576642
        },
        {
          x: 54.0145985,
          y: 36.5923934
        },
        {
          x: 92.7007299,
          y: 29.1970803
        },
        {
          x: 92.7007299,
          y: 54.7445255
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -5.2,
      top: 11.9,
      width: 38.69,
      height: 25.4,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2.19,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: -180,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 54.0145985,
          y: 48.3576642
        },
        {
          x: 54.0145985,
          y: 36.5923934
        },
        {
          x: 92.7007299,
          y: 29.1970803
        },
        {
          x: 92.7007299,
          y: 54.5985401
        }
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -6.07,
      top: -14.88,
      width: 38.69,
      height: 25.18,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: true,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          93.4306569,
          48.0839416
        ],
        [
          'L',
          93.4306569,
          36.4867461
        ],
        [
          'L',
          116.733067,
          34.307023
        ],
        [
          'C',
          117.254649,
          34.2582339,
          117.768523,
          34.147232,
          118.263738,
          33.9763825
        ],
        [
          'L',
          132.116788,
          29.1970803
        ],
        [
          'L',
          132.116788,
          29.1970803
        ],
        [
          'L',
          132.116788,
          54.379562
        ],
        [
          'L',
          117.518248,
          49.3430657
        ],
        [
          'L',
          93.4306569,
          48.0839416
        ],
        [
          'Z'
        ]
      ]
    },
    {
      type: 'path',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -6.66,
      top: -15.55,
      width: 38.69,
      height: 24.45,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 2.19,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: true,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      path: [
        [
          'M',
          93.4306569,
          48.0839416
        ],
        [
          'L',
          93.4306569,
          36.4867461
        ],
        [
          'L',
          116.898336,
          34.2915636
        ],
        [
          'C',
          117.310687,
          34.2529921,
          117.718466,
          34.1755134,
          118.116229,
          34.0601622
        ],
        [
          'L',
          132.116788,
          30
        ],
        [
          'L',
          132.116788,
          30
        ],
        [
          'L',
          132.116788,
          54.4525547
        ],
        [
          'L',
          117.518248,
          49.3430657
        ],
        [
          'L',
          93.4306569,
          48.0839416
        ],
        [
          'Z'
        ]
      ]
    }
  ],
  k_factor_equation: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -50.82,
      top: -30.09,
      width: 100,
      height: 59.21,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 59.2105263
        },
        {
          x: 100,
          y: 59.2105263
        },
        {
          x: 100,
          y: 0
        },
        {
          x: 0,
          y: 0
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -52.32,
      top: -31.59,
      width: 100,
      height: 59.21,
      fill: '',
      stroke: '#E9EAEB',
      strokeWidth: 4,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 59.2105263
        },
        {
          x: 100,
          y: 59.2105263
        },
        {
          x: 100,
          y: 0
        },
        {
          x: 0,
          y: 0
        }
      ]
    },
    {
      type: 'line',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -12.22,
      top: -23.47,
      width: 28.55,
      height: 0.7,
      fill: '',
      stroke: '#E9EAEB',
      strokeWidth: 2,
      strokeDashArray: null,
      strokeLineCap: 'square',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 28,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      x1: -14.273715800000002,
      x2: 14.273715800000002,
      y1: -0.3508850499999996,
      y2: 0.3508850499999996
    },
    {
      type: 'line',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -13.49,
      top: 21.13,
      width: 28.55,
      height: 0.7,
      fill: '',
      stroke: '#E9EAEB',
      strokeWidth: 2,
      strokeDashArray: null,
      strokeLineCap: 'square',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: -28,
      flipX: false,
      flipY: true,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      x1: -14.273715800000002,
      x2: 14.273715800000002,
      y1: -0.35088505000000225,
      y2: 0.35088505000000225
    }
  ],
  idelchik_5_4: [
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -50.5,
      top: -12.8,
      width: 24.59,
      height: 24.46,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 24.4647039
        },
        {
          x: 0,
          y: 0.0686279636
        },
        {
          x: 24.5901639,
          y: 6.79031939e-16
        },
        {
          x: 24.5901639,
          y: 24.4647039
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -51.5,
      top: -13.8,
      width: 24.59,
      height: 24.46,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 24.4647039
        },
        {
          x: 0,
          y: 0.0686279636
        },
        {
          x: 24.5901639,
          y: 6.79031939e-16
        },
        {
          x: 24.5901639,
          y: 24.4647039
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -26.59,
      top: -21.3,
      width: 32.79,
      height: 38.5,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 4,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 31.8417531
        },
        {
          x: 0,
          y: 7.37704918
        },
        {
          x: 32.7868852,
          y: 0.380327869
        },
        {
          x: 32.7868852,
          y: 38.8803279
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: -25.09,
      top: -20.18,
      width: 32.79,
      height: 39.22,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 31.8417531
        },
        {
          x: 0,
          y: 7.37704918
        },
        {
          x: 32.7868852,
          y: -4.46942113e-12
        },
        {
          x: 32.7868852,
          y: 39.2188023
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: 8.52,
      top: -21,
      width: 40.98,
      height: 40.77,
      fill: '#393C4B',
      stroke: '',
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 40.7745065
        },
        {
          x: 0,
          y: 0.114379939
        },
        {
          x: 40.9836066,
          y: 1.1317199e-15
        },
        {
          x: 40.9836066,
          y: 40.7745065
        }
      ]
    },
    {
      type: 'polygon',
      version: '3.6.3',
      originX: 'left',
      originY: 'top',
      left: 7.52,
      top: -22,
      width: 40.98,
      height: 40.77,
      fill: '',
      stroke: '#DADADA',
      strokeWidth: 3,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'evenodd',
      paintFirst: 'fill',
      globalCompositeOperation: 'source-over',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      points: [
        {
          x: 0,
          y: 40.7745065
        },
        {
          x: 0,
          y: 0.114379939
        },
        {
          x: 40.9836066,
          y: 1.1317199e-15
        },
        {
          x: 40.9836066,
          y: 40.7745065
        }
      ]
    }
  ]
};
