import BaseService from './base-service';
import { IModelSummary } from '@/view-models/model-summary';
import { AxiosInstance } from 'axios';

export class HydraulicModelBuilderService extends BaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  public async loadModelSummaryList(customerKey: string): Promise<IModelSummary[]> {
    return this.httpClient.get<IModelSummary[]>('model-builder/model-summaries/customer/' + customerKey);
  }

  public async loadModelSummaryListByAsset(customerKey: string, assetKey: string): Promise<IModelSummary[]> {
    return this.httpClient.get<IModelSummary[]>(
      'model-builder/model-summaries/customer/' + customerKey + '/asset/' + assetKey
    );
  }

  public async getModelSummary(customerKey: string, assetKey: string, modelName: string): Promise<IModelSummary> {
    return this.httpClient.get<IModelSummary>(
      'model-builder/model-summary/customer/' +
        customerKey +
        '/asset/' +
        assetKey +
        '/model/' +
        modelName
    );
  }

  public async saveModelSummaryItem(
    summaryItem: IModelSummary,
    overwriteDuplicateModel: boolean,
    saveCompleteCheckOnly: boolean
  ): Promise<IModelSummary> {
    const model = {
      modelSummary: summaryItem,
      resetDetails: overwriteDuplicateModel,
      saveCompleteCheckOnly,
    };
    return this.httpClient.post<IModelSummary>('model-builder/model-summary/', model);
  }

  public async deleteModelSummaryItem(summaryItem: IModelSummary): Promise<IModelSummary> {
    summaryItem.markedForDelete = true;
    const model = {
      modelSummary: summaryItem,
      resetDetails: false,
    };
    return this.httpClient.post<IModelSummary>('model-builder/model-summary/', model);
  }

  public async hideOrUnhideModelSummaryItem(summaryItem: IModelSummary, hide: boolean): Promise<IModelSummary> {
    summaryItem.hidden = hide;
    const model = {
      modelSummary: summaryItem,
      resetDetails: false,
      hideUnHideOnly: true
    };
    return this.httpClient.post<IModelSummary>('model-builder/model-summary/', model);
  }
}
