














































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { ITuningHistoryModel, TuningStatusType } from '@/view-models/hydraulic-tuning-history';
import EventBus, { hmbEvents, navigateTypes } from '@/components/hydraulicModelTuner/eventBus';
import ScatterChart from '@/components/hydraulicModelTuner/components/ScatterChart.vue';
import store from '@/store';
import { ITrainingCase } from '@/view-models/training-data-model';
import { ITuningResultModel } from '@/view-models/tuning-result-model';
import ConfirmDiscardNoteUpdatesModal from '@/components/hydraulicModelTuner/components/DiscardNoteUpdatesModal.vue';

@Component({
  name: 'ResultSummary',
  components: {
    ScatterChart,
    ConfirmDiscardNoteUpdatesModal
  }
})
export default class ResultSummary extends Vue {
  public tunerHistory: ITuningHistoryModel;
  private trainingCasesExpanded: boolean = false;
  private summaryTrainingCases: ITrainingCase[] = undefined;
  private tuningResult: ITuningResultModel = null;
  private originalEngineerNotes: string = null;
  private engineerNotes: string = null;
  private showDiscardNoteConfirmation: boolean = false;
  private navigateContext: string = '';
  private isTrainingCaseDeleted: boolean = false;
  private isHistoryHidden?: boolean = false;
  private deletedTrainingCaseKeys: [string] = [''];
  private message: string = '';
  private isPublishing = false;

  public created(): void {
    this.tunerHistory = store.getters['tuner/getCurrentTuningHistory'];
  }

  public async mounted(): Promise<void> {
    EventBus.$emit(hmbEvents.tuningSummaryLoaded, true);
    EventBus.$on(hmbEvents.hideAllInfo, this.hideAllInfo);
    EventBus.$on(hmbEvents.navigateNotesCheck, (context: string) => {
      this.navigateContext = context;
      this.confirmDiscardNote();
    });

    try {
      await store.dispatch('tuner/loadTuningResult');
    } catch (ex) {
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'Error Loading Tuning Results In Tuning Summary\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }
    this.tuningResult = store.getters['tuner/getTuningResult'];

    // get the training cases from the store and match them up with tunerHistory.trainingDataKeys
    const tunerTrainingCases = store.getters['tuner/getTrainingData'];
    this.summaryTrainingCases = tunerTrainingCases
      ?.filter((trainingCase: ITrainingCase) => this.tunerHistory.trainingDataKeys?.includes(trainingCase.key));

    // Check if any training cases were deleted
    if (this.summaryTrainingCases) {
      this.isTrainingCaseDeleted = this.summaryTrainingCases.some((traningCase) => traningCase.markedForDelete);
    }

    // see exactly which training cases were deleted
    if (this.summaryTrainingCases && this.isTrainingCaseDeleted) {
      this.summaryTrainingCases.forEach((trainingCase) => {
        if (trainingCase.markedForDelete) {
          this.deletedTrainingCaseKeys.push(trainingCase.key);
        }
      });
    }

    this.isHistoryHidden = this.tunerHistory.hidden;
    this.engineerNotes = this.tunerHistory.engineerNotes ?? '';
    this.originalEngineerNotes = this.engineerNotes;
  }

  private beforeDestroy(): void {
    EventBus.$emit(hmbEvents.tuningSummaryLoaded, false);
    EventBus.$off(hmbEvents.navigateNotesCheck);
  }

  private confirmDiscardNote(): void {
    if ((this.engineerNotes?.trim() ?? '') !== (this.originalEngineerNotes?.trim() ?? '')) {
      this.$bvModal.show('confirmDiscardNoteUpdates');
    } else {
      switch (this.navigateContext) {
        case navigateTypes.goBack:
          EventBus.$emit(hmbEvents.newTunerLoadHistory);
          EventBus.$emit(hmbEvents.navigateGoBack);
          break;
        case navigateTypes.home:
          EventBus.$emit(hmbEvents.navigateHome);
          break;
        case navigateTypes.trainingData:
          EventBus.$emit(hmbEvents.navigateTrainingData);
          break;
        case navigateTypes.newModelTuning:
          EventBus.$emit(hmbEvents.navigateNewModelTuning);
          break;
        case navigateTypes.retrainModel:
          EventBus.$emit(hmbEvents.retrainModel);
          break;
        case navigateTypes.toggleResultDetails:
          EventBus.$emit(hmbEvents.toggleResultDetails);
          break;
        default:
          break;
      }
    }
  }

  private async publish(): Promise<void> {
    try {
      this.isPublishing = true;
      const modelName = this.tunerHistory.modelName;
      const response =  await store.dispatch('tuner/publishTuningHistory');
      this.tunerHistory = response.data;
      this.tunerHistory.currentlyPublished = this.tunerHistory.published;
      this.tunerHistory.modelName = modelName;
      store.commit('tuner/setCurrentTuningHistory', this.tunerHistory);
      this.message = response.message;
      (this.$refs['successful-publish-modal'] as BModal)?.show();
      window.setTimeout(() =>
        (this.$refs['successful-publish-modal'] as BModal)?.hide()
      , 1200);
    } catch (ex) {
      this.message = '';
    } finally {
      this.isPublishing = false;
    }
  }

  private statusComplete(): boolean {
    return this.tunerHistory.tuningStatus === TuningStatusType.COMPLETE;
  }

  private exportFile(): void {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.tuningResult));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', 'tuningResults' + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  private returnToHistory(): void {
    EventBus.$emit(hmbEvents.hideTuningSummary);
  }

  private toggleInfoModal(index: number): void {
    // if user clicked info icon of already open modal, close it.
    // else hide all the other modals and open the one they clicked
    const modalWindow = this.$refs['show-info-modal' + index] as BModal[];
    if (modalWindow[0].isVisible) {
      this.$bvModal.hide('show-info-modal' + index);
    } else {
      this.hideAllInfo();
      this.$bvModal.show('show-info-modal' + index);
    }
  }

  private toggleWarningModal(): void {
    const modalWindow = this.$refs.warningModalTrainingCaseDeleted as BModal;
    if (modalWindow.isVisible) {
      this.$bvModal.hide('warning-modal-training-case-deleted');
    } else {
      this.hideAllInfo();
      this.$bvModal.show('warning-modal-training-case-deleted');
    }
  }

  private toggleIndividualWarningModal(index: number): void {
    const modalWindow = this.$refs['individual-warning-modal-training-case-deleted' + index] as BModal[];
    if (modalWindow[0].isVisible) {
      this.$bvModal.hide('individual-warning-modal-training-case-deleted' + index);
    } else {
      this.hideAllInfo();
      this.$bvModal.show('individual-warning-modal-training-case-deleted' + index);
    }
  }

  private findInfoModalPosition(index: number): void {
    // places the modal right below the info icon they clicked on, wherever it is on the screen
    const infoIconRef = this.$refs['infoIconRef' + index] as Element[];
    const left = infoIconRef[0].getBoundingClientRect().left;
    const top = infoIconRef[0].getBoundingClientRect().top;
    const modalWindow: HTMLElement = document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.querySelector('#show-info-modal' + index + '___BV_modal_content_');
    modalWindow.style.position = 'fixed';
    modalWindow.style.left = (left - 220) + 'px';
    modalWindow.style.top = (top + 25) + 'px';
  }

  private findIndividualWarningModalPosition(index: number): void {
    // places the modal right below the info icon they clicked on, wherever it is on the screen
    const individualWarningIconRef = this.$refs['individualWarningRef' + index] as Element[];
    const left = individualWarningIconRef[0].getBoundingClientRect().left;
    const top = individualWarningIconRef[0].getBoundingClientRect().top;
    const modalWindow: HTMLElement = document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.querySelector('#individual-warning-modal-training-case-deleted' + index + '___BV_modal_content_');
    modalWindow.style.position = 'fixed';
    modalWindow.style.left = (left + 20) + 'px';
    modalWindow.style.top = (top + 25) + 'px';
  }

  private findWarningModalPosition(): void {
    // places the modal right below the warning icon they clicked on, wherever it is on the screen
    const warningIconRef = this.$refs.warningIconRef as Element;
    const left = warningIconRef.getBoundingClientRect().left;
    const top = warningIconRef.getBoundingClientRect().top;
    const modalWindow: HTMLElement = document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.querySelector('#warning-modal-training-case-deleted___BV_modal_content_');
    modalWindow.style.position = 'fixed';
    modalWindow.style.left = (left + 20) + 'px';
    modalWindow.style.top = (top + 25) + 'px';
  }

  private async hideAllInfo(): Promise<void> {
    // allows click anywhere on the app to close modal
    if (this.summaryTrainingCases) {
      for (let i = 0; i < this.summaryTrainingCases.length; i++) {
        const ref = this.$refs[`show-info-modal${i}`] as BModal;
        if (ref && ref.length > 0) {
          ref[0].hide();
        }
      }
    }
    if (this.deletedTrainingCaseKeys) {
      for (let i = 0; i < this.deletedTrainingCaseKeys.length; i++) {
        const ref = this.$refs[`individual-warning-modal-training-case-deleted${i}`] as BModal;
        if (ref && ref.length > 0) {
          ref[0].hide();
        }
      }
    }
    this.$bvModal.hide('warning-modal-training-case-deleted');
  }

  private disablePublish(): boolean {
    return this.tunerHistory.currentlyPublished ||
      this.isTrainingCaseDeleted ||
      this.isHistoryHidden ||
      (this.tuningResult != null && this.tuningResult.solverError) ||
      !this.statusComplete();
  }

  get tuningDuration(): string {
    let startTime: Date = null;
    let endTime: Date = null;
    let timeDifference: number = null;

    if (this.tunerHistory.tuningStartDateTime) {
      startTime = new Date(this.tunerHistory.tuningStartDateTime);
    }
    if (this.tunerHistory.tuningCompletionDateTime) {
      endTime = new Date(this.tunerHistory.tuningCompletionDateTime);
    }

    if (startTime && endTime) {
      timeDifference = endTime.getTime() - startTime.getTime();
    }

    if (timeDifference !== null) {
      const minutes: number = Math.floor((timeDifference / (1000 * 60)) % 60);
      const hours: number = Math.floor((timeDifference / (1000 * 60 * 60)));

      const minuteString: string = (minutes < 10) ? '0' + minutes : minutes.toString();
      const hourString: string = (hours === 0) ? '00' : hours.toString();

      return hourString + ':' + minuteString;
    }

    return 'N/A';
  }

  private tryRetrain(): void {
    store.commit('tuner/setShouldRetrain', true);
    this.navigateContext = navigateTypes.retrainModel;
    this.confirmDiscardNote();
  }

  private saveNote(): void {
    this.tunerHistory.engineerNotes = this.engineerNotes;
    store.dispatch('tuner/saveTuningHistory', this.tunerHistory)
    .then(() => {
      this.originalEngineerNotes = this.engineerNotes;
    });
  }

  private cancelNote(): void {
    this.engineerNotes = this.tunerHistory.engineerNotes ?? '';
  }

  private engineerNotesCancelSaveDisabled(): boolean {
    return this.engineerNotes?.trim() === this.originalEngineerNotes?.trim();
  }
}
