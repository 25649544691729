


















































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ElementContextToolbar from './ElementContextToolbar.vue';
import BurnerSetupError from './modals/BurnerSetupError.vue';
import { IGridPointerPosition } from '@/view-models/grid-view-models';
import { Mode } from '@/view-models/mode';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { ElementAttributeModel } from '@/view-models/element-attribute-model';
import { IBurner, IRequiredBurnerSettupAttributes } from '@/view-models/burner-view-models';
import { getFlowElementId } from '@/store/grid/helper/gridStoreHelper';
import store from '@/store/';
import { fabric } from 'fabric';
import { dragscroll } from 'vue-dragscroll';
import { BootstrapVue, BModal } from 'bootstrap-vue';
import Loading from '../../common/Loading.vue';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import HelperMethods from '@/shared/helper-methods';

Vue.use(BootstrapVue);

@Component({
  name: 'Grid',
  components: {
    ElementContextToolbar,
    Loading,
    BurnerSetupError
  },
  directives: {
    dragscroll
  }
})
export default class Grid extends Vue {
  private newModel: boolean = false;
  private isLoading: boolean = false;
  private pointerPosition: IGridPointerPosition = { x: 0, y: 0 };
  private isScrolling: number = 0;
  private unSubscribeFlowElementConnection: (() => void) = null;
  private unSubscribeElementSelectionInPreviewMode: (() => void) = null;
  private unSubscribeModeSwitch: (() => void) = null;
  private mode: Mode = Mode.EDIT;
  private previewElement: FlowElementModel = null;
  private invalidBurnersExist: boolean = false;
  private showBurnerSetupError: boolean = true;
  private canvas: fabric.Canvas = {} as fabric.Canvas;

  private async mounted(): Promise<void> {
    this.newModel = store.getters['app/isNewModel'];
    // Set listener to show Burner Setup Error when this event is emitted
    this.showBurnerSetupError = true;
    this.isLoading = true;
    EventBus.$on(hmbEvents.navigateFromPreview, this.disableBurnerSetUpError);
    EventBus.$on(hmbEvents.burnerSetupError, this.showBurnerSetupErrorModal);
    // Initialize grid
    const canvasElement: HTMLElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('canvas');
    this.canvas = new fabric.Canvas(canvasElement as HTMLCanvasElement, { selection: false });
    store.commit('grid/updateEditorGrid', this.canvas);
    if (!this.newModel) {
      const customerKey = this.$route.params.customerKey;
      const assetKey = this.$route.params.assetKey;
      const modelName = this.$route.params.modelName;
      const permalinkSummary = await store.dispatch('model/getPermalinkSummaryItem',
        { customerKey, assetKey, modelName }
      );
      store.commit('model/selectModel', permalinkSummary);
      store.commit('diagram/setCurrentModelSummary', permalinkSummary);

      const canvasModel = await store.dispatch('diagram/loadDiagramDetails');
      const burnerElements = store.getters['diagram/getBurnerElements'] as FlowElementModel[];

      if (canvasModel) {
        if (canvasModel.gridBoxSize > 0) {
          store.commit('grid/updateEditorGridBoxSize', canvasModel.gridBoxSize);
        } else {
          store.commit('grid/resetEditorGridBoxSize');
          store.commit('grid/resetUnconnectedGroups');
        }
        await store.dispatch('grid/loadFromDatalessJSON',
          { json: canvasModel?.diagram, resize: this.resizeGrid }
        );
        await store.dispatch('grid/zoomFit');
      } else {
        store.commit('diagram/initializeDiagram');
      }

      // Check the need to update burner elo name.
      const needToUpdateEloName = store.getters['diagram/getNeedToUpdateEloName'] as boolean;
      if (needToUpdateEloName) {
        store.commit('diagram/setDiagramModified', true);
        await store.dispatch('diagram/saveDiagramDetails');
      }

      // Check burnerElements against all burners.  If any burnerElements have an ID that is not in
      // the list of all burners, then we have an invalid burner.
      await store.dispatch('burner/loadBurners');
      const allBurners: IBurner[] = store.getters['burner/allBurners'];
      this.invalidBurnersExist = burnerElements.some((element) => {
        return !allBurners.some((burner) => {
          return element.burner.burnerId === burner.burnerIdentifier;
        });
      });

      // Await the load burners before setting locks on burners to ensure all burners are available
      await store.dispatch('burner/setLockedBurners', burnerElements);
    } else {
      // Resetting grid box size prevents interaction with old model when overwritting model
      store.commit('grid/resetEditorGridBoxSize');
      store.commit('grid/resetUnconnectedGroups');
      store.commit('diagram/initializeDiagram');
    }
    this.canvas.setBackgroundColor('#1a1b22', this.canvas.renderAll.bind(this.canvas));
    EventBus.$emit(hmbEvents.checkForCompleteModel);
    store.dispatch('grid/setGridListeners');
    window.addEventListener('keydown', this.handleArrowFunction, false);
    window.addEventListener('keyup', this.disableSelection, false);
    window.addEventListener('resize', this.resizeGrid);
    this.subscribeToSuccessfulElementConnection();
    this.subscribeModeSwitch();
    this.subscribeElementSelectionInPreviewMode();

    if (this.$route.query.initialState === 'preview') {
      try {
        store.commit('grid/switchMode', Mode.PREVIEW);
      } catch (ex) {
        await store.dispatch('error/setError', {
          error: ex,
          errorString: 'Error Loading Diagram Details',
          handleError: true,
          routeHomeAfterError: false
        });
      }
    }

    this.restoreGrabPointer();
    this.resizeGrid();
    this.isLoading = false;
  }

  private async setGrabPointer(): Promise<void> {
    await store.dispatch('grid/setGrabPointer');
  }

  private restoreGrabPointer(): void {
    store.dispatch('grid/restoreGrabPointer');
  }

  private beforeDestroy(): void {
    // Remove event listeners
    EventBus.$off(hmbEvents.burnerSetupError, this.showBurnerSetupErrorModal);
    EventBus.$on(hmbEvents.navigateFromPreview, this.disableBurnerSetUpError);
    window.removeEventListener('resize', this.resizeGrid);
    window.removeEventListener('keydown', this.handleArrowFunction);
    if (this.unSubscribeFlowElementConnection) {
       this.unSubscribeFlowElementConnection();
    }
    if (this.unSubscribeModeSwitch) {
      this.unSubscribeModeSwitch();
    }
    if (this.unSubscribeElementSelectionInPreviewMode) {
      this.unSubscribeElementSelectionInPreviewMode();
    }
  }

  private showBurnerSetupErrorModal(burnerSetupErrors: IRequiredBurnerSettupAttributes[]): void {
    const burnerSetupError = this.$refs.burnerSetupError as BurnerSetupError;
    burnerSetupError.setBurnerSetUpErrors(burnerSetupErrors);
    if (this.showBurnerSetupError) {
      this.$bvModal.show('burner-setup-error-modal');
    }
  }

  private disableBurnerSetUpError(): void {
    this.showBurnerSetupError = false;
  }

  private resizeGrid(): void {
    const editorElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    const gridElement: HTMLElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('grid');
    if (gridElement != null) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      const gridWidth = editorElement?.clientWidth ?? 1000;
      store.commit('grid/updateEditorGridWidth', gridWidth);
      store.commit('grid/updateEditorGridHeight', gridHeight);
    }
  }

  private handleArrowFunction(event: KeyboardEvent): void {
    const arrowFunctionPayload = {
      event,
      canvas: this.canvas
    };
    store.dispatch('grid/handleOnKeyDownEvent', arrowFunctionPayload);
  }

  private disableSelection(): void {
    this.canvas.selection = false;
  }

  private subscribeToSuccessfulElementConnection(): void {
    this.unSubscribeFlowElementConnection = store.subscribe((mutation, state) => {
      if (mutation.type === 'grid/updateElementConnection' && state.grid.elementConnected) {
        (this.$refs['successful-connection-modal'] as BModal)?.show();
        window.setTimeout(this.closeConnectionModal, 1200);
      }
    });
  }

  private subscribeModeSwitch(): void {
    this.unSubscribeModeSwitch = store.subscribe((mutation, state) => {
      if (mutation.type === 'grid/switchMode') {
        switch (state.grid.mode) {
          case Mode.PREVIEW:
            this.mode = Mode.PREVIEW;
            this.previewModel();
            break;
          case Mode.EDIT:
            this.mode = Mode.EDIT;
            this.editModel();
            break;
        }
      }
    });
  }

  private subscribeElementSelectionInPreviewMode(): void {
    this.unSubscribeElementSelectionInPreviewMode = store.subscribe((mutation, state) => {
      if (mutation.type === 'diagram/setSelectedElements' && this.mode === Mode.PREVIEW) {
        this.previewElement = HelperMethods.isArrayEmpty(state.diagram.selectedElements) ?
          null : state.diagram.selectedElements[0];
      }
    });
  }

  private resetElementConnectionFlag(): void {
    store.commit('grid/updateElementConnection', true);
  }

  private closeConnectionModal(): void {
    // reset connection flag
    store.commit('grid/updateElementConnection', false);
    (this.$refs['successful-connection-modal'] as BModal)?.hide();
  }

  private async previewModel(): Promise<void> {
    const selectedElements = store.getters['diagram/getSelectedElements'];
    this.previewElement = HelperMethods.isArrayEmpty(selectedElements) ? null : selectedElements[0];
    store.dispatch('grid/lockWorkspace');
  }

  private editModel(): void {
    this.previewElement = null;
    store.dispatch('grid/unlockWorkspace');
  }

  private requiredAttributesExist(elemAttributeList: ElementAttributeModel[]): boolean {
    if (HelperMethods.isArrayEmpty(elemAttributeList)) {
      return false;
    }
    return elemAttributeList.some((attr) => attr.required);
  }

  private isCompositeElement(): boolean {
    if (this.previewElement && !HelperMethods.isArrayEmpty(this.previewElement.subElementList)) {
      return this.previewElement.subElementList.some((subElement) => {
        return HelperMethods.isArrayEmpty(subElement.elementAttributeList);
      });
    }
    return false;
  }

  private isBurner(): boolean {
    return this.previewElement && this.previewElement.type === FlowElementType.BURNER;
  }

  private isIdelChik(): boolean {
    return this.previewElement && this.previewElement.type === FlowElementType.IDELCHIK;
  }

  private getElementType(): string {
    if (this.previewElement) {
      return FlowElementType[this.previewElement.type].toLowerCase();
    }
    return null;
  }

  private onInvalidBurnerModalShown(): void {
    // get the burner elements in the model
    const diagramElements = store.getters['diagram/getDiagramElements'] as FlowElementModel[];
    const burnerElements = diagramElements.filter((model) => model.type === FlowElementType.BURNER);
    // remove each burner element
    burnerElements.forEach((element) => {
      // search the editorGrid for the matching burner element
      const group = store.getters['grid/editorGrid'].getObjects().find((object: fabric.Object) =>
        element.flowElementId?.id && getFlowElementId(object)?.includes(element.flowElementId?.id));
      // set the selected element
      store.commit('grid/updateSelectedElements', [group]);
      store.commit('diagram/setSelectedElements', [element]);
      // disconnect and delete the selected element
      store.dispatch('grid/disconnectSelectedElements');
      store.dispatch('grid/deleteSelectedElements');

    });
    // after we're done, set selected element to null so the details panel doesn't display the last deleted burner
    store.commit('grid/updateSelectedElements', []);
    store.commit('diagram/setSelectedElements', []);
  }
 }
