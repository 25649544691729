import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IAsset } from '@/view-models/assets-view-models';

export class HydraulicCustomerAssetService extends BaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios);
  }

  /**
   * @todo Obsolete endpoint. Use User Management's GET /users/current/assignmentTree instead.
   * @returns Promise<IAsset[]>
   */
  public async getCustomerAssets(): Promise<IAsset[]> {
    return this.httpClient.get<IAsset[]>('assignmenttree');
  }
}
