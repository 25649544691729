










import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import EventBus, { hmbEvents, navigateTypes } from '@/components/hydraulicModelTuner/eventBus';
import ConfigFactory from '@/services/config';

Vue.use(BootstrapVue);

@Component({
  name: 'tuner-header',
  components: {
  }
})
export default class TunerHeader extends Vue {
  private enableClick: boolean = true;
  private tuningSummaryLoaded: boolean = false;
  private currentNavPath = '/';
  private utilitiesLink = '';
  private navHref = false;

  private async mounted(): Promise<void> {
    EventBus.$on(hmbEvents.setEnableState, (enableState: boolean) => {
      this.enableClick = enableState;
    });
    EventBus.$on(hmbEvents.tuningSummaryLoaded, (loaded: boolean) => {
      // this variable checks to see if tuning summary component is loaded.
      // if it is not loaded, we wont try to check for in progress notes. just navigate
      this.tuningSummaryLoaded = loaded;
    });
    EventBus.$on(hmbEvents.navigateHome, () => {
      this.navigate(this.currentNavPath);
    });
    // gets link for utilities breadcrumb in different environments
    const conf = await ConfigFactory.GetConfig();
    const redirectUri = process.env.VUE_APP_DEV_PORTAL_BASE_URL ?
      process.env.VUE_APP_DEV_PORTAL_BASE_URL :
      conf.get('redirectUriParent');
    this.utilitiesLink = redirectUri + '/utilities';
  }

  private tryNavigate(tryPath: string, isHref: boolean): void {
    this.currentNavPath = tryPath;
    this.navHref = isHref;
    if (this.tuningSummaryLoaded) {
      EventBus.$emit(hmbEvents.navigateNotesCheck, navigateTypes.home);
    } else {
      this.navigate(this.currentNavPath);
    }
  }

  private navigate(path: string): void {
    if (this.navHref) {
      window.location.href = path;
    } else {
      this.$router.push(path);
    }
  }
}
