
















import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import store from '@/store';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';

Vue.use(BootstrapVue);

@Component({
  name: 'EditorToolbarBreadcrumbs'
})
export default class EditorToolbarBreadcrumbs extends Vue {
  public mounted(): void {
    EventBus.$on(hmbEvents.confirmLeaveEditor, () => {
      this.returnHome();
    });
  }

  private beforeDestroy(): void {
    EventBus.$off(hmbEvents.confirmLeaveEditor);
  }

  private tryReturnHome(): void {
    const diagramModified =  store.getters['diagram/getDiagramModified'];

    if (diagramModified) {
      EventBus.$emit(hmbEvents.showSaveModelPromptModal, 'leaveEditor');
    } else {
      this.returnHome();
    }
  }

  private async returnHome(): Promise<void> {
    this.$router.push('/');
  }
}
