



















































































































































































































































import { BModal, BootstrapVue, BDropdown } from 'bootstrap-vue';
import store from '@/store/';
import { Component, Vue, Watch } from 'vue-property-decorator';
import TunerHeader from '@/components/hydraulicModelTuner/components/TunerHeader.vue';
import TunerSummary from '@/components/hydraulicModelTuner/components/TunerSummary.vue';
import { TuningStatusType, ITuningHistoryModel } from '@/view-models/hydraulic-tuning-history';
import { IModelSummary } from '@/view-models/model-summary';
import EventBus, { hmbEvents, navigateTypes } from '@/components/hydraulicModelTuner/eventBus';
import TunerHelper from '@/components/hydraulicModelTuner/components/tunerHelper';
import { ITrainingCase } from '@/view-models/training-data-model';
import Loading from '@/components/common/Loading.vue';
import tippy from 'tippy.js';

Vue.use(BootstrapVue);

@Component({
  name: 'Tuner',
  components: {
    TunerHeader,
    TunerSummary,
    Loading
  }
})
export default class Tuner extends Vue {
  private isLoading: boolean = false;
  private newModelTuningClass: string = 'button-container rectangle import-data';
  private showHidden: boolean = false;
  private filteredHistoryListLength: number = 0;
  private isCheckAll: boolean = false;
  private showDeleteModalTuning: boolean = false;
  private acknowledgeDelete: boolean = false;
  private historyToDelete: ITuningHistoryModel = null;
  private checkedModels: string[] = [];
  private loadedModels: IModelSummary[] = [];
  private selectedTuningSummary: ITuningHistoryModel = null;
  private showDetails: boolean = false;
  private firstRun: boolean = true;
  private warningModalIndex: number = 0;
  private tuningHistoryDropdownHelper: boolean = false;

  @Watch('showHidden')
  public async onShowHiddenChange(): Promise<void> {
    await this.hideAllInfo();
    store.commit('tuner/setTuningShowHidden', this.showHidden);
  }

  public async mounted(): Promise<void> {
    this.isLoading = true;

    EventBus.$on(hmbEvents.hideAllInfo, this.hideAllInfo);
    EventBus.$on(hmbEvents.newTunerLoadHistory, this.refreshData);
    EventBus.$on(hmbEvents.retrainModel, () => {
      this.$emit(hmbEvents.retrain);
    });
    EventBus.$on(hmbEvents.hideTuningSummary, () => {
      this.selectedTuningSummary = null;
    });

    await store.dispatch('model/loadModelSummaryListByAsset');
    this.loadedModels = store.getters['model/loadedModels'];
    const currentModelSummary: IModelSummary = store.getters['tuner/getCurrentModelSummary'];
    const historyCheckedModels: string[] = store.getters['tuner/getCurrentHistoryCheckedModels'];

    const shouldCheckAllModels: boolean = store.getters['tuner/getShouldCheckAllModels'];
    this.isCheckAll = false;
    this.showHidden = !!store.state.tuner.tuningShowHidden;

    if (shouldCheckAllModels) {
      const uniqueFilterModelKeys: string[] = this.uniqueFilterModels.map((model) => model.key);
      this.checkedModels = this.checkedModels.concat(uniqueFilterModelKeys);
      this.isCheckAll = true;
    } else {
      if (historyCheckedModels) {
        this.checkedModels = historyCheckedModels.filter((k) => this.loadedModels.some((m) => m.key === k));
      } else {
        this.checkedModels.push(currentModelSummary.key);
      }
      if (this.checkedModels.length === this.loadedModels.filter((model) =>
        !model.hidden && model.modelComplete).length
      ) {
        this.checkedModels.unshift('');
        this.isCheckAll = true;
      }
    }

    // Save the shouldCheckAllModels' state to the store
    store.commit('tuner/setShouldCheckAllModels', this.isCheckAll);

    try {
      await store.dispatch('tuner/loadTrainingData');
    } catch (ex) {
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'Error Loading Training Data\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }

    // Ensure all dropdown actions are captured
    this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
      if (bvEvent.componentId === 'tuner-history-dropdown' && !this.tuningHistoryDropdownHelper) {
        this.tuningHistoryDropdownHelper = true;
      }
    });
    this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
      if (bvEvent.componentId === 'tuner-history-dropdown' && this.tuningHistoryDropdownHelper) {
        bvEvent.preventDefault();
        this.tuningHistoryDropdownHelper = false;
      }
    });

    this.isLoading = false;
  }

  private beforeDestroy(): void {
    store.commit('tuner/setCurrentHistoryCheckedModels', this.checkedModels);
    EventBus.$off(hmbEvents.hideAllInfo, this.hideAllInfo);
  }

  get uniqueFilterModels(): any[] {
    let result: any[] = [];
    if (this.loadedModels) {
      result = this.loadedModels
        .filter((model: IModelSummary) => !model.hidden && model.modelComplete)
        .map((model: IModelSummary) => {
          if (model.name && model.name.length > 11) {
            return { key: model.key, name: model.name.substring(0, 11) + '...' };
          } else {
            return { key: model.key, name: model.name };
          }
        });
    }
    result.unshift({ key: '', name: 'All Models' });
    return result;
  }

  get allHistories(): ITuningHistoryModel[] {
    return store.getters['tuner/getTuningHistoryList'] as ITuningHistoryModel[];
  }

  get filteredHistoryList(): ITuningHistoryModel[] {
    const historyList = this.allHistories?.filter((history) =>
      history.modelKey &&
      this.checkedModels.includes(history.modelKey) &&
      !history.markedForDelete
    );

    if (historyList?.length > 0 && this.firstRun) {
      if (historyList.every((history) => history.hidden)) {
        this.showHidden = true;
        this.firstRun = false;
      }

      const list = this.showHidden
        ? historyList
        : historyList.filter((obj: any) => obj.hidden !== true);

      this.filteredHistoryListLength = list.length;
      return list.sort(this.compareLastUpdated);
    } else {
      this.filteredHistoryListLength = 0;
      return [];
    }
  }

  private clickModels(key: string, index: number): void {
    if (index === 0) {
      this.isCheckAll = !this.isCheckAll;
      this.checkedModels = [];
      if (this.isCheckAll) {
        this.loadedModels.forEach((model: IModelSummary) => {
          if (!model.hidden && model.modelComplete && this.checkedModels !== null) {
            this.checkedModels.push(model.key);
          }
        });
        this.checkedModels.push('');
      }
    } else {
      if (this.checkedModels.includes('')) {
        this.checkedModels = this.checkedModels.filter((item) => item !== '');
        this.isCheckAll = false;
      }
      if (this.checkedModels.includes(key)) {
        this.checkedModels = this.checkedModels.filter((item) => item !== key);
      } else {
        this.checkedModels.push(key);
      }
    }
    // Check if all models are checked.  If so, manually check "All Models"
    const numUnhiddenModels = this.loadedModels.filter((model) => !model.hidden && model.modelComplete).length;
    if (this.checkedModels.length === numUnhiddenModels) {
      this.checkedModels.push('');
      this.isCheckAll = true;
    }

    // Save the shouldCheckAllModels' state to the store
    store.commit('tuner/setShouldCheckAllModels', this.isCheckAll);
  }

  private async onClickNewModelTuning(): Promise<void> {
    store.commit('tuner/setShouldRetrain', false);
    EventBus.$emit(hmbEvents.tabChange, navigateTypes.newModelTuning);
  }

  private compareLastUpdated(a: ITuningHistoryModel, b: ITuningHistoryModel): number {
    const bDate = b.tuningCompletionDateTime ? new Date(b.tuningCompletionDateTime) : undefined;
    const aDate = a.tuningCompletionDateTime ? new Date(a.tuningCompletionDateTime) : undefined;
    const bStartDate = b.tuningStartDateTime ? new Date(b.tuningStartDateTime) : undefined;
    const aStartDate = a.tuningStartDateTime ? new Date(a.tuningStartDateTime) : undefined;
    const startDateComparison = this.compareStartDate(aStartDate, bStartDate);
    if (aDate && bDate) {
      if (aDate.getTime() === bDate.getTime()) {
        return startDateComparison ? startDateComparison : this.compareModelName(a.modelName, b.modelName);
      } else {
        return bDate.getTime() - aDate.getTime();
      }
    } else if (aDate && !bDate) {
      return 1;
    } else if (!aDate && bDate) {
      return -1;
    } else {
      return startDateComparison ? startDateComparison : this.compareModelName(a.modelName, b.modelName);
    }
  }

  private compareStartDate(startA?: Date, startB?: Date): number {
    if (startA && startB) {
      if (startA.getTime() === startB.getTime()) {
        return undefined;
      } else {
        return startB.getTime() - startA.getTime();
      }
    } else if (startA && !startB) {
      return 1;
    } else if (!startA && startB) {
      return -1;
    } else {
      return undefined;
    }
  }

  private compareModelName(a: string, b: string): number {
    const aUpper: string = a?.toUpperCase();
    const bUpper: string = b?.toUpperCase();
    if (aUpper < bUpper) {
      return -1;
    }
    if (aUpper > bUpper) {
      return 1;
    }

    return 0;
  }

  private formattedTuningResultValue(history: ITuningHistoryModel): string {
    return TunerHelper.formatNormailizedSumofSquaredErrors(history);
  }

  private formattedTuningStatus(status: TuningStatusType): string {
    switch (status) {
      case TuningStatusType.INITIAL:
        return 'Initial';
      case TuningStatusType.IN_PROGRESS:
        return 'In Progress';
      case TuningStatusType.RESULTS_PARSING:
        return 'Result Parsing';
      case TuningStatusType.COMPLETE:
        return 'Complete';
      case TuningStatusType.ERROR:
        return 'Error';
      default:
        return 'None';
    }
  }

  private hideModel(history: ITuningHistoryModel): Promise<ITuningHistoryModel> {
    this.firstRun = true;
    return store.dispatch('tuner/hideTuningHistory', {
      history,
      hide: !history.hidden
    });
  }

  private refreshData(): void {
    const asset = store.getters['asset/selectedAsset'];
    if (asset) {
      store.dispatch('tuner/loadTuningHistoryList', asset.key);
    }
  }

  private async deleteModel(history: ITuningHistoryModel): Promise<void> {
    this.historyToDelete = history;
    this.acknowledgeDelete = false;
    this.showDeleteModalTuning = true;
  }

  private async deleteModelTuningModal(): Promise<void> {
    this.showDeleteModalTuning = false;

    if (this.historyToDelete) {
      await store.dispatch('tuner/deleteTuningHistory', this.historyToDelete);
      this.historyToDelete = null;
    }
  }

  private toggleAcknowledgeChecked(): void {
    this.acknowledgeDelete = !this.acknowledgeDelete;
  }

  private findModalPosition(idx: number): void {
    const infoIconRef = this.$refs[`infoIconRef${idx}`] as Element[];
    if (!infoIconRef) {
      return;
    }
    const left = infoIconRef[0].getBoundingClientRect().left;
    const top = infoIconRef[0].getBoundingClientRect().top;
    const modalWindow: HTMLElement = document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot
      ?.querySelector('#show-info-modal-hmtlist' + idx + '___BV_modal_content_');
    modalWindow.style.position = 'fixed';
    modalWindow.style.left = left - 165 + 'px';
    modalWindow.style.top = top + 'px';
  }

  private toggleInfoModal(idx: number): void {
    for (let i = 0; i < this.filteredHistoryListLength; i++) {
      const ref = this.$refs[`show-info-modal-hmtlist${i}`] as BModal;
      if (idx === i) {
        ref[0]?.toggle();
      } else {
        ref[0]?.hide();
      }
    }
  }

  private async hideAllInfo(): Promise<void> {
    for (let i = 0; i < this.filteredHistoryListLength; i++) {
      const ref = this.$refs[`show-info-modal-hmtlist${i}`] as BModal;
      if (ref && ref.length > 0) {
        ref[0].hide();
      }
    }
  }

  get disableNewModelTuningButton(): boolean {
    const models: IModelSummary[] = store.getters['model/loadedModels'];
    if (models.length > 0) {
      if (models.every((modelSummary: IModelSummary) => modelSummary.hidden === true)) {
        this.newModelTuningClass = 'button-primary-disabled button-container rectangle import-data';
        return true;
      } else {
        this.newModelTuningClass = 'button-container rectangle import-data';
        return false;
      }
    }
    return false;
  }

  private toggleDropdown(bvEvt: any): void {
    // stop any more events from firing off
    if (bvEvt.target.classList.contains('show')) {
      return;
    }

    // it seems weird to fire the event twice,
    // but it will not work the first time, and
    // it will work the second time.
    bvEvt.vueTarget.show();
    bvEvt.vueTarget.show();
  }

  private async showSummary(history: ITuningHistoryModel): Promise<void> {
    const asset = store.getters['asset/selectedAsset'];
    if (asset && history) {
      const newHistory = await store.dispatch('tuner/loadTuningHistory', {assetKey: asset.key,
        tuningHistoryKey:history.key});
      if (newHistory) {
        store.commit('tuner/setCurrentTuningHistory', newHistory);
        this.selectedTuningSummary = newHistory;
        return;
      }
    }
    store.commit('tuner/setCurrentTuningHistory', history);
    this.selectedTuningSummary = history;
  }

  get tunerTrainingCases(): ITrainingCase[] {
    return store.getters['tuner/getTrainingData'];
  }

  private hasDeletedCases(trainingDataKeys: string[]): boolean {
    if (!trainingDataKeys) {
      return false;
    }
    const summaryTrainingCases = this.tunerTrainingCases
      ?.filter((trainingCase) => trainingDataKeys?.includes(trainingCase.key));
    return summaryTrainingCases?.some((trainingCase) => trainingCase.markedForDelete);
  }

  get hmbTableContainer(): HTMLElement {
    return document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot
      ?.querySelector('.hmb-table-container');
  }

  private async toggleIndividualWarningModal(index: number): Promise<void> {
    const modalWindow = this.$refs.warningModalTrainingCaseDeleted as BModal;
    if (modalWindow.isVisible && index === this.warningModalIndex) {
      // If we clicked the same icon, hide it
      this.$bvModal.hide('warning-modal-training-case-deleted');
      this.hmbTableContainer?.removeEventListener('scroll', this.findIndividualWarningModalPosition);
    } else {
      // If we clicked a new icon, show/move it
      this.warningModalIndex = index;
      this.$bvModal.show('warning-modal-training-case-deleted');
      this.hmbTableContainer?.addEventListener('scroll', this.findIndividualWarningModalPosition);
      this.findIndividualWarningModalPosition();
    }
  }

  get tableBounds(): DOMRect {
    return this.hmbTableContainer.getBoundingClientRect();
  }

  private findIndividualWarningModalPosition(): void {
    // places the modal right below the info icon they clicked on, wherever it is on the screen
    const individualWarningIconRef = this.$refs['individualWarningRef' + this.warningModalIndex] as Element[];
    if (!individualWarningIconRef) {
      return;
    }
    const left = individualWarningIconRef[0].getBoundingClientRect().left;
    const top = individualWarningIconRef[0].getBoundingClientRect().top;

    const tableBounds = this.hmbTableContainer.getBoundingClientRect();
    if ((top - 35) < tableBounds.top || (top + 25) > tableBounds.bottom) {
      // Scrolled past top or bottom of table, hide modal
      this.$bvModal.hide('warning-modal-training-case-deleted');
      this.hmbTableContainer?.removeEventListener('scroll', this.findIndividualWarningModalPosition);
      return;
    }
    const modalWindow: HTMLElement = document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.querySelector('#warning-modal-training-case-deleted___BV_modal_content_');
    modalWindow.style.position = 'fixed';
    modalWindow.style.left = (left + 15) + 'px';
    modalWindow.style.top = (top + 25) + 'px';
  }

  private disableOverflowMenu(history: ITuningHistoryModel): boolean {
    return !!(history.tuningStatus === TuningStatusType.IN_PROGRESS || history.currentlyPublished);
  }

  private setDeletedTestCaseTooltip(index: number): void {
    const id = 'warnIcon' + index;
    const styleString = '1px solid #4e5266;' +
      'background-color: #393c4b;' +
      'border-radius: 4px;' +
      'color: #fff;' +
      'padding: 10px;' +
      'box-shadow: 0 5px 5px 5px rgba(20,21,26,.15);' +
      'font-family: Source Sans Pro, sans-serif;' +
      'font-size: 16px;' +
      'line-height: 20px;';
    tippy((document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById(id)), {
      content:
        `<div style='${styleString}'>
        This Model Tuning includes a deleted Test Case.
        </div>`
      ,
      allowHTML: true
    });
  }

  private setSolverInfoTooltip(history: any, index: number): void {
    const id = 'solverIcon' + index;
    const solverName = history.solver.name;
    const efficiencyEstimate = history.efficiencyEstimate;
    const styleString = '1px solid #4e5266;' +
      'background-color: #393c4b;' +
      'border-radius: 4px;' +
      'color: #fff;' +
      'padding: 10px;' +
      'box-shadow: 0 5px 5px 5px rgba(20,21,26,.15);' +
      'font-family: Source Sans Pro, sans-serif;' +
      'font-size: 16px;' +
      'line-height: 20px;';
    tippy((document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById(id)), {
      content:
        `<div style='${styleString}'>
          <span>
            <strong>Solver:</strong> <br>
            ${solverName} <br>
          </span>
          <span>
            <strong>Efficiency Estimate:</strong> <br>
            ${efficiencyEstimate}
          </span>
        </div>`
      ,
      allowHTML: true
    });
  }

  private toggleTunerHistoryDropdown(): void {
    // Ensure that menu actually opens.
    const siteDropdown = this.$refs.tunerHistoryDropdown as BDropdown;
    const isDropdownOpen = siteDropdown.visible;
    if (!isDropdownOpen) {
      siteDropdown.show();
    } else {
      siteDropdown.hide();
    }
    this.tuningHistoryDropdownHelper = false;
  }
}
