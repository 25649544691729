import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { IModelSummary } from '../../view-models/model-summary';
import { FlowElementModel, FlowElementType } from '../../view-models/flow-element-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '..';
import { HydraulicModelDetailService } from '@/services/hydraulic-model-detail-service';
import sharedAxiosInstance from '@/services/api-service';
import { HydraulicModelPreviewElement } from '@/view-models/hydraulic-model-preview-element';
import { HydraulicDetailModel } from '@/view-models/hydraulic-detail-model';
import { CanvasModel } from '@/view-models/canvas-model';
import DiagramStoreHelper from './diagramStoreHelper';
import ConfigFactory from '../../services/config';

export interface IDiagramStoreState {
  stateChangedSubject: Subject<void>;
  diagramModified: boolean;
  needToUpdateEloName: boolean;
  autoSaved: boolean;
  currentModelSummary: IModelSummary;
  selectedElements: FlowElementModel[];
  diagramElements: FlowElementModel[];
  diagramMap: Map<string, FlowElementModel>;
  diagramPreviewHierarchy: HydraulicModelPreviewElement[];
  selectedPreviewElementId: string;
}

export interface IDiagramStoreGetters extends GetterTree<IDiagramStoreState, IRootState> {
  getDiagramModified(state: IDiagramStoreState): boolean;
  getNeedToUpdateEloName(state: IDiagramStoreState): boolean;
  getAutoSaved(state: IDiagramStoreState): boolean;
  getCurrentModelSummary(state: IDiagramStoreState): IModelSummary;
  getDiagramElements(state: IDiagramStoreState): FlowElementModel[];
  getDiagramElementNames(state: IDiagramStoreState): string[];
  getSelectedElements(state: IDiagramStoreState): FlowElementModel[];
  getBurnerElements(state: IDiagramStoreState): FlowElementModel[];
  getElementById(state: IDiagramStoreState): (id: string) => FlowElementModel;
  getOutletNotConnected(state: IDiagramStoreState): boolean;
  isDiagramPreviewable(state: IDiagramStoreState): boolean;
  isElementPopulated(): (element: FlowElementModel) => boolean;
  getDiagramPreviewModel(state: IDiagramStoreState): HydraulicModelPreviewElement[];
}

export interface IDiagramStoreMutations extends MutationTree<IDiagramStoreState> {
  triggerStateChanged(state: IDiagramStoreState): void;
  setDiagramModified(state: IDiagramStoreState, modified: boolean): void;
  setAutoSaved(state: IDiagramStoreState, modified: boolean): void;
  setCurrentModelSummary(state: IDiagramStoreState, modelSummary: IModelSummary): void;
  setSelectedElements(state: IDiagramStoreState, elements: FlowElementModel[]): void;
  resetDiagramPreviewHierarchy(state: IDiagramStoreState): void;
  addElement(state: IDiagramStoreState, element: FlowElementModel): void;
  removeElementById(state: IDiagramStoreState, id: string): void;
  initializeDiagram(state: IDiagramStoreState): void;
  setSelectedPreviewElementId(state: IDiagramStoreState, selectedPreviewElementId: string): void;
}

export interface IDiagramStoreActions extends ActionTree<IDiagramStoreState, IRootState> {
  initializeDiagramSaver(context: IDiagramStoreContext): Promise<void>;
  saveDiagramDetails(context: IDiagramStoreContext, isCopy: boolean): Promise<string>;
  autoSaveDiagramDetails(context: IDiagramStoreContext): Promise<boolean>;
  resetDiagramDetails(context: IDiagramStoreContext): Promise<string>;
  exportDiagramDetails(context: IDiagramStoreContext, save: boolean): Promise<string>;
  loadDiagramDetails(context: IDiagramStoreContext): Promise<CanvasModel>;
  findFlowElementById(context: IDiagramStoreContext, id: string): Promise<FlowElementModel>;
}

export type IDiagramStoreContext = ActionContext<IDiagramStoreState, IRootState>;

export const DiagramStore = {
  namespaced: true as true,
  state: {
    stateChangedSubject: new Subject<void>(),
    diagramModified: false,
    needToUpdateEloName: false,
    autoSaved: true,
    currentModelSummary: null,
    selectedElements: [],
    diagramElements: [],
    diagramMap: new Map(),
    diagramPreviewHierarchy: [],
    selectedPreviewElementId: null,
  } as IDiagramStoreState,
  getters:  {
    getDiagramModified(state: IDiagramStoreState): boolean {
      return state.diagramModified;
    },
    getNeedToUpdateEloName(state: IDiagramStoreState): boolean {
      return state.needToUpdateEloName;
    },
    getAutoSaved(state: IDiagramStoreState): boolean {
      return state.autoSaved;
    },
    getCurrentModelSummary(state: IDiagramStoreState): IModelSummary {
      return state.currentModelSummary;
    },
    getDiagramElements(state: IDiagramStoreState): FlowElementModel[] {
      return state.diagramElements;
    },
    getDiagramElementNames(state: IDiagramStoreState): string[] {
      return state.diagramElements
        .filter((element) => element.elementModelName)
        .map((element) => element.elementModelName ?? '');
    },
    getSelectedElements(state: IDiagramStoreState): FlowElementModel[] {
      return state.selectedElements;
    },
    getBurnerElements(state: IDiagramStoreState): FlowElementModel[] {
      return state.diagramElements.filter((element) => element.type === FlowElementType.BURNER);
    },
    getElementById(state: IDiagramStoreState): (id: string) => FlowElementModel {
      return (id: string) => state.diagramMap.get(id);
    },
    getOutletNotConnected(state: IDiagramStoreState): boolean {
      if (!state.diagramElements) {
        return false;
      }
      return state.diagramElements.some((element) => DiagramStoreHelper.outletNotConnected(element));
    },
    isDiagramPreviewable(state: IDiagramStoreState): boolean {
      if (!state.diagramElements || (state.diagramElements.length === 0)) {
        return false;
      }
      for (const element of state.diagramElements) {
        if ((!(element.flowElementId?.parentId) && element.flowElementId?.childIdList === null) ||
          !element.elementModelName) {
          return false;
        }

        if (DiagramStoreHelper.hasEmptyRequiredAttribute(element)) {
          return false;
        }
      }

      // checks for more than one air inlet
      if (state.diagramElements.filter((element) => !element.flowElementId.parentId).length > 1) {
        return false;
      }

      let isDuplicate: boolean = false;
      state.diagramElements
        .map((element) => {
          return element.elementModelName;
        })
        .forEach((modelName, index, arr) => {
          if (arr.indexOf(modelName) !== index) {
            isDuplicate = true;
          }
        });
      return !isDuplicate;
    },
    isElementPopulated(): (element: FlowElementModel) => boolean {
      return (element: FlowElementModel) =>
        element && !!element.elementModelName && !DiagramStoreHelper.hasEmptyRequiredAttribute(element);
    },
    getDiagramPreviewModel(state: IDiagramStoreState): HydraulicModelPreviewElement[] {
      // Hierarchy to be created if diagram is modifed or if new model is loaded from backend
      if (DiagramStoreHelper.evaluateDiagramModified(state.diagramModified, state.currentModelSummary)
        || (state.diagramElements.length > 0 &&  state.diagramPreviewHierarchy.length === 0)
      ) {
        state.diagramPreviewHierarchy = DiagramStoreHelper.constructDiagramPreviewHierarchy(state);
      }
      return state.diagramPreviewHierarchy;
    },
    selectedPreviewElementId(state: IDiagramStoreState): string {
      if (state.selectedPreviewElementId) {
        return state.selectedPreviewElementId;
      } else {
        return null;
      }
    }
  } as IDiagramStoreGetters,
  mutations: {
    triggerStateChanged(state: IDiagramStoreState): void {
      state.stateChangedSubject.next();
    },
    setDiagramModified(state: IDiagramStoreState, modified: boolean): void {
      state.diagramModified = modified;
      state.autoSaved = !modified;
      state.stateChangedSubject.next();
    },
    setAutoSaved(state: IDiagramStoreState, modified: boolean): void {
      state.autoSaved = modified;
    },
    setCurrentModelSummary(state: IDiagramStoreState, modelSummary: IModelSummary): void {
      state.currentModelSummary = modelSummary;
    },
    setSelectedElements(state: IDiagramStoreState, elements: FlowElementModel[]): void {
      state.selectedElements = elements;
    },
    resetDiagramPreviewHierarchy(state: IDiagramStoreState): void {
      state.diagramPreviewHierarchy = [];
    },
    addElement(state: IDiagramStoreState, element: FlowElementModel): void {
      if (state.diagramElements) {
        if (!element.elementModelName) {
          element.elementModelName = '';
        }
        state.diagramElements.push(element);
        if (element.flowElementId?.id) {
          state.diagramMap.set(element.flowElementId?.id, element);
        }
        state.diagramModified = true;
        state.autoSaved = false;
        state.stateChangedSubject.next();
      }
    },
    removeElementById(state: IDiagramStoreState, id: string): void {
      if (state.diagramElements && id) {
        const element = state.diagramMap.get(id);
        if (element) {
          if (element.flowElementId?.id) {
            state.diagramMap.delete(element.flowElementId.id);
          }
          const index = state.diagramElements.indexOf(element);
          if (index >= 0) {
            state.diagramElements.splice(index, 1);
            state.diagramModified = true;
            state.autoSaved = false;
            state.stateChangedSubject.next();
          }
        }
      }
    },
    initializeDiagram(state: IDiagramStoreState): void {
      state.autoSaved = true;
      state.selectedElements = [];
      state.diagramElements = [];
      state.diagramMap = new Map();
      state.diagramPreviewHierarchy =  [];
      state.diagramModified = false;
    },
    setSelectedPreviewElementId(state: IDiagramStoreState, selectedPreviewElementId: string): void {
      state.selectedPreviewElementId = selectedPreviewElementId;
    }
  } as IDiagramStoreMutations,
  actions: {
    async initializeDiagramSaver(context: IDiagramStoreContext): Promise<void> {
      // should only subscribe once for the application.
      if (context.state.stateChangedSubject.observers.length > 0) { return; }
      const conf = await ConfigFactory.GetConfig();
      context.state.stateChangedSubject.pipe(
        debounceTime(Number(process.env.VUE_APP_MODEL_SAVE_TIME_INTERVAL_MILLISECOND ?? conf.get('hmbTimeInterval'))),
        filter(() => {
          return !context.state.autoSaved;
        })
      ).subscribe(async () => {
        await context.dispatch('autoSaveDiagramDetails');
      });
    },
    async saveDiagramDetails(context: IDiagramStoreContext, isCopy: boolean = false): Promise<string> {
      if (
        context.state.currentModelSummary &&
        context.state.currentModelSummary.customer &&
        context.state.currentModelSummary.key &&
        (DiagramStoreHelper.evaluateDiagramModified(
          context.state.diagramModified, context.state.currentModelSummary) || isCopy)
      ) {
        context.state.diagramModified = false;
        const diagram = context.rootGetters['grid/editorGridDiagram'];
        const gridBoxSize = context.rootGetters['grid/editorGridBoxSize'];

        const conf = await ConfigFactory.GetConfig();
        const hmbService = new HydraulicModelDetailService(sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
        return hmbService.saveModelDetail(
          context.state.currentModelSummary.customer.key,
          context.state.currentModelSummary.key,
          context.state.diagramElements,
          diagram,
          gridBoxSize,
          false
        ).then( (response) => {
          context.state.diagramPreviewHierarchy = [];
          if (response.name.length > 20) {
            response.truncatedName = response.name.substring(0, 19) + '...';
          } else {
            response.truncatedName = response.name;
          }
          context.state.currentModelSummary = response;
          context.state.autoSaved = true;
          return Promise.resolve('Hydraulic Model Saved Successfully');
        }).catch((ex) => {
          // Setting it back to true incase of error.
          context.state.diagramModified = true;
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Saving Hydraulic Model\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
      }
      return Promise.resolve('No need to save Hydraulic Model');
    },
    // TODO: Change function to take in the model and diagram to save. Navigating to a new model and diagram
    // after autoSave is fired may cause wrong object to be saved
    async autoSaveDiagramDetails(context: IDiagramStoreContext): Promise<boolean> {
      if (
        context.state.currentModelSummary &&
        context.state.currentModelSummary.customer &&
        context.state.currentModelSummary.key &&
        !context.state.autoSaved
      ) {
        const diagram = context.rootGetters['grid/editorGridDiagram'];
        const gridBoxSize = context.rootGetters['grid/editorGridBoxSize'];
        context.state.currentModelSummary.modelComplete = false;
        const conf = await ConfigFactory.GetConfig();
        const hmbService = new HydraulicModelDetailService(sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
        return hmbService.saveModelDetail(
          context.state.currentModelSummary.customer.key,
          context.state.currentModelSummary.key,
          context.state.diagramElements,
          diagram,
          gridBoxSize,
          true
        ).then((response) => {
          if (response.name.length > 20) {
            response.truncatedName = response.name.substring(0, 19) + '...';
          } else {
            response.truncatedName = response.name;
          }
          context.state.currentModelSummary = response;
          context.state.autoSaved = true;
          return Promise.resolve(true);
        }).catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error During Auto Save\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(false);
        });
      }
      return Promise.resolve(false);
    },
    async resetDiagramDetails(context: IDiagramStoreContext): Promise<string> {
      if (
        context.state.currentModelSummary &&
        context.state.currentModelSummary.customer &&
        context.state.currentModelSummary.key &&
        DiagramStoreHelper.evaluateDiagramModified(context.state.diagramModified, context.state.currentModelSummary)
      ) {
        const conf = await ConfigFactory.GetConfig();
        const hmbService = new HydraulicModelDetailService(sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
        return hmbService.resetModelDetail(
          context.state.currentModelSummary.customer.key,
          context.state.currentModelSummary.key
        ).then((response) => {
          if (response.name.length > 20) {
            response.truncatedName = response.name.substring(0, 19) + '...';
          } else {
            response.truncatedName = response.name;
          }
          context.state.currentModelSummary = response;
          context.state.diagramModified = false;
          return Promise.resolve('Hydraulic Model Reset Successfully');
        }).catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Resetting Hydraulic Model\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
      }
      return Promise.resolve('Success');
    },
    async exportDiagramDetails(context: IDiagramStoreContext, save: boolean): Promise<string> {
      if (
        context.state.currentModelSummary &&
        context.state.currentModelSummary.customer &&
        context.state.currentModelSummary.key
      ) {
        const diagram = context.rootGetters['grid/editorGridDiagram'];
        const gridBoxSize = save ? context.rootGetters['grid/editorGridBoxSize'] : 0;
        const conf = await ConfigFactory.GetConfig();
        const hmbService = new HydraulicModelDetailService(sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
        return hmbService.exportModelDetail(
          context.state.currentModelSummary.customer.key,
          context.state.currentModelSummary.key,
          context.state.diagramElements,
          save ? diagram : null, gridBoxSize,
          save, true,
        ).then( (response) => {
          return Promise.resolve(response);
        }).catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Exporting\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
      }
      return Promise.resolve('Cannot export Hydraulic Model');
    },
    async loadDiagramDetails(context: IDiagramStoreContext): Promise<CanvasModel> {
      if (context.state.currentModelSummary && context.state.currentModelSummary.customer &&
        context.state.currentModelSummary.key) {
        const conf = await ConfigFactory.GetConfig();
        const hmbService = new HydraulicModelDetailService(sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
        return hmbService.loadModelDetail(
          context.state.currentModelSummary.customer.key,
          context.state.currentModelSummary.key).then( (response: HydraulicDetailModel) => {
          context.state.diagramElements = response.elementList;

          context.state.needToUpdateEloName = false;
          context.state.diagramMap = response.elementList.reduce((elementMap, element) => {
            if (element.flowElementId?.id) {
              elementMap.set(element.flowElementId.id, element);
            }
            if (element.burner && element.burner.burnerId) {
              const eloName = store.getters['burner/getEloName'](element.burner.burnerId);
              if (eloName && element.burner.burnerId && (eloName !== element.burner.burnerId)) {
                element.burner.burnerId = eloName;
                context.state.needToUpdateEloName = true;
              }
            }
            return elementMap;
          }, new Map());
          context.state.diagramModified = false;
          return response.canvas;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Diagram\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
      }
      return Promise.resolve(null);
    },
    async findFlowElementById(context: IDiagramStoreContext, id: string): Promise<FlowElementModel> {
      const diagramElements = context.state.diagramElements;
      if (diagramElements.length > 0) {
        return diagramElements.find((element) => element.flowElementId?.id === id) ?? null;
      }
      return null;
    }
  } as IDiagramStoreActions
};
