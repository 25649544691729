import { gridConfig } from '@/assets/configs/gridConfig';
import { IGridContext, IGridStoreState } from '@/store/grid/gridStoreInterface';
import { IZoomStatus } from '@/view-models/grid-view-models';
import { fabric } from 'fabric';

// Helper class for Zooming
export default class ZoomHelper {
  public static performZoomIn(gridContext: IGridContext, zoomPoint: fabric.Point = null): void {
    const canvas = gridContext.state.editorGrid;
    const newZoom = canvas.getZoom() * (1 + gridConfig.zoomFactor);
    if (zoomPoint != null) {
      canvas.zoomToPoint(zoomPoint, newZoom);
    } else {
      canvas.setZoom(newZoom);
    }
    canvas.requestRenderAll();
  }

  public static performZoomout(gridContext: IGridContext, zoomPoint: fabric.Point = null): void {
    const canvas = gridContext.state.editorGrid;
    const newZoom = canvas.getZoom() * (1 - gridConfig.zoomFactor);
    if (zoomPoint != null) {
      canvas.zoomToPoint(zoomPoint, newZoom);
    } else {
      canvas.setZoom(newZoom);
    }
    canvas.requestRenderAll();
  }

  public static performZoomFit(gridContext: IGridContext): void {
    const canvas = gridContext.state.editorGrid;
    // Group all objects together
    const allGridObjects = new fabric.Group(canvas.getObjects());

    // Add buffer so that objects aren't right up against the viewport edge
    const contentMargin = 200;
    const contentHeight = allGridObjects.height + contentMargin;
    const contentWidth = allGridObjects.width + contentMargin;

    // Calculate new zoom
    const currentGridHeight = canvas.getHeight();
    const currentGridWidth = canvas.getWidth() - this.sidePanelWidth();
    const newZoom = Math.min(currentGridHeight/contentHeight, currentGridWidth/contentWidth);

    // Group measurements
    const halfGroupWidth = allGridObjects.getScaledWidth() / 2;
    const halfGroupHeight = allGridObjects.getScaledHeight() / 2;
    const groupTLCoord = allGridObjects.aCoords.tl;

    // Canvas measurements
    const zoomedCanvasWidth = canvas.getWidth() / newZoom;
    const zoomedCanvasHeight = canvas.getHeight() / newZoom;

    // Calculate pan and zoom
    const panX = ((zoomedCanvasWidth / 2) - groupTLCoord.x - halfGroupWidth) * newZoom;
    const panY = ((zoomedCanvasHeight / 2) - groupTLCoord.y - halfGroupHeight) * newZoom;

    // Zoom and pan canvas. Ungroup grid objects
    canvas.setViewportTransform([newZoom, 0, 0, newZoom, panX, panY]);
    allGridObjects.destroy();

    // Update and re-render
    canvas.requestRenderAll();
  }

  public static getZoomStatus(gridState: IGridStoreState): IZoomStatus {
    const nextZoomInValue = this.getNextZoomInBoxSize(gridState);
    const nextZoomOutValue = this.getNextZoomOutBoxSize(gridState);
    const maxBoxSize = gridConfig.defaultGridBoxSize * gridConfig.maxZoomMagnification;
    const minBoxSize = gridConfig.defaultGridBoxSize / gridConfig.minZoomMagnification;
    return {
      zoomInDisabled: nextZoomInValue >= maxBoxSize,
      zoomOutDisabled: nextZoomOutValue <= minBoxSize,
      gridBoxSize: gridState.editorGridBoxSize
    };
  }

  // get the next zoom in box size.
  private static getNextZoomInBoxSize(gridState: IGridStoreState): number {
    return gridState.editorGridBoxSize;
  }

  // get the next zoom out box size.
  private static getNextZoomOutBoxSize(gridState: IGridStoreState): number {
    return gridState.editorGridBoxSize;
  }

  private static sidePanelWidth(): number {
    const element = document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('library-side-panel-container');
    if (!element || !getComputedStyle(element).height) {
      return 0;
    } else {
      const widthString = getComputedStyle(element).width;
      const widths = widthString.match(/(\d+)/);
      if (!widths?.length) {
        return 0;
      }
      return parseInt(widths[0]);
    }
  }
}
