

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { FlowElementType } from '@/view-models/flow-element-models';
import { HydraulicModelPreviewElement } from '@/view-models/hydraulic-model-preview-element';
import store from '@/store';

@Component({
  name: 'PreviewElement',
})
export default class PreviewElement extends Vue {
  @Prop({default: null})
  private element: HydraulicModelPreviewElement;
  @Prop({default: 0})
  private level: number;

  private visible: boolean = this.level === 0 || this.level % 150 < 0 || this.level % 150 > 0;
  private levelStyle(): any {
    return {
      padding: `0rem 0rem 0rem ${this.level}rem`,
    };
  }

  private async selectElement(): Promise<void> {
    if (this.element) {
      await store.dispatch('grid/selectPreviewElement', this.element.id);
      const selectedElements = store.getters['grid/selectedElements'] as fabric.Group[];
      const selectedElement = selectedElements[0];
      const selectedElementId = selectedElement.name.split(' ')[0];
      store.commit('diagram/setSelectedPreviewElementId', selectedElementId);
    }
  }

  get selectedPreviewElementId(): string {
    return store.getters['diagram/selectedPreviewElementId'];
  }

  private getElementType(): string {
    if (this.element) {
      return FlowElementType[this.element.type].toLowerCase();
    }
    return null;
  }

  private toggleButtonIcon(): void {
    if (this.element) {
      const expandCollapseIcon =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById(this.element.id + '-dropdown');
      expandCollapseIcon?.classList.contains('rotate-icon')
        ? expandCollapseIcon?.classList.remove('rotate-icon')
        : expandCollapseIcon?.classList.add('rotate-icon');
    }
  }
}
