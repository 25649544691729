import { IBurnerFolder, BurnerFolder } from '@/view-models/burner-folder-view-models';
import { IBurner, IRequiredBurnerSettupAttributes } from '@/view-models/burner-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '..';
import { HierarchyBuilderService } from '@/services/hierarchy-builder-service';
import sharedAxiosInstance from '@/services/api-service';
import { IModelSummary } from '@/view-models/model-summary';
import { IBurnerListServiceResponse, IHBBurnerListServiceResponse } from '@/view-models/burner-response-view-models';
import ConfigFactory from '@/services/config';
import { FlowElementModel } from '@/view-models/flow-element-models';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import { AssetBurnerService } from '@/services/asset/asset-burner-service';

export interface IBurnerFolderStoreState {
  burnerFolders: IBurnerFolder[];
  burners: IBurner[];
  lastSelected: IBurnerFolder;
  loading: boolean;
  burnerListResponse: IBurnerListServiceResponse | IHBBurnerListServiceResponse;
  // key = burner id/burner eloName, value = burner eloName.
  eloNameMap: Map<string, string>;
}

export interface IBurnerFolderStoreGetters extends GetterTree<IBurnerFolderStoreState, IRootState> {
  allBurners(state: IBurnerFolderStoreState): IBurner[];
  isLoading(state: IBurnerFolderStoreState): boolean;
  getBurnerFolderByEmberHierarchyLevelKey(state: IBurnerFolderStoreState): (key: string) => string[];
  allBurnerFolders(state: IBurnerFolderStoreState): IBurnerFolder[];
  rootFolders(state: IBurnerFolderStoreState): IBurnerFolder[];
  selectedCount(state: IBurnerFolderStoreState): number;
  getEloName(state: IBurnerFolderStoreState): (id: string) => string;
}

export interface IBurnerFolderStoreMutations extends MutationTree<IBurnerFolderStoreState> {
  setBurners(state: IBurnerFolderStoreState, burners: IBurner[]): void;
  updateLoading(state: IBurnerFolderStoreState, isLoading: boolean): void;
  lockBurnerById(state: IBurnerFolderStoreState, id: string): void;
  unlockBurnerById(state: IBurnerFolderStoreState, id: string): void;
  lockAll(state: IBurnerFolderStoreState): void;
  unlockAll(state: IBurnerFolderStoreState): void;
}

export interface IBurnerFolderStoreActions extends ActionTree<IBurnerFolderStoreState, IRootState> {
  loadBurners(context: IBurnerFolderContext): Promise<void>;
  setLockedBurners(context: IBurnerFolderContext, burnerElements: FlowElementModel[]): void;
}

export type IBurnerFolderContext = ActionContext<IBurnerFolderStoreState, IRootState>;

// Takes the folder to apply the change to.
// Applies the change and then checks to see if there are any
// children that need to be recursed and applies it to them as well.
// If the loop should be exited, action will return true
// (hence the use of some instead of foreach).
function recurseApply(folder: IBurnerFolder, action: (f: IBurnerFolder) => boolean): void {
  if (!action(folder)) {
    if (folder.children && folder.children.length > 0) {
      folder.children.some((child) => recurseApply(child, action));
    }
  }
}

function recurseLevels(hierarchy: any, key: string, path: string[], action: (f: IBurnerFolder) => boolean): void {
  if (action(hierarchy)) {
    hierarchy.forEach((level: { key: string; name: string; parentKey: string }) => {
      if (level.key === key) {
        path.push(level.name);
        if (level.parentKey) {
          recurseLevels(hierarchy, level.parentKey, path, action);
        }
      }
    });
  }
}

export const BurnerFolderStore = {
  namespaced: true as true,
  state: {
    burnerFolders: Array<IBurnerFolder>(),
    burners: [],
    lastSelected: undefined,
    loading: false,
    burnerListResponse: undefined,
    eloNameMap: new Map(),
  } as IBurnerFolderStoreState,
  getters: {
    allBurners(state: IBurnerFolderStoreState): IBurner[] {
      return state.burners;
    },
    isLoading(state: IBurnerFolderStoreState): boolean {
      return state.loading;
    },
    getBurnerFolderByEmberHierarchyLevelKey(state: IBurnerFolderStoreState): (key: string) => string[] {
      return (key: string) => {
        const path: string[] = [];
        recurseLevels(state.burnerListResponse?.emberHierarchyLevels, key, path, () => {
          return true;
        });
        return path;
      };
    },
    allBurnerFolders(state: IBurnerFolderStoreState): IBurnerFolder[] {
      return state.burnerFolders;
    },
    rootFolders(state: IBurnerFolderStoreState): IBurnerFolder[] {
      return state.burnerFolders
        .filter((folder) => !folder.parentKey)
        .sort((a, b) => a.burnerName < b.burnerName ? -1 : 1);
    },
    selectedCount(state: IBurnerFolderStoreState): number {
      let count = 0;
      state.burnerFolders
        .filter((folder) => folder.parentKey === '')
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.isSelected) {
              count++;
            }
            // don't short circuit
            return false;
          });
        });
      return count;
    },
    getEloName(state: IBurnerFolderStoreState): (id: string) => string {
      return (id: string) => state.eloNameMap.get(id);
    }
  } as IBurnerFolderStoreGetters,
  mutations: {
    setBurners(state: IBurnerFolderStoreState, burners: IBurner[]): void {
      state.burners = burners;
    },
    updateLoading(state: IBurnerFolderStoreState, isLoading: boolean): void {
      state.loading = isLoading;
    },
    lockBurnerById(state: IBurnerFolderStoreState, id: string): void {
      state.burnerFolders
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.children?.length === 0 && item.key === id) {
              item.lockSelection();
            }
            return false;
          });
        });
    },
    unlockBurnerById(state: IBurnerFolderStoreState, id: string): void {
      state.burnerFolders
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.children?.length === 0 && item.key === id) {
              item.unlockSelection();
            }
            return false;
          });
        });
    },
    lockAll(state: IBurnerFolderStoreState): void {
      state.burnerFolders
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.children?.length === 0 && !item.locked) {
              item.lockSelection();
            }
            return false;
          });
        });
    },
    unlockAll(state: IBurnerFolderStoreState): void {
      state.burnerFolders
        .forEach((folder) => {
          recurseApply(folder, (item) => {
            if (item.children?.length === 0) {
              item.unlockSelection();
            }
            return false;
          });
        });
    }
  } as IBurnerFolderStoreMutations,
  actions: {
    async loadBurners(context: IBurnerFolderContext): Promise<void> {
      // Returning burners list based on selected asset from service.
      const selectedModel: IModelSummary = store.getters['model/selectedModel'];
      const selectedAssetId = selectedModel?.asset?.key;
      if (!selectedAssetId) {
        return;
      }
      // Retrieve burners from portal API
      const conf = await ConfigFactory.GetConfig();
      let response: IBurnerListServiceResponse | IHBBurnerListServiceResponse = {
        burners: [],
        emberHierarchyLevels: []
      };
      // If new burnertree API FF is active, then replace info retrieved from previous API
      if (store.getters['app/getFlags'] && store.getters['app/getFlags'].hbBurnertree) {
        const hbService = new HierarchyBuilderService(sharedAxiosInstance,
          process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL ?
          process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL :
          conf.get('hbApiUrl'));
        try {
          response = await hbService.getBurnerList(selectedAssetId);
          // Add in legacy attributes burner information
          response.burners.forEach((newBurner: any) => {
            newBurner.key = newBurner.burnerIdentifier; // NEW
            newBurner.name = newBurner.burnerName; // NEW
          });
        } catch (error) {
          // Catch 404s and mimick to "no burners found" response.  Else throw error
          if (error.response.status === 404) {
            response.emberHierarchyLevels = [];
            response.burners = [];
          } else {
            // Reset burners
            context.state.burners = [];
            context.state.burnerFolders = [];
            throw error;
          }
        }
      } else {
        try {
          const assetBurnerService = new AssetBurnerService(sharedAxiosInstance,
            process.env.VUE_APP_KES_PORTAL_API_BASE_URL ?
            process.env.VUE_APP_KES_PORTAL_API_BASE_URL :
            conf.get('portalApiUrl'));
          response = await assetBurnerService.getBurnerList(selectedAssetId);
        } catch (error) {
          if (error.response.status !== 404) {
            // Reset burners
            context.state.burners = [];
            context.state.burnerFolders = [];
            throw error;
          }
        }
      }
      context.state.burnerListResponse = response;

      const loaded = context.state.burnerListResponse;
      const folders = Array<IBurnerFolder>();

      // Loop through burners and check for required attributes
      const missingRequiredAttributes: IRequiredBurnerSettupAttributes[] = [];
      loaded?.burners.forEach((burner: any) => {
        const attributeCheck: IRequiredBurnerSettupAttributes = {
        name: burner.burnerName,
        key: burner.burnerIdentifier,
        hasBurnerConfigKey: true,
        hasBurnerConfigZoneType: true,
        };
        let burnerContainsError = false;
        // Check burner config key
        if (burner.burnerConfigKey === null || burner.burnerConfigKey === undefined) {
          attributeCheck.hasBurnerConfigKey = false;
          burnerContainsError = true;
        }
        // Check burner config zone type
        if (burner.burnerConfigZoneType === null || burner.burnerConfigZoneType === undefined) {
          attributeCheck.hasBurnerConfigZoneType = false;
          burnerContainsError = true;
        }
        // If error, push burner
        if (burnerContainsError) {
          missingRequiredAttributes.push(attributeCheck);
        }
      });

      // Check for need to display burner setup error modal
      const burnerSetupErrors: IRequiredBurnerSettupAttributes[] = [];
      missingRequiredAttributes.forEach((burner) => {
        // If burner is missing any required attribute then add to error array
        if (!burner.hasBurnerConfigKey || !burner.hasBurnerConfigZoneType) {
          burnerSetupErrors.push(burner);
        }
      });

      // If burner setup errors are found, then emit event
      if (burnerSetupErrors.length > 0) {
        EventBus.$emit(hmbEvents.burnerSetupError, burnerSetupErrors);
      }

      loaded?.emberHierarchyLevels.forEach((heirarchy) => {
        folders.push(new BurnerFolder(heirarchy));
      });
      // Now loop through the burner array and attach the details
      loaded?.burners.forEach((burner: any) => {
        const parent = folders.find((p) => p.key === burner.emberHierarchyLevelKey);
        if (parent) {
          const newFolder = new BurnerFolder(burner);
          newFolder.details = burner;
          parent.children.push(newFolder);
        }

        if (burner.burnerOldIdentifier && burner.eloName) {
          context.state.eloNameMap.set(burner.burnerOldIdentifier, burner.eloName);
        }
      });
      // Loop back through the burners and build the tree
      folders.filter((b) => b.parentKey)
        .forEach((child) => {
          const parent = folders.find((p) => p.key === child.parentKey);
          if (parent) {
            parent.children.push(child);
          }
        });
      // Order the children
      // Need to here instead of in the child object because causes recursive binding
      // If this is the burner itself, sort by the orderIndex
      // otherwise sort by the node name.
      const parentsWithChildren = folders.filter((parent) => parent.childCount > 0);
      parentsWithChildren.forEach((parent) => {
        parent.children.sort((a, b) => {
          if (a.details) {
            return ((a.details?.orderIndex ?? 0) - (b.details?.orderIndex ?? 0));
          } else {
            return a.burnerName > b.burnerName ? 1 : -1;
          }
        });
      });

      context.state.burnerFolders = folders
        .filter((folder) => folder.parentKey === '')
        .sort((a, b) => a.burnerName < b.burnerName ? -1 : 1);
      context.commit('setBurners', loaded?.burners);
    },
    setLockedBurners(context: IBurnerFolderContext, burnerElements: FlowElementModel[]): void {
      context.commit('unlockAll');
      burnerElements.forEach((element) => context.commit('lockBurnerById', element.burner?.burnerId));
    }
  } as IBurnerFolderStoreActions
};
