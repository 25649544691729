// Copied from team 1 project.. will most likely change
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';

export interface ITabStoreState {
  selectedTunerTab: string;
  tunerTabEnabled: boolean;
}

export interface ITabStoreGetters extends GetterTree<ITabStoreState, IRootState> {}

export interface ITabStoreMutations extends MutationTree<ITabStoreState> {
  initializeTab(state: ITabStoreState): void;
  updateTunerTabEnabled(state: ITabStoreState, value: boolean): void;
  updateSelectedTunerTab(state: ITabStoreState, assetTab: string): void;
  resetTunerTabs(state: ITabStoreState): void;
}

export interface ITabStoreActions extends ActionTree<ITabStoreState, IRootState> {}

export type ITabContext = ActionContext<ITabStoreState, IRootState>;

export const TabStore = {
  namespaced: true,
  state: {
    selectedTunerTab: 'history',
    tunerTabEnabled: true,
  } as ITabStoreState,
  getters: {} as ITabStoreGetters,
  mutations: {
    initializeTab(state: ITabStoreState): void {
      state.selectedTunerTab = 'history';
      state.tunerTabEnabled = true;
    },
    updateTunerTabEnabled(state: ITabStoreState, value: boolean): void {
      state.tunerTabEnabled = value;
    },
    updateSelectedTunerTab(state: ITabStoreState, assetTab: string): void {
      state.selectedTunerTab = assetTab;
    },
    resetTunerTabs(state: ITabStoreState): void {
      state.tunerTabEnabled = true;
    }
  } as ITabStoreMutations,
  actions: {} as ITabStoreActions
};
