import { ILocation } from './location-model';
import { ICustomer } from './customer-model';
import { IAsset } from './asset-model';

export enum ModelType {
  Air_Duct_Network = 'Air_Duct_Network',
}

export interface IModelSummary {
  key: string;
  description: string;
  customer: ICustomer;
  location: ILocation;
  asset: IAsset;
  exportBy: string;
  exportDateTime?: string;
  updateBy: string;
  updateDateTime: string;
  createBy: string;
  createDateTime?: string;
  name: string;
  truncatedName?: string;
  isExpanded: boolean;
  manualSaveVersion: number;
  autoSaveVersion: number;
  modelType: ModelType;
  currentlyPublished: boolean;
  published: boolean;
  publishedDateTime?: string;
  hidden: boolean;
  markedForDelete: boolean;
  modelComplete: boolean;
  modelLocked?: boolean;
}
