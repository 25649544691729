










import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import ModelSelectorHMT from '@/components/hydraulicModelList/ModelSelectorHMT.vue';
import PageNotFound from '@/components/pageNotFound/PageNotFound.vue';
import Editor from '@/components/editor/EditorHMB.vue';
import { authGuard } from './services/auth';
import Component from 'vue-class-component';
import store from './store/';
import Loading from './components/common/Loading.vue';
import Error from './components/common/Error.vue';
import Tuner from './components/hydraulicModelTuner/Tuner.vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import mapInit from 'highcharts/modules/map';
import initLaunchDarkly, { ILaunchDarklyOptions } from '../../CommonLibrary/modules/launch-darkly';
import { IModelSummary } from '@/view-models/model-summary';
import moment from 'moment';
import { hmbEventBus } from './services/hydraulic-model-event-bus';
import { appRouteBase } from './utils/from-parent';
import VeeValidate from '@/vee-validate';

stockInit(Highcharts);
mapInit(Highcharts);

function synchronouslyGetLDClientID(): string {
  const request = new XMLHttpRequest();

  let baseURL = '/';
  if (process.env.VUE_APP_BASE_URL) {
    baseURL = process.env.VUE_APP_BASE_URL;
  } else if (document.getElementById('hydraulic-model-builder-script')) {
    baseURL = new URL((document.getElementById('hydraulic-model-builder-script') as HTMLScriptElement).src).origin;
  }
  if (!baseURL.endsWith('/')) {
    baseURL += '/';
  }
  request.open('GET', baseURL + 'config.json', false);
  request.send(null);
  const configs = JSON.parse(request.responseText);
  return configs.launchDarklyClientId;
}

const ldOptions: ILaunchDarklyOptions = {
  clientId: synchronouslyGetLDClientID(),
  store: store as any
};

// @ts-ignore-start
Vue.use(initLaunchDarkly, ldOptions);
// @ts-ignore-end
Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(HighchartsVue);

Vue.filter('formatDate', (dateTime: string, undefinedValue: string = '') => {
  if (dateTime) {
    return moment(dateTime).format('MM/DD/YY');
  } else {
    return undefinedValue;
  }
});

Vue.filter('formatSummaryDate', (summary: IModelSummary, undefinedValue: string = '') => {
  if (summary) {
    return (summary.updateDateTime) ? moment(summary.updateDateTime).format('MM/DD/YY')
      : moment(summary.createDateTime).format('MM/DD/YY');
  } else {
    return undefinedValue;
  }
});

Vue.filter('formatDefault', (value: string | number) => {
  if (value === undefined || value === null) {
    return 'Default';
  } else {
    return value;
  }
});

const routes = [
  { path: '/', name: 'ModelSelector', component: async () => {
    return ModelSelectorHMT;
  }},
  { path: '/modelSelector', component: async () => {
    return ModelSelectorHMT;
  }},
  // tslint:disable-next-line:max-line-length
  { path: '/editor/customer/:customerKey/asset/:assetKey/model/:modelName', name: 'editor', component: Editor, props: true},
  { path: '/tuner', name: 'tuner', component: Tuner},
  { path: '*', component: PageNotFound },
];

export const router = new VueRouter({
  mode: 'history',
  base: appRouteBase(),
  routes
});

router.beforeEach(authGuard);

@Component({
  name: 'app',
  components: {
    Loading,
    Error
  },
  router
})
export default class App extends Vue {
  private deactivated(): void {
    // Get rid of old vue instance
    this.$destroy();
    // Used when integrated into parent app
    store.commit('app/updateIsLoading', true);
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      (window as any).eftEventBus.$off(
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }

  private mounted(): void {
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    this.reinitializeData();
  }

  private created(): void {
     // Clear leftover listeners, if present.
    this.clearHmdEventBus();
    this.$router.afterEach(() => {
      store.commit('app/updateIsLoading', false);
    });
  }

  private async handleRouteChange(parentRoute: Route): Promise<void> {
    if (parentRoute.path.match(/^\/?utilities\/ember\/hydraulic-modeler\//)) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/hydraulic-modeler\//, '/');

      // if parent route change is navigating back to base or /modelSelector then refresh data
      if (currentRelativeRoute === '/' || currentRelativeRoute === '/modelSelector') {
        await this.refreshData();
      }

      if (currentRelativeRoute !== this.$route.path) {
        await this.$router.push(currentRelativeRoute);
      }
    }
  }

  private async refreshData(): Promise<void> {
    await store.dispatch('asset/loadAssets', true);
    this.reinitializeData();
  }

  private reinitializeData(): void {
    store.commit('asset/initialize');
    store.commit('model/initializeModel');
    store.commit('diagram/initializeDiagram');
    store.commit('grid/initializeGrid');
    store.commit('tuner/initializeTuner');
    store.commit('model/updateFilterText', '');
  }

  private clearHmdEventBus(): void {
    hmbEventBus.$off();
  }

  get isLoading(): boolean {
    return store.getters['app/isLoading'];
  }
}
