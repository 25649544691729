
















import { Vue, Component } from 'vue-property-decorator';
import PreviewElement from './PreviewElement.vue';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { HydraulicModelPreviewElement } from '@/view-models/hydraulic-model-preview-element';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'preview-hierarchy',
  components: {
    PreviewElement
  }
})
export default class PreviewHierarchy extends Vue {
  private diagramHierarchy: HydraulicModelPreviewElement = null;

  private async mounted(): Promise<void> {
    EventBus.$on(hmbEvents.modelBurnerRemoved, async () => {
      await this.refresh();
    });

    this.refresh();
  }

  private beforeDestroy(): void {
    EventBus.$off(hmbEvents.modelBurnerRemoved);
  }

  private async refresh(): Promise<void> {
    const diagramElements = store.getters['diagram/getDiagramElements'] as FlowElementModel[];
    if (HelperMethods.isArrayEmpty(diagramElements)) {
      await store.dispatch('diagram/loadDiagramDetails');
    }
    this.loadElementHierarchy();
  }

  private getElementType(element: FlowElementModel): string {
    return FlowElementType[element.type].toLowerCase();
  }

  private loadElementHierarchy(): void {
    this.diagramHierarchy = store.getters['diagram/getDiagramPreviewModel'];
  }
}
