import { gridConfig } from '@/assets/configs/gridConfig';
import { FlowElementType, FlowElementModel } from '@/view-models/flow-element-models';
import { FlowElementIdModel } from '@/view-models/flowelement-id-model';
import { fabric } from 'fabric';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DirectionFlowType } from '@/view-models/direction-flow-model';
import { Mode } from '@/view-models/mode';
import store from '@/store';
import { IGridContext } from '@/store/grid/gridStoreInterface';
import * as gridStoreHelpers from '../helper/gridStoreHelper';
import { svgStrings } from './../imageCache/svgStrings';
import { moveDropZones, moveLine } from '../helper/dynamicAirflowHelper';
import HelperMethods from '@/shared/helper-methods';
import ZoomHelper from '../helper/zoomHelper';

export default class GridStoreHandler {
  public static gridObjectModifiedHandler(gridContext: IGridContext): void {
    gridContext.state.editorGrid.on('object:modified', (options: fabric.IEvent) => {
      if (options.target !== undefined && options.target.left !== undefined && options.target.top !== undefined) {
        store.commit('diagram/setDiagramModified', true);
      }
    });
  }

  public static gridObjectMovingHandlers(gridContext: IGridContext): void {
    // Triggered as an element moves around the canvas.
    // Checks for drop zone hovering in helper.ts's `onObjectMoving`.
    // Handles re-adjusting dynamic lines connected to this
    // element by calling dynamicAirflowHelper.ts's `moveLine`.
    gridContext.state.editorGrid.on('object:moving', (options: fabric.IEvent) => {
      if (!HelperMethods.isNullOrUndefined(options.target?.left) &&
        !HelperMethods.isNullOrUndefined(options.target?.top) &&
        ((options.e as any).movementX || (options.e as any).movementY)
      ) {
        gridContext.commit('setIsObjectMoved', true);
        store.commit('diagram/setDiagramModified', true);
      }
    });
    gridContext.state.editorGrid.on(
      'object:moving',
      _.throttle(
        (event: fabric.IEvent) => {
          // Check for drop zone hovering and re-adjust dynamic lines
          gridStoreHelpers.onObjectMoving(gridContext);
          if (!HelperMethods.isNullOrUndefined(event.target?.name)) {
            moveLine(gridContext, event.target, true);
            moveDropZones(gridContext, event.target);
          } else {
            // adjust lines for multi-selected objects
            const selectedElements = gridContext.state.selectedElements;
            selectedElements.forEach((element) => {
              element.setCoords();
              // update lines and dropzones
              moveLine(gridContext, element, true);
              moveDropZones(gridContext, element);
            });
          }
        },
        gridConfig.debounceTime,
        { leading: false, trailing: true }
      )
    );
  }

  public static gridDragEnterHandler(gridContext: IGridContext): void {
    // Triggered when drag first touches canvas and creates the image shadow
    gridContext.state.editorGrid.on('dragenter', () => {
      gridContext.commit('setIsMouseDown', true);
      gridContext.dispatch('removeGridShadows');
      const selectedFlowElement = gridContext.rootGetters['flowElement/selectedFlowElement'] as FlowElementModel;

      // Get shadow image
      const idelchikSVG = gridContext.state.idelchikShadowSVGs.get(selectedFlowElement.name);

      // Get angle and name for shadow
      const angle =
        selectedFlowElement.directionFlowList.find((dir) => dir.type === DirectionFlowType.ELEMENT)?.degree ?? 0;
      const shadowName =
        selectedFlowElement.type === FlowElementType.IDELCHIK
          ? gridConfig.idelchikShadowName
          : gridConfig.burnerShadowName;

      // Create shadow
      fabric.loadSVGFromString(
        selectedFlowElement.type === FlowElementType.IDELCHIK ? idelchikSVG ?? '' : svgStrings.burner.shadow ?? '',
        (objects, options) => {
          const shadowObject = fabric.util.groupSVGElements(
            objects,
            {
              ...options,
              name: shadowName,
              top: 0,
              left: gridContext.state.editorGridBoxSize,
              originX: 'left',
              originY: 'top',
              angle: 360 - angle,
              hasRotatingPoint: false,
              hasControls: false,
              lockScalingX: true,
              lockScalingY: true,
              lockRotation: true,
              opacity: 0.8
            },
            '-'
          );
          // Add to canvas
          gridContext.state.selectedElements = [shadowObject];
          store.commit('diagram/setSelectedElements', [shadowObject]);
          gridContext.state.editorGrid.add(shadowObject);
        }
      );
    });
  }

  public static gridDragLeaveHandler(gridContext: IGridContext): void {
    // Triggered as the drag leaves the canvas and removes the image shadow
    gridContext.state.editorGrid.on('dragleave', () => {
      gridContext.commit('setIsMouseDown', false);
      gridContext.dispatch('removeGridShadows');
      // Remove drop zones
      gridStoreHelpers.onObjectMoved(gridContext);
    });
  }

  public static gridDragOverHandlers(gridContext: IGridContext): void {
    // Triggered as the drag moves around the canvas and handles
    // snapping the shadow to even intervals on the grid (currently 1/2 of grid box
    // size). Also checks for drop zone hovering in helper.ts's `onObjectMoving`
    gridContext.state.editorGrid.on(
      'dragover',
      _.throttle(
        async (options: fabric.IEvent) => {
          const pointerPosition = await gridContext.dispatch('getEditorPointerPosition', options.e);
          const selectedElements = gridContext.state.selectedElements;
          selectedElements.forEach((element) => {
            if (element && gridStoreHelpers.isShadow(element)) {
              const movementSize = gridContext.state.editorGridBoxSize / gridConfig.movementFactor;
              const newTop = Math.round(pointerPosition.y / movementSize) * movementSize;
              const newLeft = Math.round(pointerPosition.x / movementSize) * movementSize;
              if (newTop !== element?.top || newLeft !== element?.left) {
                element?.set({ top: newTop, left: newLeft }).setCoords();
                gridContext.commit('setIsObjectMoved', true);
                if (!gridStoreHelpers.onObjectMoving(gridContext)) {
                  gridContext.state.editorGrid.requestRenderAll();
                }
              }
            }
          });
        },
        gridConfig.debounceTime,
        { leading: false, trailing: true }
      )
    );
  }

  public static gridDropHandler(gridContext: IGridContext): void {
    // Triggered on mouse up while dragging and removes the shadow,
    // draws an element on the canvas, and checks if it needs to snap to another
    // element's drop zone by calling helper.ts's `onObjectMoved`
    gridContext.state.editorGrid.on('drop', async (event: fabric.IEvent) => {
      gridContext.commit('setIsMouseDown', false);
      gridContext.dispatch('removeGridShadows');
      const selectedFlowElement = gridContext.rootGetters['flowElement/selectedFlowElement'] as FlowElementModel;

      const pointerPosition = await gridContext.dispatch('getEditorPointerPosition', event.e);

      // Create a new element
      const newFlowElement = _.cloneDeep(selectedFlowElement);
      let uuid = uuidv4();
      let id = `${selectedFlowElement.name}-${uuid}`;
      if (newFlowElement.type === FlowElementType.BURNER) {
        id = id + 'burner-element';
      }
      // Make sure id is unique
      while (gridContext.rootGetters['diagram/getElementById'](id)) {
        uuid = uuidv4();
        id = `${selectedFlowElement.name}-${uuid}`;
      }
      newFlowElement.flowElementId = new FlowElementIdModel(id, '');

      // Create sub elements
      if (newFlowElement.subElementList) {
        newFlowElement.subElementList.forEach((subElement: FlowElementModel) => {
          const sid = `${subElement.name}-(${pointerPosition.x},${pointerPosition.y})-${uuid}`;
          subElement.flowElementId = new FlowElementIdModel(sid, '');
        });
      }
      // Add new element
      store.commit('diagram/addElement', newFlowElement);
      if (newFlowElement.type === FlowElementType.BURNER) {
        store.commit('burner/lockBurnerById', newFlowElement.burner?.burnerId);
      }
      gridStoreHelpers.addElementToGrid(gridContext, newFlowElement, pointerPosition.y, pointerPosition.x);

      // Check if new element needs to be snapped to a dropzone
      if (!gridStoreHelpers.onObjectMoved(gridContext)) {
        // If it didn't render yet, render
        gridContext.state.editorGrid.requestRenderAll();
      }
    });
  }

  public static mouseUpHandler(gridContext: IGridContext): void {
    // Draws an element on the canvas, and checks if it needs to snap
    // to another element's drop zone by calling helper.ts's `onObjectMoved`
    gridContext.state.editorGrid.on('mouse:up', () => {
      gridContext.commit('setIsMouseDown', false);
      gridContext.state.alternateMovingTarget?.target.setCoords();
      gridContext.state.alternateMovingTarget = null;
      gridStoreHelpers.onObjectMoved(gridContext);
      const selectedElements = gridContext.state.selectedElements;
      selectedElements.forEach((element) => {
        store.commit('diagram/setSelectedPreviewElementId', gridStoreHelpers.getFlowElementId(element));
      });
    });
  }

  public static mouseMoveHandlers(gridContext: IGridContext): void {
    // Triggered as an element moves around the canvas. If edge case of an element being selected/dragged
    // from under another element's hidden drop zone (i.e. if alternateMovingTarget not null), handles
    // snapping the element to even intervals on the grid (currently 1/2 of a grid box size). Also checks
    // for drop zone hovering in helper.ts's `onObjectMoving`. Also handles re-adjusting dynamic lines
    // connected to this element by calling dynamicAirflowHelper.ts's `moveLine`.
    gridContext.state.editorGrid.on(
      'mouse:move',
      _.throttle(
        (event: fabric.IEvent) => {
          if (
            gridContext.state.mode !== Mode.PREVIEW &&
            gridContext.state.alternateMovingTarget &&
            gridContext.state.alternateMovingTarget.target.size() > 0
          ) {
            const mousePos = gridContext.state.editorGrid.getPointer(event.e);
            const movementSize = gridContext.state.editorGridBoxSize / gridConfig.movementFactor;
            const oldLeft = gridContext.state.alternateMovingTarget.target.left;
            const oldTop = gridContext.state.alternateMovingTarget.target.top;
            const newLeft =
              Math.round((mousePos.x + gridContext.state.alternateMovingTarget.offsetX) / movementSize) * movementSize;
            const newTop =
              Math.round((mousePos.y + gridContext.state.alternateMovingTarget.offsetY) / movementSize) * movementSize;
            if (newLeft !== oldLeft || newTop !== oldTop) {
              gridContext.state.alternateMovingTarget.target.set({
                left: newLeft,
                top: newTop
              });

              // moveLine to adjust dynamic lines if this element is connected
              moveLine(gridContext, gridContext.state.alternateMovingTarget.target, true);
              moveDropZones(gridContext, gridContext.state.alternateMovingTarget.target);
              gridContext.commit('setIsObjectMoved', true);
              gridContext.state.editorGrid.requestRenderAll();
            }
          }
        },
        gridConfig.debounceTime,
        { leading: false, trailing: true }
      )
    );
    gridContext.state.editorGrid.on(
      'mouse:move',
      _.throttle(
        () => {
          if (gridContext.state.mode === Mode.PREVIEW) {
            return;
          }
          if (gridContext.state.alternateMovingTarget && gridContext.state.alternateMovingTarget.target.size() > 0) {
            // Check if you need to display drop zones
            gridStoreHelpers.onObjectMoving(gridContext);
          }
        },
        gridConfig.debounceTime,
        { leading: false, trailing: true }
      )
    );
  }

  public static mouseDownHandler(gridContext: IGridContext): void {
    // Used to check for edge case of an element being selected/dragged
    // from under another element's hidden drop zone. If found, sets alternativeMovingTarget
    gridContext.state.editorGrid.on('mouse:down', (event: fabric.IEvent) => {
      const multiSelection = (event.e as MouseEvent).shiftKey && gridContext.state.mode === Mode.EDIT;
      if (multiSelection) {
        return;
      }
      gridContext.commit('setIsMouseDown', true);
      store.commit('diagram/setSelectedPreviewElementId', null);
      if (!event.target || HelperMethods.isArrayEmpty(gridContext.getters.selectedElements)) {
        // Clicked on nothing useful, pan
        gridStoreHelpers.startPan(gridContext, event);
        // Clear selectFlowElement just in case
        store.commit('flowElement/selectFlowElement', null);
      }
    });
  }

  public static mouseWheelHandler(gridContext: IGridContext): void {
    // Draws an element on the canvas, and checks if it needs to snap
    // to another element's drop zone by calling helper.ts's `onObjectMoved`
    gridContext.state.editorGrid.on('mouse:wheel',
      _.throttle(
        (event: fabric.IEvent) => {
          const wheelEvent = (event.e as WheelEvent);
          const wheelDelta = (wheelEvent as any).wheelDelta;
          const zoomPoint = new fabric.Point(wheelEvent.x, wheelEvent.y);
          const currentZoom = gridContext.state.editorGrid.getZoom();
          if (wheelDelta > 0 && currentZoom <= gridConfig.maxZoomMagnification) {
            ZoomHelper.performZoomIn(gridContext, zoomPoint);
          } else if (wheelDelta < 0 && currentZoom >= gridConfig.minZoomMagnification) {
            ZoomHelper.performZoomout(gridContext, zoomPoint);
          }
        },
        gridConfig.debounceTime,
        { leading: false, trailing: true }
      )
    );
  }

  public static selectionCreatedHandler(gridContext: IGridContext): void {
    // Activated when no object is selected and you click on a new object
    gridContext.state.editorGrid.on('selection:created', (event: any) => {
      // remove border controls from selected area
      const selectionGroup = event.target as fabric.Object;
      selectionGroup.hasControls = false;
      selectionGroup.hasBorders = false;

      // select objects within selection zone
      const activeObjects = event.selected as fabric.Group[];
      if (!HelperMethods.isArrayEmpty(activeObjects)) {
        const activeGroups = activeObjects.filter((obj) => obj.type === 'group');
        gridStoreHelpers.setSelectedFlowElements(gridContext, activeGroups);
      }
    });
  }

  public static selectionUpdatedHandler(gridContext: IGridContext): void {
    // Activated when an object is already selected and you click on a new object
    gridContext.state.editorGrid.on('selection:updated', (event: any) => {
      // remove border and controls from selected area
      const selectionGroup = event.target as fabric.Object;
      selectionGroup.hasControls = false;
      selectionGroup.hasBorders = false;

      const inactiveObjects = event.deselected as fabric.Group[];
      let activeObjects = event.selected as fabric.Group[];
      const multiSelection = event.e?.shiftKey && gridContext.state.mode === Mode.EDIT;
      if (multiSelection) {
        // add on to what is already selected
        const previousActiveObjects = gridContext.state.selectedElements as fabric.Group[];
        activeObjects = activeObjects.concat(previousActiveObjects);
        // remove any elements that will be deselected
        activeObjects = activeObjects.filter(
          (activeObj) => !inactiveObjects.some((inactiveObj) => inactiveObj.name === activeObj.name)
        );
      }
      if (!HelperMethods.isArrayEmpty(inactiveObjects)) {
        const inactiveGroups = inactiveObjects.filter((obj) => obj.type === 'group');
        gridStoreHelpers.setDeselectedFlowElements(gridContext, inactiveGroups);
      }
      if (!HelperMethods.isArrayEmpty(activeObjects)) {
        const activeGroups = activeObjects.filter((obj) => obj.type === 'group');
        gridStoreHelpers.setSelectedFlowElements(gridContext, activeGroups);
      }
    });
  }

  public static selectionClearedHandler(gridContext: IGridContext): void {
    // Activated when an object is already selected and you click on blank space
    gridContext.state.editorGrid.on('selection:cleared', (event: any) => {
      if (!event.deselected || event.deselected.size === 0) {
        return;
      }
      const inactiveObjects = event.deselected;
      if (!HelperMethods.isArrayEmpty(inactiveObjects)) {
        const inactiveGroups = inactiveObjects.filter((obj) => obj.type === 'group');
        gridStoreHelpers.setDeselectedFlowElements(gridContext, inactiveGroups);
      }
    });
  }
}
