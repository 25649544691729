import { BurnerModel } from './burner-model';
import { FlowElementIdModel } from './flowelement-id-model';
import { DirectionFlowModel } from './direction-flow-model';
import { ElementAttributeModel } from './element-attribute-model';

export enum FlowElementType {
  NONE = 0,
  IDELCHIK = 1,
  CRANE = 2,
  BURNER = 3
}

export class FlowElementModel {
  public name: string  = '';
  public displayName?: string  = '';
  public description?: string;
  public type: FlowElementType = FlowElementType.NONE;
  public elementModelName?: string;
  public createBy?: string;
  public createDateTime?: string;
  public updateBy?: string;
  public updateDateTime?: string;
  public exportBy?: string;
  public exportDateTime?: string;
  public burner?: BurnerModel;
  public flowElementId?: FlowElementIdModel;
  public directionFlowList: DirectionFlowModel[] = new Array<DirectionFlowModel>();
  public subElementList?: FlowElementModel[];
  public elementAttributeList?: ElementAttributeModel[];
}
