import Vue from 'vue';

const EventBus = new Vue();
export default EventBus;

export const hmbEvents = {
  setEnableState: 'setEnableState',
  hideAllInfo: 'hideAllInfo',
  hideTuningSummary: 'hideTuningSummary',
  tuningSummaryLoaded: 'tuningSummaryLoaded',
  navigateNotesCheck: 'navigateNotesCheck',
  navigateGoBack: 'navigateGoBack',
  navigateHome: 'navigateHome',
  navigateTrainingData: 'navigateTrainingData',
  navigateNewModelTuning: 'navigateNewModelTuning',

  checkForCompleteModel: 'checkForCompleteModel',
  burnerSetupError: 'burnerSetupError',
  burnerAssetChange: 'burnerAssetChange',
  modelBurnerRemoved: 'modelBurnerRemoved',

  navigateFromPreview: 'navigateFromPreview',
  previewModeChanged: 'previewModeChanged',

  newTunerLoadHistory: 'newTunerLoadHistory',
  retrain: 'retrain',
  retrainModel: 'retrainModel',
  tabChange: 'tabChange',
  toggleResultDetails: 'toggleResultDetails',

  showSaveModelPromptModal: 'showSaveModelPromptModal',
  confirmLeaveEditor: 'confirmLeaveEditor',
  resetFromPrompt: 'resetFromPrompt',
  resetModelComplete: 'resetModelComplete',
  confirmEditModel: 'confirmEditModel',

  setSiteInfo: 'setAssetInfo'
};

export const navigateTypes = {
  goBack: 'goBack',
  history: 'history',
  home: 'home',
  newModelTuning: 'newModelTuning',
  retrainModel: 'retrainModel',
  toggleResultDetails: 'toggleResultDetails',
  trainingData: 'trainingData'
};
