




































import { Component, Vue } from 'vue-property-decorator';
import { ITuningHistoryModel } from '@/view-models/hydraulic-tuning-history';
import store from '@/store';
import EventBus, { hmbEvents, navigateTypes } from '@/components/hydraulicModelTuner/eventBus';
import ScatterChart from '@/components/hydraulicModelTuner/components/ScatterChart.vue';
import ResultSummary from '@/components/hydraulicModelTuner/components/ResultSummary.vue';
import ResultDetails from '@/components/hydraulicModelTuner/components/resultDetails/ResultDetails.vue';
import { ITuningResultModel } from '@/view-models/tuning-result-model';
import TunerHelper from '@/components/hydraulicModelTuner/components/tunerHelper';

@Component({
  name: 'TunerSummary',
  components: {
    ScatterChart,
    ResultSummary,
    ResultDetails
  }
})
export default class TunerSummary extends Vue {
  public tunerHistory: ITuningHistoryModel;
  private showDetails: boolean = false;
  private trainingCasesExpanded: boolean = false;
  private tuningResult: ITuningResultModel = null;
  private solverResultValue: string = '';
  private solverResultDescription: string = '';
  private tuningResultValue?: string = '';

  public created(): void {
    this.tunerHistory = store.getters['tuner/getCurrentTuningHistory'];
  }

  public async mounted(): Promise<void> {
    EventBus.$on(hmbEvents.navigateGoBack, () => {
      this.goBack();
    });
    EventBus.$on(hmbEvents.toggleResultDetails, () => {
      this.toggleResultDetails();
    });

    store.commit('tuner/setTuningResult');
    try {
      await store.dispatch('tuner/loadTuningResult');
      this.tuningResult = store.getters['tuner/getTuningResult'];
    } catch (ex) {
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'Error Loading Tuning Results In Tuning Summary\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }

    if (this.tuningResult) {
      if (this.tuningResult.solverSuccess) {
        this.solverResultValue = 'Solution Found';
        this.solverResultDescription = this.tuningResult.solverMessage;
      } else if (this.tuningResult.solverError) {
        this.solverResultValue = 'Solver Error';
        this.solverResultDescription = this.tuningResult.errorMessage;
      } else {
        this.solverResultValue = 'No Solution Found';
        this.solverResultDescription = this.tuningResult.solverMessage;
      }
      this.tuningResultValue = TunerHelper.formatNormailizedSumofSquaredErrorsFromResult(this.tuningResult);
    } else {
      this.solverResultValue = 'In Progress';
      this.solverResultDescription = 'N/A';

      this.tuningResultValue = TunerHelper.formatNormailizedSumofSquaredErrors(this.tunerHistory);
    }
  }

  private tryGoBack(): void {
    if (this.showDetails) {
      this.goBack();
    } else {
      EventBus.$emit(hmbEvents.navigateNotesCheck, navigateTypes.goBack);
    }
  }

  private goBack(): void {
    if (this.showDetails) {
      this.showDetails = false;
    } else {
      store.commit('tuner/setCurrentTuningHistory', undefined);
      EventBus.$emit(hmbEvents.hideTuningSummary);
    }
  }

  private tryToggleResultDetails(): void {
    if (this.showDetails) {
      this.showDetails = false;
    } else {
      EventBus.$emit(hmbEvents.navigateNotesCheck, navigateTypes.toggleResultDetails);
    }
  }

  private toggleResultDetails(): void {
    this.showDetails = true;
  }
}
