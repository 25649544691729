




































































































































































































































































































































import { BootstrapVue, BDropdown, BModal } from 'bootstrap-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ITrainingCase } from '@/view-models/training-data-model';
import { ISolver, SolverModel, ISolverVariableModel, VariableType, SolverType } from '@/view-models/solver-model';
import { IModelSummary } from '@/view-models/model-summary';
import store from '@/store';
import { IRegressionRequest } from '@/view-models/regression-request-model';
import EventBus, { hmbEvents, navigateTypes } from '@/components/hydraulicModelTuner/eventBus';
import { ITuningHistoryModel } from '@/view-models/hydraulic-tuning-history';
import Dropdown from '@/components/common/Dropdown.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'TunerNewModel',
  components: {
    Dropdown
  }
})
export default class TunerNewModel extends Vue {
  private modelOptions: any[] = [];
  private efficiencyEstimate: number | string = '1.0';
  private effMsg = '';
  private effValid = true;
  private selectedModel: any = null;
  private selectedVariables: ISolverVariableModel[] = [];
  private selectedSolverOption: string = SolverType.L_BFGS_B;
  private selectedSolver: ISolver = null;
  private isExpanded: boolean = false;
  private trainingCases: ITrainingCase[] = [];
  private checkedTestCases: string[] = [];
  private solvers: ISolver[] = [];
  private variableModels: any[] = [];
  private solverOptions: any[] = [];
  private email: boolean = false;
  private modelTrackerValid = false;
  private trainingCaseTrackerValid = false;
  private solverTrackerValid = true;
  private efficiencyEstimateTrackerValid = true;
  private newModelTuningSubmitted: boolean = false;
  private BooleanValues: string[] = ['True', 'False'];

  @Watch('selectedModel')
  public async onSelectedModelChange(): Promise<void> {
    if (this.selectedModel) {
      this.modelTrackerValid = true;
    } else {
      this.modelTrackerValid = false;
    }
  }

  @Watch('checkedTestCases')
  public async onCheckedTestCasesChange(): Promise<void> {
    if (this.checkedTestCases && this.checkedTestCases.length > 0) {
      this.trainingCaseTrackerValid = true;
    } else {
      this.trainingCaseTrackerValid = false;
    }
  }

  @Watch('efficiencyEstimate')
  public async onEfficiencyEstimateChange(): Promise<void> {
    if (this.efficiencyEstimate) {
      this.efficiencyEstimateTrackerValid = this.effIsValid();
    } else {
      this.efficiencyEstimateTrackerValid = false;
    }
  }

  @Watch('selectedSolverOption')
  private changeVariables(val: string): void {
    if (!val) {
      this.selectedVariables = [];
      this.solverTrackerValid = false;
      return;
    }

    const currentSolverVariableModels: ISolverVariableModel[] = this.variableModels.filter(
      (variable: ISolverVariableModel) => variable.solverName === val
    );
    this.selectedVariables = currentSolverVariableModels;
    this.selectedSolver = this.solvers.find((solver: ISolver) => (solver.name = val));

    // restore the other solver variable values to default.
    const otherSolverVariableModels: ISolverVariableModel[] = this.variableModels.filter(
      (variable: ISolverVariableModel) => variable.solverName !== val
    );
    otherSolverVariableModels?.forEach((e) => {
      e.value = e.defaultValue;
    });
  }

  private clearInputs(): void {
    this.variableModels?.forEach((e) => {
      e.value = e.defaultValue;
    });
    this.selectedVariables = [];
    this.email = false;
    this.selectedSolverOption = null;
    this.efficiencyEstimate = '1.0';
    this.checkedTestCases = [];
    this.selectedModel = null;
    store.commit('tuner/setShouldRetrain', false);
  }

  private formIsValid(): boolean {
    let valid = true;
    let solverTracker = true;

    for (const e of this.selectedVariables) {
      if (!e.value) {
        SolverModel.setSolverVariableValid(e);
        continue;
      }

      switch (e.variableType) {
        case VariableType.IntType:
          const intNumber = Number(e.value);
          if (isNaN(intNumber) || !Number.isInteger(intNumber)) {
            valid = false;
            solverTracker = false;
            SolverModel.setSolverVariableInvalid(e, 'Integer Required');
          } else if (intNumber < 0) {
            valid = false;
            solverTracker = false;
            SolverModel.setSolverVariableInvalid(e, 'Minimum value is 0');
          } else {
            SolverModel.setSolverVariableValid(e);
          }
          break;
        case VariableType.FloatType:
          if (!e.value.match(/^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/)) {
            valid = false;
            solverTracker = false;
            SolverModel.setSolverVariableInvalid(e, 'Real Number Required');
          } else if (Number(e.value) < 0) {
            valid = false;
            solverTracker = false;
            SolverModel.setSolverVariableInvalid(e, 'Minimum value is 0');
          } else {
            SolverModel.setSolverVariableValid(e);
          }
          break;
        case VariableType.BooleanType:
          const regex = new RegExp('^(true|false|t|f|1|0|y|n|yes|no)$', 'i');
          if (!regex.test(e.value)) {
            valid = false;
            solverTracker = false;
            SolverModel.setSolverVariableInvalid(e, 'Boolean (True, False) Required');
          } else {
            SolverModel.setSolverVariableValid(e);
          }
          break;
      }
    }
    if (this.selectedVariables && this.selectedVariables.length > 0) {
      this.solverTrackerValid = solverTracker;
    } else {
      this.solverTrackerValid = false;
    }

    if (!this.selectedModel || !this.selectedSolverOption) {
      valid = false;
    }
    return valid;
  }

  private effIsValid(): boolean {
    const nan = isNaN(parseInt(this.efficiencyEstimate + ''));

    if (this.efficiencyEstimate === null) {
      this.effValid = false;
    } else if (!Number.isInteger(parseInt(this.efficiencyEstimate + ''))) {
      this.effValid = false;
      this.effMsg = 'Real Number Required';
    } else if (parseFloat(this.efficiencyEstimate.toString()) < 0) {
      this.effValid = false;
      this.effMsg = 'Minimum value is 0';
    } else {
      this.effValid = true;
      this.effMsg = '';
    }

    if (nan) {
      this.effValid = false;
      this.effMsg = 'Real Number Required';
    }

    return this.effValid;
  }

  private async mounted(): Promise<void> {
    try {
      await store.dispatch('tuner/loadSolvers');
    } catch (ex) {
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'New Model Error Loading Solvers\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }
    const tunerSolvers: ISolver[] = store.getters['tuner/getSolvers'];
    if (tunerSolvers) {
      this.solvers = tunerSolvers;
      this.solvers.forEach((solver: ISolver) => {
        const solverVariableModels: ISolverVariableModel[] = SolverModel.convertToSolverModels(solver);
        Array.prototype.push.apply(this.variableModels, solverVariableModels);
      });
      this.solverOptions = this.solvers.map((solver: ISolver) => {
        return { value: solver.name, text: solver.name };
      })?.sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        }
        if (a.value === SolverType.L_BFGS_B) {
          return -1;
        }
        return a.value > b.value ? 1 : -1;
      });

      this.selectedVariables = this.variableModels.filter((variable: ISolverVariableModel) =>
        variable.solverName === this.selectedSolverOption);
      this.selectedSolver = this.solvers.find((solver: ISolver) => solver.name === this.selectedSolverOption);
    }
    const tunerTrainingCases = store.getters['tuner/getTrainingData']?.filter((trainingCase: ITrainingCase) =>
      trainingCase.markedForDelete === false);
    this.trainingCases = tunerTrainingCases?.sort(this.sortByCaseName);

    const allLoadedModels: IModelSummary[] = store.getters['model/loadedModels'];
    this.modelOptions = allLoadedModels
      .filter((model: IModelSummary) => {
        return !model.hidden && !model.markedForDelete && model.modelComplete;
      })
      .map((model: IModelSummary) => {
        return { value: model.key, text: model.name };
      });


    if (store.getters['tuner/getShouldRetrain'] === true) {
      const retrainModel: ITuningHistoryModel = store.getters['tuner/getCurrentTuningHistory'];
      this.selectedModel = {value: retrainModel.modelKey, text: retrainModel.modelName};

      // filter deleted training cases and set them as checked
      // Note: Tuner training cases does not contain deleted cases so validating against that
      this.checkedTestCases = retrainModel.trainingDataKeys?.filter((key) => {
        return tunerTrainingCases?.some((trainingCase: ITrainingCase) => {
          return trainingCase.key === key;
        });
      }) ?? [];

      this.selectedSolverOption = (retrainModel.solver as ISolver).name;
      if (this.selectedSolverOption) {
        const solverName = this.selectedSolverOption || '';
        this.selectedSolver = this.solvers.find((solver: ISolver) => (solver.name === solverName));
      }

      const retrainVariables = (retrainModel.solver as ISolver).solverVariables as ISolverVariableModel[];
      retrainVariables.forEach((e) => {
        if (!e.value || (e.value?.toString().toUpperCase() === 'DEFAULT')) {
          e.value = '';
        }
        e.valid = true;
        e.message = '';
      });

      this.variableModels = this.variableModels.filter((v: ISolverVariableModel) =>
        v.solverName !== this.selectedSolverOption
      );
      const solverVariableModels: ISolverVariableModel[] =
        SolverModel.convertToSolverModels(retrainModel.solver as ISolver);
      Array.prototype.push.apply(this.variableModels, solverVariableModels);

      if (retrainModel.efficiencyEstimate) {
        this.efficiencyEstimate = retrainModel.efficiencyEstimate === '1' ? '1.0' :
        parseFloat(retrainModel.efficiencyEstimate).toFixed(1);
      } else {
        this.efficiencyEstimate = '1.0';
      }

      store.commit('tuner/setShouldRetrain', false);
    }
  }

  private sortByCaseName(a: any, b: any): number {
    if (a.name) {
      if (a.name === b.name &&
          a.dateImported &&
          b.dateImported) {
        // If names are equal, sort by date newest to oldest
        const dateA = Date.parse(a.dateImported);
        const dateB = Date.parse(b.dateImported);
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else if (dateA === dateB) {
          return 0;
        }
      }
      // If names are not equal, sort by name
      return a.name.localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' });
    }
    return -1;
  }

  private expand(): void {
    this.isExpanded = !this.isExpanded;
  }

  private toggleEmailChecked(): void {
    this.email = !this.email;
  }

  private isVariableBooleanType(variable: ISolverVariableModel): boolean {
    return variable.variableType === VariableType.BooleanType;
  }

  private submit(): void {
    // set newModelTuningSubmitted to true such that button is disabled
    this.newModelTuningSubmitted = true;

    const loadedModels = store.getters['model/loadedModels'];
    if (!loadedModels) {
      return;
    }
    store.commit('tuner/setCurrentTuningHistory', undefined);
    const selectedModelSummary = loadedModels.find((model: IModelSummary) => model.key === this.selectedModel.value);
    const currentTuningHistory: ITuningHistoryModel = {
      customer: selectedModelSummary.customer,
      location: selectedModelSummary.location,
      asset: selectedModelSummary.asset,
      modelKey: this.selectedModel.value,
      modelName: selectedModelSummary.name
    };

    this.selectedSolver.solverVariables = this.selectedVariables;

    const newRegression: IRegressionRequest = {
      TuningHistoryRequest: currentTuningHistory,
      Solver: this.selectedSolver,
      EfficiencyEstimate: parseFloat(this.efficiencyEstimate.toString()),
      SendEmailNotifications: this.email,
      TrainingDataKeys: this.checkedTestCases
    };

    store
      .dispatch('tuner/submitRegression', newRegression)
      .then(() => {
        store.commit('tuner/setCurrentHistoryCheckedModels', [this.selectedModel.value]);
        store.commit('tuner/setCurrentModelSummary', selectedModelSummary);
        store.commit('tuner/setShouldCheckAllModels', false);
        store.commit('tuner/setTuningShowHidden', false);
        (this.$refs['successful-tuning-modal'] as BModal)?.show();
        window.setTimeout(this.closeConnectionModal, 2000);
        EventBus.$emit(hmbEvents.newTunerLoadHistory);
      });
  }

  private closeConnectionModal(): void {
    // reset connection flag
    (this.$refs['successful-tuning-modal'] as BModal)?.hide();
    EventBus.$emit(hmbEvents.tabChange, navigateTypes.history);
  }

  private toggleModelDropdown(): void {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.newModelModelSelectorDropdown as BDropdown;
    const isDropdownOpen = dropdown.visible;
    if (!isDropdownOpen) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
  }

  private selectModel(model: any): void {
    this.selectedModel = model;
  }

  private toggleSolverDropdown(): void {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.newModelSolverSelectorDropdown as BDropdown;
    const isDropdownOpen = dropdown.visible;
    if (!isDropdownOpen) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
  }

  private selectSolver(solver: any): void {
    this.selectedSolverOption = solver.text;
  }

  private changeDropdownText(variable: any, val: string, scope: any): void {
    scope.toggleMenu();
    variable.value = val;
  }
}
