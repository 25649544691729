































































import Vue from 'vue';
import { Route } from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import { Component, Model, Watch } from 'vue-property-decorator';
import CreateModelDialog from './components/CreateModelDialogHMT.vue';
import Loading from '@/components/common/Loading.vue';
import store from '@/store/';
import { IAsset } from '@/view-models/assets-view-models';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import { IModelSummary } from '@/view-models/model-summary';
import Dropdown from '@/components/common/Dropdown.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'ModelHeader',
  components: {
    CreateModelDialog,
    Dropdown,
    Loading
  }
})
export default class ModelHeader extends Vue {
  @Model('change')
  private showHidden: boolean;

  private isLoading: boolean = false;
  private selectedModelSite: IAsset = null;
  private selectedModelAsset: IAsset = null;
  private siteList: IAsset[] = [];
  // private siteDropdownHelper: boolean = false;
  // private assetDropdownHelper: boolean = false;
  private localShowHidden: boolean = false;

  public async mounted(): Promise<void> {
    // Handle route changes from parent
    if ((window as any).eftEventBus != null) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }

    // Ensure all dropdown actions are captured
    // this.$root.$on('bv::dropdown::show', (bvEvent: any) => {
    //   if (bvEvent.componentId === 'site-selector-dropdown' && !this.siteDropdownHelper) {
    //     this.siteDropdownHelper = true;
    //   } else if (bvEvent.componentId === 'asset-selector-dropdown' && !this.assetDropdownHelper) {
    //     this.assetDropdownHelper = true;
    //   }
    // });
    // this.$root.$on('bv::dropdown::hide', (bvEvent: any) => {
    //   if (bvEvent.componentId === 'site-selector-dropdown' && this.siteDropdownHelper) {
    //     bvEvent.preventDefault();
    //     this.siteDropdownHelper = false;
    //   } else if (bvEvent.componentId === 'asset-selector-dropdown' && this.assetDropdownHelper) {
    //     bvEvent.preventDefault();
    //     this.assetDropdownHelper = false;
    //   }
    // });
    this.isLoading = true;
    await this.loadSiteAndAssetInfo(false);
    this.selectedModelSite = store.getters['asset/selectedSite'] || null;
    this.selectedModelAsset = store.getters['asset/selectedAsset'] || null;
    if (this.selectedModelSite && this.selectedModelAsset) {
      EventBus.$emit('changedAsset');
    }
    this.isLoading = false;
  }

  public beforeDestroy(): void {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  public async handleRouteChange(parentRoute: Route): Promise<void> {
    if (parentRoute.path.match(/^\/?utilities\/ember\/hydraulic-modeler\//)) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/hydraulic-modeler\//, '/');
      if (currentRelativeRoute === '/' || currentRelativeRoute === '/modelSelector') {
        EventBus.$on(hmbEvents.setSiteInfo, this.setSiteInfo);
        await this.loadSiteAndAssetInfo(true);
        store.commit('tuner/initializeTuner');
        this.isLoading = true;
        store.commit('asset/initialize');
        this.selectedModelSite = store.getters['asset/selectedSite'] || null;
        this.selectedModelAsset = store.getters['asset/selectedAsset'] || null;
        EventBus.$emit('changedAsset');
        this.isLoading = false;
        EventBus.$off(hmbEvents.setSiteInfo, this.setSiteInfo);
      }
    }
  }

  public async loadSiteAndAssetInfo(forceLoadAsset: boolean): Promise<void> {
    // Load all assets available to the user
    await store.dispatch('asset/loadAssets', forceLoadAsset);
    if (!forceLoadAsset) {
      this.setSiteInfo();
    }
  }

  public async setSiteInfo(): Promise<void> {
    // Get the currently loaded customer
    const customers: IAsset[] = store.getters['asset/allCustomers'];
    const customerKey = customers[0]?.orgKey;

    // Build list of sites site
    const sites: IAsset[] = store.getters['asset/findSitesByParentKey'](customerKey);
    if (sites && sites.length > 0) {
      sites.sort((siteX, siteY) => siteX.name > siteY.name ? 1 : -1);
      this.siteList = sites;
    }
  }

  public async selectModelSite(val: IAsset, scope: any): Promise<void> {
    // Set site
    scope.toggleMenu();
    this.selectedModelSite = val;
    store.commit('asset/selectSite', val);
    // Reset asset pick
    this.selectedModelAsset = null;
    store.commit('asset/selectAsset', null);
    EventBus.$emit('changedAsset');
  }

  public selectModelAsset(val: IAsset, scope: any): void {
    // Set asset
    scope.toggleMenu();
    this.selectedModelAsset = val;
    store.commit('asset/selectAsset', val);
    EventBus.$emit('changedAsset');
  }

  private async tuneModel(): Promise<void> {
    const asset = store.getters['asset/selectedAsset'];
    if (asset) {
      const tunerSite = store.getters['asset/selectedSite'];
      store.commit('tab/initializeTab');
      store.commit('tuner/setCurrentSite', tunerSite);
      store.commit('tuner/setCurrentAsset', asset);
      store.commit('tuner/setCurrentHistoryCheckedModels', null);
      store.commit('tuner/setShouldCheckAllModels', true);

      await store.dispatch('tuner/loadTuningHistoryList', asset.key);
      this.$router.push('tuner');
    }
  }

  get assetList(): IAsset[] {
    if (this.selectedModelSite) {
      const assets: IAsset[] = store.getters['asset/findAssetsBySiteKey'](this.selectedModelSite.key);
      return assets.sort((assetX, assetY) => assetX.name > assetY.name ? 1 : -1);
    } else {
      return [];
    }
  }

  get tuningEnabled(): boolean {
    const loadedModels: IModelSummary[] =  store.getters['model/loadedModels'];
    return (this.selectedModelAsset !== null) && (this.selectedModelSite !== null) &&
      loadedModels.some((model: IModelSummary) => model.hidden === false && model.modelComplete === true);
  }

  @Watch('showHidden')
  private onShowHiddenChange(): void {
    this.localShowHidden = this.showHidden;
  }

  @Watch('localShowHidden')
  private onLocalShowHiddenChange(): void {
    this.$emit('change', this.localShowHidden);
  }
}
