


































































































































































































































































import Vue from 'vue';
import { BootstrapVue, BIcon } from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import FlowElement from '../flowElementLibrary/FlowElement.vue';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { ElementAttributeModel } from '@/view-models/element-attribute-model';
import store from '@/store/';
import HelperMethods from '@/shared/helper-methods';
import CopyAttributesModal from '@/components/editor/components/flowElementDetails/CopyAttributesModal.vue';

Vue.use(BootstrapVue);

@Component({
  name: 'FlowElementDetailsPanel',
  components: {
    BIcon,
    CopyAttributesModal,
    FlowElement
  }
})
export default class FlowElementDetailsPanel extends Vue {
  private editable: boolean = false;
  private unSubscribeFlowElementLibrarySelection: () => void = null;
  private unSubscribeFlowElementCanvasSelection: () => void = null;
  private hierarchy: string[] = [];
  private visible: boolean = true;

  private get isCompositeElement(): boolean {
    if (this.diagramElement && this.diagramElement.subElementList && this.diagramElement.subElementList.length > 0) {
      return this.diagramElement.subElementList.some((subElement) => {
        return !HelperMethods.isArrayEmpty(subElement.elementAttributeList);
      });
    }
    return false;
  }

  private get burnerGrouping(): string {
    return this.diagramElement.burner.groupName;
  }

  private set burnerGrouping(grouping: string) {
    grouping = grouping.trim();
    if (grouping !== this.diagramElement.burner.groupName) {
      this.diagramElement.burner.groupName = HelperMethods.isStringEmpty(grouping) ? null : grouping;
      store.commit('diagram/setDiagramModified', true);
    }
  }

  private get diagramElement(): FlowElementModel {
    const selectedElements = store.getters['diagram/getSelectedElements'];
    return HelperMethods.isArrayEmpty(selectedElements) || selectedElements.length > 1 ?
      null : selectedElements[0];
  }

  private get elementType(): string {
    return this.diagramElement && FlowElementType[this.diagramElement.type]
      ? FlowElementType[this.diagramElement.type].toLowerCase()
      : '';
  }

  public mounted(): void {
    window.addEventListener('resize', this.resizePanel);
    this.loadExistingSelection();

    this.unSubscribeFlowElementLibrarySelection = store.subscribe(async (mutation, state) => {
      if (mutation.type === 'flowElement/selectFlowElement') {
        if (state.flowElement.selectedFlowElement == null && this.editable) {
          // Do not un-set element if canvas selection is active
          return;
        }
        this.sortAttributeList();

        this.editable = false;
        if (
          state.flowElement.selectedFlowElement &&
          state.flowElement.selectedFlowElement.type === FlowElementType.BURNER
        ) {
          this.hierarchy = store.getters['burner/getBurnerFolderByEmberHierarchyLevelKey'](
            this.diagramElement?.burner?.emberHierarchyLevelKey
          );
        }
      }
    });
    this.unSubscribeFlowElementCanvasSelection = store.subscribe(async (mutation) => {
      if (mutation.type === 'diagram/setSelectedElements') {
        this.loadExistingSelection();
      }
    });
  }

  public loadExistingSelection(): void {
    this.editable = true;
    if (this.diagramElement && this.diagramElement.type === FlowElementType.BURNER && this.diagramElement.burner) {
      this.hierarchy = store.getters['burner/getBurnerFolderByEmberHierarchyLevelKey'](
        this.diagramElement.burner.emberHierarchyLevelKey
      );
    }
  }

  public updated(): void {
    this.resizePanel();
  }

  private resizePanel(): void {
    const detailsPanel: HTMLElement = document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot?.querySelector('#collapse-details');
    const appElement = document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('app');
    if (!HelperMethods.isNullOrUndefined(detailsPanel) && !HelperMethods.isNullOrUndefined(appElement)) {
      detailsPanel.style.maxHeight = appElement?.clientHeight - 250 + 'px';
    }
  }

  private sortAttributeList(): void {
    if (this.diagramElement && this.diagramElement.elementAttributeList) {
      this.diagramElement.elementAttributeList.sort((a, b) => HelperMethods.sortString(a.name, b.name));
      if (this.diagramElement.subElementList) {
        for (const subElement of this.diagramElement.subElementList) {
          subElement.elementAttributeList?.sort((a, b) => HelperMethods.sortString(a.name, b.name));
        }
      }
    }
  }

  private beforeDestroy(): void {
    if (this.unSubscribeFlowElementLibrarySelection) {
      this.unSubscribeFlowElementLibrarySelection();
    }
    if (this.unSubscribeFlowElementCanvasSelection) {
      this.unSubscribeFlowElementCanvasSelection();
    }
  }

  private attributesExist(elemAttributeList: ElementAttributeModel[]): boolean {
    return !HelperMethods.isArrayEmpty(elemAttributeList);
  }

  private requiredAttributes(attributes: ElementAttributeModel[]): ElementAttributeModel[] {
    if (!HelperMethods.isArrayEmpty(attributes)) {
      return attributes.filter((attr) => attr.required);
    }
    return [];
  }

  private optionalAttributes(attributes: ElementAttributeModel[]): ElementAttributeModel[] {
    if (!HelperMethods.isArrayEmpty(attributes)) {
      return attributes.filter((attr) => !attr.required);
    }
    return [];
  }

  private optionalAttributesExist(elemAttributeList: ElementAttributeModel[]): boolean {
    if (HelperMethods.isArrayEmpty(elemAttributeList)) {
      return false;
    }
    return elemAttributeList.some((attrib) => !attrib.required);
  }

  private requiredAttributesExist(elemAttributeList: ElementAttributeModel[]): boolean {
    if (HelperMethods.isArrayEmpty(elemAttributeList)) {
      return false;
    }
    return elemAttributeList.some((attrib) => attrib.required);
  }

  private updateAttribute(elementDisplayName: string, attributeName: string, event: Event): void {
    if (!this.diagramElement || !this.diagramElement.elementAttributeList) {
      return;
    }
    if (this.diagramElement.displayName === elementDisplayName) {
      const attribute = this.diagramElement.elementAttributeList.find((attr) => attr.name === attributeName);
      if (attribute) {
        attribute.value = event.toString();
      }
    } else if (this.diagramElement.subElementList) {
      const subElement = this.diagramElement.subElementList.find(
        (subElem) => subElem.elementAttributeList && subElem.displayName === elementDisplayName
      );

      if (subElement && subElement.elementAttributeList) {
        const attribute = subElement.elementAttributeList.find((attr) => attr.name === attributeName);
        if (attribute) {
          attribute.value = event.toString();
        }
      }
    }
    store.commit('diagram/setDiagramModified', true);
  }

  private updateModelElementName(event: Event): void {
    if (this.diagramElement) {
      this.diagramElement.elementModelName = event.toString();
      if (this.diagramElement.subElementList) {
        for (const subElement of this.diagramElement.subElementList) {
          subElement.elementModelName = event.toString() + ' ' + subElement.displayName;
        }
      }
      store.commit('diagram/setDiagramModified', true);
    }
  }

  private showCopyAttributesModal(): void {
    (this.$refs.copyAttributesModal as CopyAttributesModal).open(this.diagramElement);
  }

  private levelStyle(idx: number): any {
    return {
      padding: `0rem 0rem 0rem ${idx}rem`
    };
  }

  private makeVisible(): void {
    this.visible = !this.visible;
    this.$parent.$emit('adjustDetailPanelHeight');
  }
}
