import Vue from 'vue';
import Vuex from 'vuex';
import { AppStore, IAppStoreState } from './app/appStore';
import { AssetStore, IAssetStoreState } from './assetStore/assetStoreHMB';
import { ModelStore, IModelStoreState } from './modelStore/modelStoreHMB';
import { GridStore } from './grid/gridStoreHMB';
import { IGridStoreState } from './grid/gridStoreInterface';
import { FlowElementStore, IFlowElementStoreState } from './flowElement/flowElementStore';
import { DiagramStore, IDiagramStoreState } from './diagramStore/diagramStore';
import { TunerStore, ITunerStoreState } from './tunerStore/tunerStore';
import { BurnerFolderStore, IBurnerFolderStoreState } from './burnerStore/burnerFolderStore';
import { ErrorStore, IErrorStoreState } from './error/errorStore';
import { TabStore, ITabStoreState } from './tabStore/tabStore';

Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  assetState: IAssetStoreState;
  flowElement: IFlowElementStoreState;
  grid: IGridStoreState;
  modelState: IModelStoreState;
  diagram: IDiagramStoreState;
  tuner: ITunerStoreState;
  tab: ITabStoreState;
  burner: IBurnerFolderStoreState;
  error: IErrorStoreState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    asset: AssetStore,
    flowElement: FlowElementStore,
    grid: GridStore,
    model: ModelStore,
    diagram: DiagramStore,
    tuner: TunerStore,
    tab: TabStore,
    burner: BurnerFolderStore,
    error: ErrorStore
  }
});
