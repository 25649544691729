






























































import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

Vue.use(BootstrapVue);

@Component({
  name: 'ResultTableControls'
})
export default class ResultTableControls extends Vue {
  @Prop({ required: true })
  private highlightedBurnerCount: number;
  @Prop({ required: true })
  private totalBurnerCount: number;
  @Prop({ required: true })
  private maxPercentage: number;
  @Prop({ required: true })
  private setHighlightPercent: (newPercentage: number) => void;
  @Prop({ required: true })
  private toggleFilterHighlight: () => void;
  @Prop({ required: true })
  private toggleShowPercent: () => void;

  private debouncedSetHighlightPercent: (newPercentage: number) => void = _.debounce(this.setHighlightPercent, 20);
  private percent: number = this.maxPercentage;
  private oldEvent: any;

  @Watch('maxPercentage')
  private myMaxPercentage(newMaxPercentage: number): void {
    // Percent is initially set to 100 before maxPercentage is calculated
    // So, when it is calculated, update percent
    this.percent = newMaxPercentage;
  }

  private changePercent(valueString: string): boolean {
    const splitValueString = valueString.split('%');
    if (splitValueString.length > 1 && splitValueString[1]?.length > 0) {
      return false;
    }
    const trimmedValueString = splitValueString[0];
    const value = parseFloat(trimmedValueString);
    if (!isNaN(value)) {
      this.percent = value;
      this.setHighlightPercent(this.percent);
      this.$nextTick(() => this.moveCursorToEnd(this.oldEvent));
    }
  }

  private checkPercent(evt: any): void {
    // eslint-disable-next-line keyword-spacing
    if (this.percent > this.maxPercentage) {
      this.changePercent(String(this.maxPercentage));
    } else if (evt.target.value === '%') {
      evt.target.value = '0%';
      this.changePercent('0');
    }
  }

  private onBackspace(evt: any): void {
    if (evt.target.value.length === evt.target.selectionStart) {
      evt.preventDefault();
    }
  }

  private onSelect(evt: any): void {
    // Don't select percent sign
    evt.target.selectionEnd = Math.min(evt.target.value.length - 1, evt.target.selectionEnd);
  }

  private onArrowRight(evt: any): void {
    // Keep cursor before percent sign
    if (evt.target.value.length === evt.target.selectionStart + 1) {
      evt.preventDefault();
    }
  }

  private onArrowUp(evt: any): void {
    // Increase percent
    if (this.percent < this.maxPercentage) {
      this.percent++;
      this.setHighlightPercent(this.percent);
    }
    evt.preventDefault();

    // Prevents auto-moving past percent sign
    this.$nextTick(() => this.moveCursorToEnd(evt));
  }

  private onArrowDown(evt: any): void {
    // Decrease percent
    if (this.percent > 0) {
      this.percent--;
      this.setHighlightPercent(this.percent);
    }
    evt.preventDefault();

    // Prevents auto-moving past percent sign
    this.$nextTick(() => this.moveCursorToEnd(evt));
  }

  private isValidInput(evt: any): boolean {
    this.oldEvent = evt;
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
    } else {
      const oldString = evt.target.value;
      const index = evt.target.selectionStart;

      const selectionLength = evt.target.selectionEnd - evt.target.selectionStart; // Digits to remove
      const newString = oldString.slice(0, index) + evt.key + oldString.slice(index + selectionLength);

      const splitString = newString.split('%');
      const newValue = parseInt(splitString[0]);

      const hasAfterSignDigits = splitString.length > 1 && splitString[1]; // Check for 100%44
      // eslint-disable-next-line keyword-spacing
      if ((newValue > this.maxPercentage) || hasAfterSignDigits) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }

  private moveCursorToEnd(evt: any): void {
    // Move cursor before percent sign
    if (evt && evt.target.value && evt.target.value.length === evt.target.selectionStart) {
      const cursorPosition = evt.target.value.length - 1;
      evt.target.selectionStart = cursorPosition;
      evt.target.selectionEnd = cursorPosition;
    }
  }
}
