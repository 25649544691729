import BaseService from './base-service';

export default class BurnerBaseService extends BaseService {
  protected updateBurnerResponse(response: any): void {
    if ((response) && (response.burners)) {
      // store the burner identifier to old identifier and use elo name as identifier.
      // the old identifier is used for upgrade hydraulic model.
      response.burners.forEach((burner: any) => {
        burner.burnerOldIdentifier = burner.burnerIdentifier;
        burner.burnerIdentifier = (burner.eloName) ? burner.eloName : burner.burnerIdentifier;
      });
    }
  }
}
