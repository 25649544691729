import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

export interface IAuthClient {
  init(env: IAuthSettings): Promise<void>;
  isAuthenticated(): Promise<boolean>;
  handleRedirectCallback(url?: string): Promise<RedirectLoginResult>;
  getAuthUser(): Promise<any>;
  logOut(returnPathConfig: {saveReturnPath: boolean}): void;
  loginWithRedirect(): Promise<void>;
  smartHandleRedirectCallback(urlQuery: string | Record<string, any>): Promise<boolean>;
  getAuthToken(): Promise<string>;
}

const STATE_NAME = 'on-point.auth.state';
interface IState {
  returnPath: string;
}

export interface IAuthSettings {
  authDomain: string;
  authClientID: string;
  authAudience: string;
  authRedirectUri?: string;
}

class AuthClient implements IAuthClient {
  private auth0Client: Auth0Client | null = null;
  private fetchingTokenPromise: Promise<string> | null = null;

  private get client(): Auth0Client {
    if (this.auth0Client == null) {
      throw new Error('AuthClient has not be initialized');
    }
    return this.auth0Client;
  }

  public async init(env: IAuthSettings): Promise<void> {
    this.auth0Client = await createAuth0Client({
      domain: env.authDomain,
      client_id: env.authClientID,
      audience: env.authAudience,
      redirect_uri: window.location.origin,
    });
  }

  public async isAuthenticated(): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.client.isAuthenticated();
      if (!result) {
        await this.client.getTokenSilently();
        result = await this.client.isAuthenticated();
      }
    } catch {
      return false;
    }
    return result;
  }

  public async handleRedirectCallback(): Promise<RedirectLoginResult> {
    const result = await this.client.handleRedirectCallback();
    const currentUri = getCurrentUri();
    const returnPath = this.getState()?.returnPath;
    if (returnPath != null && returnPath !== '' && currentUri !== returnPath) {
      window.location.href = returnPath;
    }
    this.clearState();

    return result;
  }

  public async getAuthUser(): Promise<any> {
    try {
      return await this.client.getUser();
    } catch (e) {
      return null;
    }
  }

  public logOut(returnPathConfig: { saveReturnPath: boolean }): void {
    if (returnPathConfig.saveReturnPath) {
      this.saveReturnPath();
    }
    this.client.logout({
      returnTo: window.location.origin,
    });
  }

  public async loginWithRedirect() {
    this.saveReturnPath();
    await this.client.loginWithRedirect();
  }

  public getAuthToken(): Promise<string> {
    if (this.fetchingTokenPromise == null) {
      this.fetchingTokenPromise = this.client.getTokenSilently().finally(() => {
        this.fetchingTokenPromise = null;
      });
    }
    return this.fetchingTokenPromise;
  }

  public async smartHandleRedirectCallback(urlQuery: string | Record<string, any>): Promise<boolean> {
    const runIt: boolean =
      typeof urlQuery === 'string'
        ? urlQuery.includes('code=') && urlQuery.includes('state=')
        : urlQuery?.code != null && urlQuery?.state != null;

    if (runIt) {
      try {
        await this.handleRedirectCallback();
      } catch (error) {
        const status = typeof error === 'object' && error.constructor === Object ? error?.response?.status : null;

        if (status === 401) {
          this.logOut({ saveReturnPath: true });
        }
        throw error;
      }
    }

    return runIt;
  }

  private saveReturnPath() {
    const returnPath = this.getState()?.returnPath;
    if (returnPath == null) {
      this.setReturnPathState({
        returnPath: getCurrentUri(),
      });
    }
  }

  private getState(): IState | null {
    const json = localStorage.getItem(STATE_NAME);
    const state: IState | null = json == null ? null : JSON.parse(json);

    return state;
  }

  private setReturnPathState(state: IState): void {
    const json = JSON.stringify(state);
    localStorage.setItem(STATE_NAME, json);
  }

  private clearState(): void {
    localStorage.removeItem(STATE_NAME);
  }
}

export const authClient: IAuthClient = new AuthClient();

export enum Auth0ErrorsEnum {
  LoginRequired = 'login_required',
  ConsentRequired = 'consent_required',
}

export function setupCallbackHandler(client: IAuthClient, errorHandler: (error: any) => void) {
  const listener = async () => {
    try {
      const ran = await client.smartHandleRedirectCallback(window.location.search);
      if (ran) {
        window.removeEventListener('load', listener);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  window.addEventListener('load', listener);
}

function getCurrentUri() {
  return `${window.location.pathname}${window.location.search}`;
}
