import { IBurner } from './burner-view-models';
import { IFolder, IFolderItem } from './folder-view-models';
import store from '@/store';

export interface IBurnerFolder extends IFolder {
  details?: IBurner;
  children: IBurnerFolder[];
  locked: boolean;
  clearSelf(): void;
  lockSelection(): void;
  unlockSelection(): void;
}

export class BurnerFolder implements IBurnerFolder {
  public static getBurnerFolderByEmberHierarchyLevelKey(key: string): string[] {
    return store.getters['burnerFolder/getBurnerFolderByEmberHierarchyLevelKey'](key);
  }

  public isOpen: boolean = true;
  public key: string = '';
  public parentKey: string = '';
  public burnerName: string = '';
  public children = Array<IBurnerFolder>();
  public details: IBurner;
  public locked: boolean = false;
  private selected: boolean = false;

  constructor(data?: any) {
    if (data) {
      this.burnerName = data.burnerName ? data.burnerName: data.name;
      this.parentKey = data.parentKey || '';
      this.key = data.key ? data.key : data.burnerIdentifier;
    }
  }

  public get isSelected(): boolean {
    return this.selected;
  }

  public get childCount(): number {
    return this.children.length;
  }

  private get childFolders(): IFolder[] {
    return this.children;
  }

  public get visibleChildFolders(): IFolder[] {
    return this.childFolders
      .filter((folder: IFolder) => folder.isVisible);
  }

  public get isLink(): boolean {
    return this.childCount === 0;
  }

  public get isDraggable(): boolean {
    return !this.isExpandable && this.childCount === 0;
  }

  public get visibleChildItems(): IFolderItem[] {
    return Array<IFolderItem>();
  }

  public get isVisible(): boolean {
    return true;
  }

  public get isActive(): boolean {
    return true;
  }

  public get isReadonly(): boolean {
    return this.childCount > 0;
  }

  public get isExpandable(): boolean {
    return this.childCount > 0;
  }

  get someChildrenSelected(): boolean {
    return false;
  }

  public get childItemCount(): number {
    return this.children.length;
  }

  public get allCount(): number {
    return this.children.length;
  }

  public selectSelf(): void {
    this.selected = true;
  }

  public clearSelf(): void {
    this.selected = false;
  }

  public lockSelection(): void {
    this.locked = true;
  }

  public unlockSelection(): void {
    this.locked = false;
  }

  public clearSelection(): void {
    this.locked = false;
  }
}
