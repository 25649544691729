export default class HelperMethods {
  public static isNullOrUndefined(object: any): boolean {
    return object == null || typeof object === 'undefined';
  }

  public static isStringEmpty(text: string): boolean {
    return this.isNullOrUndefined(text) || text.trim() === '';
  }

  public static isArrayEmpty(array: any[]): boolean {
    return this.isNullOrUndefined(array) || array.length === 0;
  }

  public static sortString(a: string, b: string, alphabetical: boolean = true): number {
    const above = alphabetical ? -1 : 1;
    const below = alphabetical ? 1 : -1;

    if (a == null) {
      return b == null ? 0 : above;
    }
    if (b == null) {
      return below;
    }

    const textA = a.toLowerCase();
    const textB = b.toLowerCase();

    if (textA < textB) {
      return above;
    }
    if (textA > textB) {
      return below;
    }
    return 0;
  }
}
