import { ChildElementModel } from './child-element-model';

export class FlowElementIdModel {
  public id: string = '';
  public parentId?: string = null;
  public childIdList?: ChildElementModel[] = [];

  public constructor(id?: string, parentId?: string) {
    this.id = id ? id : '';
    this.parentId = parentId;
  }
}
