
















import { Vue, Component } from 'vue-property-decorator';
import store from '@/store';
import { IBurnerResult, ICaseResult, ITuningResultModel } from '@/view-models/tuning-result-model';

@Component({
  name: 'scatter-chart',
  components: {}
})
export default class ScatterChart extends Vue {
  private points: any[] = [];
  private caseResults: ICaseResult[] = [];
  private updateArgs: any[] = [true, true, {duration: 1000}];
  private chartOptions: any = {
    chart: {
      backgroundColor: '#20222A',
      marginRight: 32,
      marginTop: 32
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    xAxis: {
      min: 0,
      max: 1,
      lineColor: '#4E5266',
      gridLineWidth: 0,
      startOnTick: true,
      tickWidth: 0
    },
    yAxis: {
      min: 0,
      max: 1,
      lineColor: '#4E5266',
      title: {
        enabled: false
      }
    },
    series: [{
      type: 'line',
      name: 'Regression line',
      data: [[0,0],[1,1]],
      enableMouseTracking: false,
      marker: {
        enabled: false
      },
      color: '#188291',
      dashStyle: 'ShortDot',
      linecap: 'square'
    },
    {
      type: 'scatter',
      name: 'Overall dP',
      data: this.points,
      enableMouseTracking: false,
      marker: {
        radius: 2,
        fillColor: '#FC825C',
        symbol: 'circle'
      }
    }]
  };

  private async mounted(): Promise<void> {
    try {
      await store.dispatch('tuner/loadTuningResult');
    } catch (ex) {
      store.dispatch('error/setError', {
        error: ex,
        errorString: 'Error Loading Tuning Results In Tuning Summary\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }
    const tuningResult: ITuningResultModel = store.getters['tuner/getTuningResult'];

    this.caseResults = tuningResult?.caseResults || [];
    this.caseResults.forEach((caseResult: ICaseResult) => {
      const currentTargetDp: number = caseResult.targetDp;
      caseResult.results.forEach((burnerResult: IBurnerResult) => {
        this.points.push([currentTargetDp, burnerResult.result]);
      });
    });

    const flatArray: any[]  = [].concat.apply([], this.points);
    const maxValue: number = Math.max(...flatArray);
    const minValue: number = Math.min(...flatArray);
    this.chartOptions.series[0].data = [[minValue, minValue], [maxValue, maxValue]];
    this.chartOptions.xAxis.max = maxValue;
    this.chartOptions.yAxis.max = maxValue;
    this.chartOptions.xAxis.min = minValue;
    this.chartOptions.yAxis.min = minValue;
  }
}
