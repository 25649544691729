// Note: the ModelPreviewName is ElementModelName which defined in FlowElementModel class.
export class BurnerModel {
  // This is the burner unique identifier which is a UUID key for the single buerner instance.
  // It used to populate ZoneName field in exported JSON.
  public burnerId: string = '';

  // It is the flow element type attached to the burner
  // Example - flowelements.burner_k_factor
  // It used to populate ZoneType field in exported JSON.
  public burnerType: string = '';

  // This is the burner type unique identifier. Each burner type can have multiple of burnerIds.
  // Example - fyhgzgVD1SvG93ymTzlw6aZ7blowciyS
  // It is used to populate BurnerKey field in exported JSON.
  public burnerKey: string = '';

  // Burner hierarchy key
  public emberHierarchyLevelKey: string = '';

  // Burner config name
  public burnerConfigName: string = '';

  public groupName: string = null;
}
