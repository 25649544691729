

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { BootstrapVue } from 'bootstrap-vue';
import store from '@/store';

Vue.use(BootstrapVue);

@Component
export default class FlowElement extends Vue {
  @Prop({})
  private element: FlowElementModel;
  private svgBaseName: string = '';
  private elemType: string = '';
  private buttonActiveState: boolean = false;

  public created(): void {
    this.elemType = FlowElementType[this.element.type].toLowerCase();
    this.svgBaseName = this.element.type === FlowElementType.IDELCHIK ? this.element.name : 'COOLStar';
  }

  private selectElement(): void {
    store.dispatch('grid/unselectElementsOnEditAndPreviewMode');
    store.commit('flowElement/selectFlowElement', this.element);
  }
}
