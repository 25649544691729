export enum SolverType {
  L_BFGS_B = 'L-BFGS-B',
  NELDER_MEAD = 'Nelder-Mead',
  TNC = 'TNC',
  POWELL = 'Powell',
  BFGS = 'BFGS',
  CG = 'CG'
}

export enum VariableType {
  FloatType = 'FLOAT_TYPE',
  IntType = 'INT_TYPE',
  BooleanType = 'BOOLEAN_TYPE'
}

export interface ISolverVariable {
  variableName: string;
  variableType: VariableType;
  defaultValue: string;
  value: string;
}

export interface ISolverVariableModel extends ISolverVariable {
  solverName: string;
  defaultValue: string;
  value: string;
  valid: boolean;
  message: string;
}

export interface ISolver {
  key?: string;
  name: string;
  solverVariables: ISolverVariable[];
}

export class SolverModel {
  public static convertToSolverModels(solver: ISolver): ISolverVariableModel[] {
    return solver.solverVariables.map((variable: ISolverVariable) => {
      return {
        solverName: solver.name,
        variableName: variable.variableName,
        variableType: variable.variableType,
        value: variable.value,
        defaultValue: variable.defaultValue,
        valid: true,
        message: ''
      };
    });
  }

  public static setSolverVariableValid(solverVariable: ISolverVariableModel): void {
    if (solverVariable) {
      solverVariable.valid = true;
      solverVariable.message = '';
    }
  }

  public static setSolverVariableInvalid(solverVariable: ISolverVariableModel, message: string): void {
    if (solverVariable) {
      solverVariable.valid = false;
      solverVariable.message = message;
    }
  }
}
