import { ITuningHistoryModel, TuningStatusType } from '@/view-models/hydraulic-tuning-history';
import { ITuningResultModel } from '@/view-models/tuning-result-model';

// Helper class for Hydraulic Model Tuner
export default class TunerHelper {
  public static formatNormailizedSumofSquaredErrors(tunerHistory: ITuningHistoryModel): string {
    let normalizedSumOfSquaredErrors: string = 'N/A';
    if (tunerHistory && tunerHistory.tuningResultValue && (tunerHistory.tuningStatus === TuningStatusType.COMPLETE)) {
      if (tunerHistory.tuningResultValue > 1.0) {
        normalizedSumOfSquaredErrors = (Math.round(tunerHistory.tuningResultValue * 10) / 10).toString();
      } else {
        normalizedSumOfSquaredErrors = (Math.round(tunerHistory.tuningResultValue * 10000) / 10000).toString();
      }
    }
    return normalizedSumOfSquaredErrors;
  }

  public static formatNormailizedSumofSquaredErrorsFromResult(tunerResult: ITuningResultModel): string {
    let normalizedSumOfSquaredErrors: string = 'N/A';
    if (tunerResult && tunerResult.rmsd) {
      if (tunerResult.rmsd > 1.0) {
        normalizedSumOfSquaredErrors = (Math.round(tunerResult.rmsd * 10) / 10).toString();
      } else {
        normalizedSumOfSquaredErrors = (Math.round(tunerResult.rmsd * 10000) / 10000).toString();
      }
    }
    return normalizedSumOfSquaredErrors;
  }
}
