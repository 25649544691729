




































































import Vue from 'vue';
import { BootstrapVue, BIcon } from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import FlowElement from '@/components/editor/components/flowElementLibrary/FlowElement.vue';
import { FlowElementModel } from '@/view-models/flow-element-models';
import store from '@/store/';
import FolderItem from '@/components/editor/components/flowElementLibrary/FolderItem.vue';
import { IBurner } from '../../../../view-models/burner-view-models';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import { IBurnerFolder } from '@/view-models/burner-folder-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'side-menu',
  components: {
    FlowElement,
    BIcon,
    FolderItem
  }
})
export default class SideMenu extends Vue {
  private collapsed: boolean = false;
  public idelchikFlowElements: FlowElementModel[] = [];
  public burnerFlowElements: FlowElementModel[] = [];
  private isSideMenuOpen: boolean = false;

  public async beforeCreate(): Promise<void> {
    this.idelchikFlowElements = await store.dispatch('flowElement/loadIdelChikElements');
    store.dispatch('grid/loadImageSVGs');
  }

  private beforeDestroy(): void {
    EventBus.$off(hmbEvents.burnerAssetChange);
  }

  private async mounted(): Promise<void> {
    window.addEventListener('resize', this.resizeSideMenus);
    try {
      await this.refreshBurner();
      EventBus.$on(hmbEvents.burnerAssetChange, async () => {
        await this.refreshBurner();
      });
    } finally {
      this.resizeSideMenus();
      this.toggleSideMenu();
    }
  }

  private async refreshBurner(): Promise<void> {
    await store.dispatch('burner/loadBurners');
    const burners = store.getters['burner/allBurners'] as IBurner[];
    store.commit('flowElement/createBurnerFlowElements', burners);

    const burnerElements = store.getters['diagram/getBurnerElements'] as FlowElementModel[];
    // Always await the load burners and set locks on burners to ensure burners are properly locked
    await store.dispatch('burner/setLockedBurners', burnerElements);
  }

  private toggleSideMenu(): void {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  }

  private resizeSideMenus(): void {
    const idelchikMenu: HTMLElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('idelchik-tab');
    const burnerMenu: HTMLElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('burner-tab');
    const editorElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('editor');
    const toolbarElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('editor-toolbar-container');
    if (
      idelchikMenu !== null &&
      idelchikMenu !== undefined &&
      burnerMenu !== null &&
      burnerMenu !== undefined
    ) {
      const gridHeight = (editorElement?.clientHeight ?? 1000) - (toolbarElement?.clientHeight ?? 0);
      idelchikMenu.style.height = (gridHeight - 50) + 'px';
      burnerMenu.style.height = (gridHeight - 50) + 'px';
    }
  }

  get burnerFolders(): IBurnerFolder[] {
    return store.getters['burner/rootFolders'];
  }

  get selectedFlowElement(): FlowElementModel {
    return store.getters['flowElement/selectedFlowElement'];
  }
}
