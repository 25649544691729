





























































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { IRequiredBurnerSettupAttributes } from '@/view-models/burner-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'burner-setup-error-modal'
})
export default class BurnerSetupError extends Vue {
  public burnerSetupErrors: IRequiredBurnerSettupAttributes[] = [];

  public setBurnerSetUpErrors(burnerSetupErrors: IRequiredBurnerSettupAttributes[]): void {
    burnerSetupErrors.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      if (textA < textB) {
        return -1;
      } else if (textA > textB) {
        return 1;
      }
      return 0;
    });
    this.burnerSetupErrors = burnerSetupErrors;
  }

  public onOk(): void {
    this.$bvModal.hide('burner-setup-error-modal');
  }

  public getDisplayString(hasBurnerConfigZoneType: boolean, hasBurnerConfigKey: boolean): string {
    let displayString = '';
    // Add zone type
    if (!hasBurnerConfigZoneType) {
      displayString += 'ZoneType, ';
    }
    // Add burner key
    if (!hasBurnerConfigKey) {
      displayString += 'BurnerKey, ';
    }
    // Trim trailing comma and whitespace
    return displayString.replace(/,\s*$/, '');
  }
}
