import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IAsset } from '../../view-models/assets-view-models';
import { IModelSummary, ModelType } from '../../view-models/model-summary';
import { ITuningHistoryModel } from '../../view-models/hydraulic-tuning-history';
import ConfigFactory from '../../services/config';
import sharedAxiosInstance from '@/services/api-service';
import { HydraulicTuningHistoryService } from '@/services/hydraulic-tuning-history-service';
import { ISolver } from '@/view-models/solver-model';
import store, { IRootState } from '@/store';
import { IRegressionRequest } from '@/view-models/regression-request-model';
import { ITrainingCase, ITrainingCaseRequest } from '@/view-models/training-data-model';
import { HydraulicTuningDataService } from '@/services/hydraulic-tuning-data-service';
import { HierarchyBuilderService } from '@/services/hierarchy-builder-service';
import { ITuningResultModel, ITuningResultRequest } from '@/view-models/tuning-result-model';
import { IBurnerListServiceResponse, IHBBurnerListServiceResponse } from '@/view-models/burner-response-view-models';
import { AssetBurnerService } from '@/services/asset/asset-burner-service';

export interface ITunerStoreState {
  currentModelSummary: IModelSummary;
  currentSite: IAsset;
  currentAsset: IAsset;
  currentTuningHistory: ITuningHistoryModel;
  currentHistoryCheckedModels: string[];
  tuningShowHidden: boolean;
  tuningHistoryList: ITuningHistoryModel[];
  trainingData: ITrainingCase[];
  solvers: ISolver[];
  tuningResult: ITuningResultModel;
  shouldCheckAllModels: boolean;
  shouldRetrain: boolean;
}

export interface ITunerStoreGetters extends GetterTree<ITunerStoreState, IRootState> {
  getCurrentModelSummary(state: ITunerStoreState): IModelSummary;
  getCurrentSite(state: ITunerStoreState): IAsset;
  getCurrentAsset(state: ITunerStoreState): IAsset;
  getCurrentHistoryCheckedModels(state: ITunerStoreState): string[];
  getTuningHistoryList(state: ITunerStoreState): ITuningHistoryModel[];
  getCurrentTuningHistory(state: ITunerStoreState): ITuningHistoryModel;
  getTrainingData(state: ITunerStoreState): ITrainingCase[];
  getSolvers(state: ITunerStoreState): ISolver[];
  getTuningResult(state: ITunerStoreState): ITuningResultModel;
  getShouldCheckAllModels(state: ITunerStoreState): boolean;
  getShouldRetrain(state: ITunerStoreState): boolean;
}

export interface ITunerStoreMutations extends MutationTree<ITunerStoreState> {
  initializeTuner(state: ITunerStoreState): void;
  setCurrentModelSummary(state: ITunerStoreState, modelSummary: IModelSummary): void;
  setCurrentSite(state: ITunerStoreState, site: IAsset): void;
  setCurrentAsset(state: ITunerStoreState, asset: IAsset): void;
  setCurrentTuningHistory(state: ITunerStoreState, tuningHistory: ITuningHistoryModel): void;
  setCurrentHistoryCheckedModels(state: ITunerStoreState, checkedModels: string[]): void;
  setTuningResult(state: ITunerStoreState): void;
  setTuningShowHidden(state: ITunerStoreState, showHidden: boolean): void;
  setShouldCheckAllModels(state: ITunerStoreState, shouldCheck: boolean): void;
  setShouldRetrain(state: ITunerStoreState, shouldRetrain: boolean): void;
  updateTrainingCaseName(state: ITunerStoreState, trainingCase: ITrainingCase): void;
}

export interface ITunerStoreActions extends ActionTree<ITunerStoreState, IRootState> {
  loadBurners(context: ITunerStoreContext): Promise<any[]>;
  loadTuningHistory(
    context: ITunerStoreContext,
    params: {assetKey: string, tuningHistoryKey: string}
  ): Promise<ITuningHistoryModel>;
  loadTuningHistoryList(context: ITunerStoreContext, assetKey: string): Promise<void>;
  loadTrainingData(context: ITunerStoreContext): Promise<void>;
  loadSolvers(context: ITunerStoreContext): Promise<void>;
  saveTuningHistory(
    context: ITunerStoreContext,
    tuningHistory: ITuningHistoryModel
  ): Promise<ITuningHistoryModel>;
  submitRegression(
    context: ITunerStoreContext,
    tuningRegression: IRegressionRequest
  ): Promise<ITuningHistoryModel>;
  uploadTrainingData(context: ITunerStoreContext, requestModel: ITrainingCaseRequest): Promise<ITrainingCase>;
  hideTuningHistory(
    context: ITunerStoreContext,
    params: { history: ITuningHistoryModel; hide: boolean }
  ): Promise<ITuningHistoryModel>;
  deleteTuningHistory(context: ITunerStoreContext, history: ITuningHistoryModel): Promise<ITuningHistoryModel>;
  loadTuningResult(context: ITunerStoreContext): Promise<void>;
  publishTuningHistory(context: ITunerStoreContext): Promise<{}>;
}

export type ITunerStoreContext = ActionContext<ITunerStoreState, IRootState>;

export const TunerStore = {
  namespaced: true as true,
  state: {
    currentModelSummary: undefined,
    currentSite: undefined,
    currentAsset: undefined,
    currentTuningHistory: undefined,
    currentHistoryCheckedModels: null,
    tuningHistoryList: undefined,
    tuningShowHidden: false,
    trainingData: undefined,
    solvers: undefined,
    tuningResult: undefined,
    shouldCheckAllModels: undefined,
    shouldRetrain: false
  } as ITunerStoreState,
  getters: {
    getCurrentModelSummary(state: ITunerStoreState): IModelSummary {
      return state.currentModelSummary;
    },
    getCurrentSite(state: ITunerStoreState): IAsset {
      return state.currentSite;
    },
    getCurrentAsset(state: ITunerStoreState): IAsset {
      return state.currentAsset;
    },
    getCurrentHistoryCheckedModels(state: ITunerStoreState): string[] {
      return state.currentHistoryCheckedModels;
    },
    getTuningHistoryList(state: ITunerStoreState): ITuningHistoryModel[] {
      return state.tuningHistoryList;
    },
    getCurrentTuningHistory(state: ITunerStoreState): ITuningHistoryModel {
      return state.currentTuningHistory;
    },
    getTrainingData(state: ITunerStoreState): ITrainingCase[] {
      return state.trainingData;
    },
    getSolvers(state: ITunerStoreState): ISolver[] {
      return state.solvers;
    },
    getTuningResult(state: ITunerStoreState): ITuningResultModel {
      return state.tuningResult;
    },
    getShouldCheckAllModels(state: ITunerStoreState): boolean {
      return state.shouldCheckAllModels;
    },
    getShouldRetrain(state: ITunerStoreState): boolean {
      return state.shouldRetrain;
    }
  } as ITunerStoreGetters,
  mutations: {
    initializeTuner(state: ITunerStoreState): void {
      state.currentModelSummary = undefined;
      state.currentSite = undefined;
      state.currentAsset = undefined;
      state.currentTuningHistory = undefined;
      state.currentHistoryCheckedModels = null;
      state.tuningHistoryList = undefined;
      state.tuningResult = undefined;
    },
    setCurrentModelSummary(state: ITunerStoreState, modelSummary: IModelSummary): void {
      state.currentModelSummary = modelSummary;
    },
    setCurrentSite(state: ITunerStoreState, site: IAsset): void {
      state.currentSite = site;
    },
    setCurrentAsset(state: ITunerStoreState, asset: IAsset): void {
      state.currentAsset = asset;
    },
    setCurrentTuningHistory(state: ITunerStoreState, tuningHistory: ITuningHistoryModel): void {
      state.currentTuningHistory = tuningHistory;
    },
    setCurrentHistoryCheckedModels(state: ITunerStoreState, checkedModels: string[]): void {
      state.currentHistoryCheckedModels = checkedModels;
    },
    setTuningResult(state: ITunerStoreState): void {
      state.tuningResult = undefined;
    },
    setTuningShowHidden(state: ITunerStoreState, showHidden: boolean): void {
      state.tuningShowHidden = showHidden;
    },
    setShouldCheckAllModels(state: ITunerStoreState, shouldCheck: boolean): void {
      state.shouldCheckAllModels = shouldCheck;
    },
    setShouldRetrain(state: ITunerStoreState, shouldRetrain: boolean): void {
      state.shouldRetrain = shouldRetrain;
    },
    updateTrainingCaseName(state: ITunerStoreState, trainingCase: ITrainingCase): void {
      if (state.trainingData && trainingCase) {
        const index = state.trainingData.findIndex((t) => t.key === trainingCase.key);
        if (index >= 0) {
          state.trainingData[index].name = trainingCase.name;
        }
      }
    }
  } as ITunerStoreMutations,
  actions: {
    async loadBurners(context: ITunerStoreContext): Promise<any[]> {
      const conf = await ConfigFactory.GetConfig();
      if (!context.state.currentAsset) {
        return [];
      }

      // If new burnertree API FF is active, then replace info retrieved from previous API
      let response: IBurnerListServiceResponse | IHBBurnerListServiceResponse = {
        burners: [],
        emberHierarchyLevels: []
      };
      let url: string = '';
      try {
        if (store.getters['app/getFlags'] && store.getters['app/getFlags'].hbBurnertree) {
          url = process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL ?
            process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL :
            conf.get('hbApiUrl');

          const hbService = new HierarchyBuilderService(sharedAxiosInstance, url);
          response = await hbService.getBurnerList(context.state.currentAsset.key);

          // Add in legacy attributes burner information
          response.burners.forEach((newBurner: any) => {
            newBurner.key = newBurner.burnerIdentifier; // NEW
            newBurner.name = newBurner.burnerName; // NEW
          });
        } else {
          url = process.env.VUE_APP_KES_PORTAL_API_BASE_URL ?
            process.env.VUE_APP_KES_PORTAL_API_BASE_URL :
            conf.get('portalApiUrl');

          const assetBurnerService = new AssetBurnerService(sharedAxiosInstance, url);
          response = await assetBurnerService.getBurnerList(context.state.currentAsset.key);
        }
        return response.burners.map((e: any) => e.burnerIdentifier);
      } catch (error) {
        // Catch 404s and mimick to "no burners found" response.  Else throw error
        if (error.response.status === 404) {
          return [];
        }
        throw error;
      }
    },
    async loadTuningHistory(
      context: ITunerStoreContext,
      params: {assetKey: string, tuningHistoryKey: string}
    ): Promise<ITuningHistoryModel> {
      if (!params || !params.assetKey || !params.tuningHistoryKey) {
        return Promise.resolve(null);
      }

      const conf = await ConfigFactory.GetConfig();
      const tuningService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      try {
        const response = await tuningService.loadTuningHistory(params.assetKey, params.tuningHistoryKey);
        if (response) {
          const models: IModelSummary[] = store.getters['model/loadedModels'];
          response.modelName = models.filter((m) => m.key === response.modelKey)[0]?.name;
          if (context.state.tuningHistoryList) {
            const index = context.state.tuningHistoryList.findIndex((h) => h.key === response.key);
            if (index >= 0) {
              context.state.tuningHistoryList[index] = response;
            }
          }
          return response;
        }
        return response;
      } catch (ex) {
        store.dispatch('error/setError', {
          error: ex,
          errorString: 'Error Loading Tuning History\n',
          handleError: true,
          routeHomeAfterError: false
        });
        return Promise.reject(ex);
      }
    },
    async loadTuningHistoryList(context: ITunerStoreContext, assetKey: string): Promise<void> {
      if (assetKey) {
        const conf = await ConfigFactory.GetConfig();
        const tuningService = new HydraulicTuningHistoryService(
          sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
        );
        try {
          const response = await tuningService.loadTuningHistoryList(assetKey);
          if (response) {
            context.state.tuningHistoryList = response;
            const models: IModelSummary[] = store.getters['model/loadedModels'];
            context.state.tuningHistoryList.forEach((item) => {
              item.modelName = models.filter((m) => m.key === item.modelKey)[0]?.name;
            });
          } else {
            context.state.tuningHistoryList = [];
          }
        } catch (ex) {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Tuning History List\n',
            handleError: true,
            routeHomeAfterError: false
          });
        }
      }
    },
    async loadTrainingData(context: ITunerStoreContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const hydraulicTuningDataService = new HydraulicTuningDataService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      if (context.state.currentAsset) {
        try {
          const response = await hydraulicTuningDataService.loadTrainingData(
            context.state.currentAsset?.key,
            ModelType.Air_Duct_Network
          );
          if (response) {
            context.state.trainingData = response;
          }
        } catch (ex) {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Training Data\n',
            handleError: true,
            routeHomeAfterError: false
          });
        }
      }
    },
    async loadSolvers(context: ITunerStoreContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const hydraulicTuningDataService = new HydraulicTuningDataService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      try {
        const response = await hydraulicTuningDataService.getSolvers();
        if (response) {
          context.state.solvers = response;
        }
      } catch (ex) {
        store.dispatch('error/setError', {
          error: ex,
          errorString: 'Error Loading Solvers\n',
          handleError: true,
          routeHomeAfterError: false
        });
      }
    },
    async saveTuningHistory(
      context: ITunerStoreContext,
      tuningHistory: ITuningHistoryModel
    ): Promise<ITuningHistoryModel> {
      const conf = await ConfigFactory.GetConfig();
      const tuningService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return tuningService
        .saveTuningHistory(tuningHistory)
        .then((response) => {
          context.state.currentTuningHistory = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Saving Tuning History\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
    },
    async submitRegression(
      context: ITunerStoreContext,
      tuningRegression: IRegressionRequest
    ): Promise<ITuningHistoryModel> {
      const conf = await ConfigFactory.GetConfig();
      const tuningService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return tuningService
        .submitRegression(tuningRegression)
        .then((response) => {
          context.state.currentTuningHistory = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Saving Tuning New Model Tuning\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
    },
    async uploadTrainingData(context: ITunerStoreContext, requestModel: ITrainingCaseRequest): Promise<ITrainingCase> {
      const conf = await ConfigFactory.GetConfig();
      const tuningService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      return tuningService
        .UploadTrainingData(requestModel)
        .then((response) => {
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Importing Training Data\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
    },
    async hideTuningHistory(
      context: ITunerStoreContext,
      params: { history: ITuningHistoryModel; hide: boolean }
    ): Promise<ITuningHistoryModel> {
      const conf = await ConfigFactory.GetConfig();
      const tuningService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );

      params.history.hidden = params.hide;
      return tuningService
        .saveTuningHistory(params.history)
        .then((response) => {
          context.state.currentTuningHistory = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Hide or UnHide History\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
    },
    async deleteTuningHistory(context: ITunerStoreContext, history: ITuningHistoryModel): Promise<ITuningHistoryModel> {
      const conf = await ConfigFactory.GetConfig();
      const tuningService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );

      history.markedForDelete = true;
      return tuningService
        .saveTuningHistory(history)
        .then((response) => {
          context.state.currentTuningHistory = response;
          return response;
        })
        .catch((ex) => {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Delete History\n',
            handleError: true,
            routeHomeAfterError: false
          });
          return Promise.reject(ex);
        });
    },
    async loadTuningResult(context: ITunerStoreContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const hydraulicModelTuningHistoryService = new HydraulicTuningHistoryService(
        sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
      );
      const requestTuningHistory: ITuningHistoryModel = context.state.currentTuningHistory;

      if (requestTuningHistory
          && requestTuningHistory.asset
          && requestTuningHistory.key
          && requestTuningHistory.location
          && requestTuningHistory.customer
          && requestTuningHistory.modelKey) {
        const turningResultRequest: ITuningResultRequest = {
          assetKey: requestTuningHistory.asset?.key,
          tuningHistoryKey: requestTuningHistory.key,
          locationKey: requestTuningHistory.location?.key,
          customerKey: requestTuningHistory.customer?.key,
          modelKey: requestTuningHistory.modelKey
        };
        try {
          const response = await hydraulicModelTuningHistoryService.loadTuningResult(turningResultRequest);
          if (response) {
            context.state.tuningResult = response;
          }
        } catch (ex) {
          store.dispatch('error/setError', {
            error: ex,
            errorString: 'Error Loading Tuning Results\n',
            handleError: true,
            routeHomeAfterError: false
          });
        }
      }
    },
    async publishTuningHistory(context: ITunerStoreContext): Promise<{}> {
      const customerKey =       context.state.currentTuningHistory?.customer?.key;
      const assetKey =          context.state.currentTuningHistory?.asset?.key;
      const modelKey =          context.state.currentTuningHistory?.modelKey;
      const tuningHistoryKey =  context.state.currentTuningHistory?.key;

      if (customerKey && assetKey && modelKey && tuningHistoryKey) {
        const conf = await ConfigFactory.GetConfig();
        const hydraulicModelTuningHistoryService = new HydraulicTuningHistoryService(
          sharedAxiosInstance,
          process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl')
        );

        return hydraulicModelTuningHistoryService
          .publishModelTuning(customerKey, assetKey, modelKey, tuningHistoryKey)
          .then((response) => {
            return Promise.resolve({data: response, message:'Model Tuning Successfully Published'});
          }).catch((ex) => {
            store.dispatch('error/setError', {
              error: ex,
              errorString: 'Error Saving Tuning Results\n',
              handleError: true,
              routeHomeAfterError: false
            });
            return Promise.reject(ex);
          });
      }
      return Promise.resolve('Model Tuning Couldn\'t be Published');
    }
  } as ITunerStoreActions
};
