import { gridConfig } from '@/assets/configs/gridConfig';
import { FlowElementType, FlowElementModel } from '@/view-models/flow-element-models';
import { IGridContents, IGridPointerPosition, IZoomStatus } from '@/view-models/grid-view-models';
import {
  IGridStoreState,
  IGridStoreMutations,
  IGridStoreActions,
  IGridContext,
  IGridStoreGetters
} from '@/store/grid/gridStoreInterface';
import { fabric } from 'fabric';
import _ from 'lodash';
import { DirectionFlowType } from '@/view-models/direction-flow-model';
import { Mode } from '@/view-models/mode';
import store from '@/store';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import GridStoreHandler from './handler/gridStoreHandler';
import * as gridStoreHelpers from './helper/gridStoreHelper';
import ZoomHelper from './helper/zoomHelper';
import { getAllArrows, moveDropZones, moveLine } from './helper/dynamicAirflowHelper';
import { updateElementDegree, getAllDirections, getAbsoluteAttribute, modWithNegative } from './helper/dropZoneHelper';
import { FlowElementIdModel } from '@/view-models/flowelement-id-model';
import { svgKlasses } from './imageCache/svgKlasses';
import { svgStrings } from './imageCache/svgStrings';
import HelperMethods from '@/shared/helper-methods';
import { getFlowElementId, getObjectType } from './helper/gridStoreHelper';

export const GridStore = {
  namespaced: true,
  state: {
    editorGrid: new fabric.Canvas('', {}),
    editorGridDiagram: null,
    editorGridBoxSize: gridConfig.defaultGridBoxSize,
    previousEditorGridBoxSize: gridConfig.defaultGridBoxSize,
    editorGridLines: new fabric.Group([], {}),
    isMouseDown: false,
    selectedElements: [],
    alternateMovingTarget: null,
    elementConnected: false,
    mode: Mode.EDIT,
    gridGroups: new Map(),
    gridGroupArrows: new Map(),
    gridGroupDropAndLandingZones: new Map(),
    lineArrows: new Map(),
    fromLines: new Map(),
    toLines: new Map(),
    unconnectedGroups: [],
    idelchikImageSVGs: new Map(),
    idelchikShadowSVGs: new Map(),
    isObjectMoved: false
  } as IGridStoreState,
  getters: {
    editorGrid(gridState: IGridStoreState): fabric.Canvas {
      return gridState.editorGrid;
    },
    editorGridDiagram(gridState: IGridStoreState): string {
      const canvas = gridState.editorGrid;
      canvas.includeDefaultValues = false;
      return JSON.stringify(
        canvas.toDatalessJSON([
          'dynamic-in-arrow',
          'dynamic-out-arrow',
          'hasControls',
          'lockRotation',
          'lockScalingX',
          'lockScalingY',
          'name',
          'selectable',
          'subTargetCheck',
          'type'
        ])
      );
    },
    editorGridBoxSize(gridState: IGridStoreState): number {
      return gridState.editorGridBoxSize;
    },
    previousEditorGridBoxSize(gridState: IGridStoreState): number {
      return gridState.previousEditorGridBoxSize;
    },
    editorGridHeight(gridState: IGridStoreState): number {
      return gridState.editorGrid.getHeight();
    },
    editorGridWidth(gridState: IGridStoreState): number {
      return gridState.editorGrid.getWidth();
    },
    isMouseDown(gridState: IGridStoreState): boolean {
      return gridState.isMouseDown;
    },
    gridContents(gridState: IGridStoreState): IGridContents {
      // Remove shadows, gridlines, and temporary objects
      const gridObject: IGridContents = gridState.editorGrid.toObject(['name']);
      const permanentGridObjects: fabric.Object[] = [];
      gridObject.objects.forEach((object) => {
        if (
          object.name &&
          ![gridConfig.gridLineName, gridConfig.idelchikShadowName, gridConfig.burnerShadowName].includes(object.name)
        ) {
          permanentGridObjects.push(object);
        }
      });
      gridObject.objects = permanentGridObjects;
      return gridObject;
    },
    selectedElements(gridState: IGridStoreState): fabric.Object[] {
      return gridState.selectedElements;
    },
    mode(gridState: IGridStoreState): Mode {
      return gridState.mode;
    },
    getZoomStatus(gridState: IGridStoreState): IZoomStatus {
      return ZoomHelper.getZoomStatus(gridState);
    }
  } as IGridStoreGetters,
  mutations: {
    initializeGrid(state: IGridStoreState): void {
      state.editorGridBoxSize = gridConfig.defaultGridBoxSize;
      state.previousEditorGridBoxSize = gridConfig.defaultGridBoxSize;
      state.editorGridLines = new fabric.Group([], {});
      state.isMouseDown = false;
      state.selectedElements = [];
      state.alternateMovingTarget = null;
      state.elementConnected = false;
      state.mode = Mode.EDIT;
      state.gridGroups = new Map();
      state.gridGroupArrows = new Map();
      state.gridGroupDropAndLandingZones = new Map();
      state.lineArrows = new Map();
      state.fromLines = new Map();
      state.toLines = new Map();
      state.unconnectedGroups = [];
      state.isObjectMoved = false;
    },
    resetEditorGridBoxSize(gridState: IGridStoreState): void {
      gridState.previousEditorGridBoxSize = gridConfig.defaultGridBoxSize;
      gridState.editorGridBoxSize = gridConfig.defaultGridBoxSize;
    },
    resetUnconnectedGroups(gridState: IGridStoreState): void {
      gridState.unconnectedGroups = [];
    },
    setIsMouseDown(gridState: IGridStoreState, isMouseDown: boolean): void {
      gridState.isMouseDown = isMouseDown;
    },
    setIsObjectMoved(gridState: IGridStoreState, isObjectMoved: boolean): void {
      gridState.isObjectMoved = isObjectMoved;
    },
    updateEditorGrid(gridState: IGridStoreState, payload: fabric.Canvas): void {
      if (gridState.editorGrid) {
        gridState.editorGrid.off();
        gridState.editorGrid.clear();
      }
      gridState.editorGrid = payload;
    },
    updateEditorGridWidth(gridState: IGridStoreState, newWidth: number): void {
      gridState.editorGrid.setWidth(newWidth);
    },
    updateEditorGridHeight(gridState: IGridStoreState, newHeight: number): void {
      gridState.editorGrid.setHeight(newHeight);
    },
    updateEditorGridBoxSize(gridState: IGridStoreState, newBoxSize: number): void {
      gridState.previousEditorGridBoxSize = gridState.editorGridBoxSize;
      gridState.editorGridBoxSize = newBoxSize;
    },
    updateEditorGridLines(gridState: IGridStoreState, newGridLines: fabric.Line[]): void {
      gridState.editorGrid.remove(gridState.editorGridLines);
      gridState.editorGridLines = new fabric.Group(newGridLines, {
        name: gridConfig.gridLineName,
        selectable: false,
        evented: false,
        excludeFromExport: true
      });
      gridState.editorGrid.add(gridState.editorGridLines);
      gridState.editorGrid.renderAll();
    },
    updateSelectedElements(gridState: IGridStoreState, elements: fabric.Group[]): void {
      gridState.selectedElements = elements;
    },
    updateElementConnection(gridState: IGridStoreState, connected: boolean): void {
      gridState.elementConnected = connected;
    },
    switchMode(gridState: IGridStoreState, mode: Mode): void {
      gridState.mode = mode;
      gridState.editorGrid.selection = mode === Mode.EDIT;
      EventBus.$emit(hmbEvents.previewModeChanged, mode);
    }
  } as IGridStoreMutations,
  actions: {
    loadFromDatalessJSON(gridContext: IGridContext, payload: { json: string; resize?: () => void }): void {
      // Transform diagram by replacing json '-' image URLs with Klasses for the image itself
      const serialized =
        typeof payload.json === 'string' ? JSON.parse(payload.json) : fabric.util.object.clone(payload.json);
      serialized.objects
        ?.filter((object: any) => !gridStoreHelpers.isShadow(object))
        .map((group: any) =>
          group.objects?.map((object: any) => {
            const elementName = object.name?.split('-')[0];
            const svgObjects: any = (svgKlasses as any)[elementName];
            if (gridStoreHelpers.getObjectType(object) === 'Image' && svgObjects) {
              object.objects = svgObjects;
            } else if (gridStoreHelpers.getObjectType(object) === 'Image' && object.name?.includes('burner')) {
              object.objects = svgKlasses.burner;
            } else if (object.name?.includes(' Arrow ')) {
              object.objects = svgKlasses.arrow;
            } else if (object.name?.includes(' Hover')) {
              object.objects = svgKlasses.landingZone;
            } else if (object.name?.includes(' DropZone ')) {
              object.objects = svgKlasses.dropZone;
            }
          })
        );
      // Load the canvas from the transformed JSON
      gridContext.state.editorGrid.loadFromJSON(serialized, async () => {
        store.dispatch('grid/loadObjectMaps');
        store.dispatch('grid/colorInvalidFieldGroups');
        if (payload.resize) {
          payload.resize();
        }
      });
    },
    loadImageSVGs(gridContext: IGridContext): void {
      // create maps of svg strings to their idelchik element name for quick lookup later
      const idelchikElements = store.getters['flowElement/allIdelchikFlowElements'] as FlowElementModel[];
      gridContext.state.idelchikImageSVGs.clear();
      gridContext.state.idelchikShadowSVGs.clear();
      idelchikElements.forEach((element) => {
        gridContext.state.idelchikImageSVGs.set(element.name, (svgStrings as any)[element.name]?.image);
        gridContext.state.idelchikShadowSVGs.set(element.name, (svgStrings as any)[element.name]?.shadow);
      });
    },
    loadObjectMaps(gridContext: IGridContext): void {
      // Creates maps containing groups, arrows, and lines used to speed up later lookup operations
      // Keep a list of unconnected elements
      gridContext.state.unconnectedGroups = gridContext.state.editorGrid.getObjects().filter((object) => {
        if (object.type !== 'group' || !object.name?.includes('Group')) {
          return false;
        }

        const id = gridStoreHelpers.getFlowElementId(object);
        const flowElement: FlowElementModel = store.getters['diagram/getElementById'](id);
        const outletCount = getAllDirections(flowElement)?.filter((dir) => dir.type === DirectionFlowType.OUTLET)
          .length;
        return !(
          flowElement?.flowElementId?.parentId && flowElement?.flowElementId?.childIdList?.length === outletCount
        );
      }) as fabric.Group[];

      // Keep maps of groups, arrows, and drop zones
      gridContext.state.gridGroups.clear();
      gridContext.state.gridGroupArrows.clear();
      gridContext.state.gridGroupDropAndLandingZones.clear();

      const allGridObjects = gridContext.state.editorGrid.getObjects();
      const allGridGroups = allGridObjects.filter((object) =>
        object.type === 'group' && object.name?.includes('Group'));

      allGridGroups.forEach((group) => {
        if (group.name) {
          gridContext.state.gridGroups.set(group.name, group as fabric.Group);
        }
        (group as fabric.Group).getObjects().forEach((object) => {
          const type = gridStoreHelpers.getObjectType(object);
          if (object.name && type === 'Arrow') {
            gridContext.state.gridGroupArrows.set(object.name, object);
          } else if (object.name && type === 'DropZone') {
            if (!gridContext.state.gridGroupDropAndLandingZones.get(group)) {
              gridContext.state.gridGroupDropAndLandingZones.set(group, []);
            }
            gridContext.state.gridGroupDropAndLandingZones.get(group)?.push(object);
          }
        });
      });

      // Check for new dropzone setup
      const allGridDropZones = allGridObjects.filter((object) => object.name?.includes('DropZone'));
      allGridDropZones.forEach((dropZone) => {
        const groupId = getFlowElementId(dropZone);
        const correspondingGroup = allGridGroups.find((group) => getFlowElementId(group) === groupId);
        if (!gridContext.state.gridGroupDropAndLandingZones.get(correspondingGroup)) {
          gridContext.state.gridGroupDropAndLandingZones.set(correspondingGroup, []);
        }
        gridContext.state.gridGroupDropAndLandingZones.get(correspondingGroup)?.push(dropZone);

        // set dropzone's relationship to group
        const groupTransform = correspondingGroup.calcTransformMatrix();
        const invertedGroupTransform = fabric.util.invertTransform(groupTransform);
        const desiredTransform = fabric.util.multiplyTransformMatrices(
          invertedGroupTransform,
          dropZone.calcTransformMatrix()
        );

        (dropZone as any).relationship = desiredTransform;
      });

      // Reset lines structures
      gridContext.state.lineArrows.clear();
      gridContext.state.toLines.clear();
      gridContext.state.fromLines.clear();
      allGridObjects
        .filter((object) => object.type === 'customLine' || object.type === 'triangle')
        .forEach((object) => {
          if (object.type === 'triangle') {
            gridContext.state.lineArrows.set(object.name ?? '', object);
          } else {
            // To manipulate line name, remove '-line' at end,
            // replace '-to' with '--to' so we can split without losing the 'to-'
            const lineNames = object.name
              ?.replace('-line', '')
              .replace('-to', '--to')
              .split('--');
            if (lineNames?.length === 2) {
              const fromLineName = lineNames[0];
              const toLineName = lineNames[1];
              if (!gridContext.state.fromLines.has(fromLineName)) {
                gridContext.state.fromLines.set(fromLineName, []);
              }
              gridContext.state.fromLines.get(fromLineName)?.push(object);
              if (!gridContext.state.toLines.has(toLineName)) {
                gridContext.state.toLines.set(toLineName, []);
              }
              gridContext.state.toLines.get(toLineName)?.push(object);
            }
          }
        });
    },
    colorInvalidFieldGroups(gridContext: IGridContext): void {
      // After loading elements, check if any are missing required attributes.
      // If required attributes or name are missing, color element to indicate invalid fields (yellow currently)
      gridContext.state.editorGrid
        .getObjects()
        .filter((object) => object.type === 'group' && object.name?.includes('Group'))
        .forEach((object) => {
          const group = object as fabric.Group;
          const currentElement = gridStoreHelpers.getFlowElementByGroupName(gridContext, group);
          const isPopulated = gridContext.rootGetters['diagram/isElementPopulated'](currentElement);
          const imageColor = isPopulated ? gridConfig.baseStrokeColor : gridConfig.invalidFieldsStrokeColor;
          gridStoreHelpers.colorGroup(gridContext, group, imageColor, gridConfig.baseStrokeColor);
        });
    },
    drawGridLines(gridContext: IGridContext): void {
      // Create grid lines and add to grid
      const gridlines = [];
      const canvas = gridContext.getters.editorGrid;
      const boxSize = gridContext.getters.editorGridBoxSize;
      const gridWidth = gridContext.getters.editorGridWidth;
      const gridHeight = gridContext.getters.editorGridHeight;
      if (canvas.vptCoords && canvas.vptCoords.tl) {
        // re-drawing grid
        for (let i = 0; i < (canvas.vptCoords?.tr.x - canvas.vptCoords?.tl.x ?? 0); i += boxSize) {
          const gridLine = new fabric.Line(
            [
              i + (canvas.vptCoords?.tl.x ?? 0),
              canvas.vptCoords?.tl.y ?? 0,
              i + (canvas.vptCoords?.bl.x ?? 0),
              canvas.vptCoords?.br.y ?? 0
            ],
            { stroke: gridConfig.gridLineColor, selectable: false, hasControls: false }
          );
          gridlines.push(gridLine);
        }
        for (let j = 0; j < (canvas.vptCoords?.bl.y - canvas.vptCoords?.tl.y ?? 0); j += boxSize) {
          const gridLine = new fabric.Line(
            [
              canvas.vptCoords?.tl.x ?? 0,
              j + (canvas.vptCoords?.tl.y ?? 0),
              canvas.vptCoords?.tr.x ?? 0,
              j + (canvas.vptCoords?.tl.y ?? 0)
            ],
            { stroke: gridConfig.gridLineColor, selectable: false, hasControls: false }
          );
          gridlines.push(gridLine);
        }
      } else {
        // Initial drawing of grid
        for (let i = Math.ceil(gridWidth / boxSize); i >= 0; i--) {
          const gridLine = new fabric.Line([i * boxSize, 0, i * boxSize, gridHeight], {
            stroke: gridConfig.gridLineColor,
            selectable: false,
            hasControls: false
          });
          gridlines.push(gridLine);
        }
        for (let j = Math.ceil(gridHeight / boxSize); j >= 0; j--) {
          const gridLine = new fabric.Line([0, j * boxSize, gridWidth, j * boxSize], {
            stroke: gridConfig.gridLineColor,
            selectable: false,
            hasControls: false
          });
          gridlines.push(gridLine);
        }
      }
      gridContext.commit('updateEditorGridLines', gridlines);
      gridContext.dispatch('bringGridObjectsToFront');
    },
    bringGridObjectsToFront(gridContext: IGridContext): void {
      const gridLines = gridContext.state.editorGrid
        .getObjects()
        .find((object) => object.name === gridConfig.gridLineName);
      gridLines?.sendToBack();
      gridContext.state.editorGrid.requestRenderAll();
    },
    getEditorPointerPosition(gridContext: IGridContext, dragEvent: DragEvent): IGridPointerPosition {
      const point = gridContext.state.editorGrid.getPointer(dragEvent);
      return {
        x: point.x,
        y: point.y
      };
    },
    zoomIn(gridContext: IGridContext): void {
      ZoomHelper.performZoomIn(gridContext);
    },
    zoomOut(gridContext: IGridContext): void {
      ZoomHelper.performZoomout(gridContext);
    },
    zoomFit(gridContext: IGridContext): void {
      ZoomHelper.performZoomFit(gridContext);
    },
    removeGridShadows(gridContext: IGridContext): void {
      const shadows = gridContext.state.selectedElements;
      shadows.forEach((shadow) => {
        if (shadow && gridStoreHelpers.isShadow(shadow)) {
          gridContext.state.editorGrid.remove(shadow);
        }
      });
    },
    setGridPointers(gridContext: IGridContext): void {
      gridContext.state.editorGrid.defaultCursor = 'pointer';
      gridContext.state.editorGrid.hoverCursor = 'pointer';
      gridContext.state.editorGrid.moveCursor = 'grabbing';
    },
    setGrabPointer(gridContext: IGridContext): void {
      gridContext.state.editorGrid.defaultCursor = 'grabbing';
    },
    restoreGrabPointer(gridContext: IGridContext): void {
      gridContext.state.editorGrid.defaultCursor = 'pointer';
    },
    setGridObjectModifiedHandler(gridContext: IGridContext): void {
      GridStoreHandler.gridObjectModifiedHandler(gridContext);
    },
    setGridObjectMovingHandlers(gridContext: IGridContext): void {
      GridStoreHandler.gridObjectMovingHandlers(gridContext);
    },
    setGridDragEnterHandler(gridContext: IGridContext): void {
      GridStoreHandler.gridDragEnterHandler(gridContext);
    },
    setGridDragLeaveHandler(gridContext: IGridContext): void {
      GridStoreHandler.gridDragLeaveHandler(gridContext);
    },
    setGridDragOverHandlers(gridContext: IGridContext): void {
      GridStoreHandler.gridDragOverHandlers(gridContext);
    },
    setGridDropHandler(gridContext: IGridContext): void {
      GridStoreHandler.gridDropHandler(gridContext);
    },
    setMouseUpHandler(gridContext: IGridContext): void {
      GridStoreHandler.mouseUpHandler(gridContext);
    },
    setMouseMoveHandlers(gridContext: IGridContext): void {
      GridStoreHandler.mouseMoveHandlers(gridContext);
    },
    setMouseDownHandler(gridContext: IGridContext): void {
      GridStoreHandler.mouseDownHandler(gridContext);
    },
    setMouseWheelHandler(gridContext: IGridContext): void {
      GridStoreHandler.mouseWheelHandler(gridContext);
    },
    setSelectionCreatedHandler(gridContext: IGridContext): void {
      GridStoreHandler.selectionCreatedHandler(gridContext);
    },
    setSelectionUpdatedHandler(gridContext: IGridContext): void {
      GridStoreHandler.selectionUpdatedHandler(gridContext);
    },
    setSelectionClearedHandler(gridContext: IGridContext): void {
      GridStoreHandler.selectionClearedHandler(gridContext);
    },
    async handleOnKeyDownEvent(
      gridContext: IGridContext,
      onKeyDownEvent: { event: KeyboardEvent; canvas: fabric.Canvas }
    ): Promise<void> {
      const selectedElements = store.getters['diagram/getSelectedElements'];
      const currentSelectedElement = HelperMethods.isArrayEmpty(selectedElements) ? null : selectedElements[0];

      // enable selection when the user holds down shift.
      // deselection is handled in gridhmb when key up event is triggered in disableSelection.
      if (onKeyDownEvent.event.key === 'Shift' && gridContext.state.mode === Mode.EDIT) {
        onKeyDownEvent.canvas.selection = true;
        return;
      }

      if (currentSelectedElement != null) {
        const parentId = currentSelectedElement.flowElementId?.parentId;
        const parentFromDiagram = (await store.dispatch('diagram/findFlowElementById', parentId)) as FlowElementModel;
        const parent = onKeyDownEvent.canvas.getObjects().find((element) => {
          const key = element.name?.split(' ')[0];
          return key === parentId;
        });

        switch (onKeyDownEvent.event.key) {
          case 'Left': // IE/Edge specific value
          case 'ArrowLeft':
            if (parent) {
              onKeyDownEvent.canvas.setActiveObject(parent);
            }
            break;
          case 'Right': // IE/Edge specific value
          case 'ArrowRight':
            // There is exactly 1 child then just select that child item.
            if (
              !!currentSelectedElement.flowElementId?.childIdList &&
              currentSelectedElement.flowElementId?.childIdList?.length > 0
            ) {
              const id = currentSelectedElement?.flowElementId?.childIdList[0].childId;

              // const previouslySelected =
              const child = onKeyDownEvent.canvas.getObjects().find((element) => {
                const key = element.name?.split(' ')[0];
                return key === id;
              });

              // Select it on the canvas
              if (child) {
                onKeyDownEvent.canvas.setActiveObject(child);
              }
            }
            break;
          case 'Up': // IE/Edge specific value
          case 'Down': // IE/Edge specific value
          case 'ArrowUp':
          case 'ArrowDown':
            const childId = currentSelectedElement.flowElementId?.id;

            if (parent && parentFromDiagram.flowElementId.childIdList.length > 1) {
              const siblingChildElement =
                parentFromDiagram.flowElementId?.childIdList?.find((element) => element.childId !== childId) ?? null;
              const siblingId = siblingChildElement?.childId;
              const sibling = onKeyDownEvent.canvas.getObjects().find((element) => {
                const key = element.name?.split(' ')[0];
                return key === siblingId;
              });

              if (sibling) {
                onKeyDownEvent.canvas.setActiveObject(sibling);
              }
            }
            break;
        }
      }
    },
    setGridListeners(gridContext: IGridContext): void {
      // remove all handlers for all events in this canvas instance.
      if (gridContext.state.editorGrid) {
        gridContext.state.editorGrid.off();
      }

      gridContext.dispatch('setGridPointers');
      gridContext.dispatch('setGridObjectMovingHandlers');
      gridContext.dispatch('setGridDragEnterHandler');
      gridContext.dispatch('setGridDragLeaveHandler');
      gridContext.dispatch('setGridDragOverHandlers');
      gridContext.dispatch('setGridDropHandler');
      gridContext.dispatch('setMouseUpHandler');
      gridContext.dispatch('setMouseMoveHandlers');
      gridContext.dispatch('setMouseDownHandler');
      gridContext.dispatch('setMouseWheelHandler');
      gridContext.dispatch('setSelectionCreatedHandler');
      gridContext.dispatch('setSelectionUpdatedHandler');
      gridContext.dispatch('setSelectionClearedHandler');
    },
    async deleteSelectedElements(gridContext: IGridContext): Promise<void> {
      await store.dispatch('grid/disconnectSelectedElements');
      const canvas = gridContext.state.editorGrid;
      const selectedElements = gridContext.state.selectedElements as fabric.Group[];
      selectedElements.forEach((element) => {
        // Remove from maps
        element.getObjects().forEach((obj) => {
          if (obj.name && gridStoreHelpers.getObjectType(obj) === 'Arrow') {
            gridContext.state.gridGroupArrows.delete(obj.name);
          }
        });
        if (element) {
          const dropZones = gridContext.state.gridGroupDropAndLandingZones.get(element);
          dropZones?.forEach((dropZone) => {
            canvas.remove(dropZone);
          });
          gridContext.state.gridGroupDropAndLandingZones.delete(element);
          if (element.name) {
            gridContext.state.gridGroups.delete(element.name);
          }
        }
        // Remove from grid and relevant map, commit results
        canvas.remove(element);
        _.pull(gridContext.state.unconnectedGroups, element);
      });

      const selectedFlowElements = gridContext.rootGetters['diagram/getSelectedElements'] as FlowElementModel[];
      selectedFlowElements.forEach((flowElement) => {
        store.commit('diagram/removeElementById', flowElement.flowElementId?.id);
        if (flowElement.type === FlowElementType.BURNER) {
          store.commit('burner/unlockBurnerById', flowElement.burner?.burnerId);
        }
      });
      // Deselect deleted elements
      gridStoreHelpers.setDeselectedFlowElements(gridContext, selectedElements);
      store.commit('diagram/setDiagramModified', true);
      gridContext.state.editorGrid.discardActiveObject();
    },
    disconnectSelectedElements(gridContext: IGridContext): void {
      const connected = 'connected';
      const connectingInArrow: string = 'dynamic-in-arrow';
      const connectingOutArrow: string = 'dynamic-out-arrow';
      const selectedElements = gridContext.state.selectedElements;
      selectedElements.forEach((element) => {
        const groupToLines = gridContext.state.toLines.get(`to-${element.name}`) ?? [];
        const groupFromLines = gridContext.state.fromLines.get(`from-${element.name}`) ?? [];
        const lines = [...groupToLines, ...groupFromLines];

        // Set arrows back to base color
        const selectedGroup = (element.group ?? element) as fabric.Group;
        gridStoreHelpers.colorGroup(
          gridContext,
          selectedGroup,
          gridConfig.highlightStrokeColor,
          gridConfig.baseStrokeColor
        );

        lines.forEach((arrow) => arrow.name && gridContext.state.lineArrows.delete(arrow.name));

        // Remove each connected line
        lines.forEach((line: any) => {
          const [lineArrow, fromArrow, toArrow, fromLineObject, toLineObject] = getAllArrows(gridContext, line);

          if (lineArrow) {
            // Remove line arrow from canvas and maps
            gridContext.state.editorGrid.remove(lineArrow);
            gridContext.state.lineArrows.delete(lineArrow?.name ?? '');
            if (fromLineObject) {
              const fromLineName = `from-${fromLineObject.name}`;
              const fromLines = gridContext.state.fromLines.get(fromLineName)?.filter((l) => l !== line);
              if (fromLines) {
                gridContext.state.fromLines.set(fromLineName, fromLines);
              }
            }
            if (toLineObject) {
              const toLineName = `to-${toLineObject.name}`;
              const toLines = gridContext.state.toLines.get(toLineName)?.filter((l) => l !== line);
              if (toLines) {
                gridContext.state.toLines.set(toLineName, toLines);
              }
            }
          }

          // remove line and mark disconnected
          gridContext.state.editorGrid.remove(line);
          (fromArrow as any)[connected] = false;
          (toArrow as any)[connected] = false;
          (fromLineObject as any)[connectingOutArrow] = null;
          (toLineObject as any)[connectingInArrow] = null;

          // Remove from relevant maps
          const pId = gridStoreHelpers.getFlowElementId(fromLineObject);
          const cId = gridStoreHelpers.getFlowElementId(toLineObject);
          const parent: FlowElementModel = store.getters['diagram/getElementById'](pId);
          const child: FlowElementModel = store.getters['diagram/getElementById'](cId);

          if (child.type !== FlowElementType.BURNER) {
            toArrow?.set('visible', true);
          }
          fromArrow?.set('visible', true);

          if (child.flowElementId && pId && parent.flowElementId && cId) {
            child.flowElementId.parentId = '';

            const newList = parent.flowElementId.childIdList?.filter((o) => o.childId !== cId);
            parent.flowElementId.childIdList = newList;
          }

          if (!gridContext.state.unconnectedGroups.some((object) => object === fromLineObject)) {
            gridContext.state.unconnectedGroups.push(fromLineObject as fabric.Group);
          }
          if (!gridContext.state.unconnectedGroups.some((object) => object === toLineObject)) {
            gridContext.state.unconnectedGroups.push(toLineObject as fabric.Group);
          }
        });
      });
      store.commit('diagram/setDiagramModified', true);
    },
    rotateSelectedElement(gridContext: IGridContext, degreeAndHFlip: { degree: number; hFlip?: boolean }): void {
      // Update diagram store
      const selectedFlowElements = gridContext.rootState.diagram.selectedElements;
      selectedFlowElements.forEach((selectedFlowElement) => {
        const element = selectedFlowElement?.directionFlowList.find((dir) => dir.type === DirectionFlowType.ELEMENT);
        if (element) {
          let degree = degreeAndHFlip.degree;
          if (degreeAndHFlip.hFlip) {
            element.hFlip = !element.hFlip;
            degree = -(180 - element.degree - element.degree) + degreeAndHFlip.degree;
          }
          updateElementDegree(element, -degree);
          const selectedElements = gridContext.state.selectedElements;
          selectedElements.forEach((selectedElement) => {
            const rotation = modWithNegative((selectedElement.angle ?? 0) + degree, 360);
            selectedElement.rotate(rotation).setCoords();
            moveDropZones(gridContext, selectedElement);
            moveLine(gridContext, selectedElement, true);
          });
          gridContext.state.editorGrid.requestRenderAll();
          store.commit('diagram/triggerStateChanged');
        }
      });
    },
    xFlipSelectedElement(gridContext: IGridContext): void {
      gridContext.dispatch('flipSelectedElement', 180);
    },
    yFlipSelectedElement(gridContext: IGridContext): void {
      gridContext.dispatch('flipSelectedElement', 0);
    },
    flipSelectedElement(gridContext: IGridContext, offset: number): void {
      const selectedElements = gridContext.state.selectedElements;
      selectedElements.forEach((element) => {
        element.toggle('flipY');
      });
      gridContext.dispatch('rotateSelectedElement', { degree: -offset, hFlip: true });
    },
    copySelectedElements(gridContext: IGridContext): void {
      // Get flowElement and image
      const selectedFlowElements: FlowElementModel[] = gridContext.rootGetters['diagram/getSelectedElements'];
      const selectedElements: fabric.Object[] = gridContext.state.selectedElements;
      const canvas = gridContext.state.editorGrid;

      const newToOldGroupElementsMap = new Map<fabric.Group, fabric.Group>();

      selectedElements.forEach((element) => {
        const originalImage = (element as fabric.Group)?.getObjects()
          .find((object) => gridStoreHelpers.getObjectType(object) === 'Image');
        const elementName = element.name.replace(' Group', '');
        const origFlowElement: FlowElementModel = selectedFlowElements.find(
          (flowElement) => elementName === flowElement.flowElementId.id
        );
        // calculate new position, angle, and flip
        const newCoordsX = getAbsoluteAttribute(gridContext, element, 'left') + 100;
        const newCoordsY = getAbsoluteAttribute(gridContext, element, 'top') + 100;
        const angle = getAbsoluteAttribute(gridContext, originalImage, 'angle');
        const flip = getAbsoluteAttribute(gridContext, originalImage, 'flip');

        // Create new FlowElement
        const newFlowElement = _.cloneDeep(origFlowElement);
        const id = _.uniqueId(`${elementName}-`);
        newFlowElement.flowElementId = new FlowElementIdModel(id, '');

        // Deep clone old direction flow list
        const newFlowElementDirectionFlowList = origFlowElement.directionFlowList.map((direction) =>
          _.cloneDeep(direction)
        );
        newFlowElement.directionFlowList = newFlowElementDirectionFlowList;

        store.commit('diagram/addElement', newFlowElement);
        gridStoreHelpers.addElementToGrid(gridContext, newFlowElement, newCoordsY, newCoordsX, angle, flip);
        const copiedGridElement = canvas.getObjects()?.find((gridEl) => {
          const copyId = newFlowElement.flowElementId.id;
          const gridElId = gridStoreHelpers.getFlowElementId(gridEl);
          return copyId === gridElId && getObjectType(gridEl) === 'Group';
        });
        const copiedElementType = getObjectType(copiedGridElement);
        if (copiedElementType === 'DropZone') {
          copiedGridElement.set('visible', false);
        } else {
          newToOldGroupElementsMap.set(element as fabric.Group, copiedGridElement as fabric.Group);
        }
      });

      const originalGridElments = Array.from(newToOldGroupElementsMap.keys());
      const copiedGridElements = Array.from(newToOldGroupElementsMap.values());

      gridStoreHelpers.setDeselectedFlowElements(gridContext, originalGridElments);
      gridStoreHelpers.setSelectedFlowElements(gridContext, copiedGridElements);
      gridStoreHelpers.copyArrowsFromSelectedElements(gridContext, newToOldGroupElementsMap);

      // Force the canvas to select the newly copied elements, NOTE:
      // '_setActiveObject' is the same as 'setActiveObject' except it does not fire side effects (selection:updated).
      // This is needed since the side effects are handled with the gridStoreHelpers methods and should not repeat.
      // http://fabricjs.com/v2-breaking-changes-2 inside the 'Modify the selection programatically' section.
      const newActiveSelection = new fabric.ActiveSelection(copiedGridElements, {
        canvas, hasBorders: false, hasControls: false
      });
      canvas._setActiveObject(newActiveSelection);
      canvas.requestRenderAll();
    },
    lockWorkspace(gridContext: IGridContext): void {
      gridContext.state.editorGrid.getObjects().forEach((o) => {
        o.set(gridConfig.disabledAttributes);
        if (o.name && o.name.includes('Group') && !(o.name.includes('Group-line') || o.name.includes('triangle'))) {
          o.set('selectable', true);
        }
      });
      gridContext.state.editorGrid.requestRenderAll();
    },
    unlockWorkspace(gridContext: IGridContext): void {
      gridContext.state.editorGrid.getObjects().forEach((o) => {
        o.set(gridConfig.enabledAttributes);
        if (o.name && (o.name.includes('Group-line') || o.name.includes('triangle'))) {
          o.set('selectable', false);
        }
      });
      gridContext.state.editorGrid.requestRenderAll();
    },
    selectPreviewElement(gridContext: IGridContext, elementId: string): void {
      if (gridContext.state.mode === Mode.PREVIEW) {
        const previewElement = gridContext.state.editorGrid.getObjects().find((o) => o.name?.includes(elementId));
        if (previewElement) {
          gridContext.state.editorGrid.setActiveObject(previewElement);
        }
      }
    },
    unselectElementsOnEditAndPreviewMode(gridContext: IGridContext): void {
      gridContext.state.editorGrid.discardActiveObject();
      gridContext.state.editorGrid.requestRenderAll();
    }
  } as IGridStoreActions
};
