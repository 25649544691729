import BaseService from './base-service';
import { FlowElementModel } from '@/view-models/flow-element-models';
import { AxiosInstance } from 'axios';

export class HydraulicElementLibraryService extends BaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  public async loadIdelChikList(): Promise<FlowElementModel[]> {
    return this.httpClient.get<FlowElementModel[]>('flow-elements/idelchik-libraries');
  }
}
