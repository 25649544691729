
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {
  @Prop({ required: true, default: '' })
  public value: string;
  @Prop({ required: false, default: false })
  public disabled: boolean;
  @Prop({ required: true })
  private dropdownId: string;

  private showDropdown = false;

  public closeMenu(): void {
    this.showDropdown = false;
  }

  public mounted(): void {
    window.addEventListener('click', (e) => {
      const t = e.target as HTMLElement;
      if (!this.$el.contains(t)) {
        if (this.showDropdown) {
          this.toggleMenu();
        }
      }
    });
  }

  private toggleMenu(): void {
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;
    }
  }
}
