




















































import { Component, Vue } from 'vue-property-decorator';
import store from '../../store';
import TunerHeader from '@/components/hydraulicModelTuner/components/TunerHeader.vue';
import TunerHistory from '@/components/hydraulicModelTuner/components/TunerHistory.vue';
import TunerTrainingData from '@/components/hydraulicModelTuner/components/TunerTrainingData.vue';
import TunerNewModel from '@/components/hydraulicModelTuner/components/TunerNewModel.vue';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import { Route } from 'vue-router';

@Component({
  name: 'Tuner',
  components: {
    TunerHeader,
    TunerHistory,
    TunerTrainingData,
    TunerNewModel
  }
})
export default class Tuner extends Vue {
  private site = '';
  private asset = '';
  private enableClick: boolean = true;
  private tuningSummaryLoaded: boolean = false;
  private store = store;

  private async mounted(): Promise<void> {
    store.commit(`app/updateFF`, (this as any).flags);
    this.site = store.getters['asset/selectedSite'];
    this.asset = store.getters['asset/selectedAsset'];
    if (!this.site || !this.asset) {
      // On refresh, send user back to model Selector
      // Future improvement: Consider refactoring to add deep link routing so the user can bookmark and refresh
      // the tuner for this site-asset pair without being sent back to the model selector page
      await this.$router.push('../modelSelector');
    }

    EventBus.$on(hmbEvents.setEnableState, (enableState: boolean) => {
      this.enableClick = enableState;
    });
    EventBus.$on(hmbEvents.tuningSummaryLoaded, (loaded: boolean) => {
      // this variable checks to see if tuning summary component is loaded.
      // if it is not loaded, we wont try to check for in progress notes. just navigate
      this.tuningSummaryLoaded = loaded;
    });
    EventBus.$on(hmbEvents.navigateTrainingData, () => {
      this.setSelectedTab('trainingData');
    });
    EventBus.$on(hmbEvents.navigateNewModelTuning, () => {
      this.setSelectedTab('newModelTuning');
    });
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }

    EventBus.$on(hmbEvents.tabChange, this.tabChange);
  }

  private beforeDestroy(): void {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  private async handleRouteChange(parentRoute: Route): Promise<void> {
    if (parentRoute.path.match(/^\/?utilities\/ember\/hydraulic-modeler\//)) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(/^\/?utilities\/ember\/hydraulic-modeler\//, '/');
      if (currentRelativeRoute === '/') {
        await this.refreshData();
        this.$router.push('../modelSelector');
      }
    }
  }

  private async refreshData(): Promise<void> {
    store.commit('asset/initialize');
    store.commit('tuner/initializeTuner');
    await store.dispatch('asset/loadAssets', true);
  }

  private checkSelectedTab(tabKey: string): boolean {
    return store.state.tab.selectedTunerTab === tabKey;
  }

  private tabChange(tabKey: string): void {
   if (!this.checkSelectedTab(tabKey)) {
      this.setSelectedTab(tabKey);
    }
  }

  private retrain(): void {
    this.tabChange('newModelTuning');
  }

  private trySetSelectedTab(tabKey: string): void {
    if (this.tuningSummaryLoaded) {
      EventBus.$emit(hmbEvents.navigateNotesCheck, tabKey);
    } else {
      this.setSelectedTab(tabKey);
    }
  }

  private setSelectedTab(tabKey: string): string {
    if (store.state.tab.tunerTabEnabled) {
      store.state.tab.selectedTunerTab = tabKey;
    } else {
      store.commit('errorState/setError', {
        error: 'Please enter a valid value for all fields before navigating to a separate page.',
        config: {
          sendLog: false
        },
        uiError: true
      });
    }
    return store.state.tab.selectedTunerTab;
  }

  private async onComponentClick(): Promise<void> {
    EventBus.$emit(hmbEvents.hideAllInfo);
  }
}
