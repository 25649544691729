import { IAsset } from '../../view-models/assets-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '..';
import sharedAxiosInstance from '@/services/api-service';
import { HydraulicCustomerAssetService } from '../../services/hydraulic-customer-asset-service';
import ConfigFactory from '../../services/config';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';

export interface IAssetStoreState {
  assets: IAsset[];
  selectedSite: IAsset;
  selectedAsset: IAsset;
}

export interface IAssetStoreGetters extends GetterTree<IAssetStoreState, IRootState> {
  allAssets(state: IAssetStoreState): IAsset[];
  selectedSite(state: IAssetStoreState): IAsset;
  selectedAsset(state: IAssetStoreState): IAsset;
  parentAsset(state: IAssetStoreState, currentAsset: IAsset): IAsset;
  allCustomers(state: IAssetStoreState): IAsset[];
  findAssetParentType(state: IAssetStoreState): (currentNode: IAsset, typeName: string) => IAsset;
  findCustomerByName(state: IAssetStoreState): (customerName: string) => IAsset;
  findSitesByParentKey(state: IAssetStoreState): (parentKey: string) => IAsset[];
  findAssetsBySiteKey(state: IAssetStoreState): (siteKey: string) => IAsset[];
}

export interface IAssetStoreMutations extends MutationTree<IAssetStoreState> {
  initialize(state: IAssetStoreState): void;
  initializeAsset(state: IAssetStoreState): void;
  selectSite(state: IAssetStoreState, selected: IAsset): void;
  selectAsset(state: IAssetStoreState, selected: IAsset): void;
}

export interface IAssetStoreActions extends ActionTree<IAssetStoreState, IRootState> {
  loadAssets(context: IAssetContext, forceLoad: boolean): Promise<void>;
}

export type IAssetContext = ActionContext<IAssetStoreState, IRootState>;

const findParentType = (state: IAssetStoreState, currentNode: IAsset, typeName: string): IAsset => {
  let returnValue: IAsset;
  if (currentNode.type === typeName) {
    returnValue = currentNode;
  } else {
    if (currentNode.parentKey !== undefined) {
      const parentAsset = state.assets.find((a: IAsset) => a.key === currentNode.parentKey);
      if (parentAsset !== undefined) {
        returnValue = findParentType(state, parentAsset, typeName);
      }
    }
  }
  return returnValue;
};

export const AssetStore = {
  namespaced: true,
  state: {
    assets: [],
    selectedSite: undefined,
    selectedAsset: undefined,
  } as IAssetStoreState,
  getters:  {
    allAssets(state: IAssetStoreState): IAsset[] {
      return state.assets;
    },
    selectedSite(state: IAssetStoreState): IAsset {
      return state.selectedSite;
    },
    selectedAsset(state: IAssetStoreState): IAsset {
      return state.selectedAsset;
    },
    parentAsset(state: IAssetStoreState, currentAsset: IAsset): IAsset {
      if (currentAsset && currentAsset.parentKey !== undefined) {
        return state.assets.find((asset: IAsset) => asset.key === currentAsset.parentKey);
      } else {
        return undefined;
      }
    },
    allCustomers(state: IAssetStoreState): IAsset[] {
      return state.assets.filter((asset: IAsset) => asset.type === 'Customer');
    },
    findAssetParentType(state: IAssetStoreState): (currentNode: IAsset, typeName: string) => IAsset {
      return (currentNode: IAsset, typeName: string) => {
        return findParentType(state, currentNode, typeName);
      };
    },
    findCustomerByName(state: IAssetStoreState): (customerName: string) => IAsset {
      return (customerName: string) => {
        return state.assets.find((asset: IAsset) => asset.type === 'Customer' && asset.name === customerName);
      };
    },
    findSitesByParentKey(state: IAssetStoreState): (parentKey: string) => IAsset[] {
      return (parentKey: string) => {
        if (state.assets) {
          return state.assets
            .filter((asset) => asset.type === 'Site' && asset.parentKey === parentKey)
            .sort((asset) => asset.orderIndex);
        } else {
          return Array<IAsset>();
        }
      };
    },
    findAssetsBySiteKey(state: IAssetStoreState): (siteKey: string) => IAsset[] {
      return (siteKey: string) => {
        // Find all assets based on site key.
        // Includes assets that are children of a siteGroup that is a child of the site
        if (state.assets && siteKey) {
          return state.assets
            .filter((asset) => asset.type === 'Asset'
              && (asset.parentKey === siteKey || asset.customerSiteKey === siteKey))
            .sort((asset) => asset.orderIndex);
        } else {
          return Array<IAsset>();
        }
      };
    },
  } as IAssetStoreGetters,
  mutations: {
    initialize(state: IAssetStoreState): void {
      state.selectedAsset = null;
      state.selectedSite = null;
    },
    initializeAsset(state: IAssetStoreState): void {
      state.selectedAsset = null;
    },
    selectSite(state: IAssetStoreState, selected: IAsset): void {
      state.selectedSite = selected;
    },
    selectAsset(state: IAssetStoreState, selected: IAsset): void {
      state.selectedAsset = selected;
    }
  } as IAssetStoreMutations,
  actions: {
    async loadAssets(context: IAssetContext, forceLoad: boolean): Promise<void> {
      if ((context.state.assets != null) && (context.state.assets.length > 0) && !forceLoad) {
        return;
      }
      const conf = await ConfigFactory.GetConfig();
      const customerAssetService = new HydraulicCustomerAssetService(sharedAxiosInstance,
        process.env.VUE_APP_PORTAL_API_BASE_URL?? conf.get('portalApiUrl'));
      try {
        const assets: IAsset[] = await customerAssetService.getCustomerAssets();
        context.state.assets = assets;
        EventBus.$emit(hmbEvents.setSiteInfo);
      } catch (ex) {
        await store.dispatch('error/setError', {
          error: ex,
          errorString: 'Error Loading\n',
          handleError: true,
          routeHomeAfterError: false
        });
      }
    }
  } as IAssetStoreActions
};
