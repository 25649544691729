import { IPortalAppSdk } from '../../../CommonLibrary/models/portal-parent-sdk';
import { ensureTrailing } from './string';
export * from '../../../CommonLibrary/models/portal-parent-sdk';
export * from '../../../CommonLibrary/modules/auth/auth0';

export function parentEventBus(): Vue {
  return (window as any).eftEventBus as Vue;
}

export function inParentApp(): boolean {
  return parentEventBus() != null;
}

export const APP_TAG = 'hydraulic-model-builder' as const;
const APP_ROUTE_BASE = '/utilities/ember/hydraulic-modeler/';

export function appRouteBase(): string {
  return inParentApp() ? APP_ROUTE_BASE : '/';
}

export function findAppBaseUrl(): string {
  const url = process.env.VUE_APP_BASE_URL ?? findAppScriptSourceOrigin();
  return ensureTrailing(url, '/');
}

export function findAppScriptSourceOrigin(): string {
  const el: HTMLScriptElement = document.getElementById(`${APP_TAG}-script`) as any;
  return ensureTrailing(el == null ? '' : new URL(el.src).origin, '/');
}

export function getParentAppSdk(): IPortalAppSdk {
  return document.getElementById(APP_TAG) as any;
}
