import {
  DirectionFlowModel,
  DirectionFlowType,
} from '@/view-models/direction-flow-model';
import { FlowElementModel } from '@/view-models/flow-element-models';
import { fabric } from 'fabric';
import { IGridContext } from '../gridStoreInterface';
import {
  getDirectionId,
  getFlowElementId,
  getIsHover,
  getObjectArrowType,
  getObjectType,
  isShadow
} from './gridStoreHelper';
import HelperMethods from '@/shared/helper-methods';

/**
 * Displays drop zones on groups that are hovered over by target.
 * @param gridContext canvas context
 * @param groupObject group to add dropzones to
 * @param targetObject check if target is hovering over group
 */
export function addDropZonesForGroupsInBounds(
  gridContext: IGridContext,
  groupObject: fabric.Group,
  targetObject: fabric.Object
): boolean {
  let replacedDropZone = false;
  const groupObjectId = getFlowElementId(groupObject);
  const groupFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](groupObjectId);
  const dropZoneObjects = gridContext.state.gridGroupDropAndLandingZones.get(groupObject);

  const isNearDropZones = dropZoneObjects?.some((dropZone) =>
    intersectsWithObject(gridContext, dropZone, targetObject, true, false)
  );
  const isNearGroup = intersectsWithObject(gridContext, groupObject, targetObject, true, false);
  const isNearGroupOrZones = isNearGroup || isNearDropZones;

  dropZoneObjects?.map((dropZoneObject: fabric.Object) => {
    const childIds = groupFlowElement?.flowElementId?.childIdList?.filter(
      (childId) => String(childId.outletId) === getObjectArrowType(dropZoneObject)
    );
    const parentId = groupFlowElement?.flowElementId?.parentId;
    const isConnected =
      !HelperMethods.isArrayEmpty(childIds) ||
      (!HelperMethods.isStringEmpty(parentId) && getObjectArrowType(dropZoneObject) === 'INLET');
    if (!isConnected) {
      const canConnect = canConnectArrows(gridContext, dropZoneObject, targetObject);
      if (isNearGroupOrZones && canConnect) {
        const dropZoneIsHover = getIsHover(dropZoneObject);
        const intersects = intersectsWithObject(gridContext, dropZoneObject, targetObject, true, false);
        const shouldBeVisible = intersects === dropZoneIsHover;
        if (shouldBeVisible !== dropZoneObject.visible) {
          dropZoneObject.toggle('visible');
          replacedDropZone = true;
        }
      } else if (dropZoneObject.visible) {
        dropZoneObject.set('visible', false);
        replacedDropZone = true;
      }
    }
  });
  return replacedDropZone;
}

/**
 * Checks if two elements can connect due to flow directions
 * @param gridContext canvas context
 * @param dropZoneObject Object to test
 * @param targetObject Object to test
 */
export function canConnectArrows(
  gridContext: IGridContext,
  dropZoneObject: fabric.Object,
  targetObject: fabric.Object
): boolean {
  if (
    dropZoneObject === targetObject ||
    dropZoneObject.group === targetObject ||
    dropZoneObject === targetObject.group ||
    (dropZoneObject.group === targetObject.group && dropZoneObject.group != null)
  ) {
    return false;
  }
  const dropzoneObjectType = getObjectArrowType(dropZoneObject);
  const inletObject = dropzoneObjectType === 'INLET' ? dropZoneObject : targetObject;
  const outletObject = dropzoneObjectType === 'OUTLET' ? dropZoneObject : targetObject;

  const inletArrowAngle = getInletArrowAngle(gridContext, inletObject);
  if (HelperMethods.isNullOrUndefined(inletArrowAngle)) {
    return false;
  }
  const possibleOutletArrows = getPossibleOutletArrows(gridContext, outletObject, dropZoneObject);
  if (HelperMethods.isArrayEmpty(possibleOutletArrows)) {
    return false;
  }
  // If neither is shadow, check for possibly being already connected
  if (!isShadow(inletObject) && !isShadow(outletObject)) {
    // Get flowElements
    const inletObjectId = getFlowElementId(inletObject?.group) ?? getFlowElementId(inletObject);
    const outletObjectId = getFlowElementId(outletObject?.group) ?? getFlowElementId(outletObject);
    const outletObjectFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](outletObjectId);
    // Check if elements already connected to each other, try and prevent circular connection
    if (outletObjectFlowElement?.flowElementId?.parentId === inletObjectId) {
      return false;
    }
  }
  return true;
}

/**
 * Finds angle of inlet for the current inletObject
 * @param gridContext canvas context
 * @param inletObject object to search inlets of
 */
function getInletArrowAngle(gridContext: IGridContext, inletObject: fabric.Object): number {
  const inletObjectId = getFlowElementId(inletObject);
  const allGroups = gridContext.state.editorGrid.getObjects().filter((object) => object.name.includes(' Group'));
  const inletObjectGroup =
    inletObject.group ?? (allGroups.find((group) => getFlowElementId(group) === inletObjectId) as fabric.Group);

  if (isShadow(inletObject)) {
    const inletObjectFlowElement: FlowElementModel = gridContext.rootGetters['flowElement/selectedFlowElement'];
    const inlet = getAllDirections(inletObjectFlowElement)?.find((dir) => dir.type === DirectionFlowType.INLET);

    if (!inlet || inletObjectFlowElement?.flowElementId?.parentId) {
      // No inlet or Inlet already connected
      return null;
    }
    const rawInletArrowAngle = inlet.degree;
    // shadow cannot be flipped, so we don't have to handle like we do in the else clause below
    return modWithNegative(rawInletArrowAngle, 360);
  } else {
    const inletObjectFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](inletObjectId);
    const inletArrow = inletObjectGroup?.getObjects().find((object) => object.name?.includes('Arrow INLET'));

    if (!inletArrow || inletObjectFlowElement?.flowElementId?.parentId) {
      // Inlet already connected
      return null;
    }
    return getAbsoluteAttribute(gridContext, inletArrow, 'angle');
  }
}

/**
 * Finds possible outlet arrows who's drop zones are valid for connection with
 * provided inletArrowAngle
 * @param gridContext canvas context
 * @param outletObject object to search outlets of
 * @param dropZoneObject dropzone
 * @param inletArrowAngle angle to compare outlets to
 * @param isInputBurner If input is a burner, do not check angles
 */
function getPossibleOutletArrows(
  gridContext: IGridContext,
  outletObject: fabric.Object,
  dropZoneObject: fabric.Object
): DirectionFlowModel[] | fabric.Object[] {
  const allGroups = gridContext.state.editorGrid.getObjects().filter((object) => object.name.includes(' Group'));
  const outletObjectId = getFlowElementId(outletObject);
  const outletObjectGroup =
    outletObject.group ?? (allGroups.find((group) => getFlowElementId(group) === outletObjectId) as fabric.Group);
  if (isShadow(outletObject)) {
    const outletObjectFlowElement: FlowElementModel = gridContext.rootGetters['flowElement/selectedFlowElement'];
    return getAllDirections(outletObjectFlowElement)?.filter(
      (direction) => direction.type === DirectionFlowType.OUTLET
    );
  } else {
    const outletGroupId = getFlowElementId(outletObjectGroup);
    const outletObjectFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](outletGroupId);
    // Get possible Directions
    const outletArrowDirections = getAllDirections(outletObjectFlowElement)?.filter(
      (direction) =>
        // Element's outlets have not already been connected
        direction.type === DirectionFlowType.OUTLET &&
        !outletObjectFlowElement.flowElementId?.childIdList?.some((childId) => childId.outletId === direction.id)
    );
    return outletObjectGroup?.getObjects().filter(
      (arrow) =>
        (getObjectArrowType(dropZoneObject) !== 'OUTLET' || // Either this isn't the dropzone
          getDirectionId(dropZoneObject) === getDirectionId(arrow)) && // Or use the dropzone's outlet arrow
        outletArrowDirections?.some((direction) => direction.id === getDirectionId(arrow))
    );
  }
}

/**
 * Checks if object intersects with another object.
 * Modifies fabricjs implementation to work with objects inside groups
 * @param gridContext canvas context
 * @param staticGroup Object to test
 * @param movingObject Object to test
 * @param absolute use coordinates without viewportTransform
 * @param calculate use coordinates of current position instead of .oCoords
 */
export function intersectsWithObject(
  gridContext: IGridContext,
  staticGroup: fabric.Object,
  movingObject: fabric.Object,
  absolute: boolean,
  calculate: boolean
): boolean {
  if (staticGroup.group && staticGroup.group === movingObject.group) {
    return;
  }
  const object1Coords = getCoords(gridContext, staticGroup, absolute, calculate);

  type Coordinates = 'tl' | 'tr' | 'bl' | 'br';
  if (!staticGroup.name?.includes('Group') && object1Coords) {
    const heightWidth = getHeightWidth(staticGroup);
    for (const coord in object1Coords) {
      if (object1Coords.hasOwnProperty(coord) && staticGroup.group?.aCoords) {
        object1Coords[coord as Coordinates].x -=
          (heightWidth.width ?? 0) / 2; // since origin is center
        object1Coords[coord as Coordinates].y -=
          (heightWidth.height ?? 0) / 2; // since origin is center
      }
    }
  }
  const object2Coords = getCoords(gridContext, movingObject, absolute, calculate);
  if (!movingObject.name?.includes('Group') && object2Coords) {
    const heightWidth = getHeightWidth(movingObject);
    for (const coord in object2Coords) {
      if (object2Coords.hasOwnProperty(coord) && movingObject.group?.aCoords) {
        object2Coords[coord as Coordinates].x -=
          (heightWidth.width ?? 0) / 2; // since origin is center
        object2Coords[coord as Coordinates].y -=
          (heightWidth.height ?? 0) / 2; // since origin is center
      }
    }
  }

  const intersection = fabric.Intersection.intersectPolygonPolygon(object1Coords, object2Coords);
  return (
    (intersection as any).status === 'Intersection' ||
    isContainedWithinObject(object1Coords, object2Coords, movingObject) ||
    isContainedWithinObject(object2Coords, object1Coords, staticGroup)
  );
}

/**
 * Checks if object is fully contained within area of another object
 * Modifies fabricjs implementation to work with objects inside groups
 * @param innerObjectCoords Coordinates of object that might be contained
 * @param outerObjectCoords Coordinates of object that might contain object
 * @param outerObject Object that might contain object
 */
function isContainedWithinObject(
  innerObjectCoords: fabric.Point[],
  outerObjectCoords: fabric.Point[],
  outerObject: fabric.Object
): boolean {
  const lines = (outerObject as any)._getImageLines(transformObjectCoords(outerObjectCoords));
  for (let i = 0; i < 4; i++) {
    if (!outerObject.containsPoint(innerObjectCoords[i], lines)) {
      return false;
    }
  }
  return true;
}

/**
 * Corrects coords for objects in groups.
 * @param gridContext canvas context
 * @param object object to get the coords of
 * @param absolute use coordinates without viewportTransform
 * @param calculate use coordinates of current position instead of .oCoords
 */
function getCoords(gridContext: IGridContext, object: fabric.Object, absolute: boolean, calculate: boolean): any {
  if (!object.group) {
    return object.getCoords(absolute, calculate);
  }
  const heightWidth = getHeightWidth(object);
  return [
    new fabric.Point(
      getAbsoluteAttribute(gridContext, object, 'left') ?? 0,
      getAbsoluteAttribute(gridContext, object, 'top') ?? 0
    ),
    new fabric.Point(
      (getAbsoluteAttribute(gridContext, object, 'left') ?? 0) + heightWidth.width,
      getAbsoluteAttribute(gridContext, object, 'top') ?? 0
    ),
    new fabric.Point(
      (getAbsoluteAttribute(gridContext, object, 'left') ?? 0) + heightWidth.width,
      (getAbsoluteAttribute(gridContext, object, 'top') ?? 0) + heightWidth.height
    ),
    new fabric.Point(
      getAbsoluteAttribute(gridContext, object, 'left') ?? 0,
      (getAbsoluteAttribute(gridContext, object, 'top') ?? 0) + heightWidth.height
    )
  ];
}

/**
 * Get object attributes adjusted to be absolute for objects within groups
 * Attributes could be one of top, left, angle, scaleX, scaleY, or flip
 * @param gridContext canvas context
 * @param object object to get attributes of
 * @param key string attribute
 */
export function getAbsoluteAttribute(gridContext: IGridContext, object: fabric.Object, key: string): any {
  let objectId;
  let objectFlowElement: FlowElementModel;
  let flowElementImage;
  switch (key) {
    case 'top':
      return object.calcTransformMatrix()[5];

    case 'left':
      return object.calcTransformMatrix()[4];

    case 'flip':
      // Get flowElement, which stores updated flipY (without fabric interference)
      objectId = getFlowElementId(object.group ?? object);
      objectFlowElement = gridContext.rootGetters['diagram/getElementById'](objectId);
      flowElementImage = objectFlowElement.directionFlowList.find((dir) => dir.type === DirectionFlowType.ELEMENT);
      return flowElementImage?.hFlip;

    case 'scaleX':
      return (object.scaleX ?? 0) * (object.group?.scaleX ?? 0);

    case 'scaleY':
      return (object.scaleY ?? 0) * (object.group?.scaleY ?? 0);

    case 'angle':
      // Get flowElement, which stores updated angles (without fabric interference)
      objectId = getFlowElementId(object.group ?? object);
      objectFlowElement = gridContext.rootGetters['diagram/getElementById'](objectId) as FlowElementModel;
      let angle = 0;

      const type = getObjectType(object);
      if (object.group && type === 'Arrow') {
        // Find this arrow's direction to get starting angle to add with group's angle
        const directionId = getDirectionId(object);
        const flowElementDirection = getAllDirections(objectFlowElement)?.find((dir) => dir.id === directionId);
        angle = flowElementDirection?.degree ?? 0;
      }

      // Account for flip which we get from the image
      flowElementImage = objectFlowElement?.directionFlowList?.find((dir) => dir.type === DirectionFlowType.ELEMENT);
      if (flowElementImage?.hFlip) {
        // Flip angle
        angle *= -1;
      }

      // Add group rotation
      angle = (angle ?? 0) + (flowElementImage?.degree ?? 0);
      angle = modWithNegative(angle, 360);
      if ((angle > -0.01 && angle < 0.01) || angle > 359.99) {
        // Round negligible angles
        angle = 0;
      }
      return angle;
    default:
      return null;
  }
}

/**
 * Updates flowElement's degree
 * This is the only place we're setting element.degree to ensure it is always positive
 * @param element flowElement's DirectionFlowType.ELEMENT direction to set degree of
 * @param degree new degree to rotate element with
 */
export function updateElementDegree(element: DirectionFlowModel, degree: number): void {
  element.degree = modWithNegative(element.degree + degree, 360);
}

/**
 * Gets min and max x and y from aCoords.
 * To be used with objects within groups.
 * @param aCoords coords to parse for min and max
 */
function getACoordsMinMax(
  aCoords: { bl: fabric.Point; br: fabric.Point; tl: fabric.Point; tr: fabric.Point }
): { min: {x: number, y: number}, max: {x: number, y: number} } {
  const xCoordinates = [aCoords?.tl?.x ?? 0, aCoords?.tr?.x ?? 0, aCoords?.bl?.x ?? 0, aCoords?.br?.x ?? 0];
  const yCoordinates = [aCoords?.tl?.y ?? 0, aCoords?.tr?.y ?? 0, aCoords?.bl?.y ?? 0, aCoords?.br?.y ?? 0];

  const minX = Math.min(...xCoordinates);
  const maxX = Math.max(...xCoordinates);
  const minY = Math.min(...yCoordinates);
  const maxY = Math.max(...yCoordinates);

  const min = { x: minX, y: minY };
  const max = { x: maxX, y: maxY };

  return { min, max };
}

/**
 * Gets adjusted height and width for objects that may have been adjusted.
 * To be used with objects within groups.
 * @param element element whose coordinates to use to calculate height and width
 */
export function getHeightWidth(element: fabric.Object): { height: number, width: number } {
  const aCoords = element?.aCoords;
  const minMax = getACoordsMinMax(aCoords);
  const min = minMax.min;
  const max = minMax.max;
  const heightWidth = { height: max.y - min.y, width: max.x - min.x };

  // Parent scaling is not accounted for in child memmbers
  const scale = element?.group?.scaleX;
  if (scale) {
    heightWidth.height *= scale;
    heightWidth.width *= scale;
  }

  return heightWidth;
}

/**
 * transsforms coords from array of points to object with corners format
 * @param coords array of coords to transform
 */
function transformObjectCoords(
  coords: fabric.Point[]
): { tl: fabric.Point, tr: fabric.Point, br: fabric.Point, bl: fabric.Point } {
  return {
    tl: coords[0],
    tr: coords[1],
    br: coords[2],
    bl: coords[3]
  };
}

/**
 * Parses flow element and its subelements for all directions
 *
 * @param flowElement element to get directions from
 */
export function getAllDirections(flowElement: FlowElementModel): DirectionFlowModel[] {
  if (!flowElement) {
    return null;
  }
  const directions: DirectionFlowModel[] = [];
  const flowElementModels: FlowElementModel[] = [flowElement];
  while (flowElementModels.length) {
    const currentFlowElement = flowElementModels.pop();
    directions.push(...(currentFlowElement?.directionFlowList ?? []));
    flowElementModels.push(...(currentFlowElement?.subElementList ?? []));
  }
  return directions;
}

/**
 * Calculates modulo, using Number.mod. Handles negative mods such that -1 % 3 is 2
 * @param a dividend
 * @param n divisor
 */
export function modWithNegative(a: number, n: number): number {
  return ((a % n) + n) % n;
}

/**
 * Returns shortest distance between angles 1 and 2
 * @param angle1
 * @param angle2
 */
export function angleDistance(angle1: number, angle2: number): number {
  const phi = Math.abs(angle1 - angle2) % 360;
  return phi > 180 ? 360 - phi : phi;
}
