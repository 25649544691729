import { BurnerModel } from '@/view-models/burner-model';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { HydraulicElementLibraryService } from '@/services/hydraulic-element-library-service';
import { IBurner } from '@/view-models/burner-view-models';
import { IRootState } from '..';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { DirectionFlowModel, DirectionFlowType } from '@/view-models/direction-flow-model';

export interface IFlowElementStoreState {
  idelchikFlowElements: FlowElementModel[];
  burnerFlowElements: FlowElementModel[];
  selectedFlowElement: FlowElementModel;
}

export interface IFlowElementStoreGetters extends GetterTree<IFlowElementStoreState, IRootState> {
  allIdelchikFlowElements(state: IFlowElementStoreState): FlowElementModel[];
  allBurnerFlowElements(state: IFlowElementStoreState): FlowElementModel[];
  selectedFlowElement(state: IFlowElementStoreState): FlowElementModel;
  getBurnerFlowElementByBurnerId(state: IFlowElementStoreState): (burnerId: string) => FlowElementModel;
}

export interface IFlowElementStoreMutations extends MutationTree<IFlowElementStoreState> {
  selectFlowElement(state: IFlowElementStoreState, selected: FlowElementModel): void;
  createBurnerFlowElements(state: IFlowElementStoreState, burners: IBurner[]): void;
}

export interface IFlowElementStoreActions extends ActionTree<IFlowElementStoreState, IRootState> {
  loadIdelChikElements(context: IFlowElementContext): Promise<FlowElementModel[]>;
}

export type IFlowElementContext = ActionContext<IFlowElementStoreState, IRootState>;

export const FlowElementStore = {
  namespaced: true,
  state: {
    idelchikFlowElements: Array<FlowElementModel>(),
    burnerFlowElements: Array<FlowElementModel>(),
    // Hardcode default mock until selectFlowElement is implemented in sidebar
    selectedFlowElement: null
  } as IFlowElementStoreState,
  getters:  {
    allIdelchikFlowElements(state: IFlowElementStoreState): FlowElementModel[] {
      return state.idelchikFlowElements;
    },
    allBurnerFlowElements(state: IFlowElementStoreState): FlowElementModel[] {
      return state.burnerFlowElements;
    },
    selectedFlowElement(state: IFlowElementStoreState): FlowElementModel {
      return state.selectedFlowElement;
    },
    getBurnerFlowElementByBurnerId(state: IFlowElementStoreState): (burnerId: string) => FlowElementModel {
      return (burnerId: string) => {
        return state.burnerFlowElements?.find((element) => element.burner?.burnerId === burnerId);
      };
    },
  } as IFlowElementStoreGetters,
  mutations: {
    selectFlowElement(state: IFlowElementStoreState, selected: FlowElementModel): void {
      state.selectedFlowElement = selected;
    },
    createBurnerFlowElements(state: IFlowElementStoreState, burners: IBurner[]): void {
      // map burner, add item to burnerFlowElements so canvas can reference them
      state.burnerFlowElements = burners?.map((burnerItem) => {
        const burnerFlowElement = new FlowElementModel();
        burnerFlowElement.name = burnerItem.burnerName ? burnerItem.burnerName.split(' ').join('_') : '';
        burnerFlowElement.displayName = burnerItem.burnerName;
        burnerFlowElement.type = FlowElementType.BURNER;

        const burnerModel = new BurnerModel();
        burnerModel.burnerId = burnerItem.burnerIdentifier ? burnerItem.burnerIdentifier : '';
        burnerModel.burnerKey = burnerItem.burnerConfigKey ? burnerItem.burnerConfigKey : '';
        burnerModel.burnerType = burnerItem.burnerConfigZoneType;
        burnerModel.emberHierarchyLevelKey = burnerItem.emberHierarchyLevelKey ?
          burnerItem.emberHierarchyLevelKey : '';
        burnerModel.burnerConfigName = burnerItem.burnerConfigName ? burnerItem.burnerConfigName : '';
        burnerFlowElement.burner = burnerModel;

        const elementDirection = new DirectionFlowModel();
        elementDirection.id = '1';
        elementDirection.degree = 0;
        elementDirection.type = DirectionFlowType.ELEMENT;
        elementDirection.xAxis = 0;
        elementDirection.yAxis = 0;

        const inletDirection = new DirectionFlowModel();
        inletDirection.id = '2';
        inletDirection.degree = 90;
        inletDirection.type = DirectionFlowType.INLET;
        inletDirection.xAxis = 13;
        inletDirection.yAxis = 82;
        burnerFlowElement.directionFlowList = [elementDirection, inletDirection];

        return burnerFlowElement;
      });
    }
  } as IFlowElementStoreMutations,
  actions: {
    async loadIdelChikElements(context: IFlowElementContext): Promise<FlowElementModel[]> {
      const conf = await ConfigFactory.GetConfig();
      const hmbService = new HydraulicElementLibraryService(sharedAxiosInstance,
        process.env.VUE_APP_HYDRAULIC_MODEL_BUILDER_API_BASE_URL ?? conf.get('hmbApiUrl'));
      return hmbService.loadIdelChikList().then( (response) => {
        context.state.idelchikFlowElements = response;
        return response;
      });
    }
  } as IFlowElementStoreActions
};
