import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState} from '..';

export interface IAppStoreState {
  isLoading: boolean;
  isNewModel: boolean;
  flags: any;
}

export interface AppStoreGetters extends GetterTree<IAppStoreState, IRootState> {
  isLoading(state: IAppStoreState): boolean;
  isNewModel(state: IAppStoreState): boolean;
  getFlags(state: IAppStoreState): any;
}

export interface AppStoreMutations extends MutationTree<IAppStoreState> {
  updateIsLoading(state: IAppStoreState, newIsLoading: boolean): void;
  setNewIsLoading(state: IAppStoreState, newIsNewModel: boolean): void;
  updateFF(state: IAppStoreState, flags: any): void;
}

export interface AppStoreActions extends ActionTree<IAppStoreState, IRootState> {}

export type AppContext = ActionContext<IAppStoreState, IRootState>;

export const AppStore = {
  namespaced: true as true,
  state: {
    isLoading: true,
    isNewModel: false,
    flags: null
  } as IAppStoreState,
  getters:  {
    isLoading(state: IAppStoreState): boolean {
      return state.isLoading;
    },
    isNewModel(state: IAppStoreState): boolean {
      return state.isNewModel;
    },
    getFlags(state: IAppStoreState): any {
      return state.flags;
    }
  } as AppStoreGetters,
  mutations: {
    updateIsLoading(state: IAppStoreState, newIsLoading: boolean): void {
      state.isLoading = newIsLoading;
    },
    setNewIsLoading(state: IAppStoreState, newIsNewModel: boolean): void {
      state.isNewModel = newIsNewModel;
    },
    updateFF(state: IAppStoreState, flags: any): void {
      state.flags = flags;
    }
  } as AppStoreMutations,
  actions: {} as AppStoreActions
};
