




































import { Vue, Component, Prop } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { IBurnerFolder } from '@/view-models/burner-folder-view-models';
import { IFolder } from '@/view-models/folder-view-models';
import store from '@/store';

Vue.use(BootstrapVue);

@Component({
  name: 'folder-item'
})
export default class FolderItem extends Vue {
  @Prop({ required: true })
  public folder: IFolder;
  @Prop({ default: false })
  public isReadOnly: boolean;
  @Prop({ default: false })
  public isRoot: boolean;
  @Prop({ default: '' })
  public searchString?: string;

  public isOpen: boolean = true;
  public errors: any;
  public folderCount: number = 10;
  public itemCount: number = 10;
  public linkFolders: IFolder[] = [];
  private burnerImage: any;

  public dragStart(event: DragEvent): void {
    if (event.dataTransfer) {
      event.dataTransfer.setDragImage(this.burnerImage, 0, 0);
    }
  }

  public dragEnd(): void {
    const dragElement: HTMLElement =
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById('drag-img');
    if (dragElement !== null && dragElement !== undefined) {
      dragElement.remove();
    }
  }

  public mounted(): void {
    this.burnerImage = new Image();
    this.burnerImage.id = 'drag-img';
    this.burnerImage.src = require('@/assets/images/icons/burner/COOLStar_2D_Small.svg');
    this.addLinkFolders(this.folder);
  }

  get foldersExpandable(): boolean {
    const count: number = this.folder.visibleChildFolders.length;
    return count > 10 && this.folderCount <= count;
  }

  get hasChildren(): boolean {
    return this.folder.visibleChildFolders.length > 0 || this.folder.visibleChildItems.length > 0;
  }

  get itemsExpandable(): boolean {
    const count: number = this.folder.visibleChildItems.length;
    return count > 10 && this.itemCount <= count;
  }

  get expandBackgroundUrl(): any {
    return require('@/assets/images/icons/Expand.svg');
  }

  get collapseBackgroundUrl(): any {
    return require('@/assets/images/icons/Collapse.svg');
  }

  public toggle(): void {
    this.folder.isOpen = !this.folder.isOpen;
  }

  public selectItem(): void {
    if ((this.folder as IBurnerFolder).details?.burnerIdentifier) {
      const id = (this.folder as IBurnerFolder).details?.burnerIdentifier;
      const burnerFlowElement = store.getters['flowElement/getBurnerFlowElementByBurnerId'](id);
      store.commit('flowElement/selectFlowElement', burnerFlowElement);
    }
  }

  public isFilterMatch(): boolean {
    if ((this.searchString === undefined || this.searchString.length === 0 )) {
      return true;
    } else {
      this.folder.isOpen = true;
      return this.linkFolders.some((linkFolder: IFolder) => this.containsSearchString(linkFolder.burnerName));
    }
  }

  public addLinkFolders(folder: IFolder): void {
    if (folder.isLink) {
      const selectableBurner: Node =
        document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById(
          `folder-select-${folder.key}`);
      if (selectableBurner) {
        selectableBurner.addEventListener('dragstart', (this.dragStart) as EventListener);
        selectableBurner.addEventListener('dragend', this.dragEnd);
      }

      this.linkFolders.push(folder);
    } else {
      folder.visibleChildFolders
        .filter((childFolder) => childFolder.isLink)
        .forEach((childLinkFolder) => {
          this.linkFolders.push(childLinkFolder);
        });
      folder.visibleChildFolders
        .filter((noneLinkFolder: IFolder) => !noneLinkFolder.isLink)
        .forEach((noneLinkFolder: IFolder) => {
          return this.addLinkFolders(noneLinkFolder);
        });
    }
  }

  public containsSearchString(name: string): boolean {
    if (this.searchString !== undefined) {
      return name.toUpperCase().includes(this.searchString.toUpperCase());
    } else {
      return true;
    }
  }
}
