



























































































































































































































































































































































import Vue from 'vue';
import StringUtil from '@/utils/stringUtil';
import { BootstrapVue, BModal, BDropdown } from 'bootstrap-vue';
import Component from 'vue-class-component';
import { IAsset } from '@/view-models/assets-view-models';
import { Mode } from '@/view-models/mode';
import ConfigFactory from '@/services/config';
import { IModelSummary, ModelType } from '@/view-models/model-summary';
import store from '@/store';
import EditorToolbarBreadcrumbs from '@/components/editor/components/editorToolbar/components/EditorToolbarBreadcrumbs.vue';
import EditorToolbarNav from '@/components/editor/components/editorToolbar/components/EditorToolbarNav.vue';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { getFlowElementId } from '@/store/grid/helper/gridStoreHelper';
import EventBus, { hmbEvents } from '@/components/hydraulicModelTuner/eventBus';
import SaveEditorChangesModal from '@/components/editor/components/editorToolbar/components/SaveEditorChangesModal.vue';
import { IBurner } from '@/view-models/burner-view-models';

Vue.use(BootstrapVue);

@Component({
  name: 'EditorToolbar',
  components: {
    BModal,
    EditorToolbarBreadcrumbs,
    EditorToolbarNav,
    SaveEditorChangesModal
  }
})
export default class EditorToolbar extends Vue {
  private modelLocked: boolean = false;
  private disableEditorButton: boolean = false;

  get disableSaveButton(): boolean {
    const diagramModified = store.getters['diagram/getDiagramModified'];
    if (diagramModified) {
      this.saveButtonClass = 'button-container rectangle import-data';
    } else {
      this.saveButtonClass = 'button-primary-disabled button-container rectangle import-data';
    }
    return !diagramModified;
  }

  get disablePreviewButton(): boolean {
    const diagramElements = store.getters['diagram/getDiagramElements'] as FlowElementModel[];
    const diagramPreivewable = diagramElements && diagramElements.length > 1;
    if (diagramPreivewable) {
      this.previewButtonClass = 'button-container rectangle import-data';
    } else {
      this.previewButtonClass = 'button-primary-disabled button-container rectangle import-data';
    }
    return !diagramPreivewable;
  }

  get disableExportButton(): boolean {
    if (this.isExporting || !this.exportFileName || !this.exportFileName.replace(/\s/g, '').length) {
      this.exportButtonClass = 'button-primary-disabled button-container import-data';
      return true;
    }
    if (this.outletsWithoutBurners && !this.unassignedOutletsAcknowledged) {
      this.exportButtonClass = 'button-primary-disabled button-container import-data';
      return true;
    } else {
      this.exportButtonClass = 'button-primary-enabled button-container import-data';
      return false;
    }
  }

  get disableTuningButton(): boolean {
    if (this.selectedModel) {
      return this.selectedModel.hidden || !this.selectedModel.modelComplete;
    }
    return true;
  }

  get selectedModel(): IModelSummary {
    return store.getters['diagram/getCurrentModelSummary'];
  }

  get selectedAsset(): IAsset {
    return store.getters['asset/selectedAsset'];
  }

  private modelType: ModelType = ModelType.Air_Duct_Network;
  private modelToExportSummary: IModelSummary = null;
  private outletsWithoutBurners: boolean = false;
  private exportFileName: string = '';
  private isExporting: boolean = false;
  private saveOnExport: boolean = false;
  private unassignedOutletsAcknowledged: boolean = false;
  private previewMode: boolean = false;
  private message: string = '';
  private diagramModified: boolean = false;
  private editorButtonClass: string = 'button-container rectangle import-data';
  private saveButtonClass: string = 'button-container rectangle import-data';
  private previewButtonClass: string = 'button-container rectangle import-data';
  private exportButtonClass: string = 'button-container rectangle import-data';
  private resetConfirmed: boolean = false;
  private editorModelDescription: string = '';
  private editorModelName: string = '';
  private editorModelType: string = '';
  private editorModelCustomerName: string = '';
  private duplicateModel: boolean = false;
  private modelSite: IAsset = null;
  private modelAsset: IAsset = null;
  private originalName: string = '';
  private originalSiteKey: string = '';
  private originalAssetKey: string = '';
  private originalDescription: string = '';
  private saveAsText: string = 'Do you want to save this copy of the model with this new name?';
  private siteAssetChanged: boolean = false;
  private prevSite: IAsset = null;
  private origAsset: IAsset = null;
  private origSite: IAsset = null;
  private saveEditorPromptContext: string = '';
  private utilitiesLink: string = '';

  public onModelSiteChange(val: IAsset): void {
    if (val !== this.prevSite) {
      this.prevSite = val;
      this.modelAsset = null;
    }
  }

  get sites(): IAsset[] {
    // Get customer as asset to use customer key to find site
    this.modelType = ModelType.Air_Duct_Network;
    const customerKey = store.getters['asset/findCustomerByName'](this.editorModelCustomerName)?.key;
    return store.getters['asset/findSitesByParentKey'](customerKey);
  }

  get assets(): IAsset[] {
    return store.getters['asset/findAssetsBySiteKey'](this.modelSite?.key);
  }

  public async mounted(): Promise<void> {
    this.isExporting = false;
    if (this.$route.query.initialState === 'preview') {
      this.disableEditorButton = true;
      this.editorButtonClass = 'button-primary-disabled button-container rectangle import-data';
      EventBus.$on(hmbEvents.previewModeChanged, (previewMode: Mode) => {
        if (previewMode === Mode.PREVIEW) {
          this.disableEditorButton = false;
          this.editorButtonClass = 'button-container rectangle import-data';
        }
      });
    }

    await store.dispatch('diagram/initializeDiagramSaver');

    this.setDefaultSiteAsset();
    if (this.$route.query.initialState === 'preview') {
      this.previewMode = true;
    }
    EventBus.$on(hmbEvents.resetFromPrompt, () => {
      this.onClickReset(true);
    });
    EventBus.$on(hmbEvents.showSaveModelPromptModal, (context: string) => {
      this.saveEditorPromptContext = context;
      this.$bvModal.show('saveEditorChangesModal');
    });

    // gets link for utilities breadcrumb in different environments
    const conf = await ConfigFactory.GetConfig();
    const redirectUri = process.env.VUE_APP_PORTAL_API_URL
      ? process.env.VUE_APP_PORTAL_API_URL
      : conf.get('redirectUriParent');
    this.utilitiesLink = redirectUri + '/utilities';
  }

  private beforeDestroy(): void {
    EventBus.$off(hmbEvents.resetFromPrompt);
    EventBus.$off(hmbEvents.showSaveModelPromptModal);
    EventBus.$off(hmbEvents.previewModeChanged);
  }

  private setDefaultSiteAsset(): void {
    const customerKey = store.getters['asset/findCustomerByName'](this.editorModelCustomerName)?.key;
    const sites: IAsset[] = store.getters['asset/findSitesByParentKey'](customerKey);
    this.modelSite = sites.filter((site) => site.name === this.modelToExportSummary?.location.name)?.[0];
    this.origSite = this.modelSite;
    this.prevSite = this.modelSite;

    const assets: IAsset[] = store.getters['asset/findAssetsBySiteKey'](this.modelToExportSummary?.location.key);
    this.modelAsset = assets.filter((asset) => asset.name === this.modelToExportSummary?.asset.name)?.[0];
    this.origAsset = this.modelAsset;
  }

  private checkExportFileName(): boolean {
    if (!this.exportFileName || !this.exportFileName.replace(/\s/g, '').length) {
      this.exportButtonClass = 'button-primary-disabled button-container import-data';
      return true;
    } else {
      return this.disableExportButton;
    }
  }

  private async created(): Promise<void> {
    await this.setCurrentModelSummary();
    this.initializeCurrentModelVariables();
  }

  private initializeCurrentModelVariables(): void {
    this.modelToExportSummary = store.getters['diagram/getCurrentModelSummary'];
    if (this.modelToExportSummary) {
      this.modelLocked = !!this.modelToExportSummary.modelLocked;
      this.editorModelDescription = this.modelToExportSummary.description;
      this.editorModelName = this.modelToExportSummary?.name;
      this.editorModelType = this.translateModelType(this.modelToExportSummary?.modelType);
      this.editorModelCustomerName = this.modelToExportSummary?.customer.name;
      this.originalSiteKey = this.modelToExportSummary?.location?.key;
      this.originalAssetKey = this.modelToExportSummary?.asset?.key;
      this.origSite = this.modelSite;
      this.origAsset = this.modelAsset;
      this.originalName = this.modelToExportSummary?.name;
      this.originalDescription = this.modelToExportSummary?.description;
      this.exportFileName =
        this.modelToExportSummary.customer?.name?.replace(/ /g, '_').toLowerCase() +
        '_' +
        this.modelToExportSummary?.location?.name?.replace(/ /g, '_').toLowerCase() +
        '_' +
        this.modelToExportSummary?.asset?.name?.replace(/ /g, '_').toLowerCase();
    }
  }

  private async setCurrentModelSummary(): Promise<void> {
    const customerKey = this.$route.params.customerKey;
    const assetKey = this.$route.params.assetKey;
    const modelName = this.$route.params.modelName;

    // load and set selected asset
    await store.dispatch('asset/loadAssets');
    const allAssets: IAsset[] = store.getters['asset/allAssets'];
    const selectedAsset = allAssets.find((asset) => asset.key === assetKey);
    if (selectedAsset === undefined) {
      this.$router?.replace('/modelselector');
    } else {
      store.commit('asset/selectAsset', selectedAsset);
      store.commit('tuner/setCurrentAsset', selectedAsset);
    }

    await store.dispatch('tuner/loadTuningHistoryList', assetKey);

    // set selected site
    const sites: IAsset[] = store.getters['asset/findSitesByParentKey'](customerKey);
    const selectedSite = sites.find((site) => site.key === selectedAsset.customerSiteKey);
    if (selectedSite === undefined) {
      this.$router?.replace('/modelselector');
    } else {
      store.commit('asset/selectSite', selectedSite);
      store.commit('tuner/setCurrentSite', selectedSite);
    }

    // tslint:disable-next-line:max-line-length
    const permalinkSummary: IModelSummary = await store.dispatch('model/getPermalinkSummaryItem', {customerKey, assetKey, modelName});
    if (!permalinkSummary) {
      this.$router?.replace('/modelselector');
    } else {
      // select model and load burners
      store.commit('model/selectModel', permalinkSummary);
      store.commit('diagram/setCurrentModelSummary', permalinkSummary);
      await store.dispatch('burner/loadBurners');
      const burners = store.getters['burner/allBurners'] as IBurner[];
      store.commit('flowElement/createBurnerFlowElements', burners);
      // Await the load burners before setting locks on burners to ensure all burners are available
      const burnerElements = store.getters['diagram/getBurnerElements'] as FlowElementModel[];
      await store.dispatch('burner/setLockedBurners', burnerElements);
    }

    // initialize canvas
    const canvasModel = await store.dispatch('diagram/loadDiagramDetails');
    if (canvasModel) {
      if (canvasModel.gridBoxSize > 0) {
        store.commit('grid/updateEditorGridBoxSize', canvasModel.gridBoxSize);
      } else {
        store.commit('grid/resetEditorGridBoxSize');
        store.commit('grid/resetUnconnectedGroups');
      }
      await store.dispatch('grid/loadFromDatalessJSON',
        { json: canvasModel?.diagram }
      );
      await store.dispatch('grid/zoomFit');
    } else {
      store.commit('diagram/initializeDiagram');
    }
  }

  private translateModelType(modelType: ModelType): string {
    if (modelType === ModelType.Air_Duct_Network) {
      return 'Air Duct Network';
    }
    return '';
  }

  private cancelSaveAs(): void {
    (this.$refs.editModal as BModal).hide();
    if (this.modelToExportSummary) {
      this.editorModelName = this.modelToExportSummary.name;
      this.editorModelDescription = this.modelToExportSummary?.description;
      this.setDefaultSiteAsset();
    }
  }

  private formIsValid(): boolean {
    if (
      StringUtil.equal(this.editorModelName, this.originalName) &&
      StringUtil.equal(this.editorModelDescription, this.originalDescription) &&
      this.modelSite === this.origSite &&
      this.modelAsset === this.origAsset
    ) {
      return false;
    }

    if (this.editorModelName && !this.duplicateModel && this.modelSite && this.modelAsset) {
      return true;
    } else {
      return false;
    }
  }

  private async onClickReset(fromSavePrompt: boolean = false): Promise<void> {
    await store.dispatch('diagram/resetDiagramDetails');
    store.commit('diagram/resetDiagramPreviewHierarchy');

    const canvas = store.getters['grid/editorGrid'];
    canvas.clear();

    const canvasModel = await store.dispatch('diagram/loadDiagramDetails');
    const burnerElements = store.getters['diagram/getBurnerElements'] as FlowElementModel[];
    store.dispatch('burner/setLockedBurners', burnerElements);
    if (canvasModel) {
      if (canvasModel.gridBoxSize > 0) {
        store.commit('grid/updateEditorGridBoxSize', canvasModel.gridBoxSize);
      } else {
        store.commit('grid/resetEditorGridBoxSize');
        store.commit('grid/resetUnconnectedGroups');
      }
      store.dispatch('grid/loadFromDatalessJSON', { json: canvasModel?.diagram });
    } else {
      store.commit('grid/resetEditorGridBoxSize');
      store.commit('grid/resetUnconnectedGroups');
      store.commit('diagram/initializeDiagram');
    }

    EventBus.$emit(hmbEvents.checkForCompleteModel);
    if (fromSavePrompt) {
      EventBus.$emit(hmbEvents.resetModelComplete);
    }

    this.$root.$emit('bv::hide::modal', 'reset-modal');
  }

  private async tuneModel(): Promise<void> {
    if (this.selectedModel && this.modelSite && this.modelAsset) {
      store.commit('tuner/setCurrentModelSummary', this.selectedModel);
      store.commit('tuner/setCurrentSite', this.modelSite);
      store.commit('tuner/setCurrentAsset', this.modelAsset);
      await store.dispatch('tuner/loadTuningHistoryList', this.modelAsset.key);
      store.commit('tuner/setCurrentHistoryCheckedModels', [this.selectedModel.key]);
    }
    store.commit('tuner/setShouldCheckAllModels', false);
    store.commit('tab/initializeTab');
    this.$router.push('/tuner');
  }

  private onClickPreview(): void {
    const previewable: boolean = store.getters['diagram/isDiagramPreviewable'];
    if (!previewable) {
      (this.$refs['preview-error-modal'] as BModal).show();
    } else {
      this.previewMode = true;
      store.commit('diagram/resetDiagramPreviewHierarchy');
      store.commit('grid/switchMode', Mode.PREVIEW);
    }
    EventBus.$emit(hmbEvents.checkForCompleteModel);
  }

  private async onClickSave(): Promise<void> {
    const response = await store.dispatch('diagram/saveDiagramDetails');
    this.message = response;

    (this.$refs['save-model-modal'] as BModal)?.show();
    window.setTimeout(() => {
      (this.$refs['save-model-modal'] as BModal)?.hide();
    }, 1200);

    EventBus.$emit(hmbEvents.checkForCompleteModel);
  }

  private onClickEditor(): void {
    const currentModelSummary: IModelSummary = store.getters['diagram/getCurrentModelSummary'];
    if (currentModelSummary) {
      if (
        (!currentModelSummary.modelLocked && !currentModelSummary.modelComplete) ||
        (!currentModelSummary.modelLocked && currentModelSummary.modelComplete)
      ) {
        this.switchToEditor();
      } else if (currentModelSummary.modelLocked && currentModelSummary.modelComplete) {
        this.$bvModal.show('edit-model-modal');
      } else if (currentModelSummary.modelLocked && !currentModelSummary.modelComplete) {
        throw new Error('Inconsistent locked and model data.');
      }
    }
  }

  private switchToEditor(): void {
    this.previewMode = false;
    store.commit('grid/switchMode', Mode.EDIT);
    EventBus.$emit(hmbEvents.navigateFromPreview);
  }

  private showExportModal(): void {
    this.modelToExportSummary = store.getters['diagram/getCurrentModelSummary'];
    if (this.modelToExportSummary) {
      this.exportFileName =
        this.modelToExportSummary.customer?.name?.replace(/ /g, '_').toLowerCase() +
        '_' +
        this.modelToExportSummary?.location?.name?.replace(/ /g, '_').toLowerCase() +
        '_' +
        this.modelToExportSummary?.asset?.name?.replace(/ /g, '_').toLowerCase();
    }

    // reset flag values
    this.unassignedOutletsAcknowledged = false;
    this.saveOnExport = false;
    this.outletsWithoutBurners = store.getters['diagram/getOutletNotConnected'];
    (this.$refs['export-modal'] as BModal)?.show();
  }

  private hideExportModal(): void {
    (this.$refs['export-modal'] as BModal)?.hide();
  }

  private onClickSaveOnExport(): void {
    this.saveOnExport = !this.saveOnExport;
  }

  private onClickAcknowledge(): void {
    this.unassignedOutletsAcknowledged = !this.unassignedOutletsAcknowledged;
  }

  private async exportModel(): Promise<void> {
    try {
      this.isExporting = true;
      const json = await store.dispatch('diagram/exportDiagramDetails', this.saveOnExport);

      if (this.saveOnExport) {
        const modelSummary = store.getters['diagram/getCurrentModelSummary'];
        const exportedModelSummary = await store.dispatch('model/getSummaryItem', modelSummary);
        if (exportedModelSummary) {
          store.commit('diagram/setCurrentModelSummary', exportedModelSummary);
        }

        store.commit('diagram/setDiagramModified', false);
        EventBus.$emit(hmbEvents.checkForCompleteModel);
      }

      const url = window.URL.createObjectURL(new Blob([JSON.stringify(json, null, 4)]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.exportFileName + '.json');
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.appendChild(link);
      link.click();
      // cleanup
      document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.removeChild(link);

      // close export modal.
      (this.$refs['export-modal'] as BModal)?.hide();
      this.isExporting = false;

      if (this.modelToExportSummary && !this.modelToExportSummary.modelLocked) {
        // go back to Editor mode.
        this.previewMode = false;
        store.commit('grid/switchMode', Mode.EDIT);
      } else {
        this.previewMode = true;
        store.commit('grid/switchMode', Mode.PREVIEW);
      }
    } catch (e) {
      this.message = e;
    }
  }

  private toggleAcknowledgeChecked(): void {
    this.resetConfirmed = !this.resetConfirmed;
  }

  private onModalHidden(): void {
    this.resetConfirmed = false;
  }

  private async removeBurners(): Promise<void> {
    // get the burner elements in the model
    const diagramElements = store.getters['diagram/getDiagramElements'] as FlowElementModel[];
    const burnerElements = diagramElements.filter((model) => model.type === FlowElementType.BURNER);
    // remove each burner element
    burnerElements.forEach((element) => {
      // search the editorGrid for the matching burner element
      const group = store.getters['grid/editorGrid']
        .getObjects()
        .find(
          (object: fabric.Object) =>
            element.flowElementId?.id && getFlowElementId(object)?.includes(element.flowElementId?.id)
        );
      // set the selected element
      store.commit('grid/updateSelectedElements', [group]);
      store.commit('diagram/setSelectedElements', [element]);
      // disconnect and delete the selected element
      store.dispatch('grid/disconnectSelectedElements');
      store.dispatch('grid/deleteSelectedElements');
    });
    // after we're done, set selected element to null so the details panel doesn't display the last deleted burner
    store.commit('grid/updateSelectedElements', []);
    store.commit('diagram/setSelectedElements', []);
  }

  private async checkModelExists(): Promise<void> {
    this.duplicateModel = false;

    // Adjust selections for home screen
    store.commit('asset/selectSite', this.modelSite);
    store.commit('asset/selectAsset', this.modelAsset);

    const modelSummary = store.getters['diagram/getCurrentModelSummary'];
    const origAsset = modelSummary.asset.key;
    const model = await store.dispatch('model/getSummaryItem', modelSummary);

    if (model && this.originalName !== this.editorModelName) {
      this.saveAsText = 'Do you want to save this copy of the model with this new name?';
      (this.$refs['confirm-save-as-modal'] as BModal).show();
      return;
    } else if (model && (this.modelAsset as any).key !== origAsset) {
      this.saveAsText = 'Do you want to save this copy of the model with this new asset?';
      (this.$refs['confirm-save-as-modal'] as BModal).show();
      return;
    }

    if (!model) {
      // must set the key to null so that the model will not just be updated instead of create a new one
      modelSummary.key = null;
      modelSummary.exportBy = null;
      modelSummary.exportDateTime = null;
      modelSummary.published = false;
      modelSummary.publishedDateTime = null;
      modelSummary.manualSaveVersion = 0;
      modelSummary.autoSaveVersion = 0;

      const newModelSummary = await this.saveModelSummary(modelSummary);
      // make sure we have the correct model summary saved in the diagram store
      // also set diagram modified to true so that we can save the diagram elements and canvas
      store.commit('diagram/setCurrentModelSummary', newModelSummary);
      this.modelToExportSummary = newModelSummary;

      store.commit('diagram/setDiagramModified', true);
      // the model didn't exist originally, so now we will save the diagram elements and canvas
      this.onClickSave();
    } else {
      modelSummary.description = this.editorModelDescription;
      this.originalDescription = this.editorModelDescription;

      // If the model exists already, then just save the summary and update diagram store
      const newModelSummary = await this.saveModelSummary(modelSummary);
      if (newModelSummary?.name?.length > 20) {
        newModelSummary.truncatedName = newModelSummary?.name.substring(0, 19) + '...';
      } else {
        newModelSummary.truncatedName = newModelSummary?.name;
      }
      store.commit('diagram/setCurrentModelSummary', newModelSummary);
      this.modelToExportSummary = newModelSummary;

      // show a message to let the user know the summary was saved, similar to a regular save
      this.message = 'Model Summary Saved Successfully';
      (this.$refs['save-model-modal'] as BModal)?.show();
      window.setTimeout(() => {
        (this.$refs['save-model-modal'] as BModal)?.hide();
      }, 1200);
    }

    this.$bvModal.hide('edit-model-modal');
  }

  private async saveAs(): Promise<void> {
    const modelSummary = store.getters['diagram/getCurrentModelSummary'];
    modelSummary.key = null;
    modelSummary.exportBy = null;
    modelSummary.exportDateTime = null;
    modelSummary.name = this.editorModelName;
    modelSummary.description = this.editorModelDescription;
    modelSummary.asset = this.modelAsset;
    modelSummary.location = this.modelSite;
    modelSummary.published = false;
    modelSummary.publishedDateTime = null;
    modelSummary.modelLocked = false;
    modelSummary.manualSaveVersion = 0;
    modelSummary.autoSaveVersion = 0;

    if (this.siteAssetChanged) {
      await this.removeBurners();
      modelSummary.modelComplete = false;
      if (this.previewMode) {
        EventBus.$emit(hmbEvents.modelBurnerRemoved);
      }
    }

    const newModelSummary = await this.saveModelSummary(modelSummary);
    // make sure we have the correct model summary saved in the diagram store
    // also set diagram modified to true so that we can save the diagram elements and canvas
    store.commit('diagram/setCurrentModelSummary', newModelSummary);
    await store.dispatch('diagram/saveDiagramDetails', true);
    this.setSiteAndAsset();
    (this.$refs['confirm-save-as-modal'] as BModal).hide();
    this.$bvModal.hide('edit-model-modal');
    await store.dispatch('diagram/saveDiagramDetails');

    if (this.siteAssetChanged) {
      EventBus.$emit(hmbEvents.burnerAssetChange);
    }

    EventBus.$emit(hmbEvents.checkForCompleteModel);
    this.initializeCurrentModelVariables();
  }

  private async setSiteAndAsset(): Promise<void> {
    if (this.modelAsset) {
      store.commit('tuner/setCurrentAsset', this.modelAsset);
      store.commit('tuner/setCurrentSite', this.modelSite);
      store.dispatch('tuner/loadTuningHistoryList', this.modelAsset.key);
    }
  }

  private async setIsDuplicateModel(modelLoading: boolean): Promise<void> {
    if (this.editorModelType === null || !this.editorModelCustomerName) {
      return;
    }

    if (!this.editorModelName) {
      this.duplicateModel = false;
      return;
    }

    if (!this.modelSite || !this.modelAsset) {
      this.siteAssetChanged = false;
      this.duplicateModel = false;
      return;
    }

    this.siteAssetChanged =
      this.originalSiteKey !== this.modelSite.key || this.originalAssetKey !== this.modelAsset.key;

    this.duplicateModel = false;
    if (modelLoading) {
      return;
    }

    const modelSummary = {
      key: '',
      description: this.editorModelDescription,
      customer: {
        key: store.getters['asset/findCustomerByName'](this.editorModelCustomerName)?.key,
        name: this.editorModelCustomerName,
        s3Path: '',
        searchName: '',
        isMarkedForDelete: 0,
        customerLocationKeys: [''],
        createBy: ''
      },
      location: {
        key: this.modelSite.key,
        name: this.modelSite.name,
        lat: 0,
        lng: 0
      },
      asset: {
        key: this.modelAsset.key,
        name: this.modelAsset.name,
        searchName: '',
        isMarkedForDelete: 0
      },
      exportBy: '',
      updateBy: '',
      updateDateTime: null,
      createBy: '',
      name: this.editorModelName,
      isExpanded: false,
      manualSaveVersion: 0,
      autoSaveVersion: 0,
      modelType: this.modelType,
      published: false,
      hidden: false,
      markedForDelete: false
    };

    const model = await store.dispatch('model/getSummaryItem', modelSummary);
    const modelExists = model ? true : false;
    this.duplicateModel = this.siteAssetChanged
      ? modelExists
      : modelExists && this.originalName.trim() !== this.editorModelName.trim();
  }

  private async saveModelSummary(modelSummary: IModelSummary): Promise<IModelSummary> {
    const modelNameValid = await this.checkInvalidCharacter(modelSummary?.name);
    if (!modelNameValid) {
      return;
    }
    return store.dispatch('model/saveModelSummaryItem', {
      summaryItem: modelSummary,
      overwriteDuplicateModel: false,
      saveCompleteCheckOnly: false
    });
  }

  private async checkInvalidCharacter(modelName: string): Promise<boolean> {
    const regex = new RegExp('^[A-Za-z0-9][A-Za-z0-9 _\-]*$');
    const isValid = regex.test(modelName);
    if (!isValid) {
      alert('invalid model name: ' + modelName);
    }
    return isValid;
  }

  private toggleSiteDropdown(): void {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.editModelSiteSelectorDropdown as BDropdown;
    const isDropdownOpen = dropdown.visible;
    if (!isDropdownOpen) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
  }

  private toggleAssetDropdown(): void {
    // Ensure that menu actually opens.
    const dropdown = this.$refs.editModelAssetSelectorDropdown as BDropdown;
    const isDropdownOpen = dropdown.visible;
    if (!isDropdownOpen) {
      dropdown.show();
    } else {
      dropdown.hide();
    }
  }

  private selectModelSite(site: IAsset): void {
    this.modelSite = site;
    this.onModelSiteChange(site);
    this.setIsDuplicateModel(false);
  }

  private selectModelAsset(asset: IAsset): void {
    this.modelAsset = asset;
    this.setIsDuplicateModel(false);
  }

  private tryReturnHome(): void {
    const diagramModified = store.getters['diagram/getDiagramModified'];

    store.commit('grid/updateSelectedElements', []);
    store.commit('diagram/setSelectedElements', []);

    if (diagramModified) {
      EventBus.$emit(hmbEvents.showSaveModelPromptModal, 'leaveEditor');
    } else {
      this.$router.push('/');
    }
  }

  private tryReturnUtilities(): void {
    window.location.href = this.utilitiesLink;
  }
}
