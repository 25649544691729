import BaseService from './base-service';
import { FlowElementModel } from '@/view-models/flow-element-models';
import { AxiosInstance } from 'axios';
import { IModelSummary } from '@/view-models/model-summary';
import { HydraulicDetailModel } from '@/view-models/hydraulic-detail-model';

export class HydraulicModelDetailService extends BaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  public async saveModelDetail(
    customerKey: string,
    modelKey: string,
    elementList: FlowElementModel[],
    diagram: string,
    gridBoxSize: number,
    autoSave: boolean
  ): Promise<IModelSummary> {
    const model = this.getModel(customerKey, modelKey);
    const modelDetail = this.getModelDetail(model, elementList, diagram, gridBoxSize, autoSave);
    return this.httpClient.post<IModelSummary>(`model-builder/model-details`, modelDetail);
  }

  public async resetModelDetail(customerKey: string, modelKey: string): Promise<IModelSummary> {
    const model = this.getModel(customerKey, modelKey);
    return this.httpClient.post<IModelSummary>(`model-builder/model-detail-reset`, model);
  }

  public async loadModelDetail(customerKey: string, modelKey: string): Promise<HydraulicDetailModel> {
    return this.httpClient.get<HydraulicDetailModel>(`model-builder/model-detail/customer/${customerKey}/model/${modelKey}`);
  }

  public async exportModelDetail(
    customerKey: string,
    modelKey: string,
    elementList: FlowElementModel[],
    diagram: string,
    gridBoxSize: number,
    save: boolean,
    acknowledgeBurner: boolean
  ): Promise<string> {
    const model = this.getModel(customerKey, modelKey);
    const modelExport = this.getModelExport(model, elementList, diagram, gridBoxSize, save, acknowledgeBurner);
    return this.httpClient.post<string>(`model-builder/model-export`, modelExport);
  }

  private getModel(customerKey: string, modelKey: string): any {
    return {
      customer: {
        key: customerKey
      },
      key: modelKey,
    };
  }

  private getModelDetail(
    modelSummary: IModelSummary,
    elementList: FlowElementModel[],
    diagram: string,
    gridBoxSize: number,
    autoSave: boolean
  ): any {
    return {
      modelSummary,
      elementList,
      canvas: {
        diagram,
        gridBoxSize
      },
      autoSave
    };
  }

  private getModelExport(
    modelSummary: IModelSummary,
    elementList: FlowElementModel[],
    diagram: string,
    gridBoxSize: number,
    save: boolean,
    acknowledgeBurner: boolean
  ): any {
    return {
      modelSummary,
      elementList,
      canvas: {
        diagram,
        gridBoxSize
      },
      save,
      acknowledgeBurner
    };
  }
}
