
































import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import { ITuningHistoryModel } from '@/view-models/hydraulic-tuning-history';
import ResultTable from '@/components/hydraulicModelTuner/components/resultDetails/components/ResultTable.vue';
import ResultTableControls from '@/components/hydraulicModelTuner/components/resultDetails/components/ResultTableControls.vue';
import store from '@/store';

Vue.use(BootstrapVue);

@Component({
  name: 'ResultDetails',
  components: {
    ResultTable,
    ResultTableControls
  }
})
export default class ResultDetails extends Vue {
  private tunerHistory: ITuningHistoryModel;
  private highlightedBurnerCount: number = 0;
  private totalBurnerCount: number = 0;
  private maxPercentage: number = 100;
  private highlightPercent: number = -1;
  private filterHighlight: boolean = false;
  private showPercent: boolean = true;

  public created(): void {
    this.tunerHistory = store.getters['tuner/getCurrentTuningHistory'];
  }

  private setHighlightedBurnerCount(count: number): void {
    this.highlightedBurnerCount = count;
  }

  private setTotalBurnerCount(count: number): void {
    this.totalBurnerCount = count;
  }

  private setMaxPercentage(newPercentage: number): void {
    this.maxPercentage = newPercentage;
  }

  private setHighlightPercent(newPercentage: number): void {
    this.highlightPercent = newPercentage;
  }

  private toggleFilterHightlight(): void {
    this.filterHighlight = !this.filterHighlight;
  }

  private toggleShowPercent(): void {
    this.showPercent = !this.showPercent;
  }
}
