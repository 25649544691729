import BaseService from './base-service';
import { ITuningHistoryModel } from '@/view-models/hydraulic-tuning-history';
import { AxiosInstance } from 'axios';
import { IRegressionRequest } from '@/view-models/regression-request-model';
import { ITrainingCase, ITrainingCaseRequest } from '@/view-models/training-data-model';
import { ITuningResultModel, ITuningResultRequest } from '@/view-models/tuning-result-model';

export class HydraulicTuningHistoryService extends BaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  public async saveTuningHistory(tuningHistory: ITuningHistoryModel): Promise<ITuningHistoryModel> {
    return this.httpClient.post<ITuningHistoryModel>('model-tuner/tuning-history/update-tuning', tuningHistory);
  }

  public async loadTuningHistoryList(assetKey: string): Promise<ITuningHistoryModel[]> {
    return this.httpClient.get<ITuningHistoryModel[]>(`model-tuner/tuning-history/asset/${assetKey}`);
  }

  public async loadTuningHistory(assetKey: string, tuningHistoryKey: string): Promise<ITuningHistoryModel> {
    return this.httpClient.get<ITuningHistoryModel>(`model-tuner/tuning-history/asset/${assetKey}/tuningHistory/${tuningHistoryKey}`);
  }

  public async submitRegression(tuningRegression: IRegressionRequest): Promise<ITuningHistoryModel> {
    return this.httpClient.post<ITuningHistoryModel>(
      `model-tuner/tuning-history/submit-regression`,
      tuningRegression
    );
  }

  public async UploadTrainingData(tuningRegression: ITrainingCaseRequest): Promise<ITrainingCase> {
    return this.httpClient.post<ITrainingCase>(
      `training-data/training-data`,
      tuningRegression
    );
  }

  public async loadTuningResult(tuningResultRequest: ITuningResultRequest): Promise<ITuningResultModel> {
    return this.httpClient.get<ITuningResultModel>(
      `/model-tuner/tuning-result/customer/${tuningResultRequest.customerKey}/location/${tuningResultRequest.locationKey}/asset/${tuningResultRequest.assetKey}/model/${tuningResultRequest.modelKey}/tuningHistoryKey/${tuningResultRequest.tuningHistoryKey}`
    );
  }

  public async publishModelTuning(
    customerKey: string, assetKey: string, modelKey: string, tuningHistoryKey: string
  ): Promise<ITuningHistoryModel> {
    return this.httpClient.post<ITuningHistoryModel>(`model-tuner/tuning-history/publish/customerKey/${customerKey}/asset/${assetKey}/model/${modelKey}/tuningHistoryKey/${tuningHistoryKey}`);
  }
}
