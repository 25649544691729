

















































































































import Vue from 'vue';
import store from '@/store/';
import { Component } from 'vue-property-decorator';
import { IAsset } from '@/view-models/assets-view-models';
import { IModelSummary, ModelType } from '@/view-models/model-summary';
import Dropdown from '@/components/common/Dropdown.vue';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'CreateModelDialog',
  components: {
    Dropdown
  }
})
export default class extends Vue {
  private modelType: ModelType = ModelType.Air_Duct_Network;
  private modelName: string = null;
  private modelDescription: string = null;
  private modelCustomer: IAsset = null;
  private customerName: string = null;
  private modelSite: IAsset = null;
  private modelAsset: IAsset = null;
  private modelSummary: IModelSummary = null;
  private duplicateModel: boolean = false;
  private duplicateModelCheckComplete: boolean = false;
  private modelOverwriteConfirmed: boolean = false;
  private message: string = '';

  private get modelTypeValue(): string {
    if (this.modelType) {
      return (this.modelType as string).split('_').join(' ');
    }
    return 'Select Model Type';
  }

  private get modelNameError(): string {
    const firstError = this.$validator.errors.items.find((error) => error.field === 'model-name-input');
    if (HelperMethods.isNullOrUndefined(firstError)) {
      return null;
    }
    if (firstError.rule === 'alpha_num') {
      return 'Only alpha-numeric characters are allowed';
    }
    return firstError.msg;
  }

  private mounted(): void {
    this.$validator.extend('modelNameIsValid', {
      getMessage: (field) => `${field} can only contain alpha-numeric characters.`,
      validate: this.modelNameIsValid
    });
    this.checkModelExists();
  }

  private assignDescription(): void {
    if (this.modelSummary && this.modelDescription) {
      this.modelSummary.description = this.modelDescription;
    } else if (this.modelSummary) {
      this.modelSummary.description = '';
    }
  }

  private siteChanged(): void {
    this.duplicateModel = false;
    this.duplicateModelCheckComplete = false;
    this.modelOverwriteConfirmed = false;
    this.modelAsset = null;
  }

  private onModalShow(): void {
    this.modelSite = store.getters['asset/selectedSite'];
    this.modelAsset = store.getters['asset/selectedAsset'];
  }

  private onModalHidden(): void {
    this.modelName = null;
    this.modelDescription = null;
    this.modelCustomer = null;
    this.duplicateModel = false;
    this.duplicateModelCheckComplete = false;
    this.modelOverwriteConfirmed = false;
    this.$validator.reset();

    this.modelSite = store.getters['asset/selectedSite'];
    this.modelAsset = store.getters['asset/selectedAsset'];
  }

  private get formIsValid(): boolean {
    return (
      this.modelType !== null &&
      !!this.modelName &&
      !!this.modelCustomer &&
      !!this.modelSite &&
      !!this.modelAsset &&
      !!this.duplicateModelCheckComplete &&
      (!this.duplicateModel || (this.duplicateModel && this.modelOverwriteConfirmed))
    );
  }

  private async saveModelSummary(): Promise<void> {
    const modelAssetKey = this.modelAsset.key;
    const modelCustomerKey = this.modelCustomer.key;
    const modelName = this.modelSummary.name;

    const modelNameValid = await this.modelNameIsValid();
    if (!modelNameValid) {
      return;
    }
    this.setSiteAndAsset();
    store
      .dispatch('model/saveModelSummaryItem', {
        summaryItem: this.modelSummary,
        overwriteDuplicateModel: this.modelOverwriteConfirmed,
        saveCompleteCheckOnly: false
      })
      .then(
        (response) => {
          if (response.name.length > 20) {
            response.truncatedName = response.name.substring(0, 19) + '...';
          } else {
            response.truncatedName = response.name;
          }
          store.commit('diagram/setCurrentModelSummary', response);
          this.$router.push({ path: `/editor/customer/${modelCustomerKey}/asset/${modelAssetKey}/model/${modelName}`});
        })
      .catch((error) => {
        store.dispatch('error/setError', {
          error,
          errorString: 'Error Saving Summary\n',
          handleError: true,
          routeHomeAfterError: false,
        });
        return Promise.reject(error);
      });
  }

  private async setSiteAndAsset(): Promise<void> {
    if (this.modelAsset) {
      store.commit('tuner/setCurrentAsset', this.modelAsset);
      store.commit('tuner/setCurrentSite', this.modelSite);
      store.dispatch('tuner/loadTuningHistoryList', this.modelAsset.key);
    }
  }

  private async checkModelNameAndModelExist(): Promise<boolean> {
    const isValid = await this.modelNameIsValid();

    if (isValid && this.modelType !== null && this.modelCustomer && this.modelSite && this.modelAsset) {
      this.checkModelExists();
    }
    return isValid;
  }

  private modelNameIsValid(): Promise<boolean> {
    return new Promise((resolve) => {
      const regex = new RegExp('^[A-Za-z0-9][A-Za-z0-9 _\-]*$');
      const isValid = regex.test(this.modelName);
      resolve(isValid);
    });
  }

  private async checkModelExists(): Promise<void> {
    if (this.modelType === null || !this.modelName || !this.modelCustomer || !this.modelSite || !this.modelAsset) {
      return;
    }
    this.duplicateModel = false;
    this.duplicateModelCheckComplete = false;
    this.modelOverwriteConfirmed = false;

    this.modelSummary = {
      key: '',
      description: this.modelDescription,
      customer: {
        key: this.modelCustomer.key,
        name: this.modelCustomer.name,
        s3Path: '',
        searchName: '',
        isMarkedForDelete: 0,
        customerLocationKeys: [''],
        createBy: ''
      },
      location: {
        key: this.modelSite.key,
        name: this.modelSite.name,
        lat: 0,
        lng: 0
      },
      asset: {
        key: this.modelAsset.key,
        name: this.modelAsset.name,
        searchName: '',
        isMarkedForDelete: 0
      },
      exportBy: '',
      updateBy: '',
      updateDateTime: null,
      createBy: '',
      name: this.modelName,
      isExpanded: false,
      manualSaveVersion: 0,
      autoSaveVersion: 0,
      modelType: (this.modelType as any).value ? (this.modelType as any).value : this.modelType,
      currentlyPublished: false,
      published: false,
      hidden: false,
      markedForDelete: false,
      modelComplete: false
    };

    const modelExists = await store.dispatch('model/getSummaryItem', this.modelSummary);

    if (modelExists) {
      this.modelSummary = modelExists;
      this.modelSummary.description = this.modelDescription;
      this.duplicateModel = true;
    }
    this.duplicateModelCheckComplete = true;
  }

  private toggleAcknowledgeChecked(): void {
    this.modelOverwriteConfirmed = !this.modelOverwriteConfirmed;
  }

  private selectModelType(modelType: ModelType, scope: any): void {
    scope.toggleMenu();
    this.modelType = modelType;
    this.checkModelExists();
  }

  private selectModelSite(site: IAsset, scope: any): void {
    scope.toggleMenu();
    this.modelSite = site;
    this.siteChanged();
  }

  private selectModelAsset(asset: IAsset, scope: any): void {
    scope.toggleMenu();
    this.modelAsset = asset;
    this.checkModelExists();
  }

  get modelTypes(): any {
    return Object.keys(ModelType)
      .filter((k) => typeof (ModelType as any)[k] === 'string')
      .map((k) => ({ value: (ModelType as any)[k], text: k.replace(/_/g, ' ') }));
  }

  get sites(): IAsset[] {
    // Get customer as asset to use customer key to find site
    const customers = store.getters['asset/allCustomers'];
    if (customers && customers.length > 0) {
      this.modelCustomer = customers[0];
      this.customerName = this.modelCustomer?.name ?? null;
    } else {
      this.modelCustomer = null;
      this.customerName = null;
    }

    const customerKey = store.getters['asset/findCustomerByName'](this.modelCustomer?.name)?.key;
    return store.getters['asset/findSitesByParentKey'](customerKey);
  }

  get assets(): IAsset[] {
    return store.getters['asset/findAssetsBySiteKey'](this.modelSite?.key);
  }
}
