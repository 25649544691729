







































































































































import { Vue, Component } from 'vue-property-decorator';
import { BootstrapVue, BCard, BCardGroup, BCardText, BModal } from 'bootstrap-vue';
import Header from './components/header/HeaderHMT.vue';
import store from '@/store/';
import { IModelSummary } from '@/view-models/model-summary';
import Loading from '@/components/common/Loading.vue';
import EventBus from '@/components/hydraulicModelTuner/eventBus';
import tippy from 'tippy.js';

Vue.use(BootstrapVue);

@Component({
  name: 'ModelSelectorHMT',
  components: {
    BCard,
    BCardGroup,
    BCardText,
    BModal,
    Header,
    Loading
  }
})
export default class ModelSelectorHMT extends Vue {
  public isLoading: boolean = false;
  private showHidden: boolean = false;
  private showDeleteModal: boolean = false;
  private showPreviewTune: boolean = false;
  private acknowledgeDelete: boolean = false;
  private modelToDelete: IModelSummary = null;
  private hasNoData = true;
  private hasSelectedAsset = false;
  private allModels: IModelSummary[] = [];
  private selectedCompleteModel: IModelSummary = undefined;

  public mounted(): void {
    store.commit(`app/updateFF`, (this as any).flags);
  }

  public created(): void {
    EventBus.$on('changedAsset', this.loadModels);
  }

  public beforeDestroy(): void {
    EventBus.$off('changedAsset', this.loadModels);
  }

  public async loadModels(): Promise<void> {
    this.isLoading = true;
    this.hasSelectedAsset = store.getters['asset/selectedAsset'] ? true : false;
    if (this.hasSelectedAsset) {
      await store.dispatch('model/loadModelSummaryListByAsset');
      this.allModels = store.getters['model/loadedModels'];
      const models = this.allModels;
      if (models && models.length > 0) {
        if (models.every((modelSummary: IModelSummary) => modelSummary.hidden === true)) {
          this.showHidden = true;
        }
      }
      this.hasNoData = models && models.length === 0;
    } else {
      this.hasNoData = true;
    }
    this.isLoading = false;
  }

  public get filteredModels(): IModelSummary[] {
    let models: IModelSummary[] = this.allModels;
    if (!this.showHidden) {
      models = models.filter((model) => model.hidden !== true);
    }
    models.sort(this.compareLastUpdated);
    return models;
  }

  private toggleAcknowledgeChecked(): void {
    this.acknowledgeDelete = !this.acknowledgeDelete;
  }

  private toggleDropdown(bvEvt: any): void {
    // stop any more events from firing off
    if (bvEvt.target.classList.contains('show')) {
      return;
    }

    // it seems weird to fire the event twice,
    // but it will not work the first time, and
    // it will work the second time.
    bvEvt.vueTarget.show();
    bvEvt.vueTarget.show();
  }

  private navigateToEditor(record: IModelSummary): void {
    this.setSiteAndAsset();
    store.commit('model/selectModel', record);
    store.commit('diagram/setCurrentModelSummary', record);
    if (record.modelComplete) {
      this.showPreviewTune = true;
      this.selectedCompleteModel = record;
    } else {
      store.commit('app/setNewIsLoading', false);
      // tslint:disable-next-line:max-line-length
      this.$router.push({ path: `/editor/customer/${record.customer.key}/asset/${record.asset.key}/model/${record.name}`});
    }
  }

  private async setSiteAndAsset(): Promise<void> {
    const asset = store.getters['asset/selectedAsset'];
    if (asset) {
      const tunerSite = store.getters['asset/selectedSite'];
      store.commit('tuner/setCurrentSite', tunerSite);
      store.commit('tuner/setCurrentAsset', asset);
      store.dispatch('tuner/loadTuningHistoryList', asset.key);
    }
  }

  private formattedSummaryBy(summary: IModelSummary): string {
    if (summary) {
      return summary.updateBy ? summary.updateBy : summary.createBy;
    }
    return '';
  }

  private compareLastUpdated(a: IModelSummary, b: IModelSummary): number {
    const initialDate: Date = new Date(0);
    let aDate: Date = initialDate;
    let bDate: Date = initialDate;

    if (a.updateDateTime) {
      aDate = new Date(a.updateDateTime);
    } else if (a.createDateTime) {
      aDate = new Date(a.createDateTime);
    }

    if (b.updateDateTime) {
      bDate = new Date(b.updateDateTime);
    } else if (b.createDateTime) {
      bDate = new Date(b.createDateTime);
    }

    if (a.updateDateTime && b.updateDateTime === null) {
      return -1;
    }
    if (a.updateDateTime == null && b.updateDateTime) {
      return 1;
    }
    if (aDate.getTime() !== bDate.getTime()) {
      return bDate.getTime() - aDate.getTime();
    }
    return this.compareModelName(a.name, b.name);
  }

  private compareModelName(a: string, b: string): number {
    const aUpper: string = a.toUpperCase();
    const bUpper: string = b.toUpperCase();
    if (aUpper < bUpper) {
      return -1;
    }
    if (aUpper > bUpper) {
      return 1;
    }

    return 0;
  }

  private async deleteModel(record: IModelSummary): Promise<void> {
    this.modelToDelete = record;
    this.acknowledgeDelete = false;
    this.showDeleteModal = true;
  }

  private async deleteModelModal(): Promise<void> {
    this.showDeleteModal = false;

    if (this.modelToDelete) {
      const result: IModelSummary = await store.dispatch('model/deleteModelSummaryItem', {
        summaryItem: this.modelToDelete
      });
      if (result.markedForDelete) {
        this.modelToDelete.markedForDelete = true;
        this.loadModels();
      }
    }
  }

  private hideModel(record: IModelSummary): Promise<IModelSummary> {
    return store.dispatch('model/hideModelSummaryItem', {
      summaryItem: record,
      hide: !record.hidden
    });
  }

  private async previewCompleteModel(): Promise<void> {
    store.commit('diagram/initializeDiagram');
    await this.$router.push({ path:  `/editor/customer/${ this.selectedCompleteModel.customer.key}/asset/${this.selectedCompleteModel.asset.key}/model/${ this.selectedCompleteModel.name}`, query: { initialState: 'preview' } });
  }

  private tuneCompleteModel(): void {
    if (this.selectedCompleteModel) {
      this.tuneModel(this.selectedCompleteModel);
    }
  }

  private async tuneModel(record: IModelSummary): Promise<void> {
    const asset = store.getters['asset/selectedAsset'];
    if (asset) {
      this.hasSelectedAsset = true;
      const tunerSite = store.getters['asset/selectedSite'];
      store.commit('tab/initializeTab');
      store.commit('tuner/setCurrentSite', tunerSite);
      store.commit('tuner/setCurrentAsset', asset);
      store.commit('tuner/setCurrentModelSummary', record);
      store.commit('tuner/setShouldCheckAllModels', false);
      store.commit('tuner/setCurrentHistoryCheckedModels', [record.key]);

      await store.dispatch('tuner/loadTuningHistoryList', asset.key);
      this.$router.push('tuner');
    }
    this.hasSelectedAsset = false;
  }

  private setModelInfoTooltip(record: IModelSummary, index: number): void {
    const id = 'infoIcon' + index;
    const recordDescription = record.description ? record.description : 'N/A';
    const formattedSummaryBy = this.formattedSummaryBy(record);
    const styleString =
      '1px solid #4e5266;' +
      'background-color: #393c4b;' +
      'border-radius: 4px;' +
      'color: #fff;' +
      'padding: 10px;' +
      'box-shadow: 0 5px 5px 5px rgba(20,21,26,.15);' +
      'font-family: Source Sans Pro, sans-serif;' +
      'font-size: 16px;' +
      'line-height: 20px;';
    tippy(document?.querySelector('#hydraulic-model-builder')?.shadowRoot?.getElementById(id), {
      content: `<div style='${styleString}'>
          <span>
            <strong>Model description:</strong> <br>
            ${recordDescription} <br>
          </span>
          <span>
            <strong>Last updated by:</strong> <br>
            ${formattedSummaryBy}
          </span>
        </div>`,
      allowHTML: true
    });
  }
}
