import { gridConfig } from '@/assets/configs/gridConfig';
import store from '@/store';
import { ChildElementModel } from '@/view-models/child-element-model';
import { DirectionFlowModel, DirectionFlowType } from '@/view-models/direction-flow-model';
import { FlowElementModel, FlowElementType } from '@/view-models/flow-element-models';
import { fabric } from 'fabric';
import _ from 'lodash';
import * as dropZoneHelpers from './dropZoneHelper';
import { addChildLine, getCoordinates, moveDropZones, moveLine } from './dynamicAirflowHelper';
import { IGridContext } from '../gridStoreInterface';
import { svgStrings } from '../imageCache/svgStrings';
import HelperMethods from '@/shared/helper-methods';

const connected: string = 'connected';
const connectingInArrow: string = 'dynamic-in-arrow';
const connectingOutArrow: string = 'dynamic-out-arrow';
let elementsToRemove: fabric.Object[] = [];

const directionTypeIsInOrOut = (flowModel: DirectionFlowModel): boolean => {
  return flowModel.type === DirectionFlowType.INLET || flowModel.type === DirectionFlowType.OUTLET;
};

const setArrowColor = (arrow: fabric.Group, arrowColor: string): void => {
  arrow.getObjects().forEach((path: fabric.Object) => {
    if (path.fill && path.fill !== '') {
      path.fill = arrowColor;
    }
  });
  arrow.dirty = true;
};

// Helper functions for gridStore
/**
 * Creates a new element from a new flowElement and add it to the canvas
 *
 * Element naming conventions
 * 0:{flowElementId} 1:{type: Image/Group/Arrow/DropZone} 2:{arrowType}? 3:{direction.id}? 4:{Hover}?
 * @param gridContext canvas context
 * @param newFlowElement diagramStore flowElement to create element from
 * @param top image top offset
 * @param left image left offset
 * @param origAngle optional angle of original element if this element is a copy
 * @param origHFlip optional hFlip of original element if this element is a copy
 */
export const addElementToGrid = (
  gridContext: IGridContext,
  newFlowElement: FlowElementModel,
  top: number,
  left: number,
  origAngle?: number,
  origHFlip?: boolean
): void => {
  const idelchikSVG = gridContext.state.idelchikImageSVGs.get(newFlowElement.name);

  fabric.loadSVGFromString(
    newFlowElement.type === FlowElementType.IDELCHIK ? idelchikSVG ?? '' : svgStrings.burner.image ?? '',
    (imageObjects, imageOptions) => {
      // Create image svg

      const imageObject = fabric.util
        .groupSVGElements(
          imageObjects,
          {
            ...imageOptions,
            name: `${newFlowElement.flowElementId?.id} Image`,
            top,
            left,
            originY: 'top',
            originX: 'left'
          },
          '-'
        )
        .setCoords();

      // Create group for image and arrows
      const imageGroup = new fabric.Group([imageObject], {
        name: `${newFlowElement.flowElementId?.id} Group`,
        originY: 'top',
        originX: 'left',
        height: imageObject.height,
        width: imageObject.width,
        hasRotatingPoint: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        selectable: true,
        subTargetCheck: true
      });

      // Get flow directions to visualize with arrows
      const directions = dropZoneHelpers
        .getAllDirections(newFlowElement)
        ?.filter((direction: DirectionFlowModel) => directionTypeIsInOrOut(direction));

      // Use the original grid top left so that adding and moving arrows does not change the other arrows
      const groupTop = imageGroup?.top ?? 0;
      const groupLeft = imageGroup?.left ?? 0;

      let arrowCount = 0;
      directions
        ?.filter((dir) => directionTypeIsInOrOut(dir))
        ?.forEach((direction: DirectionFlowModel) => {
          // Create arrow
          fabric.loadSVGFromString(svgStrings.arrow ?? '', (arrowObjects, arrowOptions) => {
            const arrowObject = fabric.util
              .groupSVGElements(
                arrowObjects,
                {
                  ...arrowOptions,
                  angle: dropZoneHelpers.modWithNegative(360 - direction.degree + 90, 360),
                  originY: 'top',
                  originX: 'left',
                  hasControls: false,
                  lockScalingX: true,
                  lockScalingY: true,
                  lockRotation: true
                },
                '-'
              )
              .setCoords();

            // Move arrow to correct relative location
            const arrowType = direction.type === DirectionFlowType.INLET ? 'INLET' : 'OUTLET';
            arrowObject
              .set({
                name: `${newFlowElement.flowElementId?.id} Arrow ${arrowType} ${direction.id}`,
                originY: 'top',
                originX: 'left',
                top: groupTop + direction.yAxis,
                left: groupLeft + direction.xAxis
              })
              .setCoords();

            // Burners
            if (newFlowElement.type === FlowElementType.BURNER) {
              arrowObject.set('visible', false);
              arrowCount++;
              imageGroup.addWithUpdate(arrowObject).setCoords();
              gridContext.state.gridGroupArrows.set(arrowObject.name ?? '', arrowObject);
              transformAndAddNewGroup(gridContext, imageGroup, top, left, origAngle, origHFlip);
            } else {
              arrowCount++;
              // Add drop zones, when finished, will call transformAndAddNewGroup
              addDropZonesForIdelchikElements(
                gridContext,
                direction,
                arrowObject,
                arrowType,
                newFlowElement,
                imageGroup,
                top,
                left,
                directions.length,
                arrowCount,
                origAngle,
                origHFlip
              );
            }
          });
        });
    }
  );
};

/**
 * Checks if targetObject can be snapped to dropzone. If it can, creates the snap
 * by repositioning the element, making the outlet arrow invisible, and creating
 * an invisible dynamic arrow for future use.
 * @param gridContext canvas context
 * @param dropZoneObject drop zone to try to connect to
 * @param targetObject IMAGE (not group) to try to connect to drop zone
 */
const snapElement = async (
  gridContext: IGridContext,
  dropZoneObject: fabric.Object,
  targetObject: fabric.Object,
  snapTogether: boolean = true
): Promise<void> => {
  // If the dropZone and target aren't valid, return
  if (!dropZoneObject || !targetObject || isShadow(dropZoneObject) || isShadow(targetObject)) {
    return;
  }
  // get flow element for dropZoneObject and targetObject
  const dropZoneObjectId = getFlowElementId(dropZoneObject);
  const dropZoneObjectFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](
    dropZoneObjectId
  );
  const targetObjectId = getFlowElementId(targetObject);
  const targetObjectFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](targetObjectId);

  // get inlet and outlet objects and their flowElements
  const dropZoneObjectType = getObjectArrowType(dropZoneObject);
  const inletObject = dropZoneObjectType === 'INLET' ? dropZoneObject : targetObject;
  const inletObjectFlowElement = dropZoneObjectType === 'INLET' ? dropZoneObjectFlowElement : targetObjectFlowElement;
  const outletObject = dropZoneObjectType === 'OUTLET' ? dropZoneObject : targetObject;
  const outletObjectFlowElement = dropZoneObjectType === 'OUTLET' ? dropZoneObjectFlowElement : targetObjectFlowElement;

  const allGroups = gridContext.state.editorGrid.getObjects().filter((object) => object.name.includes(' Group'));
  const inletObjectId = getFlowElementId(inletObject);
  const outletObjectId = getFlowElementId(outletObject);

  const inletObjectGroup =
    inletObject.group ?? (allGroups.find((group) => getFlowElementId(group) === inletObjectId) as fabric.Group);
  const outletObjectGroup =
    outletObject.group ?? (allGroups.find((group) => getFlowElementId(group) === outletObjectId) as fabric.Group);

  // If inlet or already connected, return;
  const hasInlet =
    inletObjectFlowElement.burner ||
    dropZoneHelpers
      .getAllDirections(inletObjectFlowElement)
      ?.some((direction) => direction.type === DirectionFlowType.INLET);

  // Inlet already connected
  if (!inletObjectFlowElement || !hasInlet || inletObjectFlowElement.flowElementId?.parentId) {
    return;
  }

  // getinletArrow
  const inletArrow = inletObjectGroup?.getObjects().find((object) => object.name?.includes('Arrow INLET'));
  if (!inletArrow) {
    return;
  }

  // Check if elements already connected to each other, try and prevent circular connection
  if (outletObjectFlowElement.flowElementId?.parentId === inletObjectFlowElement.flowElementId?.id) {
    return;
  }

  // Get unconnected outlet directions
  const outletArrowDirections = dropZoneHelpers
    .getAllDirections(outletObjectFlowElement)
    ?.filter((direction) => direction.type === DirectionFlowType.OUTLET)
    ?.filter(
      (direction) =>
        // Element has not already been connected
        !outletObjectFlowElement.flowElementId?.childIdList?.some((childId) => childId.outletId === direction.id)
    );

  // Find possible outlet arrows based on unconnected outlet directions
  const possibleOutletArrows = outletObjectGroup?.getObjects()
    .filter((object) =>
        object.name?.includes('Arrow OUTLET') &&
        outletArrowDirections?.some((direction) => direction.id === getDirectionId(object))
    );
  if (!possibleOutletArrows?.length) {
    return;
  }

  const inletArrowLeft = dropZoneHelpers.getAbsoluteAttribute(gridContext, inletArrow, 'left');
  const inletArrowTop = dropZoneHelpers.getAbsoluteAttribute(gridContext, inletArrow, 'top');
  let outletArrow = null;
  let minDistance = Number.MAX_SAFE_INTEGER;

  // Find the best (closest) outlet to connect to
  possibleOutletArrows.forEach((arrow) => {
    const outletArrowLeft = dropZoneHelpers.getAbsoluteAttribute(gridContext, arrow, 'left');
    const outletArrowTop = dropZoneHelpers.getAbsoluteAttribute(gridContext, arrow, 'top');

    const distSquared = Math.pow(inletArrowLeft - outletArrowLeft, 2) + Math.pow(inletArrowTop - outletArrowTop, 2);
    // Don't take square root because that's an expensive operation.
    // We don't need to find the actual distance. We just need a distance comparison.
    if (distSquared < minDistance) {
      minDistance = distSquared;
      outletArrow = arrow;
    }
  });

  // If dropzone was an outlet, override it with the outlet's dropzone if available
  // This has already filtered out connected outlets and those with differing angles
  const outletArrowName = getDirectionId(dropZoneObject);
  if (dropZoneObjectType === 'OUTLET') {
    outletArrow = possibleOutletArrows.find((arrow) => getDirectionId(arrow) === outletArrowName);
  }
  if (!outletArrow) {
    return;
  }

  const dropZoneArrow = dropZoneObjectType === 'INLET' && inletArrow ? inletArrow : outletArrow;
  // Set targetArrow. Note if no inletArrow, inletArrow, so must be a burner, use the image instead of inletArrow
  const targetArrow = dropZoneObjectType === 'OUTLET' ? inletArrow ?? outletObject : outletArrow;

  let arrowAngleDiff = 0;
  // Rotate Idelchiks, do not rotate burners
  if (targetObjectFlowElement.type === FlowElementType.IDELCHIK && snapTogether) {
    // Set angle of target group
    const dropZoneArrowAngle = dropZoneHelpers.getAbsoluteAttribute(gridContext, dropZoneArrow, 'angle');
    const targetArrowAngle = dropZoneHelpers.getAbsoluteAttribute(gridContext, targetArrow, 'angle');
    arrowAngleDiff = dropZoneArrowAngle - targetArrowAngle;
    const rawAngle = (360 - (360 - (targetObject.group?.angle ?? 0) + arrowAngleDiff)) % 360;
    const angle = dropZoneHelpers.modWithNegative(rawAngle, 360);
    targetObject.group?.rotate(angle).setCoords();
  }

  // set position of target group
  if (targetObjectFlowElement.type === FlowElementType.IDELCHIK && snapTogether) {
    const targetArrowCoords = getCoordinates(targetArrow);
    const dropZoneArrowCoords = getCoordinates(dropZoneArrow);
    targetObject.group
      ?.set({
        top: (targetObject.group?.top ?? 0) + (dropZoneArrowCoords.y - targetArrowCoords.y),
        left: (targetObject.group?.left ?? 0) + (dropZoneArrowCoords.x - targetArrowCoords.x)
      })
      .setCoords();
  } else if (targetObjectFlowElement.type === FlowElementType.BURNER) {
    const dropZoneObjectCoords = getCoordinates(dropZoneObject);
    const targetGroupHeightWidth = dropZoneHelpers.getHeightWidth(targetObject.group);
    const targetArrowHeightWidth = dropZoneHelpers.getHeightWidth(targetArrow);

    targetObject.group
      ?.set({
        top: dropZoneObjectCoords.y - ((targetGroupHeightWidth.height ?? 0) - (targetArrowHeightWidth.height ?? 0)) / 2,
        left: dropZoneObjectCoords.x - (targetGroupHeightWidth.width ?? 0) / 2
      })
      .setCoords();
  }

  // Mark relevant objects as connected, create invisible dynamic line for later use
  if (!outletArrow[connected] && !inletArrow[connected]) {
    inletArrow?.set('visible', false);
    inletArrow[connected] = true;
    outletArrow[connected] = true;
    const dynamicArrow = addChildLine(gridContext, outletObjectGroup, inletObjectGroup, outletArrow, inletArrow);
    (inletObjectGroup as any)[connectingInArrow] = dynamicArrow.name;
    (outletObjectGroup as any)[connectingOutArrow] = dynamicArrow.name;
    // Update diagram store
    updateDiagramStore(
      gridContext,
      outletObjectGroup,
      outletArrow,
      inletObjectGroup,
      targetObject.group === outletObjectGroup,
      arrowAngleDiff
    );
    gridContext.commit('updateElementConnection', true);
  } else {
    // only align the elements
    const inArrow = gridContext.state.gridGroupArrows.get(name);
    const inLineArrowName = (inArrow as any).lineArrowName;
    const inLineArrow = gridContext.state.gridGroupArrows.get(inLineArrowName);
    inArrow?.set('visible', false);
    inLineArrow?.set('visible', false);
    const connectingOutArrowName = (inletObjectGroup as any)[connectingOutArrow];
    const outArrow = gridContext.state.gridGroupArrows.get(connectingOutArrowName);
    const outLineArrowName = (inArrow as any).lineArrowName;
    const outLineArrow = gridContext.state.gridGroupArrows.get(outLineArrowName);
    outArrow?.set('visible', false);
    outLineArrow?.set('visible', false);
    outletArrow.set('visible', true);
  }

  // Set element and arrows to highlighted color
  const targetId = targetObjectFlowElement?.flowElementId;
  const isConnected = targetId.parentId || !HelperMethods.isArrayEmpty(targetId.childIdList);
  const arrowColor = isConnected ? gridConfig.highlightStrokeColor : null;
  colorGroup(gridContext, targetObject?.group, gridConfig.highlightStrokeColor, arrowColor);

  // show connecting lines if not being snapped together
  if (!snapTogether) {
    const toLines = gridContext.state.toLines.get(`to-${targetObject.group?.name}`) ?? [];
    const fromLines = gridContext.state.fromLines.get(`from-${targetObject.group?.name}`) ?? [];
    const lines = [...toLines, ...fromLines];
    lines.forEach((line) => {
      line.set('visible', true);
    });
    moveLine(gridContext, targetObject.group, true);
  }
  moveDropZones(gridContext, targetObject.group);
  gridContext.state.editorGrid.requestRenderAll();
};

/**
 * Updates diagramStore elements for newly connected members
 * @param gridContext canvas context
 * @param parentObject parent whose outlet is connected to childObject
 * @param outletObject parent's outlet being connected
 * @param childObject child whose inlet is connected to parentObject
 * @param updateParentPosition which element's position should be updated, parent if true,
 * child if false. One element stays still while the other is snapped to it
 */
const updateDiagramStore = (
  gridContext: IGridContext,
  parentObject: fabric.Object,
  outletObject: fabric.Object,
  childObject: fabric.Object,
  updateParentPosition: boolean,
  arrowAngleDiff: number
): void => {
  const pId = getFlowElementId(parentObject.group) ?? getFlowElementId(parentObject);
  const cId = getFlowElementId(childObject.group) ?? getFlowElementId(childObject);
  const outletId = getDirectionId(outletObject);
  const parent: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](pId);
  const child: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](cId);
  if (child.flowElementId && pId && parent.flowElementId && cId) {
    // Add ids to flowElements to mark parent/child connection
    child.flowElementId.parentId = pId;
    const childElementModel: ChildElementModel = {
      childId: cId,
      outletId: outletId ? outletId : null
    };

    // If this was the last unconnected outlet or inlet for the parent/child, the element is
    // no longer unnconnected, so remove from unconnectedGroups
    const childOutletCount = dropZoneHelpers
      .getAllDirections(child)
      ?.filter((dir) => dir.type === DirectionFlowType.OUTLET).length;
    if (child.flowElementId.childIdList?.length === childOutletCount) {
      elementsToRemove.push(childObject);
    }
    if (!parent.flowElementId.childIdList) {
      parent.flowElementId.childIdList = [];
    }
    const newLength = parent.flowElementId.childIdList.push(childElementModel);
    const parentOutletCount = dropZoneHelpers
      .getAllDirections(parent)
      ?.filter((dir) => dir.type === DirectionFlowType.OUTLET).length;
    if (parent.flowElementId.parentId && newLength === parentOutletCount) {
      elementsToRemove.push(parentObject);
    }
  }

  // Update position to reflect being snapped into place
  if (updateParentPosition) {
    // Angle, orientation and position of parent object
    const parentElement = parent.directionFlowList.find((dir) => dir.type === DirectionFlowType.ELEMENT);
    if (parentElement) {
      dropZoneHelpers.updateElementDegree(parentElement, arrowAngleDiff);
      parentElement.xAxis = parentObject.left ? parentObject.left : parentElement.xAxis;
      parentElement.yAxis = parentObject.top ? parentObject.top : parentElement.yAxis;
    }
  } else {
    // Angle, orientation and position of child object
    const childElement = child.directionFlowList.find((dir) => dir.type === DirectionFlowType.ELEMENT);
    if (childElement) {
      dropZoneHelpers.updateElementDegree(childElement, arrowAngleDiff);
      childElement.xAxis = childObject.left ? childObject.left : childElement.xAxis;
      childElement.yAxis = childObject.top ? childObject.top : childElement.yAxis;
    }
  }
  store.commit('diagram/triggerStateChanged');
};

export function getDropZonesFromImage(gridContext: IGridContext, image: fabric.Object): fabric.Object[] {
  const imageId = image.name.replace(' Image', '');
  const allDropZones = gridContext.state.gridGroupDropAndLandingZones;
  const groupElementKey = Array.from(allDropZones.keys()).find((d) => d.name.replace(' Group', '') === imageId);
  return allDropZones.get(groupElementKey);
}

/**
 * Selects group by outlining it in blue and setting it's connected arrows to blue
 * @param gridContext canvas context
 * @param selectedGroup group to select
 */
export function setSelectedFlowElements(gridContext: IGridContext, selectedGroups: fabric.Group[]): void {
  // Set selectedElement
  const currentElements = selectedGroups.map((group) => getFlowElementByGroupName(gridContext, group));
  gridContext.commit('updateSelectedElements', selectedGroups);
  store.commit('diagram/setSelectedElements', currentElements);

  selectedGroups.forEach((group) => {
    // Remove default selected element blue outline
    group.set('borderColor', 'transparent');
    // Set colors to selected
    const matchingFlowElement = currentElements.find((flowElement) =>
      group.name.includes(flowElement?.flowElementId?.id)
    );

    const isConnected =
      matchingFlowElement?.flowElementId?.parentId ||
      !HelperMethods.isArrayEmpty(matchingFlowElement?.flowElementId?.childIdList);

    const arrowColor = isConnected ? gridConfig.highlightStrokeColor : null;
    colorGroup(gridContext, group, gridConfig.highlightStrokeColor, arrowColor);
  });
  gridContext.state.editorGrid.requestRenderAll();
}

/**
 * Deselects group by returning its outline and connected arrows to their original color
 * @param gridContext canvas context
 * @param deselectedGroup group to deselect
 */
export function setDeselectedFlowElements(gridContext: IGridContext, deselectedGroups: fabric.Group[]): void {
  // Set selectedElement null
  gridContext.commit('updateSelectedElements', []);
  store.commit('diagram/setSelectedElements', []);

  deselectedGroups.forEach((deselectedGroup) => {
    // Get image color by checking if required fields are populated
    const currentElement = getFlowElementByGroupName(gridContext, deselectedGroup);
    const isPopulated = gridContext.rootGetters['diagram/isElementPopulated'](currentElement);
    const imageColor = isPopulated ? gridConfig.baseStrokeColor : gridConfig.invalidFieldsStrokeColor;
    // Set colors to deselected
    colorGroup(gridContext, deselectedGroup, imageColor, gridConfig.baseStrokeColor);
  });
}

/**
 * Get's the top, left, bottom, and right-most position bounding the provided coords
 * @param aCoords coords to find the bounds of
 * @param offset offset to pad bounding box with
 */
export function getBoundingPositions(
  aCoords: {
    bl: fabric.Point;
    br: fabric.Point;
    tl: fabric.Point;
    tr: fabric.Point;
  },
  offset?: number
): { left: number, right: number, top: number, bottom: number } {
  const xCoordinates = [aCoords?.tl.x ?? 0, aCoords?.tr.x ?? 0, aCoords?.bl.x ?? 0, aCoords?.br.x ?? 0];
  const yCoordinates = [aCoords?.tl.y ?? 0, aCoords?.tr.y ?? 0, aCoords?.bl.y ?? 0, aCoords?.br.y ?? 0];

  const leftmost = Math.min(...xCoordinates) - (offset ?? 0);
  const rightmost = Math.max(...xCoordinates) + (offset ?? 0);
  const topmost = Math.min(...yCoordinates) - (offset ?? 0);
  const bottommost = Math.max(...yCoordinates) + (offset ?? 0);

  return { left: leftmost, right: rightmost, top: topmost, bottom: bottommost };
}

/**
 * Handler for objects moving on or over canvas. Checks for hovering over other elemnts to display drop zones
 * or hover landing zones
 * @param gridContext canvas context
 */
export function onObjectMoving(gridContext: IGridContext): boolean {
  // If there's been no movement, or is no valid target, return
  if (!gridContext.state.isMouseDown || !gridContext.state.isObjectMoved) {
    return false;
  }
  gridContext.commit('setIsObjectMoved', false);
  let replacedDropZones = false;
  const targetObject = getTargetObject(gridContext);
  if (!targetObject) {
    return false;
  }
  // See if we need to make any drop zones visible/invisble or hovered
  const selectedDiagramElements = gridContext.rootGetters['diagram/getSelectedElements'];
  if (HelperMethods.isArrayEmpty(selectedDiagramElements) || selectedDiagramElements.length > 1) {
    return false;
  }
  const selectedDiagramElement = selectedDiagramElements[0];
  gridContext.state.unconnectedGroups.forEach((groupObject) => {
    const currentElement = getFlowElementByGroupName(gridContext, groupObject);
    if (targetObject && currentElement !== selectedDiagramElement) {
      const replaced = dropZoneHelpers.addDropZonesForGroupsInBounds(gridContext, groupObject, targetObject);
      replacedDropZones = replacedDropZones || replaced;
    }
  });

  // If dropZones have been replaced and we're not already rendering (this is shadow i.e. selectedElement)
  // Then re-render
  if (replacedDropZones && !gridContext.state.selectedElements.includes(targetObject)) {
    gridContext.state.editorGrid.requestRenderAll();
    return true;
  }
  return false;
}

/**
 * Handler for objects dropped on canvas or stopped moving on canvas. Removes any visible drop zones
 * or hover landing zones. Also checks if select element can connect to any dropzones, if so, makes the snap
 * @param gridContext canvas context
 */
export function onObjectMoved(gridContext: IGridContext): boolean {
  let needToRender = false;
  // Return if no valid target
  const targetObject = getTargetObject(gridContext);
  if (!targetObject) {
    return false;
  }
  elementsToRemove = [];
  // Check if there are any elements that can be connected because the target object
  // is over a valid dropzone and the pair cooresponds to a valid inlet-outlet match
  gridContext.state.unconnectedGroups.forEach((groupObject) => {
    gridContext.state.gridGroupDropAndLandingZones.get(groupObject)?.forEach((dropZoneObject: fabric.Object) => {
      if (targetObject) {
        const canConnect = dropZoneHelpers.canConnectArrows(gridContext, dropZoneObject, targetObject);
        const isIntersecting = dropZoneHelpers.intersectsWithObject(
          gridContext,
          dropZoneObject,
          targetObject,
          true,
          false
        );
        if (canConnect && isIntersecting && dropZoneObject) {
          // Snap here
          snapElement(gridContext, dropZoneObject, targetObject);
          needToRender = true;
        }
      }
      if (dropZoneObject.visible) {
        // set all visible dropzones to invisible
        dropZoneObject.set('visible', false);
        needToRender = true;
      }
    });
  });
  _.pull(gridContext.state.unconnectedGroups, ...elementsToRemove);
  if (needToRender) {
    // Zeroing and resetting width removes an odd artifact when not visible
    gridContext.state.editorGrid.requestRenderAll();
    return true;
  }
  return false;
}

/**
 * Gets relevant target object, selectedElement if a shadow, otherwise, gets the selectElement's image
 * @param gridContext canvas context
 */
function getTargetObject(gridContext: IGridContext): fabric.Object {
  let targetObject: fabric.Object = null;
  // If there is a shadow, use that
  const selectedElements = gridContext.state.selectedElements;
  if (HelperMethods.isArrayEmpty(selectedElements) || HelperMethods.isNullOrUndefined(selectedElements[0])) {
    return targetObject;
  }
  const selectedElement = selectedElements[0];
  if (isShadow(selectedElement)) {
    targetObject = selectedElement;
  } else if (selectedElement.isType('group')) {
    // If there is no shadow, but the selectedElement is a group, use the group's image
    targetObject = (selectedElement as fabric.Group).getObjects().find((object) => getObjectType(object) === 'Image');
  }
  return targetObject;
}

/**
 * Checks if object is a shadow
 * @param object
 */
export const isShadow = (object: fabric.Object): boolean =>
  [gridConfig.idelchikShadowName, gridConfig.burnerShadowName].includes(object?.name ?? '');

/**
 * Starts pan by setting handlers to track pan movement and pan stop
 * @param gridContext canvas context
 * @param event pan event
 */
export function startPan(gridContext: IGridContext, event: any): void {
  let x0 = event.offsetX;
  let y0 = event.offsetY;
  function continuePan(cEv: any) {
    const x = cEv.offsetX;
    const y = cEv.offsetY;

    if (x0 && y0 && x && y) {
      gridContext.state.editorGrid.relativePan(new fabric.Point(x - x0, y - y0));
    }
    x0 = x;
    y0 = y;
  }
  function stopPan() {
    window.removeEventListener('mousemove', continuePan);
    window.removeEventListener('mouseup', stopPan);
  }

  window.addEventListener('mousemove', continuePan);
  window.addEventListener('mouseup', stopPan);
}

/* Methods to decompose an object's name into its parts */

/** Uses object's name to get flowElementId, cooresponds to this object's flowElement in diagramStore */
export const getFlowElementId = (groupObject: fabric.Object): string => groupObject?.name?.split(' ')[0];

/** Uses object's name to get type, one of Image/Group/Arrow/DropZone */
export const getObjectType = (groupObject: fabric.Object): string => groupObject?.name?.split(' ')[1];

/** Uses object's name to get arrowType if present, one of INLET/OBJECT */
export const getObjectArrowType = (groupObject: fabric.Object): string => groupObject?.name?.split(' ')[2];

/** Uses object's name to get direction id if present, cooresponds to the flowElement's directionModelId */
export const getDirectionId = (groupObject: fabric.Object): string => groupObject?.name?.split(' ')[3];

/** Uses object's name to get hover if present, true if this is a hover landing zone, otherwise false */
export const getIsHover = (groupObject: fabric.Object): boolean => {
  const objectNameArray = groupObject?.name?.split(' ');
  return objectNameArray?.length === 5 && objectNameArray[4] === 'Hover';
};

/**
 * Gets diagramStore flowElement for this group
 * @param gridContext canvas context
 * @param group group to obtain flowElement from
 */
export function getFlowElementByGroupName(gridContext: IGridContext, group: fabric.Group): FlowElementModel {
  const elementId = getFlowElementId(group);
  return gridContext.rootGetters['diagram/getElementById'](elementId);
}

/**
 * Adds a drop zone and cooresponding hover landing zone.
 * If this is for the group's last arrow, transforms group and adds it to canvas
 * @param gridContext canvas context
 * @param direction DirectionFlowModel cooresponding to these drop zone
 * @param arrowObject arrow cooresponding to these drop zones
 * @param arrowType type of arrow INLET or OUTLET
 * @param newFlowElement diagramStore flowElement for this group
 * @param group group to add drop zone to
 * @param top image top offset
 * @param left image left offset
 * @param directionsLength number of total directions for this group, used to determine when to finish and render
 * @param arrowCount current number of arrows added to this group, used to determine when to finish and render
 * @param origAngle optional angle of original element if this element is a copy
 * @param origHFlip optional hFlip of original element if this element is a copy
 */
const addDropZonesForIdelchikElements = (
  gridContext: IGridContext,
  direction: DirectionFlowModel,
  arrowObject: fabric.Object,
  arrowType: string,
  newFlowElement: FlowElementModel,
  group: fabric.Group,
  top: number,
  left: number,
  directionsLength: number,
  arrowCount: number,
  origAngle?: number,
  origHFlip?: boolean
): void => {
  gridContext.state.gridGroupArrows.set(arrowObject.name ?? '', arrowObject);
  group.addWithUpdate(arrowObject);
  arrowObject.calcCoords();

  // Calculates correct dropzone position relative to inlet/outlet arrow
  const reverseArrow = direction.type === DirectionFlowType.INLET ? 180 : 0;
  const ySign = 0 - Math.sin((((direction.degree + reverseArrow) % 360) * Math.PI) / 180);
  const xSign = Math.cos((((direction.degree + reverseArrow) % 360) * Math.PI) / 180);

  const bounds = getBoundingPositions(arrowObject.aCoords);
  const dropZonetop = (bounds.top + bounds.bottom) / 2;
  const dropZoneleft = (bounds.left + bounds.right) / 2;

  const canvas = gridContext.state.editorGrid;

  // Load unhovered drop zone image
  fabric.loadSVGFromString(svgStrings.dropZone ?? '', (dropZoneObjects, dropZoneOptions) => {
    const dropZoneObject = fabric.util.groupSVGElements(dropZoneObjects, {
      ...dropZoneOptions,
      name: `${newFlowElement.flowElementId?.id} DropZone ${arrowType} ${direction.id}`,
      top: dropZonetop + 2.5 * ySign * (arrowObject?.height ?? 0),
      left: dropZoneleft + 2.5 * xSign * (arrowObject?.width ?? 0),
      originX: 'center',
      originY: 'center',
      // Arrow image is rotated + 90, so we undo that
      angle: dropZoneHelpers.modWithNegative((arrowObject?.angle ?? 0) - 90, 360),
      hasRotatingPoint: false,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      visible: false,
      selectable: false
    });

    // Load hover landing zone image
    fabric.loadSVGFromString(svgStrings.landingZone ?? '', (landingZoneObjects, landingZoneOptions) => {
      const landingZoneObject = fabric.util.groupSVGElements(landingZoneObjects, {
        ...landingZoneOptions,
        name: `${newFlowElement.flowElementId?.id} DropZone ${arrowType} ${direction.id} Hover`,
        top: dropZonetop + 2.5 * ySign * (arrowObject?.height ?? 0),
        left: dropZoneleft + 2.5 * xSign * (arrowObject?.width ?? 0),
        originX: 'center',
        originY: 'center',
        // Arrow image is rotated + 90, so we undo that
        angle: dropZoneHelpers.modWithNegative((arrowObject?.angle ?? 0) - 90, 360),
        hasRotatingPoint: false,
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        visible: false,
        selectable: false
      });

      // Add to maps tracking objects
      const dropAndLandingZone = gridContext.state.gridGroupDropAndLandingZones.get(group) ?? [];
      dropAndLandingZone.push(dropZoneObject, landingZoneObject);
      gridContext.state.gridGroupDropAndLandingZones.set(group, dropAndLandingZone);

      const groupTransform = group.calcTransformMatrix();
      const invertedGroupTransform = fabric.util.invertTransform(groupTransform);
      const desiredTransform = fabric.util.multiplyTransformMatrices(
        invertedGroupTransform,
        dropZoneObject.calcTransformMatrix()
      );

      (dropZoneObject as any).relationship = desiredTransform;
      (landingZoneObject as any).relationship = desiredTransform;

      canvas.add(dropZoneObject);
      canvas.add(landingZoneObject);
      moveDropZones(gridContext, group);

      if (arrowCount === directionsLength) {
        // after adding last drop zone, add the group to the grid
        transformAndAddNewGroup(gridContext, group, top, left, origAngle, origHFlip);
      }
    });
  });
};

/**
 * Manipulates group before adding it to canvas.
 * Scales and repositions group if the canvas has been zoomed
 * Rotates and flips group if origAngle or origHFlip are provided, which occurs when this is a copy of another element
 * @param gridContext canvas context
 * @param group group to transform and add to canvas
 * @param top image top offset
 * @param left image left offset
 * @param origAngle optional angle of original element if this element is a copy
 * @param origHFlip optional hFlip of original element if this element is a copy
 */
const transformAndAddNewGroup = (
  gridContext: IGridContext,
  group: fabric.Group,
  top: number,
  left: number,
  origAngle?: number,
  flipY?: boolean
): void => {
  // Adjust group if angle and flip are provided, which occurs when this is a copied element
  group.set('flipY', flipY ?? false).setCoords();
  if (origAngle) {
    group.rotate(360 - origAngle).setCoords();
  }

  // Scale the element and reposition if the grid has been zoomed in or out
  const movementSize = gridContext.state.editorGridBoxSize / gridConfig.movementFactor;
  const zoomLevel = gridContext.getters.editorGridBoxSize / gridConfig.defaultGridBoxSize;

  const topDiff = top - (group?.top ?? 0);
  const newTop = (group?.top ?? 0) + topDiff * (1 - zoomLevel);
  const leftDiff = left - (group?.left ?? 0);
  const newLeft = (group?.left ?? 0) + leftDiff * (1 - zoomLevel);
  group.set({
    top: Math.round(newTop / movementSize) * movementSize,
    left: Math.round(newLeft / movementSize) * movementSize
  });
  // Update coords with scaled values;
  group.setCoords();
  group.getObjects().forEach((object) => object.setCoords());

  // Add to maps tracking groups
  gridContext.state.gridGroups.set(group.name ?? '', group);
  gridContext.state.unconnectedGroups.push(group);

  // Add object and set it active
  gridContext.state.editorGrid.add(group);
  gridContext.state.editorGrid.setActiveObject(group);
};

/**
 * Sets image and arrow color for provided group to imageColor and arrowColor respectively
 * @param gridContext canvas context
 * @param group group to recolor
 * @param imageColor new color of image
 * @param arrowColor new color of arrows
 */
export const colorGroup = (
  gridContext: IGridContext,
  group: fabric.Group,
  imageColor: string,
  arrowColor?: string
): void => {
  const selectedImage = group.getObjects().find((object) => getObjectType(object) === 'Image');
  if (selectedImage) {
    // Change image outline color
    const imageGroup = selectedImage as fabric.Group;
    imageGroup.getObjects().forEach((imagePath: fabric.Object) => {
      if (imagePath.stroke && imagePath.stroke !== '') {
        imagePath.stroke = imageColor;
        group.dirty = true;
      }
    });

    if (arrowColor) {
      // Change color on static arrows
      group
        .getObjects()
        .filter((object) => getObjectType(object) === 'Arrow')
        .forEach((arrow) => {
          setArrowColor(arrow as fabric.Group, arrowColor);
        });

      // Change color on attached connected arrows
      const toLines = gridContext.state.toLines.get(`to-${group?.name}`) ?? [];
      const fromLines = gridContext.state.fromLines.get(`from-${group?.name}`) ?? [];
      const lines = [...toLines, ...fromLines];

      // Color the line segment and triangle portion of the dynamic arrow
      if (lines && lines.length > 0) {
        lines.forEach((line) => {
          line.fill = arrowColor;
          line.stroke = arrowColor;
          line.dirty = true;
          const lineArrow = gridContext.state.lineArrows.get((line as any).lineArrowName);
          if (lineArrow) {
            lineArrow.fill = arrowColor;
            lineArrow.stroke = arrowColor;
            lineArrow.dirty = true;
          }

          // Color the static outlet arrow (inlet arrows are made invisible between connect elements)
          const toArrow = gridContext.state.gridGroupArrows.get((line as any).fromArrowName);
          if (toArrow) {
            setArrowColor(toArrow as fabric.Group, arrowColor);
            toArrow.group.dirty = true;
            gridContext.state.editorGrid.requestRenderAll();
          }
        });
      }
    }
  }
};

/**
 * Copies and connects arrows amongst newly copied flow elements in the same pattern as
 * their original sources' arrows. The copying is performed in a top-down approach in which
 * all the outlet dropzones are cycled through and connected to their corresponding target.
 * @param gridContext canvas context
 * @param newToOldElementsMap a mapping of the source elements to their copies
 */
export const copyArrowsFromSelectedElements = (
  gridContext: IGridContext,
  newToOldElementsMap: Map<fabric.Group, fabric.Group>
): void => {
  const origElements = Array.from(newToOldElementsMap.keys());
  const copiedElements = Array.from(newToOldElementsMap.values());

  const origFlowElements = origElements.map((origElem) => {
    const id = getFlowElementId(origElem);
    return gridContext.rootGetters['diagram/getElementById'](id);
  });

  newToOldElementsMap.forEach((copiedElement: fabric.Group, originalElement: fabric.Group) => {
    const origOutletDropZones = gridContext.state.gridGroupDropAndLandingZones.get(originalElement).filter((elem) => {
      return getObjectArrowType(elem) === 'OUTLET';
    });
    const copiedOutletDropZones = gridContext.state.gridGroupDropAndLandingZones.get(copiedElement).filter((elem) => {
      return getObjectArrowType(elem) === 'OUTLET';
    });

    // loop through orignal source drop zones to see if they are connected to another element
    origOutletDropZones.forEach((origDropZone) => {
      const origId = getFlowElementId(origDropZone);
      const origFlowElement: FlowElementModel = gridContext.rootGetters['diagram/getElementById'](origId);
      const origDirectionId = getDirectionId(origDropZone);

      // child elements are the downstream elements that this object is connected to
      const origChildElementIds = origFlowElement.flowElementId.childIdList;
      // evaluate each downstream element to determine connection
      if (HelperMethods.isArrayEmpty(origChildElementIds)) {
        return;
      }
      // check if the child element is part of the copied group. If so, connect together.
      const origDropZoneChild = origChildElementIds.find((childId) => childId.outletId === origDirectionId);
      if (HelperMethods.isNullOrUndefined(origDropZoneChild)) {
        return;
      }
      const childFlowElement = gridContext.rootGetters['diagram/getElementById'](origDropZoneChild.childId);
      if (origFlowElements.includes(childFlowElement)) {
        const correspondingTarget: fabric.Group = copiedElements.find((copiedElem) => {
          return copiedElem.name.startsWith(childFlowElement.flowElementId.id);
        });
        // connect corresponding copied elements together with line
        const correspondingImage = correspondingTarget.getObjects().find((o) => {
          return getObjectType(o) === 'Image';
        });
        const copiedDropZone = getCorrespondingCopiedDropZone(origDropZone, copiedOutletDropZones);
        snapElement(gridContext, copiedDropZone, correspondingImage, false);
      }
    });
  });
};

const getCorrespondingCopiedDropZone = (
  origDropZone: fabric.Object,
  copiedDropZones: fabric.Object[]
): fabric.Object => {
  const origNameFirstSpace = origDropZone.name.indexOf(' ');
  const origNameSubString = origDropZone.name.substring(origNameFirstSpace);

  return copiedDropZones.find((copiedZone) => {
    const copyNameFirstSpace = copiedZone.name.indexOf(' ');
    const copyNameSubstring = copiedZone.name.substring(copyNameFirstSpace);
    return copyNameSubstring === origNameSubString;
  });
};
