import { AxiosInstance } from 'axios';
import HttpClient from './http-client';

export default class BaseService {
  protected httpClient: HttpClient;

  constructor(
    private readonly base: string,
    private readonly prefix: string,
    private readonly http: AxiosInstance,
    private readonly path: string = 'api'
  ) {
    this.base = base;
    this.prefix = prefix || '';
    this.path = path != null && typeof path === 'string' ? path : 'api';
    this.http = http;
    this.http.defaults.baseURL = this.base;
    this.httpClient = new HttpClient(
      this.http,
      this.getBaseUri()
    );
  }

  protected getBaseUri(): string {
    if ((this.path) && (this.prefix)) {
      return `${this.path}/${this.prefix}`;
    }
    if (this.path) {
      return `${this.path}`;
    }
    if (this.prefix) {
      return `${this.prefix}`;
    }
    return '';
  }

  protected isEmpty(str: string): boolean {
    if (!str) {
      return false;
    }
    return (str !== '');
  }
}
