import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { ITrainingCase } from '@/view-models/training-data-model';
import { ISolver } from '@/view-models/solver-model';
import { ModelType } from '@/view-models/model-summary';


export class HydraulicTuningDataService extends BaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  public async loadTrainingData(assetKey: string, modelType: ModelType): Promise<ITrainingCase[]> {
    return this.httpClient.get<ITrainingCase[]>(`/training-data/training-data-summary/${assetKey}/${modelType}`);
  }

  public async getSolvers(): Promise<ISolver[]> {
    return this.httpClient.get<ISolver[]>('model-solver/solvers');
  }

  public async updateTrainingCaseName(trainingCase: ITrainingCase): Promise<ITrainingCase> {
    const updateModel: UpdateModel = {
      modelType: trainingCase.modelType,
      assetKey: trainingCase.asset?.key,
      markedForDelete: false,
      key: trainingCase.key,
      name: trainingCase.name
    };
    return this.httpClient.put<ITrainingCase>(`/training-data/training-data`, updateModel);
  }

  public async deleteTrainingCaseName(trainingCase: ITrainingCase): Promise<ITrainingCase> {
    const updateModel: UpdateModel = {
      modelType: trainingCase.modelType,
      assetKey: trainingCase.asset?.key,
      markedForDelete: true,
      key: trainingCase.key,
      name: trainingCase.name
    };
    return this.httpClient.put<ITrainingCase>(`/training-data/training-data`, updateModel);
  }
}

interface UpdateModel {
  modelType: ModelType;
  assetKey: string;
  markedForDelete: boolean;
  key: string;
  name: string;
}
