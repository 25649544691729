import BurnerBaseService from './burner-base-service';
import { AxiosInstance } from 'axios';
import { IBurnerListServiceResponse } from '../view-models/burner-response-view-models';

export class HierarchyBuilderService extends BurnerBaseService {
  constructor(axios: AxiosInstance, url: string) {
    super(url, '', axios, '');
  }

  public async getBurnerList(assetKey: string): Promise<IBurnerListServiceResponse> {
    const url = `heater/${assetKey}/burnertree`;
    const response = (await this.httpClient.get<IBurnerListServiceResponse>(url));
    this.updateBurnerResponse(response);
    return response;
  }
}
