




















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IGridPointerPosition } from '@/view-models/grid-view-models';
import { Mode } from '@/view-models/mode';
import store from '@/store/';
import { FlowElementType } from '../../../view-models/flow-element-models';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'ElementContextToolbar'
})
export default class ElementContextToolbar extends Vue {
  private enabled: boolean = true;
  private rotateByDegree: number = 90;
  private showDeleteModal: boolean = false;

  private get selectedElements(): fabric.Object[] {
    const selected = store.getters['grid/selectedElements'];
    return HelperMethods.isNullOrUndefined(selected) ? [] : selected;
  }

  private get singleFlowElementSelected(): boolean {
    return !this.burnerSelected && !this.isConnectedIn && !this.isConnectedOut && this.elementsSelected === 1;
  }

  private get elementsSelected(): number {
    return this.selectedElements.length;
  }

  private get burnerSelected(): boolean {
    return this.selectedElements.some((element) => {
      const selectedId = element.name?.split(' ')[0];
      const selectedElement = store.getters['diagram/getElementById'](selectedId);
      return selectedElement?.type === FlowElementType.BURNER;
    });
  }

  private get isConnectedIn(): boolean {
    return this.selectedElements.some((selectedElement) => {
      return !HelperMethods.isNullOrUndefined(selectedElement['dynamic-in-arrow']);
    });
  }

  private get isConnectedOut(): boolean {
    return this.selectedElements.some((selectedElement) => {
      return !HelperMethods.isNullOrUndefined(selectedElement['dynamic-out-arrow']);
    });
  }

  private mounted(): void {
    window.addEventListener('contextmenu', this.contextMenuHandler);
    window.addEventListener('click', this.contextMenuClickHandler);
  }

  private beforeDestroy(): void {
    window.removeEventListener('contextmenu', this.contextMenuHandler);
    window.removeEventListener('click', this.contextMenuClickHandler);
  }

  private validateRotationInput(): void {
    this.rotateByDegree =
      this.rotateByDegree === null ||
      isNaN(this.rotateByDegree) ||
      this.rotateByDegree < -360 ||
      this.rotateByDegree > 360
        ? 90
        : this.rotateByDegree;
  }

  private contextMenuHandler(event: MouseEvent): void {
    event.preventDefault();
    const selectedElement: fabric.Object = this.selectedElements[0];
    if (!selectedElement) {
      return;
    }
    if (store.getters['grid/mode'] === Mode.EDIT) {
      this.enabled = true;
      this.positionMenu(event);
    } else {
      this.enabled = false;
    }
  }

  private getPosition(e: MouseEvent): IGridPointerPosition {
    let posx: number = 0;
    let posy: number = 0;

    if (e.pageX || e.pageY) {
      posx = e.offsetX;
      posy = e.offsetY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        (document?.querySelector('#hydraulic-model-builder')?.scrollLeft ?? 0) +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        (document?.querySelector('#hydraulic-model-builder')?.scrollTop ?? 0) +
        document.documentElement.scrollTop;
    }
    return {
      x: posx,
      y: posy
    };
  }

  private positionMenu(e: MouseEvent): void {
    this.$nextTick(() => {
      const menu: HTMLElement = document
        ?.querySelector('#hydraulic-model-builder')
        ?.shadowRoot?.querySelector('#context-toolbar');
      const canvas: HTMLElement = document
        ?.querySelector('#hydraulic-model-builder')
        ?.shadowRoot?.querySelector('#canvas');

      if (menu) {
        menu.classList.add('context-toolbar-active');
        const menuPosition: IGridPointerPosition = this.getPosition(e);
        const menuPositionX: number = menuPosition.x;
        const menuPositionY: number = menuPosition.y;
        const menuWidth: number = menu.offsetWidth + 4;
        const menuHeight: number = menu.offsetHeight + 4;

        if (canvas.offsetWidth < menuPositionX + menuWidth) {
          menu.style.left = canvas.offsetWidth - menuWidth + 'px';
        } else if (menuPositionX <= 0) {
          menu.style.left = 30 + 'px';
        } else {
          menu.style.left = menuPositionX + 'px';
        }

        if (canvas.offsetHeight < menuPositionY + menuHeight) {
          menu.style.top = canvas.offsetHeight - 40 + 'px';
        } else {
          menu.style.top = menuPositionY + 40 + 'px';
        }
      }
    });
  }

  private contextMenuClickHandler(event: MouseEvent): void {
    const menu: HTMLElement = document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot?.querySelector('#context-toolbar');
    if (menu) {
      const menuOptionClicked = this.menuOptionClicked(event);
      if (!menuOptionClicked) {
        const button = event.which || event.button;
        if (button === 1) {
          menu.classList.remove('context-toolbar-active');
        }
      }
    }
  }

  private menuOptionClicked(e: MouseEvent): string | boolean {
    const menu: HTMLElement = document
      ?.querySelector('#hydraulic-model-builder')
      ?.shadowRoot?.querySelector('#context-toolbar');
    const radix = 10;
    return (
      e.offsetX > parseInt(menu.style.left.split('px')[0], radix) &&
      e.offsetX < parseInt(menu.style.left.split('px')[0], radix) + (menu.offsetWidth + 4) &&
      e.offsetY > parseInt(menu.style.top.split('px')[0], radix) &&
      e.offsetY < parseInt(menu.style.top.split('px')[0], radix) + (menu.offsetHeight + 75)
    );
  }

  private deleteElement(): void {
    if (this.elementsSelected > 0) {
      store.dispatch('grid/deleteSelectedElements');
      store.commit('diagram/setDiagramModified', true);
      this.showDeleteModal = false;
      this.enabled = false;
    }
  }

  private disconnectElement(): void {
    if (this.elementsSelected > 0) {
      store.dispatch('grid/disconnectSelectedElements');
      store.commit('diagram/setDiagramModified', true);
      this.enabled = false;
    }
  }

  private rotateElement(): void {
    if (this.elementsSelected > 0) {
      store.dispatch('grid/rotateSelectedElement', { degree: Number(this.rotateByDegree) });
      store.commit('diagram/setDiagramModified', true);
    }
  }

  private flipXElement(): void {
    if (this.elementsSelected > 0) {
      store.dispatch('grid/xFlipSelectedElement');
      store.commit('diagram/setDiagramModified', true);
    }
  }

  private flipYElement(): void {
    if (this.elementsSelected > 0) {
      store.dispatch('grid/yFlipSelectedElement');
      store.commit('diagram/setDiagramModified', true);
    }
  }

  private copySelectedElements(): void {
    if (this.elementsSelected > 0) {
      store.dispatch('grid/copySelectedElements');
      store.commit('diagram/setDiagramModified', true);
      this.enabled = false;
    }
  }
}
